import React from "react";
import { Oval } from "react-loader-spinner";

export const Spinner = ({
  height = 50,
  width = 50,
  color = "#FFFFFF",
  secondaryColor = "#233876",
  strokeWidth = 2,
  strokeWidthSecondary = 2,
  containerHeight = 90,
}) => {
  return (
    <div
      className="flex align-middle justify-center mx-auto"
      style={{ height: containerHeight }}
    >
      <Oval
        height={height}
        width={width}
        color={color}
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor={secondaryColor}
        strokeWidth={strokeWidth}
        strokeWidthSecondary={strokeWidthSecondary}
      />
    </div>
  );
};
