import React, { createContext, useState, useEffect } from "react";
import {
  ObtenerElementoConfiguracion,
  ObtenerConfiguracionInventarioEmpresa,
  GuardarConfiguracion,
} from "../../../../services/InventarioService";
import { useSelector } from "react-redux";
import { ModalStatusConfiguracion } from "../modalConfiguracion.Slice";

export const ValoracionContext = createContext();

export const ValoracionProvider = ({ children }) => {
  const [valoracion, setValoracion] = useState([]);
  const empresa = useSelector((state) => state.empresa.empresa);
  const [valoracionEmpresa, setValoracionEmpresa] = useState(["23f63223-7a8f-435f-b22c-c7b5038828c0"]);
  const [isLoading, setIsLoading] = useState(false);

  const modalStatus = useSelector(
    (state) => state.modalConfiguracion.modalStatus
  );

  useEffect(() => {
    getValoracion();
  }, []);

  // useEffect(() => {
  //   if (
  //     empresa &&
  //     empresa.idEmpresa &&
  //     modalStatus === ModalStatusConfiguracion.valoracion
  //   ) {
  //     ObtenerConfiguracionInventarioEmpresa(
  //       "/api/valoracion/obtener-valoracion-empresa",
  //       empresa.idEmpresa
  //     ).then((response) => setValoracionEmpresa(response.data));
  //   }
  // }, [empresa, modalStatus]);

  useEffect(() => {
    GuardarConfiguracion("/api/valoracion/crear-valoracion-empresa", {
      idEmpresa: empresa.idEmpresa,
      idValoracion: [""],
    });
  }, [empresa]);

  const getValoracion = async () => {
    setIsLoading(true);
    try {
      const response = await ObtenerElementoConfiguracion(
        "/api/valoracion/obtener-valoracion"
      );
      setValoracion(response.data);
    } catch (error) {
      console.error("Error al obtener la trazabilidads", error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveChangesValoracion = async () => {
    const changes = {
      idEmpresa: empresa.idEmpresa,
      idValoracion: valoracionEmpresa,
    };
    await GuardarConfiguracion(
      "/api/valoracion/crear-valoracion-empresa",
      changes
    );
  };

  return (
    <ValoracionContext.Provider
      value={{
        valoracion,
        valoracionEmpresa,
        setValoracionEmpresa,
        saveChangesValoracion,
        isLoading,
      }}
    >
      {children}
    </ValoracionContext.Provider>
  );
};
