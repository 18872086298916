import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { getListV2 } from "../../services";
import { StyleInputDisabled } from "../Constantes";
import MesageError from "./MessageError";
import { useFormContext } from "react-hook-form";
import InputText from "./common/InputText";
import InputNumber from "./common/InputNumber";
import { ClipLoader } from "react-spinners";
import { Transition } from "@headlessui/react";
import axios from "axios";

const ContribuyenteUseForm = ({
  setConsumidorFinalToggle,
  ConsumidorFinalToggle,
}) => {
  const empresa = useSelector((store) => store.empresa.empresa);

  const [BuscarCliente, setBuscarCliente] = useState(false);
  const [BuscarClienteIndetificacion, setBuscarClienteIndetificacion] =
    useState(false);
  const [BuscarClienteRazonSocial, setBuscarClienteRazonSocial] = useState([]);
  const [LoadingBuscarClienteRazon, setLoadingBuscarClienteRazon] =
    useState(false);

  const [LoadingRazonOptions, setLoadingRazonOptions] = useState(false);

  const {
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
  } = useFormContext();

  const clienteTelfObs = watch("clienteTelefono");
  const clienteIdentificacionObs = watch("clienteIdentificacion");
  const clienteRazonSocialObs = watch("clienteRazonSocial");

  const permisos = useSelector((store) => store.permisosEmpresa.permisos);

  useEffect(() => {
    if (clienteIdentificacionObs === "9999999999999") {
      setConsumidorFinalToggle(true);
    }
  }, [clienteIdentificacionObs]);

  const handleOnclick = async () => {
    if (!getValues().clienteIdentificacion.trim()) return;
    await searchByIdentification(getValues().clienteIdentificacion);
  };

  const handleOnclickRazonSocial = (e) => {
    if (!LoadingBuscarClienteRazon) {
      setBuscarCliente(!BuscarCliente);
    }
  };

  const searchByIdentification = async (value) => {
    try {
      setBuscarCliente(false);
      let typeIdentification = 5;

      if (!value) return setValue("clienteIdentificacion", "");

      if (value.length === 10) typeIdentification = 1;
      if (value.length === 13) {
        value = value.substr(0, 10);
        typeIdentification = 2;
      }

      setBuscarClienteIndetificacion(true);

      let data;

      if (permisos.some((s) => s.codigo === "36")) {
        const res = await axios.get(
          `api/Contribuyente/ObtenerContribuyenteBaseCatrastoForm?idEmpresa=${
            empresa.idEmpresa
          }&identificacion=${value.trim()}&tipoIdentificacion=${typeIdentification}}`
        );

        data = res.data.data;
      } else {
        const res = await getListV2(
          empresa,
          "SearchContribuyenteForForm",
          `?identificacion=${value.trim()}&tipoIdentificacion=${typeIdentification}`
        );

        data = res;
      }

      setValue("clienteRazonSocial", data.razonSocial);
      setValue("clienteDireccion", data.direccion);
      setValue("clienteEmail", data.correo);
      setValue("clienteTelefono", data.telefonoCelular || "");
      setValue("idContribuyente", data.idContribuyente);
    } catch (err) {
      setValue("clienteRazonSocial", "");
      setValue("clienteDireccion", "");
      setValue("clienteEmail", "");
      setValue("clienteTelefono", "");
    } finally {
      handleSubmit();
      setBuscarClienteIndetificacion(false);
    }
  };

  useEffect(() => {
    const timeOutId = setTimeout(
      () => handleSearchRazonSocial(clienteRazonSocialObs),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [clienteRazonSocialObs]);

  const handleSearchRazonSocial = async (param) => {
    try {
      setLoadingRazonOptions(true);
      if (param) {
        if (param.trim() !== "") {
          if (param.trim().length > 2) {
            const data = await getListV2(
              empresa,
              "GetContribuyenteByRazonSocial",
              `?razonSocial=${param.trim()}&isActive=1&empresaId=${
                empresa.idEmpresa
              }`
            );
            setBuscarClienteRazonSocial(data._embedded);
          }
        }
      } else {
        setBuscarClienteRazonSocial([]);
      }
    } finally {
      setLoadingRazonOptions(false);
    }
  };

  const handleOnClickCliente = async (cliente, index) => {
    setBuscarCliente(false);
    setBuscarClienteRazonSocial([]);
    setValue("clienteRazonSocial", cliente.razonSocial);
    await searchByIdentificacionOpciones(cliente.identificacion);
    setLoadingBuscarClienteRazon(false);
  };

  const searchByIdentificacionOpciones = async (value) => {
    try {
      setBuscarCliente(false);
      let typeIdentification = 5;

      if (!value) return setValue("clienteIdentificacion", "");

      if (value.length === 10) typeIdentification = 1;
      if (value.length === 13) {
        value = value.substr(0, 10);
        typeIdentification = 2;
      }

      setLoadingBuscarClienteRazon(true);

      let data;

      const res = await getListV2(
        empresa,
        "SearchContribuyenteForForm",
        `?identificacion=${value.trim()}&tipoIdentificacion=${typeIdentification}`
      );

      data = res;

      if (data.idTipoIdentificacion === 2) {
        data.identificacion = data.identificacion + "001";
      }
      setValue("clienteIdentificacion", data.identificacion);
      setValue("clienteRazonSocial", data.razonSocial);
      setValue("clienteDireccion", data.direccion);
      setValue("clienteEmail", data.correo);
      setValue("clienteTelefono", data.telefonoCelular || "");
      setValue("idContribuyente", data.idContribuyente);
    } catch (err) {
      setValue("clienteRazonSocial", "");
      setValue("clienteDireccion", "");
      setValue("clienteEmail", "");
      setValue("clienteTelefono", "");
    } finally {
      handleSubmit();
      setLoadingBuscarClienteRazon(false);
    }
  };

  const searchByRazonSocial = async (value) => {
    try {
      if (!value) return setValue("clienteRazonSocial", "");
      setLoadingBuscarClienteRazon(true);
      const data = await getListV2(
        empresa,
        "GetContribuyenteByRazonSocial",
        `?razonSocial=${value.trim()}&isActive=1&empresaId=${empresa.idEmpresa}`
      );

      const contribuyente = data._embedded[0];

      setValue("clienteRazonSocial", contribuyente.razonSocial);
      setValue("clienteIdentificacion", contribuyente.identificacion);
      setValue("clienteDireccion", contribuyente.direccion);
      setValue("clienteEmail", contribuyente.correo);
      setValue("clienteTelefono", contribuyente.telefonoCelular || "");
      setValue("idContribuyente", contribuyente.idContribuyente);
    } catch (err) {
      // setValue("clienteIdentificacion", "");
      // setValue("clienteDireccion", "");
      // setValue("clienteEmail", "");
      // setValue("clienteTelefono", "");
    } finally {
      setLoadingBuscarClienteRazon(false);
    }
  };

  useEffect(() => {
    if (ConsumidorFinalToggle) {
      setValue("clienteRazonSocial", "CONSUMIDOR FINAL");
      setValue("clienteIdentificacion", "9999999999999");
      setValue("clienteDireccion", "dasasasasasasasasasasasasas");
      setValue("clienteEmail", "saddsadawadssdasdadssaddas");
      setValue("clienteTelefono", "0999999999");
    } else {
      setValue("clienteRazonSocial", "");
      setValue("clienteDireccion", "");
      setValue("clienteIdentificacion", "");
      setValue("clienteEmail", "");
      setValue("clienteTelefono", "");
    }
  }, [ConsumidorFinalToggle]);

  const [isOpen, setIsOpen] = useState(true);
  return (
    <div className="w-full ">
      {ConsumidorFinalToggle ? (
        <>
          <div className="border  rounded-lg p">
            <div className="text-sm py-1.5  px-2 text-left bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-t-lg font-medium leading-6 text-white flex justify-between">
              <h3>Contribuyente / Cliente</h3>

              <div className="flex space-x-1">
                <h3>Consumidor final</h3>
                <input
                  className="ml-2"
                  type="checkbox"
                  name="consumidorFinal"
                  checked={ConsumidorFinalToggle}
                  onChange={(e) => setConsumidorFinalToggle(e.target.checked)}
                  id="consumidorFinal"
                />
                <svg
                  onClick={() => setIsOpen(!isOpen)}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 my-auto"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </div>
            </div>

            <Transition
              show={isOpen}
              enter="transition ease-out duration-300" // Aumenta la duración de entrada
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-300" // Aumenta la duración de salida
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className=" grid lg:grid-cols-5 gap-2 md:grid-cols-3 grid-cols-1 p-2">
                {/* <div className="grid md:grid-cols-2 md:gap-1"> */}
                <div className="relative  w-full  ">
                  <div className="relative z-0  w-full ">
                    <label className="block text-[0.813rem] font-medium text-black dark:text-white">
                      RUC / Cédula: *{" "}
                    </label>
                    <input
                      type="text"
                      className={StyleInputDisabled}
                      name="clienteIdentificacion"
                      value={"9999999999999"}
                      disabled
                    />
                  </div>
                </div>
                <div className="relative z-0  w-full  ">
                  <label
                    htmlFor="company-website"
                    className="block text-[0.813rem] font-medium text-black dark:text-white"
                  >
                    {" "}
                    Razón social: *{" "}
                  </label>
                  <input
                    type="text"
                    className={StyleInputDisabled}
                    value={"CONSUMIDOR FINAL"}
                    disabled
                  />
                </div>
                {/* </div> */}
                {/* <div className="grid grid-cols-6 gap-1 w-full"> */}
                <div className="">
                  <label className="block text-[0.813rem] font-medium text-black dark:text-white">
                    Correo:{" "}
                  </label>
                  <input type="text" className={StyleInputDisabled} disabled />
                </div>
                <div className=" ">
                  <label className="block text-[0.813rem] font-medium text-black dark:text-white">
                    Teléfono:{" "}
                  </label>
                  <NumberFormat
                    format="###-###-####"
                    className={StyleInputDisabled}
                    value={""}
                    disabled
                  />
                </div>
                <div className="">
                  <label className="block text-[0.813rem] font-medium text-black dark:text-white">
                    Dirección:{" "}
                  </label>
                  <input
                    type="text"
                    value={""}
                    className={StyleInputDisabled}
                    disabled
                  />
                </div>
                {/*  </div> */}
              </div>
            </Transition>
          </div>
        </>
      ) : (
        <>
          <div className="border rounded-lg border-gray-300 dark:border-gray-600">
            <div
              className="text-sm py-1.5  px-2 border text-left bg-[#003B5B] 
            dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-t-lg 
            font-medium leading-6 text-white flex justify-between"
            >
              <h3>Contribuyente / Cliente</h3>
              <div className="flex space-x-1">
                <h3>Consumidor final</h3>
                <input
                  className="ml-2"
                  type="checkbox"
                  name="consumidorFinal"
                  checked={ConsumidorFinalToggle}
                  onChange={(e) => setConsumidorFinalToggle(e.target.checked)}
                  id="consumidorFinal"
                />
                <svg
                  onClick={() => setIsOpen(!isOpen)}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className={`w-4 h-4 my-auto lg:hidden md:hidden duration-200 ${
                    isOpen ? "rotate-180" : ""
                  }`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </div>
            </div>
            <Transition
              show={isOpen}
              enter="transition ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-300"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className=" grid md:grid-cols-5 gap-3 mb-0 sm:grid-cols-1 p-2 ">
                <div className="relative  w-full group ">
                  <InputText
                    title={"RUC / Cédula: *"}
                    maxLength="20"
                    name={"clienteIdentificacion"}
                    placeholder="Digite el RUC / Cédula"
                    onChange={(e) => {
                      const valueTrim = e.target.value.trim();
                      setValue("clienteIdentificacion", valueTrim);
                    }}
                    onBlur={(e) => searchByIdentification(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        searchByIdentification(e.target.value);
                      }
                    }}
                  />
                  <button
                    onClick={handleOnclick}
                    type="button"
                    id="dropdownSearchButton"
                    data-dropdown-toggle="dropdownSearch"
                    data-dropdown-placement="bottom"
                    className="absolute inset-y-0 right-0  items-center pl-3 text-sm text-gray-900 pt-2 border border-r-0 border-t-0 border-l-0 border-b-0 border-gray-300  dark:text-gray-400 dark:border-gray-600"
                  >
                    {BuscarClienteIndetificacion ? (
                      <>
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline mr-3 w-4 h-4 animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          className="inline mr-2 -ml-1 w-5 h-5 dark:stroke-white dark:hover:stroke-blue-600  "
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          ></path>
                        </svg>
                      </>
                    )}
                  </button>

                  {errors.clienteIdentificacion ? (
                    <MesageError message={errors.clienteIdentificacion} />
                  ) : null}
                </div>
                <div className="relative  mb-0 w-full group ">
                  <InputText
                    title={"Razón social: *"}
                    maxLength="300"
                    name={"clienteRazonSocial"}
                    placeholder="Digite la Razón Social"
                    onChange={(e) => {
                      setValue("clienteRazonSocial", e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        setBuscarCliente(false);
                        searchByRazonSocial(e.target.value);
                      }
                    }}
                  />
                  <button
                    onClick={handleOnclickRazonSocial}
                    type="button"
                    id="dropdownSearchButton"
                    data-dropdown-toggle="dropdownSearch"
                    data-dropdown-placement="bottom"
                    className={`absolute inset-y-0 right-0  items-center pl-3 text-sm text-gray-900 pt-2 border border-r-0 border-t-0 border-l-0 border-b-0 border-gray-300  dark:text-gray-400 dark:border-gray-600`}
                  >
                    {LoadingBuscarClienteRazon ? (
                      <>
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline mr-3 w-4 h-4 animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          className="inline mr-2 -ml-1 w-5 h-5 dark:stroke-white dark:hover:stroke-blue-600  "
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          ></path>
                        </svg>
                      </>
                    )}
                  </button>

                  {errors.clienteRazonSocial ? (
                    <MesageError message={errors.clienteRazonSocial} />
                  ) : null}

                  <div
                    id="dropdownSearch"
                    className={`${
                      BuscarCliente ? "block" : "hidden"
                    }  bg-white rounded shadow dark:bg-gray-700 mt-1 absolute w-full`}
                    style={{ zIndex: "150" }}
                  >
                    <ul
                      className="overflow-y-auto px-3 mt-4 pb-3 h-48 text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="dropdownSearchButton"
                    >
                      {BuscarClienteRazonSocial.length === 0 &&
                      !LoadingRazonOptions ? (
                        <li key="01">
                          <div className="text-sm mt-1 text-yellow-400 dark:text-yellow-400 inline-flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                            <div style={{ position: "relative" }}>
                              <span className="font-semibold">
                                No se encontraron datos
                              </span>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {BuscarClienteRazonSocial.length > 0 &&
                        !LoadingRazonOptions &&
                        BuscarClienteRazonSocial.map((el, index) => (
                          <li key={index}>
                            <div
                              onClick={() =>
                                handleOnClickCliente(
                                  {
                                    identificacion: el.identificacion,
                                    razonSocial: el.razonSocial,
                                  },
                                  1
                                )
                              }
                              className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                                />
                              </svg>
                              <label
                                htmlFor="checkbox-item-11"
                                className="py-2 ml-2 w-full text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                              >
                                {el.identificacion} - {el.razonSocial}
                              </label>
                            </div>
                          </li>
                        ))}
                      {LoadingRazonOptions && (
                        <div className="w-full flex items-center h-full justify-center">
                          <ClipLoader color="#d8d4d4" size={"35"} />
                        </div>
                      )}
                    </ul>
                    <div className="flex items-center p-3 text-sm font-medium text-red-600 bg-gray-50 border-t border-gray-200 dark:border-gray-600  dark:bg-gray-700  dark:text-red-500 "></div>
                  </div>
                </div>
                {/* </div> */}

                {/* <div className="grid lg:grid-cols-6 md:grid-cols-6 grid-cols-1 gap-1 w-full"> */}
                <div className="relative  mb-0 w-full group ">
                  <InputText title={"Correo: *"} name={"clienteEmail"} />
                </div>
                <div className="relative  mb-2 w-full group ">
                  <InputNumber
                    name={"Teléfono:"}
                    format="###-###-####"
                    identifier={"clienteTelefono"}
                    value={clienteTelfObs}
                  />
                </div>
                <div className="relative  mb-0 w-full group ">
                  <InputText
                    title={"Dirección: *"}
                    maxLength="300"
                    name={"clienteDireccion"}
                  />
                </div>
                {/*  </div> */}
              </div>
            </Transition>
          </div>
        </>
      )}
    </div>
  );
};

export default ContribuyenteUseForm;
