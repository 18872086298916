import { useContext, useState } from "react";
import { CategoriaContext } from "../context/CategoriaContext";
import { useModalCategoriaStore } from "./ModalCategoriaContext";
import { useStore } from "zustand";
import { OffIcon } from "../../../../Icons/ButtonIcons";
import { toast } from "react-toastify";

export const ModalDesabilitarCategoria = ({ CheckedHookCategoria }) => {
  const { CancelCategorias } = useContext(CategoriaContext);
  const { turnCategoriaModalOff } = useStore(useModalCategoriaStore);
  const [isDisabling, setIsDisabling] = useState(false);

  const doCategoriaDesactive = async () => {
    setIsDisabling(true);
    await toast.promise(
      CancelCategorias(CheckedHookCategoria.elementosChecked),
      {
        pending: "Desactivando las categorias...",
        success: "¡Las categorias fueron desactivadas con éxito!",
        error: "Hubo un error al desactivar las categorias.",
      }
    );
    CheckedHookCategoria.setIsCheckedAll(false);
    CheckedHookCategoria.setElementosChecked([]);
    setIsDisabling(false);
    turnCategoriaModalOff();
  };

  return (
    <div
      onClick={() => turnCategoriaModalOff()}
      className="fixed flex items-center bg-black/25 top-0 left-0 z-[100] justify-center w-full h-full"
    >
      <section
        style={{
          padding: "38px",
          height: "fit-content",
          position: "relative",
        }}
        onClick={(e) => e.stopPropagation()}
        className="shadow-xl rounded-lg flex md:flex-row bg-white dark:bg-gray-900 flex-col md:gap-6 gap-3 items-center lg:w-[600px] md:w-[450px] w-[375px]"
      >
        <div className="text-[#003B5B] h-full items-center justify-center">
          <OffIcon w={"100"} h={"100"} />
        </div>
        <div className="flex flex-col gap-5 items-center">
          <h1 className="text-md font-semibold dark:text-white text-center">
            ¿Estás seguro que deseas desactivar todas las Categorias?
          </h1>
          <div className="flex flex-row-reverse gap-8 text-white">
            <button
              onClick={() => doCategoriaDesactive()}
              disabled={isDisabling}
              className="bg-[#003B5B] hover:bg-[#112935] p-8 pt-3 pb-3 h-8 text-[12px] w-28 flex items-center justify-center rounded-lg whitespace-nowrap"
            >
              Si, desactivalas
            </button>
            <button
              onClick={() => turnCategoriaModalOff()}
              className="bg-gray-600 p-8 pt-2 pb-2 h-8 text-[12px] hover:bg-gray-700 flex items-center justify-center w-28 rounded-lg whitespace-nowrap"
            >
              No, mantenlas
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};
