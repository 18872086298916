import React, { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ObtenerKardex } from "../../../../../services/InventarioService";

export const KardexContext = createContext();

export const KardexProvider = ({ children }) => {
  const [Kardex, setKardex] = useState([]);
  const [currentKardex, setCurrentKardex] = useState(0);
  const empresa = useSelector((state) => state.empresa.empresa);
  const [isLoading, setIsLoading] = useState(false);

  const getKardex = async (Filtros) => {
    setIsLoading(true);
    try {
      const response = await ObtenerKardex(
        "/api/kardex/obtener-lista-kardex",
        empresa.idEmpresa,
        Filtros.fechaInicio
          ? Filtros.fechaInicio
          : new Date().toISOString().split("T")[0],
        Filtros.fechaFinal
          ? Filtros.fechaFinal
          : new Date().toISOString().split("T")[0],
        Filtros.idCategoria,
        Filtros.idProducto,
        Filtros.idSeccion
      );
      setKardex(response.data);
      setCurrentKardex(0);
    } catch (err) {
      throw new Error(
        err,
        "No se pudo obtener la información de los Kardex. Inténtelo de nuevo más tarde."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const GoNextKardex = () => {
    if (currentKardex < Kardex.length - 1) {
      setCurrentKardex((prevState) => prevState + 1);
    }
  };

  const GoBackKardex = () => {
    if (currentKardex > 0) {
      setCurrentKardex((prevState) => prevState - 1);
    }
  };

  return (
    <KardexContext.Provider
      value={{
        getKardex,
        isLoading,
        Kardex,
        GoBackKardex,
        GoNextKardex,
        currentKardex,
        setCurrentKardex,
      }}
    >
      {children}
    </KardexContext.Provider>
  );
};
