import React, { useContext, useState } from "react";
import {
  StyleInput,
  StyleInputError,
  StyleLabel,
  StyleLabelError,
} from "../../../features/Constantes";
import { useForm } from "react-hook-form";
import { LargeModalForm } from "../../../features/components/LargeModalForm";
import { EstablecimientosContext } from "./EstablecimientosProvider";
import { useEffect } from "react";
import { post, PutElementV1 } from "../../../services";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export const ListaPuntoEmision = () => {
  const {
    PuntosEmisionList,
    setPuntosEmisionList,
    EstablecimientoIdPtoEmision,
    setidPTESecuencial,
  } = useContext(EstablecimientosContext);

  const [error, setError] = useState("");
  const PuntosEmisionForm = useForm("onSubmit");
  const isAdmin = useSelector((store) => store.app.user.esAdministradorSistema);

  const [EditPuntoEmision, setEditPuntoEmision] = useState("");
  const [ButtonPtoEmision, setButtonPtoEmision] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (EditPuntoEmision !== "") {
      PuntosEmisionForm.setValue("codigo", EditPuntoEmision.codigo);
      PuntosEmisionForm.setValue("descripcion", EditPuntoEmision.descripcion);
      setButtonPtoEmision(true);
    }
  }, [EditPuntoEmision]);

  useEffect(() => {
    setEditPuntoEmision("");
  }, [PuntosEmisionList]);

  const handleAddPuntoEmision = async (e) => {
    let value = PuntosEmisionList.find((el) => el.codigo === Number(e.codigo));
    if (value) {
      if (ButtonPtoEmision === false) {
        toast.info("Este punto de emision ya existe.", { autoClose: 2000 });
      } else {
        value.descripcion = e.descripcion;
        const resPut = await PutElementV1(
          `api/puntoemision/${value.idPuntoEmision}`,
          value
        );
        const toastPost = () =>
          new Promise((resolve) => setInterval(() => resolve(resPut), 2000));
        await toast.promise(toastPost(), {
          pending: {
            render() {
              setIsLoading(true);
              return "Actualizando...";
            },
            isLoading: true,
          },
          success: {
            render() {
              setIsLoading(false);
              return "Punto de emision editado con exito.";
            },
            autoClose: 2000,
            closeOnClick: true,
          },
          error: {
            render() {
              setIsLoading(false);
              return "Al a sucedido, intente nuevamente.";
            },
            autoClose: 2000,
            closeOnClick: true,
          },
        });
      }
    } else {
      e.activo = true;
      e.idEstablecimiento = EstablecimientoIdPtoEmision;

      let newitem = await post("api/puntoemision/guardarpuntoemision", e);
      const toastPost = () =>
        new Promise((resolve) => setInterval(() => resolve(newitem), 2000));
      await toast.promise(toastPost(), {
        pending: {
          render() {
            setIsLoading(true);
            return "Guardando...";
          },
          isLoading: true,
        },
        success: {
          render() {
            setPuntosEmisionList([...PuntosEmisionList, newitem.data]);
            setIsLoading(false);
            return "Punto de emision guardado con exito.";
          },
          autoClose: 2000,
          closeOnClick: true,
        },
        error: {
          render() {
            setIsLoading(false);
            return "Al a sucedido, intente nuevamente.";
          },
          autoClose: 2000,
          closeOnClick: true,
        },
      });
    }
    setError("");
    setButtonPtoEmision(false);
    PuntosEmisionForm.setValue("codigo", "");
    PuntosEmisionForm.setValue("descripcion", "");
  };

  const ToggleActivoPuntoEmision = async (index) => {
    PuntosEmisionList[index - 1].activo = !PuntosEmisionList[index - 1].activo;
    await PutElementV1(
      `api/puntoemision/${PuntosEmisionList[index - 1].idPuntoEmision}`,
      PuntosEmisionList[index - 1]
    );
    setPuntosEmisionList([...PuntosEmisionList]);
  };

  const EditarSecuencial = (idPuntoEmision) => {
    setidPTESecuencial(idPuntoEmision);
    document.getElementById("listaPuntoEmisionclose").click();
  };

  return (
    <>
      <LargeModalForm
        buttonModal={"listaPuntoEmision"}
        title="Editar puntos de emisión"
        showSubmit={true}
        submitText={null}
        setClose={true}
        onClose={() => {
          setEditPuntoEmision("");
          PuntosEmisionForm.setValue("codigo", "");
          PuntosEmisionForm.setValue("descripcion", "");
          setButtonPtoEmision(false);
        }}
      >
        <div
          style={{ marginTop: "20px", marginBottom: "20px" }}
          className="relative z-0 mb-2 w-full group"
        >
          <div>
            <label className="text-gray-800  dark:text-white block mt-15 mb-1 font-bold text-base  tracking-wide">
              Punto de emisión:
            </label>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3	 grid-cols-1 my-4">
              <div className="relative z-0 mb-2  group">
                {ButtonPtoEmision ? (
                  <input
                    type="number"
                    readOnly
                    disabled
                    className={
                      PuntosEmisionForm.formState.errors.codigo
                        ? StyleInputError
                        : StyleInput + " bg-gray-200 dark:bg-gray-600"
                    }
                    placeholder=" "
                    id="codigo"
                    name="codigo"
                    {...PuntosEmisionForm.register("codigo", {})}
                  />
                ) : (
                  <input
                    type="number"
                    className={
                      PuntosEmisionForm.formState.errors.codigo
                        ? StyleInputError
                        : StyleInput
                    }
                    placeholder=" "
                    id="codigo"
                    name="codigo"
                    {...PuntosEmisionForm.register("codigo", {
                      required: {
                        value: true,
                        message: "Codigo es requerido",
                      },
                      maxLength: {
                        value: 3,
                        message: "No más de 3 carácteres!",
                      },
                      minLength: {
                        value: 3,
                        message: "Mínimo 3 carácteres",
                      },
                    })}
                  />
                )}

                <label
                  htmlFor="floating_last_name"
                  className={
                    PuntosEmisionForm.formState.errors.codigo
                      ? StyleLabelError
                      : StyleLabel
                  }
                >
                  Código
                </label>
              </div>
              <div className="relative  z-0 mb-2  group">
                <input
                  type="text"
                  className={
                    PuntosEmisionForm.formState.errors.descripcion
                      ? StyleInputError
                      : StyleInput
                  }
                  placeholder=" "
                  id="descripcion"
                  name="descripcion"
                  {...PuntosEmisionForm.register("descripcion", {
                    maxLength: {
                      value: 300,
                      message: "No más de 300 carácteres!",
                    },
                    minLength: {
                      value: 1,
                      message: "Mínimo 1 carácteres",
                    },
                  })}
                />
                <label
                  htmlFor="floating_last_name"
                  className={
                    PuntosEmisionForm.formState.errors.descripcion
                      ? StyleLabel
                      : StyleLabel
                  }
                >
                  Descripción
                </label>
              </div>
              <div className="">
                <button
                  disabled={IsLoading}
                  className="text-white bg-[#04BC53] hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto ml-3 px-2.5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  type="button"
                  onClick={PuntosEmisionForm.handleSubmit(
                    handleAddPuntoEmision
                  )}
                >
                  {IsLoading ? "..." : ButtonPtoEmision ? "EDITAR" : "AGREGAR"}
                </button>
              </div>
            </div>
          </div>

          <button
            style={{ display: "none" }}
            id="editarPuntoEmision"
            data-bs-toggle="modal"
            data-bs-target="#modalEditarPuntoEmision"
            className="  inline-block bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 text-sm border border-gray-300 rounded shadow-sm"
          >
            Editar punto emisión
          </button>
        </div>

        <div className="overflow-x-auto  my-4  relative overflow-hidden shadow ring-1 ring-black  ring-opacity-5 md:rounded-lg">
          <table className=" w-full text-sm text-center text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase divide-y bg-[#D7D7D7] dark:bg-gray-600 dark:text-gray-400 ">
              <tr className="">
                <th className=" text-center py-3.5 pl-4 pr-3  text-sm font-semibold text-gray-900 dark:text-white sm:pl-6">
                  #
                </th>
                <th className=" text-center py-3.5 pl-4 pr-3  text-sm font-semibold text-gray-900 dark:text-white sm:pl-6">
                  Descripción
                </th>
                <th className=" text-center py-3.5 pl-4 pr-3  text-sm font-semibold text-gray-900 dark:text-white sm:pl-6">
                  Cód. Punto de emisión
                </th>
                <th className=" text-center py-3.5 pl-4 pr-3  text-sm font-semibold text-gray-900 dark:text-white sm:pl-6">
                  Activo
                </th>
                <th className=" text-center py-3.5 pl-4 pr-3  text-sm font-semibold text-gray-900 dark:text-white sm:pl-6">
                  Opciones
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200 dark:divide-gray-600 bg-white ">
              {PuntosEmisionList.map((item, index) => {
                return (
                  <tr
                    className="bg-white dark:bg-gray-800 dark:hover:bg-gray-900 hover:bg-gray-200  border-b-gray-600 "
                    key={item.idPuntoEmision}
                  >
                    <td>{(index = index + 1)}</td>
                    <td style={{ maxWidth: "5rem", wordBreak: "break-all" }}>
                      {item.descripcion}
                    </td>
                    <td>{item.codigo}</td>

                    <td>
                      <div className="mx-auto">
                        <input
                          type="checkbox"
                          value=""
                          className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          onChange={() => ToggleActivoPuntoEmision(index)}
                          checked={item.activo}
                        />
                      </div>
                    </td>
                    <td>
                      <button
                        onClick={() => setEditPuntoEmision(item)}
                        disabled={IsLoading}
                        type="submit"
                        className=" hover:bg-gray-200  dark:hover:bg-gray-700  rounded-lg p-2  text-sm font-medium leading-5  dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 dark:fill-white stroke-gray-600"
                          viewBox="0 0 576 512"
                        >
                          <path d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z" />
                        </svg>
                      </button>
                      {isAdmin && (
                        <button
                          onClick={() => EditarSecuencial(item.idPuntoEmision)}
                          disabled={IsLoading}
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#editarSecuenciales"
                          className="hover:bg-gray-200  dark:hover:bg-gray-700  rounded-lg p-2  text-sm font-medium leading-5  dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            className="h-4 w-4 dark:fill-white stroke-gray-600"
                            viewBox="0 0 24 24"
                            strokeWidth={2.5}
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                            />
                          </svg>
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </LargeModalForm>
    </>
  );
};
