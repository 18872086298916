import { createContext, useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Obtener,
  Agregar,
  EliminarSeccion,
  Editar,
  SubirExcel,
} from "../../../../services/InventarioService";

export const SeccionContext = createContext();
export const SeccionProvider = ({ children }) => {
  const empresa = useSelector((state) => state.empresa.empresa);
  const [isLoading, setIsLoading] = useState(false);
  const [secciones, setSecciones] = useState([]);
  const [infoPaginacion, setInfoPaginacion] = useState({
    totalRecords: 0,
    totalPages: 0,
  });
  const [parametroPaginacion, setParametroPaginacion] = useState({
    pageNumber: 1,
    pageSize: 50,
  });

  useEffect(() => {
    if (empresa && empresa.idEmpresa) getSecciones();
  }, [parametroPaginacion, empresa]);

  const getSecciones = async () => {
    setIsLoading(true);
    try {
      const response = await Obtener(
        "/api/secciones/obtener-secciones",
        empresa.idEmpresa,
        parametroPaginacion.pageSize,
        parametroPaginacion.pageNumber
      );
      setSecciones(response.data.data);
      setInfoPaginacion({
        totalPages: response.data.totalPages,
        totalRecords: response.data.totalRecords,
      });
    } catch (err) {
      throw new Error(
        err,
        "No se pudo obtener la información de las secciones. Inténtelo de nuevo más tarde."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const addSeccion = async (newSeccion) => {
    const res = await Agregar("/api/secciones/crear-seccion", newSeccion);
    if (res.code === 200) {
      getSecciones()
    } else {
      throw new Error("No se puedo guardar la sección");
    }
  };

  const deleteSeccion = async (idSeccion) => {
    const res = await EliminarSeccion(
      "/api/secciones/eliminar-seccion",
      idSeccion
    );
    if (res.code === 200) {
      getSecciones()
    } else {
      throw new Error("No se pudo eliminar la sección");
    }
  };

  const updateSeccion = async (newSeccion) => {
    const res = await Editar("/api/secciones/editar-seccion", newSeccion);
    if (res.code === 200) {
      getSecciones()
    } else {
      throw new Error("No se puedo editar la sección");
    }
  };

  const uploadExcelSecciones = async (secciones) => {
    const res = await SubirExcel(
      "/api/secciones/crear-secciones-excel",
      secciones
    );
    if (res.code === 200) {
    } else {
      throw new Error("No se pudo subir las secciones");
    }
  };

  return (
    <SeccionContext.Provider
      value={{
        secciones,
        addSeccion,
        deleteSeccion,
        uploadExcelSecciones,
        parametroPaginacion,
        updateSeccion,
        getSecciones,
        isLoading,
        setParametroPaginacion,
        infoPaginacion,
      }}
    >
      {children}
    </SeccionContext.Provider>
  );
};
