import { useContext, useEffect } from "react";
import { ConsultaContext } from "../Provider/ConsultaProvider";
import moment from "moment";

export default function ConsultaResponse() {
  const {
    isConsulta,
    paginacionEstablecimiento,
    setPaginacionEstablecimiento,
    infoPaginacionEstablecimiento,
    IsLoadingPagination,
  } = useContext(ConsultaContext);

  return (
    <>
      <div className="p-6 pt-0 flex flex-col gap-2 bg-white dark:bg-gray-800 mx-auto">
        <div className="flex flex-col md:flex-row md:gap-0 gap-4  md:space-x-4 justify-between">
          <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
            <div className="p-2">
              <p className="font-bold text-base dark:text-gray-300">RUC</p>
              <p className="text-gray-700 text-lg dark:text-gray-200">
                {isConsulta?.numeroRuc}
              </p>
            </div>
            <div className="p-2">
              <p className="font-bold text-base dark:text-gray-300">
                Razón social
              </p>
              <p className="text-gray-700 text-lg dark:text-gray-200">
                {isConsulta?.razonSocial !== "" ? isConsulta?.razonSocial : "-"}
              </p>
            </div>
          </div>
          <div className="p-2 text-start md:text-end md:p-2 ">
            <p className="font-bold text-base dark:text-gray-300">
              Estado del contribuyente en el RUC
            </p>
            <p
              className={`text-lg ${
                isConsulta?.estadoContribuyenteRuc === "ACTIVO"
                  ? "text-green-800"
                  : "text-red-600"
              } font-semibold`}
            >
              {isConsulta?.estadoContribuyenteRuc}
            </p>
          </div>
        </div>
        <div className="bg-gray-100 dark:bg-gray-700 shadow-md rounded-lg mt-2 p-6 mb-2">
          <h1 className="text-lg font-bold dark:text-gray-400 text-gray-700">
            REPRESENTANTE LEGAL
          </h1>
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div>
              <p className="font-bold dark:text-gray-300">
                Nombre / Razón Social:
              </p>
              <p className="text-gray-700 dark:text-gray-300">
                {isConsulta?.representantesLegales.length > 0
                  ? isConsulta?.representantesLegales[0].representanteLegal
                  : "-"}
              </p>
            </div>
            <div>
              <p className="font-bold dark:text-gray-300">Identificación</p>
              <p className="text-gray-700 dark:text-gray-300">
                {isConsulta?.representantesLegales.length > 0
                  ? isConsulta?.representantesLegales[0].identificacionLegal
                  : "-"}
              </p>
            </div>
          </div>
        </div>

        {/* Activity Section */}
        <div className="bg-white dark:bg-gray-700 border dark:text-gray-300 dark:border-gray-600 rounded-lg p-6 mb-2">
          <h2 className="text-lg font-bold">Actividad económica principal</h2>
          <p className="mt-2">{isConsulta?.actividadEconomicaPrincipal}</p>

          <div className="grid md:grid-cols-3 grid-cols-2 gap-4 mt-4">
            <div>
              <p className="font-bold">Tipo contribuyente</p>
              <p>{isConsulta?.tipoContribuyente}</p>
            </div>
            <div>
              <p className="font-bold">Régimen</p>
              <p>{isConsulta?.regimen}</p>
            </div>
            <div>
              <p className="font-bold">Categoría</p>
              <p>
                {(isConsulta?.categoria === "" || isConsulta?.categoria === null)? "-" : isConsulta?.categoria}
              </p>
            </div>

            <div>
              <p className="font-bold">Obligado a llevar contabilidad</p>
              <p>{isConsulta?.obligadoLlevarContabilidad}</p>
            </div>
            <div>
              <p className="font-bold">Agente de retención</p>
              <p>{isConsulta?.agenteRetencion ?? "-"}</p>
            </div>
            <div>
              <p className="font-bold">Contribuyente especial</p>
              <p>{isConsulta?.contribuyenteEspecial ?? "-"}</p>
            </div>

            <div>
              <p className="font-bold">Fecha de inicio actividades</p>
              <p>
                {moment(new Date(isConsulta?.informacionFechasContribuyente.fechaInicioActividades)).format("DD/MM/YYYY")}
              </p>
            </div>

            <div>
              <p className="font-bold">Fecha actualización</p>
              <p>
                {isConsulta?.informacionFechasContribuyente.fechaActualizacion !== "" &&
                isConsulta?.informacionFechasContribuyente.fechaActualizacion !== null
                  ? moment(new Date(isConsulta?.informacionFechasContribuyente.fechaActualizacion)).format(
                      "DD/MM/YYYY"
                    )
                  : "-"}
              </p>
            </div>
            <div>
              <p className="font-bold">Fecha cese actividades</p>
              <p>
                {isConsulta?.informacionFechasContribuyente.fechaCese !== "" && isConsulta?.informacionFechasContribuyente.fechaCese !== null
                  ? moment(new Date(isConsulta?.informacionFechasContribuyente.fechaCese)).format("DD/MM/YYYY")
                  : "-"}
              </p>
            </div>
            <div>
              <p className="font-bold">Fecha reinicio actividades</p>
              <p>
                {isConsulta?.informacionFechasContribuyente.fechaReinicioActividades !== "" &&
                isConsulta?.informacionFechasContribuyente.fechaReinicioActividades !== null
                  ? moment(new Date(isConsulta?.informacionFechasContribuyente.fechaReinicioActividades)).format(
                      "DD/MM/YYYY"
                    )
                  : "-"}
              </p>
            </div>
          </div>
        </div>

        {/* Establecimientos */}
        <div className="bg-white dark:bg-gray-700 dark:border-gray-600 border rounded-lg p-6 mb-2">
          {isConsulta?.establecimientos.data.filter((s) => s.tipoEstablecimiento === "MAT").length >
            0 && (
            <>
              {" "}
              <h2 className="text-lg font-bold dark:text-gray-400">
                Establecimientos matriz
              </h2>
              <div className="overflow-auto">
                <table className="table-auto w-full border-collapse border dark:border-gray-600 border-gray-200 mt-4">
                  <thead>
                    <tr className="bg-gray-100 dark:bg-gray-600 dark:text-gray-300">
                      <th className="border px-4 py-2 text-left">
                        No. establecimiento
                      </th>
                      <th className="border px-4 py-2 text-left">
                        Nombre comercial
                      </th>
                      <th className="border px-4 py-2 text-left">Ubicación</th>
                      <th className="border px-4 py-2 text-left">
                        Estado del establecimiento
                      </th>
                    </tr>
                  </thead>
                  <tbody className="dark:text-gray-300">
                    {isConsulta?.establecimientos.data
                      .filter((s) => s.tipoEstablecimiento === "MAT")
                      .map((s) => (
                        <tr key={s?.numeroEstablecimiento}>
                          <td className="border px-4 py-2">
                            {s?.numeroEstablecimiento}
                          </td>
                          <td className="border px-4 py-2">
                            {s?.nombreFantasiaComercial}
                          </td>
                          <td className="border px-4 py-2">
                            {s?.direccionCompleta}
                          </td>
                          <td
                            className={`border px-4 py-2 ${
                              s?.estado === "CERRADO"
                                ? "text-red-600"
                                : "text-green-800"
                            } `}
                          >
                            {s?.estado}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {isConsulta?.establecimientos.data.filter((s) => s.tipoEstablecimiento !== "MAT").length >
            0 && (
            <div className="mt-3">
              <h2 className="text-lg font-bold dark:text-gray-400">
                Establecimiento adicionales
              </h2>
              <div className="overflow-auto">
                <table className="table-auto w-full border-collapse border border-gray-200 mt-4">
                  <thead>
                    <tr className="bg-gray-100 dark:bg-gray-600 dark:text-gray-300">
                      <th className="border px-4 py-2 text-left">
                        No. establecimiento
                      </th>
                      <th className="border px-4 py-2 text-left">
                        Nombre comercial
                      </th>
                      <th className="border px-4 py-2 text-left">Ubicación</th>
                      <th className="border px-4 py-2 text-left">
                        Estado del establecimiento
                      </th>
                    </tr>
                  </thead>
                  <tbody className="dark:text-gray-300">
                    {isConsulta?.establecimientos.data
                      .filter((s) => s.tipoEstablecimiento !== "MAT")
                      .map((s) => (
                        <tr key={s?.numeroEstablecimiento}>
                          <td className="border px-4 py-2">
                            {s?.numeroEstablecimiento}
                          </td>
                          <td className="border px-4 py-2">
                            {s?.nombreFantasiaComercial}
                          </td>
                          <td className="border px-4 py-2">
                            {s?.direccionCompleta}
                          </td>
                          <td
                            className={`border px-4 py-2 ${
                              s?.estado === "CERRADO"
                                ? "text-red-600"
                                : "text-green-800"
                            } `}
                          >
                            {s?.estado}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <div className="bg-white mt-8 dark:bg-gray-700 dark:text-gray-400 px-4 py-3 my-2 flex items-center justify-between border-t border-gray-200 dark:border-gray-700 sm:px-6">
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              {!IsLoadingPagination ? (
                <span className="text-sm font-normal ml-2 text-gray-400 dark:text-gray-400">
                  Mostrando{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {(paginacionEstablecimiento.pageNumber - 1) *
                      paginacionEstablecimiento.pageSize +
                      1}{" "}
                    -{" "}
                    {Math.min(
                      paginacionEstablecimiento.pageNumber *
                        paginacionEstablecimiento.pageSize,
                      infoPaginacionEstablecimiento.totalRecords +
                        isConsulta?.establecimientos.data.filter(
                          (s) => s.tipoEstablecimiento === "MAT"
                        ).length
                    )}{" "}
                  </span>
                  de{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {infoPaginacionEstablecimiento.totalRecords +
                      isConsulta?.establecimientos.data.filter(
                        (s) => s.tipoEstablecimiento === "MAT"
                      ).length}{" "}
                  </span>
                  Establecimientos
                </span>
              ) : (
                <span className="font-normal ml-2 text-gray-400 text-sm dark:text-white">
                  Cargando...
                </span>
              )}
              <div>
                <nav
                  className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                  aria-label="Pagination"
                >
                  <button
                    type="button"
                    disabled={IsLoadingPagination}
                    onClick={(e) => {
                      setPaginacionEstablecimiento((prevState) => {
                        if (prevState.start !== 0) {
                          return {
                            ...prevState,
                            pageNumber: 1,
                          };
                        } else return prevState;
                      });
                    }}
                    className="relative inline-flex items-center dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600 px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50"
                  >
                    <span className="sr-only">First</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-5 w-5"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                  <button
                    type="button"
                    disabled={IsLoadingPagination}
                    onClick={() => {
                      setPaginacionEstablecimiento((prevState) => {
                        if (prevState.pageNumber > 1) {
                          return {
                            ...prevState,
                            pageNumber: prevState.pageNumber - 1,
                          };
                        } else return prevState;
                      });
                    }}
                    className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                  >
                    <span className="sr-only">Previous</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-5 w-5"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>

                  <button
                    type="button"
                    disabled={IsLoadingPagination}
                    onClick={() => {
                      setPaginacionEstablecimiento((prevState) => {
                        if (
                          prevState.pageNumber <
                          infoPaginacionEstablecimiento.totalPages
                        ) {
                          return {
                            ...prevState,
                            pageNumber: prevState.pageNumber + 1,
                          };
                        } else return prevState;
                      });
                    }}
                    className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                  >
                    <span className="sr-only">Next</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-5 w-5"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                  <button
                    type="button"
                    disabled={IsLoadingPagination}
                    onClick={(e) => {
                      setPaginacionEstablecimiento((prevState) => {
                        if (
                          prevState.pageNumber !==
                            infoPaginacionEstablecimiento.totalPages &&
                          infoPaginacionEstablecimiento.totalPages !== 0
                        ) {
                          return {
                            ...prevState,
                            pageNumber:
                              infoPaginacionEstablecimiento.totalPages,
                          };
                        } else return prevState;
                      });
                    }}
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  border-gray-300 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50"
                  >
                    <span className="sr-only">Last</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-5 w-5"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      ></path>
                      <path
                        fillRule="evenodd"
                        d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {isConsulta?.missInformation && (
          <span className="text-sm mt-2 px-3 font-semibold bg-yellow-200 p-2 rounded-lg w-fit">
            Nota: El contribuyente cuenta con información faltante. El Bot de
            Intuito actualmente esta consultando al SRI, puede reintentar la
            consulta dentro de 5 minutos.
          </span>
        )}
      </div>
    </>
  );
}
