import { createContext, useState } from "react";
import { useSelector } from "react-redux";
import { ObtenerValoracionInventario } from "../../../../../services/InventarioService";

export const ValoracionInventarioContext = createContext();

export const ValoracionInventarioProvider = ({ children }) => {
  const [valoracionInventario, setValoracionInventario] = useState([]);
  const empresa = useSelector((state) => state.empresa.empresa);
  const [isLoading, setIsLoading] = useState(false);

  const getValoracionInventario = async (Filtros) => {
    setIsLoading(true);
    try {
      const response = await ObtenerValoracionInventario(
        "/api/valoracionInventario/obtener-valoracion-inventario",
        empresa.idEmpresa,
        Filtros?.categoria?.idCategoriaProducto,
        Filtros.seccion.idSeccion,
        Filtros.periodo
      );
      setValoracionInventario(response.data);
    } catch (err) {
      throw new Error(
        err,
        "No se pudo obtener la información de la valoración de Inventario. Inténtelo de nuevo más tarde."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ValoracionInventarioContext.Provider
      value={{
        getValoracionInventario,
        isLoading,
        valoracionInventario,
      }}
    >
      {children}
    </ValoracionInventarioContext.Provider>
  );
};
