import { create } from 'zustand'

export const ModalSeccionStatus = {
    off: "off",
    editar: "editar",
    eliminar: "eliminar"
}

export const useModalSeccionStore = create((set) => ({
  ModalSeccion: ModalSeccionStatus.off,
  SeccionAfectada: null,
  changeSeccionModal: (status, seccion) => set({ModalSeccion: status, SeccionAfectada: seccion}),
  turnSeccionModalOff: () => set({ModalSeccion: ModalSeccionStatus.off, SeccionAfectada: null}),
  setSeccionAfectada: (seccion) => set({SeccionAfectada: seccion})
}))