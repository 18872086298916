import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

const useVistaPrevia = () => {
  const [IsLoading, setIsLoading] = useState(false);

  const OpenVistaPrevia = async (data) => {
    let byteCharacters = atob(data);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: "application/pdf;base64" });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const GetVistaPreviaFactura = async (data) => {
    try {
      setIsLoading(true);
      let res = await axios.post("api/VistaPrevia/factura", data);
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const GetVistaPreviaProforma = async (data) => {
    try {
      setIsLoading(true);
      let res = await axios.post("api/VistaPrevia/factura", data);
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  };
  const GetVistaPreviaFacturaPTVenta = async (data) => {
    try {
      setIsLoading(true);
      let res = await axios.post("api/VistaPrevia/FacturaPTVenta", data);
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const GetVistaPreviaGuiaRemision = async (data) => {
    try {
      setIsLoading(true);
      let res = await axios.post("api/VistaPrevia/guiaremision", data);
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const GetVistaPreviaLiquidacion = async (data) => {
    try {
      setIsLoading(true);
      let res = await axios.post("api/VistaPrevia/liquidacion", data);
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const GetVistaPreviaRetencion = async (data) => {
    try {
      setIsLoading(true);
      let res = await axios.post("api/VistaPrevia/retencion", data);
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const GetVistaPreviaNotaDebito = async (data) => {
    try {
      setIsLoading(true);
      let res = await axios.post("api/VistaPrevia/notadebito", data);
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const GetVistaPreviaNotaCredito = async (data) => {
    try {
      setIsLoading(true);
      let res = await axios.post("api/VistaPrevia/notacredito", data);
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    IsLoading,
    GetVistaPreviaFactura,
    GetVistaPreviaGuiaRemision,
    GetVistaPreviaLiquidacion,
    GetVistaPreviaRetencion,
    GetVistaPreviaNotaDebito,
    GetVistaPreviaNotaCredito,
    OpenVistaPrevia,
    GetVistaPreviaFacturaPTVenta,
    GetVistaPreviaProforma,
  };
};

export default useVistaPrevia;
