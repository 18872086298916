import { TransaccionesProvider } from "../../TransaccionesEmitidas/context/TransaccionesContext";
import AjusteInventarioForm from "./AjusteInventarioForm";
import AjusteInventarioProvider from "./Context/AjusteInventarioProvider";

export default function AjusteInventario() {
  return (
      <TransaccionesProvider>
        <AjusteInventarioProvider>
          <AjusteInventarioForm />
        </AjusteInventarioProvider>
      </TransaccionesProvider>
  );
}
