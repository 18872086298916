import AsyncSelect from "react-select/async";
import debounce from "lodash.debounce";
import { React, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

function AutoCompleteInput({
  title,
  identificador,
  name,
  array,
  parametro,
  disabled,
  validations,
  searchFn,
  active = false,
  option,
}) {
  const loadOptions = debounce((inputText, callback) => {
    if (searchFn) {
      searchFn(inputText).then((response) => callback(response));
    } else {
      buscarElemento(inputText).then((response) => callback(response));
    }
  }, 400);

  const {
    formState: { errors },
  } = useFormContext();

  const buscarElemento = async (input) => {
    if (active === true)
      return array
        .filter((elemento) => elemento.estado === true)
        .filter((elemento) =>
          String(elemento[parametro])
            .toLowerCase()
            .includes(input.toLowerCase())
        );
    else {
      return array.filter((elemento) =>
        String(elemento[parametro]).toLowerCase().includes(input.toLowerCase())
      );
    }
  };

  const customStyles = () => ({
    control: (styles) => ({
      ...styles,
      height: "28px",
      backgroundColor: window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "#24262d"
        : "#ffffff",
      borderColor: window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "#4b5563" /* border-gray-600 */
        : "#d1d5db" /* border-gray-300 */,
      color: window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "#f9fafb" /* text-gray-100 */
        : "#1f2937" /* text-gray-900 */,
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "#374151" /* bg-gray-700 */
        : "#ffffff" /* bg-white */,
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused
        ? window.matchMedia("(prefers-color-scheme: dark)").matches
          ? "#4b5563" /* bg-gray-600 */
          : "#e5e7eb" /* bg-gray-200 */
        : window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "#374151" /* bg-gray-700 */
        : "#ffffff" /* bg-white */,
      color: window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "#f9fafb" /* text-gray-100 */
        : "#1f2937" /* text-gray-900 */,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "white"
        : "black",
    }),
  });

  const StyleNormalSelect =
    "focus:outline-none left-0 relative  focus:border-blue-500 focus:ring-blue-500 focus:ring-1 ring-0 w-full rounded-md h-[40px]  text-xs space-x-0";

  const StyleErrorSelect =
    "border-red-500 focus:outline-none left-0 relative  focus:border-red-500 focus:ring-red-500 focus:ring-1 ring-0 w-full border rounded-md h-[40px] text-black text-xs space-x-0";

  const StyleDisableSelect =
    "border-gray-300 focus:outline-none left-0 relative focus:border-blue-500 focus:ring-blue-500 focus:ring-1 ring-0 w-full bg-gray-300 border text-black rounded-md h-[40px] text-xs space-x-0";

  return (
    <div className={`text-xs`}>
      <label
        className={
          errors[title.toLowerCase()]
            ? "w-full my-auto  flex flex-col gap-1"
            : "w-full text-gray-600 dark:text-white my-auto font-normal flex flex-col gap-1"
        }
        htmlFor={title}
      >
        <span
          className={
            errors[name ? name : title.toLowerCase()] && "text-red-600"
          }
        >
          {title}:
        </span>
        <Controller
          name={name ? name : title.toLowerCase()}
          rules={validations}
          render={({ field }) => (
            <AsyncSelect
              className={
                errors[name ? name : title.toLowerCase()]
                  ? StyleErrorSelect
                  : disabled
                  ? StyleDisableSelect
                  : StyleNormalSelect
              }
              isDisabled={disabled}
              styles={customStyles()}
              isClearable
              {...field}
              noOptionsMessage={() =>
                `No se encontraron registros de ${title.toLowerCase()}.`
              }
              loadOptions={loadOptions}
              loadingMessage={() =>
                `Cargando sus registros de ${title.toLowerCase()}`
              }
              defaultOptions={
                active
                  ? array.filter((elemento) => elemento.estado === true)
                  : array
              }
              getOptionLabel={option}
              getOptionValue={(e) => e[identificador]}
              placeholder={`Seleccione su ${title.toLowerCase()}`}
            />
          )}
        />
        {errors[name ? name : title.toLowerCase()] &&
          errors[name ? name : title.toLowerCase()].type === "required" && (
            <span className="text-red-600 text-[10px]">
              El Campo {title.toLowerCase()} es requerido.
            </span>
          )}
      </label>
    </div>
  );
}

export default AutoCompleteInput;
