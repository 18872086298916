import { createContext, useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { getFechaInicio } from "../../../../../../services/Utilitario";
import { ObtenerSecuencialPerTransaccion } from "../../../../../../services/InventarioService";
import { useSelector } from "react-redux";
import { TransaccionesContext } from "../../../TransaccionesEmitidas/context/TransaccionesContext";
import { toast } from "react-toastify";
import { v4 as uuidv } from "uuid";
import useGlobalToastify from "../../../../../../features/hooks/GlobalToastify/useGlobalToastify";
import { ObtenerProductosTransaccion } from "../../../../../../services/InventarioService";

export const TransferenciasProviderContext = createContext();

const TransferenciasProvider = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const empresa = useSelector((state) => state.empresa.empresa);
  const { addTransaccion } = useContext(TransaccionesContext);
  const [secuencial, setSecuencial] = useState(0);
  const [productosPerSecciones, setproductosPerSecciones] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const { ErrorToast } = useGlobalToastify();
  const [dataToConfirm, setDataToConfirm] = useState(null);
  const [alertaStockProducts, setAlertaStockProducts] = useState([]);

  const methods = useForm({
    defaultValues: {
      fechaCreacion: getFechaInicio(),
      tipoOperacion: "Transferencia",
    },
  });

  useEffect(() => {
    if (empresa.idEmpresa && methods.watch("ubicacionEnvia")) {
      ObtenerProductosTransaccion(
        "/api/producto/obtener-productos-transaccion",
        empresa.idEmpresa,
        methods.watch("ubicacionEnvia").idSeccion
      ).then((response) => {
        setproductosPerSecciones(response.data);
      });
    }
  }, [methods.watch("ubicacionEnvia"), empresa]);

  useEffect(() => {
    if (empresa && empresa.idEmpresa) getSecuencial();
  }, [empresa]);

  const getSecuencial = () => {
    ObtenerSecuencialPerTransaccion(
      empresa.idEmpresa,
      "40EB31EB-D0DF-4EFE-AA73-BB2B79A05B28"
    ).then((response) => setSecuencial(response.data));
  };

  const [rowBase, setRowBase] = useState({
    codigo: "",
    cuenta: "",
    cantidad: "",
    costo: "",
    lote: "",
    fechaCaducidad: "",
  });

  const [rows, setRows] = useState([{ ...rowBase }]);

  const validateTransferencia = async (data) => {
    if (
      rows.some(
        (row) =>
          row.codigo === "" || row.codigo === null || row.codigo === undefined
      )
    ) {
      ErrorToast("Debes seleccionar un producto en cada registro.");
      return;
    }
    if (rows.length === 0) {
      ErrorToast("No puedes generar una transacción vacia.");
      return;
    }

    const productosAbajoStock = [];

    const productosUnicosStock = new Set();

    for (const row of rows) {
      productosUnicosStock.add(row.codigo);
    }

    for (const producto of productosUnicosStock) {
      const valorRestante =
        producto.stock -
        rows.reduce((accumulator, value) => {
          if (value.codigo?.idProducto === producto.idProducto) {
            return accumulator + Number(value.cantidad);
          } else if (
            value.codigo?.subProductos?.length > 0 &&
            value.codigo?.subProductos
              .map((sp) => sp.idSubProducto)
              .includes(producto.idProducto)
          ) {
            const subProducto = value.codigo?.subProductos.find(
              (sp) => sp.idSubProducto === producto.idProducto
            );
            return subProducto
              ? accumulator +
                  Number(value.cantidad) * Number(subProducto.cantidad)
              : accumulator;
          }
          return accumulator;
        }, 0);
      if (valorRestante < 0) {
        productosAbajoStock.push({
          producto: producto.nombre,
          cantidadRestante: valorRestante,
        });
      }
    }

    if (productosAbajoStock.length > 0) {
      ErrorToast("La cantidad a egresar de ciertos productos supera su stock");
      return;
    }

    const productosUnicos = new Set();

    for (const producto of productosPerSecciones) {
      if (
        producto.isMinimoStock === true &&
        producto.alertaStock !== 0 &&
        producto.stock >= producto.alertaStock
      ) {
        productosUnicos.add(producto);
      }
    }

    const productosUnicosArray = Array.from(productosUnicos);
    const productosConAlerta = [];

    for (const producto of productosUnicosArray) {
      const valorRestante =
        producto.stock -
        rows.reduce((accumulator, value) => {
          if (value.codigo?.idProducto === producto.idProducto) {
            return accumulator + Number(value.cantidad);
          } else if (
            value.codigo?.subProductos?.length > 0 &&
            value.codigo?.subProductos
              .map((sp) => sp.idSubProducto)
              .includes(producto.idProducto)
          ) {
            const subProducto = value.codigo?.subProductos.find(
              (sp) => sp.idSubProducto === producto.idProducto
            );
            return subProducto
              ? accumulator +
                  Number(value.cantidad) * Number(subProducto.cantidad)
              : accumulator;
          }
          return accumulator;
        }, 0);
      if (valorRestante < producto.alertaStock) {
        productosConAlerta.push({
          producto: producto.nombre,
          cantidadRestante: valorRestante,
          alertaStock: producto.alertaStock,
        });
      }
    }

    if (productosConAlerta.length > 0) {
      setAlertaStockProducts(productosConAlerta);
      setDataToConfirm(data);
      setIsOpen(true);
      return;
    }

    handleTransferencia(data);
  };

  const handleTransferencia = async (data) => {
    const idTransaccion = uuidv();
    const newTransferencia = {
      idTransaccion: idTransaccion,
      idEmpresa: empresa.idEmpresa,
      idTipoTransaccion: "40EB31EB-D0DF-4EFE-AA73-BB2B79A05B28",
      idSeccionOrigen: data.ubicacionEnvia.idSeccion,
      idSeccionDestino: data.ubicacionRecibe.idSeccion,
      codigo: secuencial.codigo,
      detalle: data.detalle,
      fechaCreacion: data.fechaCreacion,
      productos: rows.map((registro) => ({
        idDetalleTransaccion: uuidv(),
        idTransaccion: idTransaccion,
        idProducto: registro.codigo.idProducto,
        cuentaAnalitica: registro.cuenta,
        cantidad: registro.cantidad,
        costoDecimal: registro.costo,
        lote: registro?.lote,
        fechaCaducidad: registro?.fechaCaducidad,
      })),
    };
    try {
      setIsSending(true);
      await toast.promise(addTransaccion(newTransferencia), {
        pending: "Realizando la Transferencia...",
        success: "¡La transferencia se realizo con éxito!",
        error: {
          render({ data }) {
            return (
              data?.response?.data?.message ||
              "Hubo un error al realizar la transferencia."
            );
          },
        },
      });
    } finally {
      setIsSending(false);
    }
    methods.reset();
    methods.setValue("ubicacionEnvia", "");
    methods.setValue("ubicacionRecibe", "");
    getSecuencial();
    setRows([{ ...rowBase }]);
  };

  const ChangeRow = (row) => {
    const IsInclude = Object.keys(rowBase).includes(row);
    if (IsInclude) {
      const updatedRowBase = { ...rowBase };
      delete updatedRowBase[row];
      setRowBase(updatedRowBase);
    } else {
      const updatedRowBase = { ...rowBase };
      updatedRowBase[row] = "";
      setRowBase(updatedRowBase);
    }
  };

  return (
    <TransferenciasProviderContext.Provider
      value={{
        rows,
        setRows,
        methods,
        getSecuencial,
        validateTransferencia,
        setAlertaStockProducts,
        alertaStockProducts,
        setDataToConfirm,
        dataToConfirm,
        handleTransferencia,
        rowBase,
        ChangeRow,
        productosPerSecciones,
        isSending,
        setIsSending,
        secuencial,
        setIsOpen,
        isOpen,
      }}
    >
      {props.children}
    </TransferenciasProviderContext.Provider>
  );
};

export default TransferenciasProvider;
