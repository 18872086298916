import { FormProvider, useForm } from "react-hook-form";
import ConsultaResponse from "./ConsultaResponse";
import ConsultaForm from "./ConsultaForm";
import { useContext } from "react";
import { ConsultaContext } from "../Provider/ConsultaProvider";
import { Transition } from "@headlessui/react";

export default function ConsultaLayout() {
  const initialState = {
    identificacionConsulta: "",
  };
  const methods = useForm({ defaultValues: initialState });
  const { isConsulta, cleanConsulta } = useContext(ConsultaContext);

  return (
    <FormProvider {...methods}>
      <section className="mx-4 h-full my-4 flex flex-col">
        <div className="flex md:flex-row md:justify-between gap-1 md:gap-0 flex-col p-5 pt-3">
          <div className="flex flex-col md:gap-1 gap-0 md:border-b pb-3 w-full border-white md:border-gray-300">
            <h1 className="text-2xl mt-2 dark:text-gray-300 font-semibold whitespace-nowrap">
              Consultar RUC
            </h1>
          </div>
          {isConsulta && (
            <div className="border-b pb-3 border-gray-300">
              <button
                className="bg-[#00314D] hover:scale-90 p-3 py-2 rounded-lg text-white whitespace-nowrap gap-2 flex flex-row items-center border-gray-300 text-sm"
                onClick={() => cleanConsulta()}
              >
                <span>Nueva Consulta</span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-arrow-clockwise"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
                    />
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                  </svg>
                </span>
              </button>
            </div>
          )}
        </div>
        <Transition
          show={isConsulta == null}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
        >
          <ConsultaForm methods={methods} />
        </Transition>
        <Transition
          show={isConsulta !== null}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
        >
          <ConsultaResponse />
        </Transition>
      </section>
    </FormProvider>
  );
}
