import React, { useState } from "react";
import NumberFormat from "react-number-format";

const TotalesComponents = ({
  Totales,
  IsActiveExportacion,
  listExportacion,
  totalImporte,
  DiseñoExportacion,
  DiseñoPago,
  incrementarContador,
  decrementarContador,
  incrementarContadorIVA5,
  decrementarContadorIVA5,
  incrementarContadorIVA13,
  decrementarContadorIVA13,
  incrementarContadorIVA8,
  decrementarContadorIVA8,
  incrementarContadorIVA12,
  decrementarContadorIVA12,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [acceptedAction, setAcceptedAction] = useState(false);

  const incrementarContadorIVA5f = () => {
    setShowModal(true);
    if (acceptedAction) {
      incrementarContadorIVA5();
    }
  };
  const decrementarContadorIVA5f = () => {
    setShowModal(true);
    if (acceptedAction) {
      decrementarContadorIVA5();
    }
  };
  const incrementarContadorIVA13f = () => {
    setShowModal(true);
    if (acceptedAction) {
      incrementarContadorIVA13();
    }
   
  };
  const decrementarContadorIVA13f = () => {
    setShowModal(true);
    if (acceptedAction) {
      decrementarContadorIVA13();
    }
   
  };
  const incrementarContadorIVA8f = () => {
    setShowModal(true);
    if (acceptedAction) {
      incrementarContadorIVA8();

    }
  };
  const decrementarContadorIVA8f = () => {
    setShowModal(true);
    if (acceptedAction) {
      decrementarContadorIVA8();

    }
  
  };
  const incrementarContadorIVA12f = () => {
    setShowModal(true);
    if (acceptedAction) {
      incrementarContadorIVA12();

    }
  
  };

  const decrementarContadorIVA12f = () => {
    setShowModal(true);
    if (acceptedAction) {
      decrementarContadorIVA12();

    }

  };
  const incrementarContadorf = () => {
    setShowModal(true);
    if (acceptedAction) {
      incrementarContador();

    }

  };
  const decrementarContadorf = () => {
    setShowModal(true);
    if (acceptedAction) {
      decrementarContador();

    }
  
  };

  const handleAceptar = () => {
    setShowModal(false);
    setAcceptedAction(true);
    // Aquí puedes ejecutar la acción que deseas después de aceptar
  };

  const handleCancelar = () => {
    setShowModal(false);
    // Aquí puedes ejecutar acciones adicionales si se cancela
  };

  return (
    <>
      {showModal && !acceptedAction && (
        <div
          style={{ width: "100vw", height: "100vh", zIndex: "1000000" }}
          className="  z-[10000] fixed justify-center items-center align-middle  top-0 left-0   text-center"
        >
          <div className=" flex justify-center items-center h-full">
            <div className="bg-white border rounded-md shadow-lg w-96 ">
              <div>
                <h2>Información</h2>
                <p>
                Al realizar esta modificación del valor del IVA, usted asume total responsabilidad por los cambios realizados en estos campos.
                </p>
                <div className="my-4 flex space-x-3 justify-center">
                  <button
                    className="mr-2 px-4 py-2 bg-[#00314D] text-white rounded-lg"
                    onClick={handleAceptar}
                  >
                    Aceptar
                  </button>
                  <button
                    className="px-4 py-2 border rounded-lg"
                    onClick={handleCancelar}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className=" flex-wrap justify-between  w-full   overflow-x-auto  ">
        <h3 className="text-sm py-1.5  px-2 text-center bg-[#00314D] dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-t-lg font-medium leading-6 text-white">
          Totales
        </h3>

        <div className="flex align-middle p-4 justify-center flex-col ">
          {Totales.subTotalIva !== 0 && (
            <div className="flex flex-center justify-center mb-2 pt-2 ">
              <div className="text-gray-800 select-none max-w-xs text-right pr-2   rounded-l-lg px-1 py-2 flex-1 dark:text-white text-xs font-semibold">
                SUBTOTAL IVA 12 %
              </div>

              <div className={DiseñoPago + " w-56 select-none"}>
                <div
                  className="text-gray-800  font-medium text-right pr-10  dark:text-white"
                  x-html="netTotal"
                >
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={Totales.subTotalIva}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </div>
              </div>
            </div>
          )}
          {Totales.subTotalIva15 !== 0 && (
            <div className="flex flex-center justify-center  mb-2 pt-2 ">
              <div className="text-gray-800 select-none max-w-xs text-right pr-2   rounded-l-lg px-1 py-2 flex-1 dark:text-white text-xs   font-semibold">
                SUBTOTAL IVA 15 %
              </div>

              <div className={DiseñoPago + " w-56 select-none"}>
                <div
                  className="text-gray-800 font-medium text-right pr-10  dark:text-white"
                  x-html="netTotal"
                >
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={Totales.subTotalIva15}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </div>
              </div>
            </div>
          )}
          {Totales.subTotalIva0 > 0 && (
            <div className="flex flex-center justify-center  mb-2 pt-2 ">
              <div className="text-gray-800 select-none max-w-xs text-right pr-2     rounded-l-lg px-1 py-2 flex-1 dark:text-white text-sm font-semibold">
                SUBTOTAL IVA 0 %
              </div>

              <div className={DiseñoPago + " w-56 select-none"}>
                <div
                  className="text-gray-800 font-medium text-right pr-10  dark:text-white"
                  x-html="netTotal"
                >
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={Totales.subTotalIva0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </div>
              </div>
            </div>
          )}

          {Totales.subTotalIva5 !== 0 && (
            <div className="flex flex-center justify-center  mb-2 pt-2 ">
              <div className="text-gray-800 select-none max-w-xs text-right pr-2   rounded-l-lg px-1 py-2 flex-1 dark:text-white text-xs font-semibold">
                SUBTOTAL IVA 5 %
              </div>

              <div className={DiseñoPago + " w-56 select-none"}>
                <div
                  className="text-gray-800 font-medium text-right pr-10  dark:text-white"
                  x-html="netTotal"
                >
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={Totales.subTotalIva5}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </div>
              </div>
            </div>
          )}

          {Totales.subTotalIva8 !== 0 && (
            <div className="flex flex-center justify-center  mb-2 pt-2  ">
              <div className="text-gray-800 select-none max-w-xs text-right pr-2   rounded-l-lg px-1 py-2 flex-1 dark:text-white text-xs font-semibold">
                SUBTOTAL IVA 8 %
              </div>

              <div className={DiseñoPago + " w-56 select-none"}>
                <div
                  className="text-gray-800 font-medium text-right pr-10  dark:text-white"
                  x-html="netTotal"
                >
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={Totales.subTotalIva8}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </div>
              </div>
            </div>
          )}

          {Totales.subTotalIva13 !== 0 && (
            <div className="flex flex-center justify-center  mb-2 pt-2  ">
              <div className="text-gray-800 select-none max-w-xs text-right pr-2   rounded-l-lg px-1 py-2 flex-1 dark:text-white text-xs font-semibold">
                SUBTOTAL IVA 13 %
              </div>

              <div className={DiseñoPago + " w-56 select-none"}>
                <div
                  className="text-gray-800 font-medium text-right pr-10 dark:text-white"
                  x-html="netTotal"
                >
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={Totales.subTotalIva13}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </div>
              </div>
            </div>
          )}

          {Totales.subTotalNoObjetoIva !== 0 ? (
            <div className="flex flex-center justify-center mb-2">
              <div className="text-gray-800 select-none max-w-xs text-right pr-2   rounded-l-lg px-1 py-2 flex-1 dark:text-white text-sm font-semibold">
                SUBTOTAL NO OBJETO IVA
              </div>
              <div className={DiseñoPago + " w-56 select-none"}>
                <div
                  className="text-gray-800 font-medium text-right pr-10 dark:text-white"
                  x-html="netTotal"
                >
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={Totales.subTotalNoObjetoIva}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {Totales.subTotalExentoIva !== 0 ? (
            <div className="flex flex-center justify-center mb-2">
              <div className="text-gray-800 select-none max-w-xs text-right pr-2   rounded-l-lg px-1 py-2 flex-1 dark:text-white text-sm font-semibold">
                SUBTOTAL EXENTO IVA
              </div>
              <div className={DiseñoPago + " w-56 select-none"}>
                <div
                  className="text-gray-800 font-medium text-right pr-10 dark:text-white"
                  x-html="netTotal"
                >
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={Totales.subTotalExentoIva}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {IsActiveExportacion ? (
            <div className="flex flex-center justify-center mb-2">
              <div className="text-gray-800 select-none max-w-xs text-right pr-2   rounded-l-lg px-1 py-2 flex-1 dark:text-white text-sm font-semibold">
                FLETE INTERNACIONAL
              </div>
              <div className={DiseñoExportacion + " w-56 select-none"}>
                <div
                  className="text-gray-800 font-medium text-right pr-10 dark:text-white"
                  x-html="netTotal"
                >
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={
                      parseFloat(listExportacion.fleteInternacional) || 0.0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {IsActiveExportacion ? (
            <div className="flex flex-center justify-center mb-2">
              <div className="text-gray-800 select-none max-w-xs text-right pr-2   rounded-l-lg px-1 py-2 flex-1 dark:text-white text-sm font-semibold">
                SEGURO INTERNACIONAL
              </div>
              <div className={DiseñoExportacion + " w-56 select-none"}>
                <div
                  className="text-gray-800 font-medium text-right pr-10 dark:text-white"
                  x-html="netTotal"
                >
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={
                      parseFloat(listExportacion.seguroInternacional) || 0.0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {IsActiveExportacion ? (
            <div className="flex flex-center justify-center mb-2">
              <div className="text-gray-800 select-none max-w-xs text-right pr-2   rounded-l-lg px-1 py-2 flex-1 dark:text-white text-sm font-semibold">
                GASTOS ADUANEROS
              </div>
              <div className={DiseñoExportacion + " w-56 select-none"}>
                <div
                  className="text-gray-800 font-medium text-right pr-10 dark:text-white"
                  x-html="netTotal"
                >
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={parseFloat(listExportacion.gastosAduaneros) || 0.0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {IsActiveExportacion ? (
            <div className="flex flex-center justify-center mb-2">
              <div className="text-gray-800 select-none max-w-xs text-right pr-2   rounded-l-lg px-1 py-2 flex-1 dark:text-white text-sm font-semibold">
                GASTOS TRANSPORTE
              </div>
              <div className={DiseñoExportacion + " w-56 select-none"}>
                <div
                  className="text-gray-800 font-medium text-right pr-10 dark:text-white"
                  x-html="netTotal"
                >
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={
                      parseFloat(listExportacion.gastosTransporteOtros) || 0.0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </div>
              </div>
            </div>
          ) : null}

          <div className="flex flex-center justify-center mb-2 ">
            <div className="text-gray-800 select-none max-w-xs text-right pr-2   rounded-l-lg px-1 py-2 flex-1 dark:text-white text-xs  font-semibold">
              TOTAL SIN IMPUESTO
            </div>
            <div className={DiseñoPago + " w-56 select-none"}>
              <div
                className="text-gray-800 font-medium text-right pr-10 dark:text-white"
                x-html="netTotal"
              >
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={parseFloat(Totales.totalSinImpuesto)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-center justify-center mb-2">
            <div className="text-gray-800 select-none max-w-xs text-right pr-2   rounded-l-lg px-1 py-2 flex-1 dark:text-white text-xs  font-semibold">
              TOTAL DESCUENTOS
            </div>
            <div className={DiseñoPago + " w-56 select-none"}>
              <div
                className="text-gray-900 font-medium text-right pr-10 dark:text-white"
                x-html="netTotal"
              >
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={Totales.totalDescuentos}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </div>
            </div>
          </div>
          {Totales.totalIce !== 0 ? (
            <div className="flex flex-center justify-center mb-2">
              <div className="text-gray-800 select-none max-w-xs text-right pr-2   rounded-l-lg px-1 py-2 flex-1 dark:text-white text-xs font-semibold">
                ICE
              </div>
              <div className={DiseñoPago + " w-56 select-none"}>
                <div
                  className="text-sm text-gray-600 text-right pr-10 dark:text-white"
                  x-html="totalGST"
                >
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={Totales.totalIce}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {Totales.totalIva !== 0 && (
            <div className="flex flex-center justify-center mb-2">
              <div className="text-gray-800 select-none max-w-xs text-right pr-2   rounded-l-lg px-1 py-2 flex-1 dark:text-white text-xs font-semibold">
                IVA 12 %
              </div>
              <div className={DiseñoPago + " w-56 select-none"}>
                <div
                  className="text-gray-800 flex items-center justify-end gap-x-3 font-medium dark:text-white"
                  x-html="totalGST"
                >
                  <svg
                    onClick={() => {
                      decrementarContadorIVA12f();
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 12h6" />
                    <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                  </svg>
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={Totales.totalIva}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                  <svg
                    onClick={() => {
                      incrementarContadorIVA12f();
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                    <path d="M15 12h-6" />
                    <path d="M12 9v6" />
                  </svg>
                </div>
              </div>
            </div>
          )}
          {Totales.totalIva15 !== 0 && (
            <div className="flex flex-center justify-center mb-2">
              <div className="text-gray-800 select-none max-w-xs text-right pr-2   rounded-l-lg px-1 py-2 flex-1 dark:text-white text-xs  font-semibold">
                IVA 15 %
              </div>
              <div className={DiseñoPago + " w-56 select-none"}>
                <div
                  className="text-gray-800 flex items-center justify-end gap-x-3 font-medium dark:text-white"
                  x-html="totalGST"
                >
                  <svg
                    onClick={() => {
                      decrementarContadorf();
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 12h6" />
                    <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                  </svg>
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={Totales.totalIva15}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />

                  <svg
                    onClick={() => {
                      incrementarContadorf();
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                    <path d="M15 12h-6" />
                    <path d="M12 9v6" />
                  </svg>
                </div>
              </div>
            </div>
          )}

          {Totales.totalIva5 !== 0 && (
            <div className="flex flex-center justify-center mb-2">
              <div className="text-gray-800 select-none max-w-xs text-right pr-2   rounded-l-lg px-1 py-2 flex-1 dark:text-white text-xs font-semibold">
                IVA 5 %
              </div>
              <div className={DiseñoPago + " w-56 select-none"}>
                <div
                  className="text-gray-800 flex items-center justify-end gap-x-3 font-medium dark:text-white"
                  x-html="totalGST"
                >
                  <svg
                    onClick={() => {
                      decrementarContadorIVA5f();
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 12h6" />
                    <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                  </svg>

                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={Totales.totalIva5}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />

                  <svg
                    onClick={() => {
                      incrementarContadorIVA5f();
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                    <path d="M15 12h-6" />
                    <path d="M12 9v6" />
                  </svg>
                </div>
              </div>
            </div>
          )}

          {Totales.totalIva13 !== 0 && (
            <div className="flex flex-center justify-center mb-2">
              <div className="text-gray-800 select-none max-w-xs text-right pr-2   rounded-l-lg px-1 py-2 flex-1 dark:text-white text-xs font-semibold">
                IVA 13 %
              </div>
              <div className={DiseñoPago + " w-56 select-none"}>
                <div
                  className="text-gray-800 flex items-center justify-end gap-x-3 font-medium dark:text-white"
                  x-html="totalGST"
                >
                  <svg
                    onClick={() => {
                      decrementarContadorIVA13f();
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 12h6" />
                    <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                  </svg>

                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={Totales.totalIva13}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />

                  <svg
                    onClick={() => {
                      incrementarContadorIVA13f();
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                    <path d="M15 12h-6" />
                    <path d="M12 9v6" />
                  </svg>
                </div>
              </div>
            </div>
          )}

          {Totales.totalIva8 !== 0 && (
            <div className="flex flex-center justify-center mb-2">
              <div className="text-gray-800 select-none max-w-xs text-right pr-2   rounded-l-lg px-1 py-2 flex-1 dark:text-white text-xs font-semibold">
                IVA 8 %
              </div>
              <div className={DiseñoPago + " w-56 select-none"}>
                <div
                  className="text-gray-800 flex items-center justify-end gap-x-3 font-medium dark:text-white"
                  x-html="totalGST"
                >
                  <svg
                    onClick={() => {
                      decrementarContadorIVA8f();
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 12h6" />
                    <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                  </svg>

                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={Totales.totalIva8}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />

                  <svg
                    onClick={() => {
                      incrementarContadorIVA8f();
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                    <path d="M15 12h-6" />
                    <path d="M12 9v6" />
                  </svg>
                </div>
              </div>
            </div>
          )}

          {Totales.totalIRBPNR !== 0 ? (
            <div className="flex flex-center justify-center mb-2">
              <div className="text-gray-800 select-none max-w-xs text-right pr-2   rounded-l-lg px-1 py-2 flex-1 dark:text-white text-sm font-semibold">
                IRBPNR
              </div>
              <div className={DiseñoPago + " w-56 select-none"}>
                <div
                  className="text-sm text-gray-600 dark:text-white"
                  x-html="totalGST"
                >
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={Totales.totalIRBPNR}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {Totales.propina !== 0 ? (
            <div className="flex flex-center justify-center mb-2">
              <div className="text-gray-800 select-none max-w-xs text-right pr-2   rounded-l-lg px-1 py-2 flex-1 dark:text-white text-sm font-semibold">
                PROPINA
              </div>
              <div className={DiseñoPago + " w-56 select-none"}>
                <div
                  className="text-sm text-gray-600 dark:text-white"
                  x-html="totalGST"
                >
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={Totales.propina}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </div>
              </div>
            </div>
          ) : null}

          <div className="flex flex-center justify-center mb-2">
            <div className="text-gray-800 select-none text-right pr-2 text-[0.813rem]  dark:text-white max-w-xs  rounded-l-lg px-1 py-2  flex-1 text-sm font-bold">
              VALOR TOTAL
            </div>
            <div className={DiseñoPago + " w-56 select-none"}>
              <div
                className="text-gray-800 font-bold text-right pr-10 dark:text-white "
                x-html="totalGST"
              >
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={totalImporte}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TotalesComponents;
