import { useContext, useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DownArrowIcon, SearchIcon } from "../../../../Icons/ButtonIcons";
import { InputGlobal } from "../../context/inputContext";
import { ProductoContext } from "../../Productos/context/ProductContext";
import { ExcelIcon } from "../../../../Icons/FileIcon";
import AutoCompleteInput from "../../context/AutoCompleteSelect";
import { KardexContext } from "./context/KardexValoradoContext";
import { useSelector } from "react-redux";
import { PDFIcon } from "../../../../Icons/FileIcon";
import {
  ObtenerSeccionesTransaccion,
  ObtenerCategoriasActivas,
  GenerarExcelKardex,
  GenerarPdfKardex
} from "../../../../services/InventarioService";
import useGlobalToastify from "../../../../features/hooks/GlobalToastify/useGlobalToastify";
import { Transition } from "@headlessui/react";
import { ClipLoader } from "react-spinners";

export const KardexValoradoForm = () => {
  const [openForm, setOpenForm] = useState(true);
  const { productos } = useContext(ProductoContext);
  const [categoriasActivas, setCategoriasActivas] = useState([]);
  const { Kardex, getKardex } = useContext(KardexContext);
  const [isDownloadingExcel, setIsDownloadingExcel] = useState(false);
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
  const [filtros, setFiltros] = useState(null);
  const [periodo, setPeriodo] = useState("");
  const methods = useForm({
    defaultValues: {
      fechaInicio: new Date().toISOString().split("T")[0],
      fechaFin: new Date().toISOString().split("T")[0],
    },
  });
  const { ErrorToast, Toast } = useGlobalToastify();

  const empresa = useSelector((state) => state.empresa.empresa);

  const [listaSecciones, setListaSecciones] = useState([]);

  useEffect(() => {
    if (empresa && empresa.idEmpresa) {
      ObtenerSeccionesTransaccion(
        "/api/transacciones/obtener-secciones",
        empresa.idEmpresa
      ).then((response) => setListaSecciones(response.data));
    }
  }, [empresa]);

  useEffect(() => {
    if (empresa && empresa.idEmpresa) {
      ObtenerCategoriasActivas(
        "/api/categoria/obtener-categorias-activas",
        empresa.idEmpresa
      ).then((response) => setCategoriasActivas(response.data));
    }
  }, [empresa]);

  useEffect(() => {
    if (periodo) {
      const [year, month] = periodo.split("-");
      const firstDay = new Date(year, month - 1, 1).toISOString().split("T")[0];
      const lastDay = new Date(year, month, 0).toISOString().split("T")[0];
      methods.setValue("fechaFin", lastDay);
      methods.setValue("fechaInicio", firstDay);
    }
  }, [periodo]);

  const saveFilter = async (data) => {
    await Toast.dismiss();
    if (
      !data.seccion?.idSeccion &&
      !data.categoria?.idCategoriaProducto &&
      !data.producto?.idProducto
    ) {
      ErrorToast("Debe seleccionar al menos un filtro principal.");
      return;
    }
    if (!data.fechaFin && !data.fechaInicio) {
      ErrorToast("Debe seleccionar un rango de fecha valido.");
      return;
    }
    setFiltros({
      fechaInicio: data.fechaInicio,
      fechaFinal: data.fechaFin,
      idSeccion: data.seccion?.idSeccion ? data.seccion?.idSeccion : "",
      idCategoria: data.categoria?.idCategoriaProducto
        ? data.categoria?.idCategoriaProducto
        : "",
      idProducto: data.producto?.idProducto ? data.producto?.idProducto : "",
    });
    await getKardex({
      fechaInicio: data.fechaInicio,
      fechaFinal: data.fechaFin,
      idSeccion: data.seccion?.idSeccion ? data.seccion?.idSeccion : "",
      idCategoria: data.categoria?.idCategoriaProducto
        ? data.categoria?.idCategoriaProducto
        : "",
      idProducto: data.producto?.idProducto ? data.producto?.idProducto : "",
    });
  };

  useEffect(() => {
    if (methods.watch("producto")) {
      methods.setValue(
        "categoria",
        categoriasActivas.find(
          (categoria) =>
            categoria.idCategoriaProducto ===
            methods.watch("producto")?.idCategoriaProducto
        )
      );
    } else {
      methods.setValue("categoria", "");
    }
  }, [methods.watch("producto")]);

  const searchProducto = async (inputText) => {
    return productos
      .filter((producto) => producto.estado === true)
      .filter(
        (producto) =>
          String(producto.codigo).toLowerCase() ===
            String(inputText).toLowerCase() ||
          String(producto.nombre).toLowerCase() ===
            String(inputText).toLowerCase() ||
          String(producto.codigo)
            .toLowerCase()
            .includes(String(inputText).toLowerCase()) ||
          String(producto.nombre)
            .toLowerCase()
            .includes(String(inputText).toLowerCase())
      );
  };
  const searchSecciones = async (inputText) => {
    return listaSecciones.filter(
      (seccion) =>
        String(seccion.nombreBodega)
          .toLowerCase()
          .includes(String(inputText).toLowerCase()) ||
        String(seccion.nombreSeccion)
          .toLowerCase()
          .includes(String(inputText).toLowerCase()) ||
        String(seccion.establecimiento)
          .padStart(3, "0")
          .toLowerCase()
          .includes(String(inputText).toLowerCase())
    );
  };

  const exportKardexExcel = async () => {
    try {
      setIsDownloadingExcel(true);
      const excelBase64 = await GenerarExcelKardex(empresa.idEmpresa, filtros);
      const excelBinary = atob(excelBase64);
      const byteNumbers = new Array(excelBinary.length);
      for (let i = 0; i < excelBinary.length; i++) {
        byteNumbers[i] = excelBinary.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const blobUrl = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = "Kardex.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (err) {
      console.error("Error al procesar el archivo Excel:", err);
    } finally {
      setIsDownloadingExcel(false);
    }
  };

  const exportKardexPdf = async () => {
    try {
      setIsDownloadingPdf(true);
      const res = await GenerarPdfKardex(empresa.idEmpresa, filtros);
      const fileBase64 = res
      .replace(/\\/g, "")
      .replace(/"/g, "")
      .replace(/\n/g, "");

      const pdfBinary = atob(fileBase64);

      let byteNumbers = new Array(pdfBinary.length);
      
      for (let i = 0; i < pdfBinary.length; i++) {
        byteNumbers[i] = pdfBinary.charCodeAt(i);
      }
      
      const byteArray = new Uint8Array(byteNumbers);
      
      const blob = new Blob([byteArray], {
        type: "application/pdf",
      });
      
      const blobUrl = URL.createObjectURL(blob);
  
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = "Kardex.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(blobUrl);
    } catch (err) {
      console.error("Error al procesar el archivo Pdf:", err);
    } finally {
      setIsDownloadingPdf(false);
    }
  };
  
  

  return (
    <FormProvider {...methods}>
      <div className="p-4 flex flex-row w-full justify-between items-center bg-[#003B5B] rounded-t-md">
        <h1 className="text-xl pl-3 pr-3 text-white font-bold">Filtros</h1>
        <button
          className={`text-white ${
            openForm ? "rotate-180 duration-300" : "rotate-0 duration-300"
          }`}
          onClick={() => setOpenForm(!openForm)}
        >
          <DownArrowIcon />
        </button>
      </div>
      <form
        onSubmit={methods.handleSubmit(saveFilter)}
        autoComplete="off"
        className="flex flex-col gap-1 relative"
      >
        <Transition
          show={openForm}
          enter="transition ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-300"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="w-full border border-gray-200 dark:bg-gray-700 dark:border-gray-600 rounded-b-lg p-6 pl-8 pr-8">
            <div className="grid lg:grid-cols-3 w-full gap-4">
              <InputGlobal
                type={"month"}
                title={"Período"}
                onChange={(e) => setPeriodo(e.target.value)}
                value={periodo}
                name={"periodo"}
              />
              <InputGlobal
                type={"date"}
                title={"Fecha de Inicio"}
                name={"fechaInicio"}
              />
              <InputGlobal
                type={"date"}
                title={"Fecha Final"}
                name={"fechaFin"}
              />
              <AutoCompleteInput
                title={"Producto"}
                disabled={
                  (methods.watch("categoria") &&
                    (methods.watch("producto") === null ||
                      methods.watch("producto") === undefined ||
                      methods.watch("producto") === "")) ||
                  (methods.watch("bodega") &&
                    (methods.watch("categoria") === null ||
                      methods.watch("categoria") === undefined ||
                      methods.watch("categoria") === "" ||
                      methods.watch("producto") === null ||
                      methods.watch("producto") === undefined ||
                      methods.watch("producto") === ""))
                }
                searchFn={searchProducto}
                identificador={"idProducto"}
                option={(e) =>
                  `${String(e["codigo"])} - ${String(e["nombre"])}`
                }
                array={productos}
                parametro={"nombre"}
              />

              <AutoCompleteInput
                title={"Categoria"}
                active={true}
                disabled={
                  methods.watch("producto") ||
                  (methods.watch("bodega") &&
                    (methods.watch("categoria") === null ||
                      methods.watch("categoria") === undefined ||
                      methods.watch("categoria") === "") &&
                    (methods.watch("producto") === null ||
                      methods.watch("producto") === undefined ||
                      methods.watch("producto") === ""))
                }
                array={categoriasActivas}
                identificador={"idCategoriaProducto"}
                option={(e) => e["nombre"]}
                parametro={"nombre"}
              />

              <AutoCompleteInput
                title={"Sección"}
                name={"seccion"}
                searchFn={searchSecciones}
                array={listaSecciones}
                parametro={"nombre"}
                identificador={"idSeccion"}
                option={(e) => `${String(e["bodegaSeccion"])}`}
              />
            </div>
          </div>
        </Transition>
        <div className="flex flex-row flex-wrap gap-2 p-2 items-center mt-3 text-white text-[12px]">
          <button
            type="submit"
            className="p-3 rounded-lg bg-blue-600 text-white whitespace-nowrap hover:bg-blue-700 pr-5 pl-5 flex flex-row gap-2 items-center"
          >
            <span>
              <SearchIcon />
            </span>
            Buscar
          </button>

          {Kardex.length > 0 && filtros !== null && (
            <button
              type="button"
              disabled={isDownloadingExcel}
              onClick={() => exportKardexExcel()}
              className="p-[12px] pl-6 pr-6 rounded-lg bg-gray-600 hover:bg-gray-700 whitespace-nowrap flex flex-row gap-2 items-center"
            >
              {isDownloadingExcel === false ? (
                <>
                  <span>
                    <ExcelIcon />
                  </span>
                  <h1 className="hide-on-small">Generar Excel</h1>
                </>
              ) : (
                <>
                  <ClipLoader color="#d8d4d4" size={"19"} />
                  <span>Cargando</span>
                </>
              )}
            </button>
          )}
          {Kardex.length > 0 && filtros !== null && (
            <button
              className="p-[12px] pl-6 pr-6 rounded-lg bg-gray-600 hover:bg-gray-700 whitespace-nowrap flex flex-row gap-2 items-center"
              disabled={isDownloadingPdf}
              onClick={() => exportKardexPdf()}
              type="button"
            >
              {isDownloadingPdf === false ? (
                <>
                  <span>
                    <PDFIcon w={"15"} h={"15"} />
                  </span>
                  <h1 className="hide-on-small">Generar PDF</h1>
                </>
              ) : (
                <>
                  <ClipLoader color="#d8d4d4" size={"19"} />
                  <span>Cargando</span>
                </>
              )}
            </button>
          )}
        </div>
      </form>
    </FormProvider>
  );
};
