import { useState } from "react";

export const useProgressBar = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const updateProgressBar = (percentaje) => {
    if (progress > 100 + percentaje) {
      setIsUploading(false);
      setProgress(0);
    }
    setProgress((prevState) => prevState + percentaje);
  };

  const getPercentaje = (size) => {
    return parseFloat(100 / size);
  };

  return {
    updateProgressBar,
    getPercentaje,
    progress,
    isUploading,
    setIsUploading,
    setProgress,
  };
};
