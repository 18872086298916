import { InputGlobal } from "../../context/inputContext";
import { SelectGlobal } from "../../context/selectContext";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { getListV2 } from "../../../../services";
import { useFormContext } from "react-hook-form";

export const ProductFinanzasForm = ({ productosCompuestos }) => {
  const empresa = useSelector((store) => store.empresa.empresa);
  const [ImpuestosList, setImpuestosList] = useState([]);
  const { watch, setValue } = useFormContext();

  useEffect(() => {
    getListV2(empresa, "GetAllimpDetalles", "?activo=true&idImpuesto=1").then(
      (response) => setImpuestosList(response._embedded)
    );
  }, [empresa]);

  useEffect(() => {
    if (watch("tipoProducto") === "3af8aada-beb6-486b-bc0f-7afd0c0c28e1") {
      const totalCost =
        productosCompuestos && productosCompuestos.length > 0
          ? productosCompuestos.reduce((accumulator, value) => {
              const costo = parseFloat(value.costo);
              return accumulator + costo * value.cantidad;
            }, 0)
          : 0;
      setValue("costo", totalCost.toFixed(2));
    } else {
      setValue("costo", "");
    }
  }, [watch("tipoProducto"), productosCompuestos]);

  return (
    <div className="flex flex-col">
      <p className="bg-[#003B5B] text-white flex items-center flex-row gap-2 font-semibold rounded-t-lg p-3">
        Datos Financieros
      </p>
      <div className="w-full grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-1 gap-4 p-8 pl-8 pr-8 border-gray-200 dark:border-gray-600 border rounded-b-lg text-[12px]">
        <SelectGlobal
          title={"Impuestos"}
          validations={{ required: true }}
          name={"impuestos"}
        >
          <option value="" selected hidden>
            Seleccione un Impuesto Aplicable
          </option>
          {ImpuestosList?.length > 0 &&
            ImpuestosList.map((el, index) => (
              <option
                key={index}
                data-index={index}
                value={el.idImpuestoDetalle}
              >
                {el.detalle}
              </option>
            ))}
        </SelectGlobal>
        <InputGlobal
          title={"Costo"}
          isMoneyInput={true}
          disabled={
            watch("tipoProducto") === "3af8aada-beb6-486b-bc0f-7afd0c0c28e1"
              ? true
              : false
          }
          type={"number"}
          min={0.000001}
          step={0.000001}
          validations={{ required: true }}
          name={"costo"}
          isPlaceHolder={"0.00"}
        />
        <InputGlobal
          title={"Precio de Venta"}
          isMoneyInput={true}
          type={"number"}
          min={0.01}
          step={0.01}
          validations={{ required: true }}
          name={"precioVenta"}
          isPlaceHolder={"0.00"}
        />
      </div>
    </div>
  );
};
