import { useState, useEffect, useContext } from "react";
import { FormProvider } from "react-hook-form";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { useStore } from "zustand";
import { useModalProductoStore } from "../Crear/ModalProductoContext";
import { EditarProductGeneralForm } from "./EditarProductGeneralForm";
import { EditarProductFinanzasForm } from "./EditarProductFinanzasForm";
import { EditarProductoCompuestoForm } from "./EditarProductoCompuestoForm";
import {
  BackIcon,
  CancelIcon,
  SaveIcon,
  NextIcon,
} from "../../../../Icons/ButtonIcons";
import { ProductoContext } from "../context/ProductContext";
import useGlobalToastify from "../../../../features/hooks/GlobalToastify/useGlobalToastify";
import { CategoriaContext } from "../../Categorias/context/CategoriaContext";
import { useSelector } from "react-redux";
import { v4 as uuidv } from "uuid";
import { toast } from "react-toastify";
import { ObtenerImagenesProducto } from "../../../../services/InventarioService";

export const EditarProductoSection = () => {
  const [principalImage, setPrincipalImage] = useState({
    index: 0,
    id: "",
  });
  const { ProductoAfectado } = useStore(useModalProductoStore);
  const [mappedImages, setMappedImages] = useState([]);
  const [fileInputKey, setFileInputKey] = useState(Date.now());

  const [deletedImages, setDeletedImages] = useState([]);
  const [imageProducts, setImageProducts] = useState([]);
  const [isLoadingImagenes, setIsLoadingImagenes] = useState(true);
  const { ErrorToast } = useGlobalToastify();
  const ListaProductos = ProductoAfectado.subProductos
    ? ProductoAfectado?.subProductos
    : [];
  const [productosCompuestos, setProductosCompuestos] =
    useState(ListaProductos);
  const [startCarrusel, setStartCarrusel] = useState(0);
  const empresa = useSelector((state) => state.empresa.empresa);
  const [endCarrusel, setEndCarrusel] = useState(3);
  const { categorias } = useContext(CategoriaContext);
  const [isError, setIsError] = useState("");
  const { updateProducto } = useContext(ProductoContext);
  const methods = useForm({
    defaultValues: {
      codigo: ProductoAfectado.codigo,
      nombreProducto: ProductoAfectado.nombre,
      detalleProducto: ProductoAfectado.detalle,
      termino: ProductoAfectado.idTerminoProducto,
      usoProducto: ProductoAfectado.idUsoProducto,
      controlFechaCaducidad: String(ProductoAfectado.isFechaCaducidad),
      controlLote: String(ProductoAfectado.isLote),
      isMinimoStock: String(ProductoAfectado.isMinimoStock),
      codigoBarras: ProductoAfectado.codigoBarra,
      costo: parseFloat(ProductoAfectado.costo).toFixed(2),
      precioVenta: parseFloat(ProductoAfectado.precioVenta).toFixed(2),
      tipoProducto: ProductoAfectado.idTipoProducto,
      alertaStock: ProductoAfectado?.alertaStock,
    },
  });
  const navigate = useNavigate();
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    toast.dismiss();
  }, []);

  useEffect(() => {
    if (categorias.length > 0 && ProductoAfectado.idCategoriaProducto) {
      methods.setValue(
        "categoria",
        categorias.find(
          (categoria) =>
            categoria.idCategoriaProducto ===
            ProductoAfectado.idCategoriaProducto
        )
      );
    }
  }, [categorias, ProductoAfectado]);

  useEffect(() => {
    if (ProductoAfectado.imagenes.length > 0) {
      setIsLoadingImagenes(true);
      const imagenes = ProductoAfectado.imagenes.map((imagen) => ({
        idImagenProducto: imagen.idImagenProducto,
        url: imagen.url,
        isPrincipal: imagen.isPrincipal,
        extension: imagen.extension,
      }));
      ObtenerImagenesProducto(imagenes)
        .then((response) => {
          setImageProducts(response.data);
          const index = response.data.findIndex((image) => image.isPrincipal);

          if (index !== -1) {
            const principalImage = {
              id: response.data[index].idImagenProducto,
              index: index,
            };
            setPrincipalImage(principalImage);
          }
        })
        .finally(() => {
          setIsLoadingImagenes(false);
        });
    } else {
      setIsLoadingImagenes(false);
    }
  }, [ProductoAfectado]);

  const handleFileChange = (e) => {
    const files = e.target.files;
    const remainingCapacity = 7 - imageProducts.length;
    const filesToAdd = Array.from(files).slice(0, remainingCapacity);
    const mappedFiles = [];

    if (files.length > 7) {
      setIsError("El número máximo de imágenes permitidas es 7.");
      return;
    } else {
      let filesSize = 0;
      for (const file of filesToAdd) {
        filesSize += file.size;
      }
      if (filesSize > 50 * Math.pow(1024, 2)) {
        setIsError("No se pueden subir imágenes de más de 50MB");
        return;
      }
      setIsError(false);

      filesToAdd.forEach((file) => {
        const idImagenProducto = uuidv();
        mappedFiles.push({
          idImagenProducto,
          archivo: file,
          isPrincipal: false,
        });
      });

      setMappedImages((prevState) => [...prevState, ...mappedFiles]);

      const readFile = (index) => {
        if (index >= filesToAdd.length || imageProducts.length >= 7) {
          return;
        }
        const file = filesToAdd[index];

        if (!(file instanceof Blob)) {
          return;
        }

        const idImagenProducto = mappedFiles[index].idImagenProducto;
        const reader = new FileReader();

        reader.onloadend = () => {
          setImageProducts((prevState) => [
            ...prevState,
            {
              idImagenProducto,
              base64: reader.result,
              isPrincipal: false,
            },
          ]);
          readFile(index + 1);
        };

        reader.readAsDataURL(file);
      };

      readFile(0);
    }
    setFileInputKey(Date.now());
  };

  const GoNextImage = () => {
    if (endCarrusel < imageProducts.length) {
      setStartCarrusel((prevState) => prevState + 1);
      setEndCarrusel((prevState) => prevState + 1);
    } else {
      setStartCarrusel(0);
      setEndCarrusel(3);
    }
  };

  const GoBackImage = () => {
    if (startCarrusel > 0) {
      setStartCarrusel((prevState) => prevState - 1);
      setEndCarrusel((prevState) => prevState - 1);
    } else {
      setStartCarrusel(imageProducts.length - 3);
      setEndCarrusel(imageProducts.length);
    }
  };

  const DeleteImage = (imagen, index) => {
    const filteredImages = imageProducts.filter((image, i) => i !== index);
    setDeletedImages((prevState) => [...prevState, imagen]);
    setImageProducts(filteredImages);
    setMappedImages((prevState) =>
      prevState.filter(
        (image) => image.idImagenProducto !== imagen.idImagenProducto
      )
    );
    if (principalImage.index === index)
      setPrincipalImage({
        id: "",
        index: 0,
      });
    if (principalImage.index > index && principalImage.index > 0) {
      setPrincipalImage((prevState) => ({
        id: "",
        index: prevState.index - 1,
      }));
    }
    if (startCarrusel > 0) {
      setStartCarrusel((prevState) => prevState - 1);
    }
    if (endCarrusel > 3) {
      setEndCarrusel((prevState) => prevState - 1);
    }
  };

  const watchTipoProducto = methods.watch("tipoProducto");

  const editedProduct = async (data) => {
    if (
      data.tipoProducto === "3af8aada-beb6-486b-bc0f-7afd0c0c28e1" &&
      productosCompuestos.length === 0
    ) {
      ErrorToast(
        "Un Producto Compuesto debe contener al menos un producto simple."
      );
      return;
    }
    delete data["producto"];
    delete data["cantidadCompuesto"];
    const editedProduct = {
      idProducto: ProductoAfectado.idProducto,
      idCategoriaProducto: data.categoria.idCategoriaProducto,
      idUsoProducto: data.usoProducto,
      idImpuestoDetalle: data.impuestos,
      idUnidadesMedida: ProductoAfectado.idUnidadesMedida,
      idTerminoProducto: data.termino,
      idEmpresa: empresa.idEmpresa,
      estado: ProductoAfectado.estado,
      idTipoProducto: data.tipoProducto,
      isMinimoStock: data.isMinimoStock,
      alertaStock:
        data.alertaStock && data.isMinimoStock === "true"
          ? data.alertaStock
          : 0,
      isCompuesto:
        data.tipoProducto === "3af8aada-beb6-486b-bc0f-7afd0c0c28e1" &&
        productosCompuestos.length > 0
          ? true
          : false,
      subProductos:
        data.tipoProducto === "3af8aada-beb6-486b-bc0f-7afd0c0c28e1" &&
        productosCompuestos.length > 0
          ? productosCompuestos
          : null,
      newImagenPrincipal:
        principalImage?.id !== ""
          ? principalImage?.id
          : imageProducts.length > 0
          ? imageProducts[0].idImagenProducto
          : "",
      isLote: data.controlLote ? data.controlLote : false,
      isFechaCaducidad: data.controlFechaCaducidad
        ? data.controlFechaCaducidad
        : false,
      costo: String(data.costo),
      precioVenta: String(data.precioVenta),
      imagenes: mappedImages,
      imagenesDelete: deletedImages.length > 0 ? deletedImages : [],
      codigo: data.codigo.trimEnd().trimStart(),
      detalle: data.detalleProducto.trimEnd().trimStart(),
      codigoBarra: data.codigoBarras.trimEnd().trimStart(),
      nombre: data.nombreProducto.trimEnd().trimStart(),
    };
    try {
      setIsSending(true);
      await toast.promise(updateProducto(editedProduct), {
        pending: "Editando producto...",
        success: "¡El producto fue editado con éxito!",
        error: {
          render({ data }) {
            return (
              data?.response?.data?.message ||
              "Hubo un error al editar el producto."
            );
          },
        },
      });
      setTimeout(() => {
        navigate("/inventario/productos/crear");
      }, 2000);
    } catch (err) {
      console.log(err);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <section className="w-full h-fit p-8 bg-white dark:bg-gray-900  rounded-lg shadow-md">
        <div className="bg-gray-200 dark:text-white dark:bg-gray-700 rounded-lg text-[12px] w-full flex flex-col justify-center p-6">
          <h1 className="font-semibold text-2xl font-[Outfit]">
            Información del Producto
          </h1>
          <h4>Editar Información</h4>
        </div>
        <div className="w-full flex flex-col items-center gap-10 justify-center mt-4 p-6">
          <div
            className={`w-full flex flex-col ${
              imageProducts.length === 7 && "mb-6"
            } items-center gap-4 justify-center`}
          >
            {imageProducts.length === 0 && (
              <div className="flex opacity-50 items-center justify-center md:w-96 w-64 md:h-64 h-48 bg-gray-300 rounded dark:bg-gray-700">
                <svg
                  class="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>
            )}
            {imageProducts.length === 0 && (
              <div className="flex lg:flex-row gap-4 flex-col">
                <div className="flex opacity-50 items-center justify-center w-64 h-48 bg-gray-300 rounded dark:bg-gray-700">
                  <svg
                    class="w-10 h-10 text-gray-200 dark:text-gray-600"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 18"
                  >
                    <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                  </svg>
                </div>
                <div className="flex opacity-50 items-center justify-center w-64 h-48 bg-gray-300 rounded dark:bg-gray-700">
                  <svg
                    class="w-10 h-10 text-gray-200 dark:text-gray-600"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 18"
                  >
                    <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                  </svg>
                </div>
                <div className="flex opacity-50 items-center justify-center w-64 h-48 bg-gray-300 rounded dark:bg-gray-700">
                  <svg
                    class="w-10 h-10 text-gray-200 dark:text-gray-600"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 18"
                  >
                    <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                  </svg>
                </div>
              </div>
            )}
            {imageProducts.length > 0 && (
              <img
                src={imageProducts[principalImage.index]?.base64}
                alt="Imagen de tu Producto"
                className="w-96 h-64 rounded-lg"
              />
            )}
            {imageProducts.length > 0 && (
              <div className="flex md:flex-row flex-col items-center gap-4">
                {imageProducts.length > 3 && (
                  <button
                    id="back"
                    className="opacity-60 hover:scale-90"
                    onClick={() => GoBackImage()}
                  >
                    <BackIcon />
                  </button>
                )}
                {imageProducts
                  .map((image, i) => ({
                    ...image,
                    index: i,
                  }))
                  .slice(startCarrusel, endCarrusel)
                  .map((image, i) => (
                    <div
                      key={image.idImagenProducto}
                      className="w-fit h-fit rounded-lg relative"
                    >
                      <img
                        src={image.base64}
                        onClick={() =>
                          setPrincipalImage({
                            index: image.index,
                            id: image.idImagenProducto,
                          })
                        }
                        alt="Imagen de tu Producto"
                        className="w-64 h-48 rounded-lg"
                      />
                      <button
                        onClick={() =>
                          DeleteImage(
                            {
                              idImagenProducto: image.idImagenProducto,
                              extension: image.extension,
                            },
                            image.index
                          )
                        }
                        className="absolute top-2 right-2 text-gray-300"
                      >
                        <CancelIcon />
                      </button>
                    </div>
                  ))}
                {imageProducts.length > 3 && (
                  <button
                    id="next"
                    className="opacity-60 hover:scale-90"
                    onClick={() => GoNextImage()}
                  >
                    <NextIcon />
                  </button>
                )}
              </div>
            )}
          </div>
          {isError && (
            <span className="text-red-600 font-semibold text-[15px]">
              {isError}
            </span>
          )}
          {imageProducts.length < 7 && (
            <div className="file-upload">
              <label
                htmlFor="fileInput"
                className={`${
                  isLoadingImagenes
                    ? "custom-file-disabled"
                    : "custom-file-upload"
                }`}
              >
                <span className="text-white">
                  {isLoadingImagenes
                    ? "Cargando Imagenes..."
                    : "Subir Imagenes"}
                </span>
              </label>
              <input
                key={fileInputKey}
                disabled={isLoadingImagenes}
                id="fileInput"
                accept=".jpg,.png,.jpeg,.gif,.svg,.webp,.avif"
                onChange={handleFileChange}
                type="file"
                multiple
              />
            </div>
          )}
        </div>
        <form
          onSubmit={methods.handleSubmit(editedProduct)}
          autoComplete="off"
          className="w-full flex flex-col gap-3"
        >
          <EditarProductGeneralForm productoAfectado={ProductoAfectado} />
          <EditarProductFinanzasForm
            productosCompuestos={productosCompuestos}
          />
          {watchTipoProducto === "3af8aada-beb6-486b-bc0f-7afd0c0c28e1" && (
            <EditarProductoCompuestoForm
              productosCompuestos={productosCompuestos}
              setProductosCompuestos={setProductosCompuestos}
            />
          )}
          <div className="flex flex-row h-fit gap-4 w-full mt-6 justify-end items-center text-white">
            <NavLink
              exact="true"
              to={"/inventario/productos/crear"}
              className="bg-gray-600 p-5 h-8 text-[12px] hover:bg-gray-700 gap-2 flex items-center justify-center w-28 rounded-lg"
            >
              Cancelar
              <CancelIcon />
            </NavLink>
            <button
              type="submit"
              disabled={isSending}
              className="bg-[#04BC53] hover:bg-green-500 p-5 h-8 text-[12px] gap-2  w-28 flex items-center justify-center rounded-lg"
            >
              Guardar
              <SaveIcon />
            </button>
          </div>
        </form>
      </section>
    </FormProvider>
  );
};
