import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const ConsultaContribuyente = (props) => {
  const [isPagesMenuOpen, setIsPagesMenuOpen] = useState(false);

  const [isPagesMenuOpen2, setIsPagesMenuOpen2] = useState(false);

  const hanldeclick2 = () => {
    setIsPagesMenuOpen2(!isPagesMenuOpen2);
  };

  return (
    <ul className="pt-2 text-white" title={!props.OpenMenu ? "Consulta" : ""}>
      {props.OpenMenu && (
        <NavLink
          title="Consulta Contribuyente"
          className={
            " text-sm flex items-center  hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md"
          }
          exact="true"
          to="/consulta/consulta-contribuyente"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
              clipRule="evenodd"
            />
          </svg>
          <span
            className={`${
              !props.OpenMenu && "hidden"
            } origin-left duration-200`}
          >
            Consulta de RUC SRI
          </span>
        </NavLink>
      )}

      {!props.OpenMenu && !props.openMobil && (
        <NavLink
          title="Consulta Contribuyente"
          className={
            " text-sm flex items-center hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md"
          }
          exact="true"
          to="/consulta/consulta-contribuyente"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
              clipRule="evenodd"
            />
          </svg>
          <span
            className={`${
              !props.OpenMenu && "hidden"
            } origin-left duration-200`}
          >
            Consulta de RUC SRI
          </span>
        </NavLink>
      )}

      {props.openMobil ? (
        <NavLink
          title="Consulta Contribuyente"
          className={
            " text-sm flex items-center  hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md"
          }
          exact="true"
          to="/consulta/consulta-contribuyente"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
              clipRule="evenodd"
            />
          </svg>
          <span
            className={`${
              !props.OpenMenu && ""
            } text-gray-300 origin-left duration-200`}
          >
            Consulta de RUC SRI
          </span>
        </NavLink>
      ) : null}
    </ul>
  );
};

export default ConsultaContribuyente;
