import { TransaccionesProvider } from "../../TiposOperaciones/TransaccionesEmitidas/context/TransaccionesContext";
import { KardexValorado } from "./KardexValorado";
import { ProductoProvider } from "../../Productos/context/ProductContext";
import { BodegaProvider } from "../../Bodegas/Crear/context/BodegasContext";
import { KardexProvider } from "./context/KardexValoradoContext";

export const IndexKardexValorado = () => {
  return (
    <KardexProvider>
      <TransaccionesProvider>
        <BodegaProvider>
          <ProductoProvider>
            <KardexValorado />
          </ProductoProvider>
        </BodegaProvider>
      </TransaccionesProvider>
    </KardexProvider>
  );
};
