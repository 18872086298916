import { SmallModalInfoSri } from "../../../features/components/SmallModalInfoSri";
import UserErrorIcon from "../../Mantenimiento/Contribuyentes/AddContribuyente/Icons/userErrorIcon";

export const ModalError = () => {
  return (
    <SmallModalInfoSri
      buttonModal="ModalErrorSolicitud"
      title="Error al generar"
    >
      <div className="relative z-0 my-2 w-full group">
        <div className="flex flex-col items-center justify-center gap-1.5 text-center">
          <div className="">
            <UserErrorIcon width={55} height={55} />
          </div>
          <div className="flex flex-col mt-4">
            <p className="font-semibold ">
              Hubo un error al generar tu solicitud de descarga
            </p>
            <p className="text-center text-gray-600 my-2">
              Intente de nuevo mas tarde.
            </p>
          </div>
        </div>
      </div>
    </SmallModalInfoSri>
  );
};
