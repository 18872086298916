import React, { createContext, useState, useEffect } from "react";
import {
  ObtenerElementoConfiguracion,
  ObtenerConfiguracionInventarioEmpresa,
  GuardarConfiguracion,
} from "../../../../services/InventarioService";
import { useSelector } from "react-redux";
import { ModalStatusConfiguracion } from "../modalConfiguracion.Slice";

export const TrazabilidadContext = createContext();

export const TrazabilidadProvider = ({ children }) => {
  const [trazabilidad, setTrazabilidad] = useState([]);
  const empresa = useSelector((state) => state.empresa.empresa);
  const [trazabilidadEmpresa, setTrazabilidadEmpresa] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const modalStatus = useSelector(
    (state) => state.modalConfiguracion.modalStatus
  );

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true)
        if(trazabilidad.length === 0) {
          await getTrazabilidad()
        }
        if (
          empresa &&
          empresa.idEmpresa &&
          modalStatus === ModalStatusConfiguracion.trazabilidad
        ) {
          await getEmpresaTrazabilidad();
        }
      } catch (err) {
         console.error(err)
      } finally {
        setIsLoading(false)
      }
    })();
  }, [empresa, modalStatus]);

  const getTrazabilidad = async () => {
    const response = await ObtenerElementoConfiguracion(
      "/api/trazabilidad/obtener-trazabilidad"
    );
    setTrazabilidad(response.data);
  };

  const getEmpresaTrazabilidad = async () => {
    const res = await ObtenerConfiguracionInventarioEmpresa(
      "/api/trazabilidad/obtener-trazabilidad-empresa",
      empresa.idEmpresa
    );
    setTrazabilidadEmpresa(res.data);
  };

  const saveChangesTrazabilidad = async () => {
    const changes = {
      idEmpresa: empresa.idEmpresa,
      idTrazabilidades: trazabilidadEmpresa,
    };
    await GuardarConfiguracion("/api/trazabilidad/crear-trazabilidad", changes);
  };

  return (
    <TrazabilidadContext.Provider
      value={{
        trazabilidad,
        trazabilidadEmpresa,
        isLoading,
        setTrazabilidadEmpresa,
        saveChangesTrazabilidad,
      }}
    >
      {children}
    </TrazabilidadContext.Provider>
  );
};
