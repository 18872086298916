import React, { useEffect, useState, useContext } from "react";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { getListV2 } from "../../services";
import { StyleInput, StyleInputDisabled, StyleInputError } from "../Constantes";
import MesageError from "./MessageError";
import { Transition } from "@headlessui/react";
import { ClipLoader } from "react-spinners";
import axios from "axios";

const Contribuyente = ({ formik, IsShowConsumFinal, Totales }) => {
  const empresa = useSelector((store) => store.empresa.empresa);

  const [BuscarCliente, setBuscarCliente] = useState(false);
  const [BuscarClienteIndetificacion, setBuscarClienteIndetificacion] =
    useState(false);
  const [
    BuscarClienteIndetificacionError,
    setBuscarClienteIndetificacionError,
  ] = useState(false);
  const [ConsumidorFinalToggle, setConsumidorFinalToggle] = useState(false);
  const [BuscarClienteRazonSocial, setBuscarClienteRazonSocial] = useState([]);
  const [LoadingBuscarClienteRazon, setLoadingBuscarClienteRazon] =
    useState(false);

  const [LoadingRazonOptions, setLoadingRazonOptions] = useState(false);

  const permisos = useSelector((store) => store.permisosEmpresa.permisos);

  const handleOnclick = async () => {
    if (!formik.values?.clienteIdentificacion.trim()) return;
    await handleOnBlurCliente(formik.values?.clienteIdentificacion);
  };

  const handleOnclickRazonSocial = async () => {
    if (!LoadingBuscarClienteRazon) {
      setBuscarCliente(!BuscarCliente);
    }
  };

  const handleOnClickCliente = (cliente, index) => {
    setBuscarClienteRazonSocial([]);
    setBuscarCliente(false);
    formik.setFieldValue("clienteRazonSocial", cliente.razonSocial);
    formik.setFieldValue("clienteIdentificacion", cliente.identificacion);
    handleOnBlurClienteOptions(cliente.identificacion);
  };

  useEffect(() => {
    const timeOutId = setTimeout(
      () => handleSearchRazonSocial(formik.values?.clienteRazonSocial),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [formik.values?.clienteRazonSocial]);

  const handleSearchRazonSocial = async (param) => {
    try {
      setLoadingRazonOptions(true);
      if (param) {
        if (param.trim() !== "") {
          if (param.trim().length > 2) {
            const data = await getListV2(
              empresa,
              "GetContribuyenteByRazonSocial",
              `?razonSocial=${param.trim()}&isActive=1&empresaId=${
                empresa.idEmpresa
              }`
            );
            setBuscarClienteRazonSocial(data._embedded);
          }
        }
      } else {
        setBuscarClienteRazonSocial([]);
      }
    } finally {
      setLoadingRazonOptions(false);
    }
  };

  const handleOnBlurRazonSocial = async (value) => {
    try {
      setLoadingBuscarClienteRazon(true);

      if (!value) return formik.setFieldValue("clienteRazonSocial", "");

      const data = await getListV2(
        empresa,
        "GetContribuyenteByRazonSocial",
        `?razonSocial=${value.trim()}&isActive=1&empresaId=${empresa.idEmpresa}`
      );
      const contribuyente = data._embedded[0];

      formik.setFieldValue("clienteRazonSocial", contribuyente.razonSocial);
      formik.setFieldValue(
        "clienteIdentificacion",
        contribuyente.identificacion
      );
      formik.setFieldValue("clienteDireccion", contribuyente.direccion);
      formik.setFieldValue("clienteEmail", contribuyente.correo);
      formik.setFieldValue("clienteTelefono", contribuyente.telefonoCelular);
      formik.setFieldValue("idContribuyente", contribuyente.idContribuyente);
      formik.setFieldValue("IsPartRel", contribuyente.isPartRel ? "1" : "0");
    } catch (err) {
      // formik.setFieldValue("clienteIdentificacion", "");
      // formik.setFieldValue("clienteDireccion", "");
      // formik.setFieldValue("clienteEmail", "");
      // formik.setFieldValue("clienteTelefono", "");
    } finally {
      setLoadingBuscarClienteRazon(false);
      formik.validateForm();
    }
  };

  const handleOnBlurCliente = async (value) => {
    setBuscarCliente(false);

    if (value) {
      if (value.length > 5) {
        setBuscarClienteIndetificacionError(false);

        setBuscarClienteIndetificacion(true);

        // agregar el codigo de buscar por cedula en la tabla contribuyentes
        try {
          let typeIdentification = 5;

          if (!value) return formik.setFieldValue("clienteIdentificacion", "");

          if (value.length === 10) typeIdentification = 1;
          if (value.length === 13) {
            value = value.substr(0, 10);
            typeIdentification = 2;
          }
          let data;

          if (permisos.some((s) => s.codigo === "36")) {
            const res = await axios.get(
              `api/Contribuyente/ObtenerContribuyenteBaseCatrastoForm?idEmpresa=${
                empresa.idEmpresa
              }&identificacion=${value.trim()}`
            );
            data = res.data.data;
          } else {
            const res = await getListV2(
              empresa,
              "SearchContribuyenteForForm",
              `?identificacion=${value.trim()}`
            );

            data = res;
          }

          // const valores = data;

          //formik.setFieldValue("clienteIdentificacion", valores.esRuc ? valores.identificacion + "001" : valores.identificacion);
          formik.setFieldValue("clienteRazonSocial", data.razonSocial);
          formik.setFieldValue("clienteDireccion", data.direccion);
          formik.setFieldValue("clienteEmail", data.correo);
          formik.setFieldValue("clienteTelefono", data.telefonoCelular || "");
          formik.setFieldValue("idContribuyente", data.idContribuyente);
        } catch (err) {
          formik.setFieldValue("clienteRazonSocial", "");
          formik.setFieldValue("clienteDireccion", "");
          formik.setFieldValue("clienteEmail", "");
          formik.setFieldValue("clienteTelefono", "");
          setBuscarClienteIndetificacionError(true);
        } finally {
          formik.validateForm();
        }

        setBuscarClienteIndetificacion(false);
      } else {
        setBuscarClienteIndetificacionError(true);
      }
    }
  };

  const handleOnBlurClienteOptions = async (value) => {
    setBuscarCliente(false);

    if (value) {
      if (value.length > 5) {
        setLoadingBuscarClienteRazon(true);

        // agregar el codigo de buscar por cedula en la tabla contribuyentes
        try {
          let typeIdentification = 5;

          if (!value) return formik.setFieldValue("clienteIdentificacion", "");

          if (value.length === 10) typeIdentification = 1;
          if (value.length === 13) {
            value = value.substr(0, 10);
            typeIdentification = 2;
          }
          let data;

          if (permisos.some((s) => s.codigo === "36")) {
            const res = await axios.get(
              `api/Contribuyente/ObtenerContribuyenteBaseCatrastoForm?idEmpresa=${
                empresa.idEmpresa
              }&identificacion=${value.trim()}`
            );
            data = res.data.data;
          } else {
            const res = await getListV2(
              empresa,
              "SearchContribuyenteForForm",
              `?identificacion=${value.trim()}`
            );

            data = res;
          }

          // const valores = data;

          //formik.setFieldValue("clienteIdentificacion", valores.esRuc ? valores.identificacion + "001" : valores.identificacion);
          formik.setFieldValue("clienteRazonSocial", data.razonSocial);
          formik.setFieldValue("clienteDireccion", data.direccion);
          formik.setFieldValue("clienteEmail", data.correo);
          formik.setFieldValue("clienteTelefono", data.telefonoCelular || "");
          formik.setFieldValue("idContribuyente", data.idContribuyente);
        } catch (err) {
          formik.setFieldValue("clienteRazonSocial", "");
          formik.setFieldValue("clienteDireccion", "");
          formik.setFieldValue("clienteEmail", "");
          formik.setFieldValue("clienteTelefono", "");
        } finally {
          formik.validateForm();
        }

        setLoadingBuscarClienteRazon(false);
      }
    }
  };

  useEffect(() => {
    if (ConsumidorFinalToggle) {
      formik.setFieldValue("clienteRazonSocial", "CONSUMIDOR FINAL");
      formik.setFieldValue("clienteIdentificacion", "9999999999999");
      formik.setFieldValue("clienteDireccion", "dasasasasasasasasasasasasas");
      formik.setFieldValue("clienteEmail", "saddsadawadssdasdadssaddas");
      formik.setFieldValue("clienteTelefono", "0999999999");
    } else {
      formik.setFieldValue("clienteRazonSocial", "");
      formik.setFieldValue("clienteDireccion", "");
      formik.setFieldValue("clienteIdentificacion", "");
      formik.setFieldValue("clienteEmail", "");
      formik.setFieldValue("clienteTelefono", "");
    }
  }, [ConsumidorFinalToggle]);

  useEffect(() => {
    if (ConsumidorFinalToggle && Totales.importeTotal > 50) {
      document.getElementById("ModalConsumAlertButton").click();
    }
  }, [Totales]);

  useEffect(() => {
    if (IsShowConsumFinal) {
      setConsumidorFinalToggle(false);
    }
  }, [IsShowConsumFinal]);

  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="w-full dark:border-gray-600 border lg:mt-2 rounded-lg ">
      {ConsumidorFinalToggle && Totales.importeTotal < 50 ? (
        <div>
          <div className="text-sm  py-1.5 my-2 px-2 text-left bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 dark:border-gray-700 rounded-t-lg font-medium leading-6 text-white flex justify-between">
            <h3>
              Contribuyente / Cliente
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
            </h3>

            <div className="flex">
              <h3>Consumidor final</h3>
              <input
                className="ml-2"
                type="checkbox"
                name="consumidorFinal"
                value={ConsumidorFinalToggle}
                onChange={(e) =>
                  setConsumidorFinalToggle(!ConsumidorFinalToggle)
                }
                id="consumidorFinal"
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-4">
            <div className="relative  w-full group">
              <div className="relative z-0 mb-2 w-full group">
                <label className="block text-[0.813rem] font-medium text-black dark:text-white">
                  RUC / Cédula: *{" "}
                </label>
                <input
                  type="text"
                  className={StyleInputDisabled}
                  name="clienteIdentificacion"
                  value={"9999999999999"}
                  disabled
                />
              </div>
            </div>
            <div className="relative z-0 mb-2 w-full group">
              <label
                htmlFor="company-website"
                className="block text-[0.813rem] font-medium text-black dark:text-white"
              >
                {" "}
                Razón Social: *{" "}
              </label>
              <input
                type="text"
                className={StyleInputDisabled}
                value={"CONSUMIDOR FINAL"}
                disabled
              />
            </div>
          </div>
          <div className="flex lg:flex-row flex-col gap-1">
            <div className="basis-[40%] ">
              <label className="block text-[0.813rem] font-medium text-black dark:text-white">
                Correo: *{" "}
              </label>
              <input type="text" className={StyleInputDisabled} disabled />
            </div>
            <div className="basis-1/4 ">
              <label className="block text-[0.813rem] font-medium text-black dark:text-white">
                Teléfono:{" "}
              </label>
              <NumberFormat
                format="###-###-####"
                className={StyleInputDisabled}
                value={""}
                disabled
              />
            </div>
            <div className="basis-[50%]">
              <label className="block text-[0.813rem] font-medium text-black dark:text-white">
                Dirección: *{" "}
              </label>
              <input
                type="text"
                value={""}
                className={StyleInputDisabled}
                disabled
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="text-sm py-1.5  px-2 text-left bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 dark:border-gray-700 rounded-t-lg font-medium leading-6 text-white flex justify-between">
            <h3 className="">Contribuyente / Cliente</h3>
            <svg
              onClick={() => setIsOpen(!isOpen)}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className={`w-4 h-4 my-auto lg:hidden md:hidden text-white duration-150 ${
                isOpen ? "transform rotate-180" : ""
              }`}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
            {IsShowConsumFinal ? (
              <></>
            ) : (
              <div className="flex">
                <h3>Consumidor final</h3>
                <input
                  className="ml-2"
                  type="checkbox"
                  name="consumidorFinal"
                  value={ConsumidorFinalToggle}
                  onChange={(e) => {
                    setConsumidorFinalToggle(!ConsumidorFinalToggle);
                  }}
                  id="consumidorFinal"
                />
              </div>
            )}
          </div>
          <Transition
            show={isOpen}
            enter="transition ease-out duration-300" // Aumenta la duración de entrada
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-300" // Aumenta la duración de salida
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="grid md:grid-cols-5 md:gap-1 px-4 py-2">
              <div className="relative  w-full group">
                <div className="relative z-0 mb-2 w-full group">
                  <label className="block text-[0.813rem] font-medium text-black dark:text-white">
                    {" "}
                    RUC / Cédula: *{" "}
                  </label>
                  <input
                    type="text"
                    className={
                      (formik.touched.clienteIdentificacion &&
                        formik.errors.clienteIdentificacion) ||
                      BuscarClienteIndetificacionError
                        ? StyleInputError
                        : StyleInput
                    }
                    placeholder="Digite el RUC / Cédula"
                    name="clienteIdentificacion"
                    id="clienteIdentificacion"
                    value={formik.values.clienteIdentificacion}
                    onChange={(e) => {
                      const trimmedValue = e.target.value.trim();
                      formik.setFieldValue(
                        "clienteIdentificacion",
                        trimmedValue
                      );
                    }}
                    onBlur={(e) => handleOnBlurCliente(e.target.value)}
                    maxLength="20"
                  />

                  <button
                    onClick={handleOnclick}
                    disabled={!formik.values.clienteIdentificacion?.trim()}
                    type="button"
                    id="dropdownSearchButton"
                    data-dropdown-toggle="dropdownSearch"
                    data-dropdown-placement="bottom"
                    className={`absolute inset-y-0 right-0 ${
                      !formik.values.clienteIdentificacion?.trim() &&
                      "opacity-60"
                    } items-center pl-3 text-sm text-gray-900 pt-4  border border-r-0 border-t-0 border-l-0 border-b-0 border-gray-300  dark:text-gray-400 dark:border-gray-600`}
                  >
                    {BuscarClienteIndetificacion ? (
                      <>
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline mr-3 w-4 h-4 animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          className="inline mr-2 -ml-1 w-5 h-5 dark:stroke-white dark:hover:stroke-blue-600  "
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          ></path>
                        </svg>
                      </>
                    )}
                  </button>
                </div>
                {formik.touched.clienteIdentificacion &&
                formik.errors.clienteIdentificacion ? (
                  <MesageError message={formik.errors.clienteIdentificacion} />
                ) : null}
              </div>
              <div >
                <div className="relative z-0 mb-2 w-full group">
                  <label
                    htmlFor="company-website"
                    className="block text-[0.813rem] font-medium text-black dark:text-white"
                  >
                    {" "}
                    Razón social: *{" "}
                  </label>
                  <input
                    type="text"
                    className={
                      formik.touched.clienteRazonSocial &&
                      formik.errors.clienteRazonSocial
                        ? StyleInputError
                        : StyleInput
                    }
                    placeholder="Digite la Razón Social"
                    id="clienteRazonSocial"
                    name="clienteRazonSocial"
                    value={formik.values.clienteRazonSocial}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "clienteRazonSocial",
                        e.target?.value
                      );
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        setBuscarCliente(false);
                        handleOnBlurRazonSocial(e.target.value);
                      }
                    }}
                    maxLength="300"
                  />
                  <button
                    onClick={handleOnclickRazonSocial}
                    type="button"
                    id="dropdownSearchButton"
                    data-dropdown-toggle="dropdownSearch"
                    data-dropdown-placement="bottom"
                    className={`absolute inset-y-0 right-0 items-center pl-3 text-sm text-gray-900 pt-4  border border-r-0 border-t-0 border-l-0 border-b-0 border-gray-300  dark:text-gray-400 dark:border-gray-600`}
                  >
                    {LoadingBuscarClienteRazon ? (
                      <>
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline mr-3 w-4 h-4 animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          className="inline mr-2 -ml-1 w-5 h-5 dark:stroke-white dark:hover:stroke-blue-600  "
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          ></path>
                        </svg>
                      </>
                    )}
                  </button>
                  <div
                    id="dropdownSearch"
                    className={`${
                      BuscarCliente ? "block" : "hidden"
                    }  bg-white rounded shadow dark:bg-gray-700 mt-1 absolute w-full`}
                    style={{ zIndex: "150" }}
                  >
                    <ul
                      className="overflow-y-auto px-3 mt-4 pb-3 h-48 text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="dropdownSearchButton"
                    >
                      {BuscarClienteRazonSocial.length === 0 &&
                      !LoadingRazonOptions ? (
                        <li key="01">
                          <div className="text-sm mt-1 text-yellow-400 dark:text-yellow-400 inline-flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                            <div style={{ position: "relative" }}>
                              <span className="font-semibold">
                                No se encontraron datos
                              </span>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {BuscarClienteRazonSocial.length > 0 &&
                        !LoadingRazonOptions &&
                        BuscarClienteRazonSocial.map((el, index) => (
                          <li key={index}>
                            <div
                              onClick={() =>
                                handleOnClickCliente(
                                  {
                                    identificacion: el.identificacion,
                                    razonSocial: el.razonSocial,
                                  },
                                  1
                                )
                              }
                              className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                                />
                              </svg>
                              <label
                                htmlFor="checkbox-item-11"
                                className="py-2 ml-2 w-full text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                              >
                                {el.identificacion} - {el.razonSocial}
                              </label>
                            </div>
                          </li>
                        ))}
                      {LoadingRazonOptions && (
                        <div className="w-full flex items-center h-full justify-center">
                          <ClipLoader color="#d8d4d4" size={"35"} />
                        </div>
                      )}
                    </ul>
                    <div className="flex items-center p-3 text-sm font-medium text-red-600 bg-gray-50 border-t border-gray-200 dark:border-gray-600  dark:bg-gray-700  dark:text-red-500 "></div>
                  </div>
                </div>
                {formik.touched.clienteRazonSocial &&
                formik.errors.clienteRazonSocial ? (
                  <MesageError message={formik.errors.clienteRazonSocial} />
                ) : null}
              </div>
              <div className="">
                <label className="block text-[0.813rem] font-medium text-black dark:text-white">
                  {" "}
                  Correo: *{" "}
                </label>
                <input
                  type="text"
                  className={
                    formik.touched.clienteEmail && formik.errors.clienteEmail
                      ? StyleInputError
                      : StyleInput
                  }
                  placeholder=" "
                  id="clienteEmail"
                  name="clienteEmail"
                  value={formik.values.clienteEmail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength="300"
                />
              </div>

              <div className="">
                <label className="block text-[0.813rem] font-medium text-black dark:text-white">
                  {" "}
                  Teléfono:{" "}
                </label>
                <NumberFormat
                  format="###-###-####"
                  className={
                    formik.touched.clienteTelefono &&
                    formik.errors.clienteTelefono
                      ? StyleInputError
                      : StyleInput
                  }
                  placeholder=" "
                  id="clienteTelefono"
                  name="clienteTelefono"
                  value={formik.values.clienteTelefono}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength="20"
                />
              </div>
              <div className="">
                <label className="block text-[0.813rem] font-medium text-black dark:text-white">
                  {" "}
                  Dirección:*{" "}
                </label>
                <input
                  type="text"
                  className={
                    formik.touched.clienteDireccion &&
                    formik.errors.clienteDireccion
                      ? StyleInputError
                      : StyleInput
                  }
                  placeholder=" "
                  id="clienteDireccion"
                  name="clienteDireccion"
                  value={formik.values.clienteDireccion}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength="300"
                />
              </div>
            </div>
          </Transition>
        </div>
      )}
    </div>
  );
};

export default Contribuyente;
