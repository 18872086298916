import React, { useContext, useState, useRef, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as XLSX from "xlsx";
import useGlobalToastify from "../../../features/hooks/GlobalToastify/useGlobalToastify";
import { CalcularImpuestos, GetCodigoIva, GetInfoTarifas } from "../../EmisionElectronico/EmitirFactura/services/impuestos";
import { roundToTwo } from "../../../services/Utilitario";
import ModalErroresExcel from "../shared/ModalErroresExcel";
import { getListV2 } from "../../../services/index"
import { toast } from "react-toastify";
import ModalProgress from "../shared/ModalProgress";
import FacturasTabla from "./FacturasTabla";
import ResultadoTabla from "./ResultadoTabla";
import ModalAdvertencias from "../shared/ModalAdvertencias";
import { EmitirFacturaContext } from "../../EmisionElectronico/EmitirFactura/EmitirFacturaProvider";


const SubirFacturas = () => {
    const {
        setSecuencialesExistentes, setSecuencialesProblemas, secuencialesExistentes, secuencialesProblemas, groupedData, setGroupedData
    } = useContext(EmitirFacturaContext);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalAdVisible, setIsModalAdVisibile] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [toUploading, setToUploading] = useState(true);
    const [progress, setProgress] = useState(0);
    const [documentsProcessed, setDocumentsProcessed] = useState(0);
    const [totalDocuments, setTotalDocuments] = useState(0);
    const [erroresDetalles, setErroresDetalles] = useState([])
    const [advertenciasDetalles, setAdvertenciasDetalles] = useState([]);
    const [formasPago, setFormasPago] = useState({});
    const [tarifas, setTarifas] = useState({});
    const [isPostingComplete, setIsPostingComplete] = useState(false);
    const [errorServer, setErrorServer] = useState(false);
    const navigate = useNavigate();

    const empresa = useSelector((store) => store.empresa.empresa);
    const { ErrorToast } = useGlobalToastify();
    const inputFileRef = useRef(null);

    const [IsLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const loadFormasPago = async () => {
            try {
                const formasPago = await getListV2(empresa, "GetFormasPago");
                const formaPagoMap = formasPago._embedded.reduce((map, formaPago) => {
                    if (formaPago.activo) {
                        map[formaPago.codigo] = formaPago.idFormaPagoSri;
                    }
                    return map;
                }, {});

                setFormasPago(formaPagoMap);
            } catch (error) {
                console.error("Error al cargar formas de pago", error);
            }
        };

        loadFormasPago();
        GetInfoTarifas(empresa, setTarifas);

    }, []);


    const limpiar = () => {
        setSecuencialesProblemas([]);
        setSecuencialesExistentes([])
        setGroupedData([]);
        setAdvertenciasDetalles([]);
        setProgress(0);
    }

    useEffect(() => {
        if (advertenciasDetalles.length > 0) {
            setIsModalAdVisibile(true);
        }
    }, [advertenciasDetalles]);

    const handleFileImportSeccion = (e) => {
        try {
            const importedFile = e.target.files[0];
            const reader = new FileReader();
            setSecuencialesProblemas([]);
            setSecuencialesExistentes([])
            setGroupedData([]);
            setAdvertenciasDetalles([]);
            setIsLoading(true);
            setToUploading(true);
            setProgress(0);

            reader.onload = (e) => {
                try {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });

                    const sheetNames = workbook.SheetNames;
                    const sheetsData = {};

                    sheetNames.forEach((sheetName) => {
                        const worksheet = workbook.Sheets[sheetName];
                        const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                        const rowsWithLineNumbers = rows.map((row, index) => ({
                            lineNumber: index + 1,
                            data: row
                        }));

                        sheetsData[sheetName] = filterCompletelyEmptyRows(rowsWithLineNumbers);
                    });

                    const facturaData = sheetsData['FACTURA'].filteredRows;

                    const groupedData = groupDataBySecuencial(facturaData);

                    setGroupedData(groupedData);

                } catch (error) {
                    console.error("Error in reader.onload:", error);
                    setGroupedData([]);
                    setAdvertenciasDetalles([]);
                    setIsModalAdVisibile(false);
                    ErrorToast('El formato del documento no es correcto.');
                    return;
                } finally {
                    setIsLoading(false);
                }
            };

            reader.readAsArrayBuffer(importedFile);
            e.target.value = null;
        } catch (error) {
            console.error("Error in handleFileImportSeccion:", error);
            setIsLoading(false);
            ErrorToast("Error al importar archivo excel.");
        }
    };

    const filterCompletelyEmptyRows = (rows) => {
        const advertencias = [];

        const filteredRows = rows.filter((row, index) => {
            const [establecimiento, ptoEmision, secuencial] = row.data;

            const isRowCompletelyEmpty = !establecimiento && !ptoEmision && !secuencial;

            if (!isRowCompletelyEmpty) {
                if (!establecimiento) {
                    advertencias.push({
                        LINEA: index + 2,
                        ESTABLECIMIENTO: 'N/A',
                        PTOEMISION: ptoEmision || 'N/A',
                        SECUENCIAL: secuencial || 'N/A',
                        WARNING: 'Valor de Establecimiento faltante, dicha línea no será considerada completamente.',
                    });
                }
                if (!ptoEmision) {
                    advertencias.push({
                        LINEA: index + 2,
                        ESTABLECIMIENTO: establecimiento || 'N/A',
                        PTOEMISION: 'N/A',
                        SECUENCIAL: secuencial || 'N/A',
                        WARNING: 'Valor de Punto de Emisión faltante, dicha línea no será considerada completamente.',
                    });
                }
                if (!secuencial) {
                    advertencias.push({
                        LINEA: index + 2,
                        ESTABLECIMIENTO: establecimiento || 'N/A',
                        PTOEMISION: ptoEmision || 'N/A',
                        SECUENCIAL: 'N/A',
                        WARNING: 'Valor de Secuencial faltante, dicha línea no será considerada completamente.',
                    });
                }

                return true;
            }

            return false;
        });

        setAdvertenciasDetalles(advertencias);

        return { filteredRows: filteredRows.map(row => row.data) };
    };


    const parseFecha = (fechaStr) => {
        const [day, month, year] = fechaStr.split('/').map(Number);
        return new Date(year, month - 1, day);
    };

    const groupDataBySecuencial = (data) => {
        const grouped = {};
        const errores = [];
        const advertencias = [];
        const fechaActual = new Date();
        const fechaActualSinHora = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), fechaActual.getDate());
        const rangoInferior = new Date(fechaActual);
        rangoInferior.setHours(fechaActual.getHours() - 72);

        const rangoInferiorSinHora = new Date(rangoInferior.getFullYear(), rangoInferior.getMonth(), rangoInferior.getDate());

        const cleanCurrencyValue = (value) => {
            if (typeof value === 'string') {
                return parseFloat(value.replace(/[^0-9.-]+/g, '').replace(',', '.'));
            }
            return value;
        };

        data.slice(1).forEach((item, index) => {

            const key = `${Number(item[0])}-${Number(item[1])}-${Number(item[2])}`;

            const establecimiento = item[0];
            const ptoEmision = item[1];
            const secuencial = item[2];
            const cliente = item[4];
            const identificacionCliente = String(item[5]).trim();
            const direccionCliente = item[6];
            const correoCliente = item[7];
            const fechaEmision = item[3];
            const guiaRemision = item[8];
            const codigoPrincipal = item[9];
            const codigoAuxiliar = item[10] ?? "";
            const descripcion = item[11]
                .replace(/\r?\n/g, ' \n')   
                .replace(/\s{2,}/g, ' ')
                .replace(/"/g, '')   
                .trim();               

            const cantidad = Number(item[12]);
            const precioUnitario = cleanCurrencyValue(item[13]);
            const descuento = item[14] ? cleanCurrencyValue(item[14]) : 0;
            const totalSinImpuesto = cleanCurrencyValue(item[15]);
            const total = cleanCurrencyValue(item[18]);
            const codigoIva = item[16];
            const codFormaPago = item[25];
            const plazo = item[26];
            const unidadTiempo = item[27];

            if (!grouped[key]) {
                const detallesRequeridos = [
                    { value: establecimiento, name: 'Establecimiento' },
                    { value: ptoEmision, name: 'Punto de Emisión' },
                    { value: secuencial, name: 'Secuencial' },
                    { value: cliente, name: 'Cliente' },
                    { value: identificacionCliente, name: 'Identificación Cliente' },
                    { value: direccionCliente, name: 'Dirección Cliente' },
                    { value: correoCliente, name: 'Correo Cliente' },
                    { value: fechaEmision, name: 'Fecha Emisión' },
                    { value: codigoPrincipal, name: 'Código Principal' },
                    { value: descripcion, name: 'Descripción' },
                    { value: cantidad, name: 'Cantidad' },
                    { value: precioUnitario, name: 'Precio Unitario' },
                    { value: descuento, name: 'Descuento' },
                    { value: codigoIva, name: 'Codigo IVA' },
                    { value: codFormaPago, name: 'Código Forma de Pago' },
                    { value: total, name: 'Total' },
                    { value: plazo, name: 'Plazo' },
                    { value: unidadTiempo, name: 'Unidad de Tiempo' }
                ];

                const fechaEmisionDate = parseFecha(fechaEmision);

                const fechaEmisionSinHora = new Date(fechaEmisionDate.getFullYear(), fechaEmisionDate.getMonth(), fechaEmisionDate.getDate());

                detallesRequeridos.forEach((detalle) => {

                    if (!detalle.value && detalle.value !== 0) {
                        errores.push({
                            LINEA: index + 2,
                            ESTABLECIMIENTO: establecimiento || 'N/A',
                            PTOEMISION: ptoEmision || 'N/A',
                            SECUENCIAL: secuencial || 'N/A',
                            ERROR: `${detalle.name} es un campo requerido y está vacío.`,
                        });
                    }

                    if (detalle.name === 'Fecha Emisión') {
                        if (fechaEmisionSinHora > fechaActualSinHora) {
                            errores.push({
                                LINEA: index + 2,
                                ESTABLECIMIENTO: establecimiento || 'N/A',
                                PTOEMISION: ptoEmision || 'N/A',
                                SECUENCIAL: secuencial || 'N/A',
                                ERROR: `La ${detalle.name} no puede ser superior a la fecha actual.`,
                            });
                        }
                        // else if (fechaEmisionSinHora < fechaActualSinHora && fechaEmisionSinHora >= rangoInferiorSinHora) {
                        //     advertencias.push({
                        //         LINEA: index + 2,
                        //         ESTABLECIMIENTO: establecimiento || 'N/A',
                        //         PTOEMISION: ptoEmision || 'N/A',
                        //         SECUENCIAL: secuencial || 'N/A',
                        //         WARNING: `La ${detalle.name} es menor a la fecha actual, pero dentro del rango de 72 horas.`,
                        //     });
                        // }
                        else if (fechaEmisionSinHora < rangoInferiorSinHora) {
                            advertencias.push({
                                LINEA: index + 2,
                                ESTABLECIMIENTO: establecimiento || 'N/A',
                                PTOEMISION: ptoEmision || 'N/A',
                                SECUENCIAL: secuencial || 'N/A',
                                WARNING: `La ${detalle.name} es mayor a 72 horas antes de la fecha actual.`,
                            });
                        }

                    }
                });
            }

            const adicionalesProducto = [];
            for (let i = 19; i <= 24; i += 2) {
                const infAdicional = item[i];
                const valorAdicional = item[i + 1];
                if (infAdicional && valorAdicional) {
                    adicionalesProducto.push({
                        INFADICIONAL: infAdicional,
                        VALORINFADICIONAL: valorAdicional,
                    });
                }
            }

            const adicionalesFactura = [];
            for (let i = 28; i <= 58; i += 2) {
                const infAdicional = item[i];
                const valorAdicional = item[i + 1];
                if (infAdicional && valorAdicional) {
                    adicionalesFactura.push({
                        INFADICIONAL: infAdicional,
                        VALORINFADICIONAL: valorAdicional,
                    });
                }
            }

            if (grouped[key]) {
                grouped[key].DETALLES.push({
                    CODIGOPRINCIPAL: codigoPrincipal,
                    CODIGOAUXILIAR: codigoAuxiliar,
                    DESCRIPCION: descripcion,
                    CANTIDAD: cantidad,
                    PRECIOUNITARIO: parseFloat(precioUnitario.toFixed(2)),
                    DESCUENTO: parseFloat(descuento.toFixed(2)),
                    TOTALSINIMPUESTO: parseFloat(totalSinImpuesto.toFixed(2)),
                    TARIFAIVA: tarifas[(codigoIva)],
                    PORCENTAJEIVA: codigoIva || 0,
                    VALORIVA: parseFloat(item[17].toFixed(2)),
                    TOTAL: parseFloat(total.toFixed(2)),
                    ADICIONALES: adicionalesProducto,
                });
            } else {
                grouped[key] = {
                    CLIENTE: cliente,
                    IDENTIFICACIONCLIENTE: identificacionCliente,
                    CORREOCLIENTE: correoCliente,
                    DIRECCIONCLIENTE: direccionCliente,
                    FECHAEMISION: fechaEmision,
                    ESTABLECIMIENTO: establecimiento,
                    PTOEMISION: ptoEmision,
                    SECUENCIAL: secuencial,
                    IMPORTETOTAL: 0,
                    PRECIOBRUTO: 0,
                    TOTALDESCUENTO: 0,
                    GUIAREMISION: guiaRemision,
                    DETALLES: [{
                        CODIGOPRINCIPAL: codigoPrincipal,
                        CODIGOAUXILIAR: codigoAuxiliar,
                        DESCRIPCION: descripcion,
                        CANTIDAD: cantidad,
                        PRECIOUNITARIO: parseFloat(precioUnitario.toFixed(2)),
                        DESCUENTO: parseFloat(descuento.toFixed(2)),
                        TOTALSINIMPUESTO: parseFloat(totalSinImpuesto.toFixed(2)),
                        TARIFAIVA: tarifas[(codigoIva)],
                        PORCENTAJEIVA: codigoIva || 0,
                        VALORIVA: parseFloat(item[17].toFixed(2)),
                        TOTAL: parseFloat(total.toFixed(2)),
                        ADICIONALES: adicionalesProducto,
                    }],
                    FORMASPAGO: [{
                        IDFORMAPAGOSRI: item[25],
                        UNIDADTIEMPO: item[27],
                        PLAZO: item[26],
                        TOTAL: 0,
                    }],
                    ADICIONAL: adicionalesFactura,
                };
            }

            const detallesRequeridosDetalles = [
                { value: codigoPrincipal, name: 'Código Principal' },
                { value: descripcion, name: 'Descripción' },
                { value: cantidad, name: 'Cantidad' },
                { value: precioUnitario, name: 'Precio Unitario' },
                { value: totalSinImpuesto, name: 'Total Sin Impuesto' },
                { value: total, name: 'Total' },
            ];

            detallesRequeridosDetalles.forEach((detalle) => {
                if (!detalle.value && detalle.value !== 0) {
                    errores.push({
                        LINEA: index + 2,
                        ESTABLECIMIENTO: establecimiento || 'N/A',
                        PTOEMISION: ptoEmision || 'N/A',
                        SECUENCIAL: secuencial || 'N/A',
                        ERROR: `${detalle.name} en los detalles es un campo requerido y está vacío.`,
                    });
                }

                if (detalle.name === 'Descripción' && detalle.value.length > 300) {
                    errores.push({
                        LINEA: index + 2,
                        ESTABLECIMIENTO: establecimiento || 'N/A',
                        PTOEMISION: ptoEmision || 'N/A',
                        SECUENCIAL: secuencial || 'N/A',
                        ERROR: `Se ha excedido la cantidad máxima de caracteres permitidos en "Descripción".`,
                    });
                }

                const lineBreakPattern = /[\r\n]+/;;
                if (detalle.name === 'Descripción' && lineBreakPattern.test(detalle.value)) {
                    errores.push({
                        LINEA: index + 2,
                        ESTABLECIMIENTO: establecimiento || 'N/A',
                        PTOEMISION: ptoEmision || 'N/A',
                        SECUENCIAL: secuencial || 'N/A',
                        ERROR: `El campo "Descripción" no debe contener saltos de línea.`,
                    });
                }

            });
        });

        setAdvertenciasDetalles(advertencias);
        setErroresDetalles(errores);

        Object.keys(grouped).forEach(key => {
            const item = grouped[key];
            let precioBruto = item.DETALLES.reduce((acc, detalle) => {

                return acc + (detalle.TOTALSINIMPUESTO);
            }, 0);

            let totalTarifa = item.DETALLES.reduce((acc, detalle) => {
                return acc + ((detalle.TOTALSINIMPUESTO) * (detalle.TARIFAIVA / 100));
            }, 0);


            let descuentoTotal = item.DETALLES.reduce((acc, detalle) => {
                return acc + (detalle.DESCUENTO || 0);
            }, 0);


            item.PRECIOBRUTO = precioBruto;
            item.IMPORTETOTAL = precioBruto + totalTarifa;
            item.TOTALDESCUENTO = descuentoTotal;
        });


        return Object.values(grouped).sort((a, b) => a.SECUENCIAL - b.SECUENCIAL);
    };


    const chunkArray = (array, size) => {
        const chunkedArray = [];
        for (let i = 0; i < array.length; i += size) {
            chunkedArray.push(array.slice(i, i + size));
        }
        return chunkedArray;
    };


    const handleCloseModal = () => {
        setIsModalVisible(false);
        setIsLoading(false);
    };

    const handleAdvertenciaClose = () => {
        setIsModalAdVisibile(false);
        setIsLoading(false);
    };


    const downloadTemplate = async () => {
        try {
            const response = await fetch('/PlantillaFacturas.xlsx', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'PlantillaFacturas.xlsx');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else {
                console.error('Failed to download file');
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };


    function convertDate(dateStr) {
        const [day, month, year] = dateStr.split('/').map(Number);

        if (isNaN(day) || isNaN(month) || isNaN(year)) {
            throw new Error('Input must be in dd/mm/yyyy format');
        }

        const formattedDate = `${String(month).padStart(2, '0')}/${String(day).padStart(2, '0')}/${year}`;
        return formattedDate;
    }

    const guardarDocumentos = async () => {
        try {
            if (erroresDetalles.length > 0) {
                setIsLoading(true);
                setIsModalVisible(true);
                return;
            }

            setProgress(1);
            setIsSending(true);
            updateProgress();

            const construirDocumento = async (data) => {
                const {
                    CLIENTE,
                    IDENTIFICACIONCLIENTE,
                    DIRECCIONCLIENTE,
                    CORREOCLIENTE,
                    FECHAEMISION,
                    GUIAREMISION,
                    ESTABLECIMIENTO,
                    PTOEMISION,
                    SECUENCIAL,
                    PRECIOBRUTO,
                    IMPORTETOTAL,
                    TOTALDESCUENTO,
                    DETALLES,
                    FORMASPAGO,
                    ADICIONAL
                } = data;

                let InformacionAdicionalFactura = ADICIONAL.map((x) => ({
                    Descripcion: x['INFADICIONAL'],
                    Valor: x['VALORINFADICIONAL'],
                }));

                if (CORREOCLIENTE.trim() !== "") {
                    InformacionAdicionalFactura.push({
                        Valor: CORREOCLIENTE.replace(/,/g, ";").replace(/\s+/g, ""),
                        Descripcion: "email",
                    });
                }

                let DetalleFactura = DETALLES.map((detalle) => {
                    let InformacionAdicionalProducto = detalle.ADICIONALES ? detalle.ADICIONALES.map((x) => ({
                        Descripcion: x['INFADICIONAL'],
                        Valor: x['VALORINFADICIONAL'],
                    })) : [];

                    return {
                        Descripcion: detalle['DESCRIPCION'],
                        Cantidad: detalle['CANTIDAD'],
                        CodigoPrincipal: detalle['CODIGOPRINCIPAL'],
                        CodigoAuxiliar: detalle['CODIGOAUXILIAR'],
                        PrecioUnitario: detalle['PRECIOUNITARIO'].toFixed(2),
                        Descuento: detalle['DESCUENTO'].toFixed(2) || 0,
                        ImpuestoCodigoIva: 2,
                        ImpuestoCodigoPorcentajeIva: detalle['PORCENTAJEIVA'],
                        PrecioTotalSinImpuesto: detalle['TOTALSINIMPUESTO'].toFixed(2),
                        ImpuestoBaseImponibleIva: detalle['TOTALSINIMPUESTO'].toFixed(2),
                        ImpuestoValorIva: detalle['VALORIVA'].toFixed(2),
                        Tarifa: detalle['TARIFAIVA'],
                        ImporteTotal: detalle['TOTAL'].toFixed(2),
                        PorcentajeIva: detalle['PORCENTAJEIVA'],
                        DetalleServicioFactura: InformacionAdicionalProducto,
                    };
                });

                return {
                    IdentificacionCliente: IDENTIFICACIONCLIENTE,
                    RazonSocial: CLIENTE,
                    Direccion: DIRECCIONCLIENTE,
                    Emisor: empresa.emisor,
                    Correo: CORREOCLIENTE.replace(/,/g, ";").replace(/\s+/g, ""),
                    FechaEmision: convertDate(FECHAEMISION),
                    GuiaRemision: GUIAREMISION || "",
                    Establecimiento: ESTABLECIMIENTO,
                    PuntoEmision: PTOEMISION,
                    ImporteTotal: IMPORTETOTAL.toFixed(2),
                    TotalSinImpuestos: PRECIOBRUTO.toFixed(2),
                    TotalDescuento: TOTALDESCUENTO.toFixed(2),
                    Secuencial: SECUENCIAL,
                    ListaImpuesto: await CalcularImpuestos(tarifas, DETALLES),
                    Ambiente: process.env.REACT_APP_ENVIRONMENT,
                    IdEmpresa: empresa.idEmpresa,
                    RucEmpresa: empresa.ruc,
                    CodDoc: 1,
                    Reembolsos: null,
                    exportacion: null,
                    InformacionAdicionalFactura: InformacionAdicionalFactura,
                    FormaPagoFactura: FORMASPAGO.map((e) => ({
                        IdFormaPagoSri: formasPago[e['IDFORMAPAGOSRI']],
                        UnidaDeTiempo: e['UNIDADTIEMPO'],
                        Plazo: e['PLAZO'],
                        Total: IMPORTETOTAL.toFixed(2)
                    })),
                    DetalleFactura: DetalleFactura,
                };
            };

            const documentosList = await Promise.all(
                Object.values(groupedData).map(async (data) => construirDocumento(data))
            );


            setTotalDocuments(documentosList.length);
            const chunkedDocumentos = chunkArray(documentosList, 10);
            let hasErrors = false;
            setDocumentsProcessed(0);

            for (const chunk of chunkedDocumentos) {
                try {
                    setDocumentsProcessed(prev => prev + chunk.length);

                    const response = await axios.post("/api/FacturasEmitidas/CrearFacturasExcel", chunk);

                    if (response.data.erroresExistentes.length > 0 || response.data.erroresProcesos.length > 0) {
                        hasErrors = true;

                        const secuencialesExistentes = response.data.erroresExistentes.map(error => error.factura);

                        setSecuencialesExistentes(prev => [...prev, ...secuencialesExistentes]);


                        const otrosErrores = response.data.erroresProcesos.map(error => ({
                            factura: error.factura,
                            mensaje: error.mensaje
                        }));

                        setSecuencialesProblemas(prev => [...prev, ...otrosErrores]);

                        // setErroresDetalles(prev => [
                        //     ...prev, 
                        //     ...otrosErrores 
                        // ]);
                    }

                } catch (error) {
                    setErrorServer(true);
                    hasErrors = true;
                    ErrorToast("Error al subir las facturas");
                }
            }

            if (!hasErrors && erroresDetalles.length === 0) {
                toast.success("Facturas agregadas con éxito.");
                inputFileRef.current.value = null;
                setGroupedData([]);
                setIsSending(false);
                setIsLoading(false);
                setToUploading(false);
                setIsPostingComplete(true);
                navigate('/emision/reporte/general');
            } else {
                setIsSending(false);
                setToUploading(secuencialesExistentes.length !== 0 && secuencialesProblemas.length !== 0);
            }

        } catch (error) {
            setErrorServer(true);
            ErrorToast("Error al procesar documentos");
        }
    };


    const updateProgress = () => {
        const progressPercentage = Math.round((documentsProcessed / totalDocuments) * 100);

        const incrementProgress = (currentProgress, targetProgress) => {
            if (currentProgress < targetProgress) {
                const newProgress = Math.min(currentProgress + 1, targetProgress);
                setProgress(newProgress);
                setTimeout(() => incrementProgress(newProgress, targetProgress), 100);
            }
        };

        incrementProgress(progress, progressPercentage);
    };

    useEffect(() => {
        if (totalDocuments > 0 && documentsProcessed === totalDocuments && isPostingComplete) {
            setProgress(100);
            setTimeout(() => {
                setIsSending(false);
            }, 2000);
        } else {
            updateProgress();
        }
    }, [documentsProcessed, totalDocuments, isPostingComplete]);


    return (
        <div className="space-y-6 xl:mx-4 my-2">
            <ModalErroresExcel
                isVisible={isModalVisible}
                erroresDetalles={erroresDetalles}
                onClose={handleCloseModal}
            />
            <ModalAdvertencias
                isVisible={isModalAdVisible}
                advertenciasDetalles={advertenciasDetalles}
                onClose={handleAdvertenciaClose}
            />
            <ModalProgress
                isVisible={isSending}
                onClose={() => setIsSending(false)}
                progress={progress}
            />
            <div className="bg-white shadow  dark:bg-gray-700 sm:rounded-lg md:py-2 md:px-4 py-2 px-2">
                <div className=" py-2">
                    <h2 className=" pl-4 text-2xl p-5 rounded-lg bg-[#D6D6D6] dark:bg-gray-800   font-semibold text-gray-700 dark:text-gray-200">
                        Emisión masiva de facturas
                    </h2>
                </div>
                <div className="flex flex-row flex-wrap gap-4 my-4">
                    <button
                        className={' items-center px-4 py-2 border border-transparent shadow-sm text-sm leading-5 font-body rounded-md text-white  focus:outline-none focus:ring-2 bg-green-500 '}
                        onClick={downloadTemplate}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="inline mr-2 -ml-1 w-5 h-5">
                            <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 242.7-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7 288 32zM64 352c-35.3 0-64 28.7-64 64l0 32c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-32c0-35.3-28.7-64-64-64l-101.5 0-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352 64 352zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                        </svg>

                        Descargar plantilla
                    </button>
                    <div className="relative inline-block">
                        <input
                            type="file"
                            onChange={handleFileImportSeccion}
                            accept=".xlsx, .xls"
                            className="absolute inset-0 opacity-0 cursor-pointer"
                            ref={inputFileRef}
                        />

                        <button
                            type="button"
                            className="px-4 py-2 border border-gray-300 rounded-md shadow-sm bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            onClick={() => inputFileRef.current?.click()}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="currentColor" className="inline mr-2 -ml-1 w-5 h-5">
                                <path d="M0 55.2L0 426c0 12.2 9.9 22 22 22c6.3 0 12.4-2.7 16.6-7.5L121.2 346l58.1 116.3c7.9 15.8 27.1 22.2 42.9 14.3s22.2-27.1 14.3-42.9L179.8 320l118.1 0c12.2 0 22.1-9.9 22.1-22.1c0-6.3-2.7-12.3-7.4-16.5L38.6 37.9C34.3 34.1 28.9 32 23.2 32C10.4 32 0 42.4 0 55.2z" />
                            </svg>
                            Seleccionar archivo
                        </button>
                    </div>
                    <div className=" cursor-pointer w-10 h-10 rounded-full inline-flex items-center justify-center">
                        <button
                            type="button"
                            onClick={limpiar}
                            className="inline-flex h-10 w-10 mt-1 cursor-pointer shadow-md  items-center justify-center rounded-xl  transition dark:bg-gray-900 dark:hover:bg-gray-800"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="h-6 w-6 dark:text-white"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                            </svg>

                            <div className="absolute mx-auto w-24 -top-8 -left-8 hidden dark:bg-white  rounded-md bg-gray-800 group-hover:flex text-center p-1">
                                <p className="text-white dark:text-black mx-auto text-sm ">
                                    Limpiar
                                </p>
                            </div>
                        </button>
                    </div>
                </div>


                {IsLoading ? (
                    <div className="flex items-center justify-center py-4">
                        <svg
                            aria-hidden="true"
                            role="status"
                            className={`inline mr-3 w-8 h-8 animate-spin`}
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="#E5E7EB"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                ) :

                    groupedData.length > 0 ? !toUploading && erroresDetalles.length == 0 && !errorServer ? (
                        <ResultadoTabla />
                    ) : (
                        <FacturasTabla />
                    ) : (
                        <div className="py-4 flex flex-col justify-center items-center h-[65vh]">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="inline mr-2 -ml-1 w-5 h-5 fill-yellow-400">
                                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24l0 112c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-112c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
                            </svg>
                            <p className="text-2xl dark:text-gray-300">No existe informacion cargada.</p>
                        </div>)}
                <div className="flex flex-row flex-wrap gap-2 mt-8 justify-end">
                    {
                        (toUploading && groupedData.length > 0) && (
                            <button
                                onClick={guardarDocumentos}
                                type="submit"
                                className=" text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-base px-6 py-2.5 text-center md:mr-5 mb-3 md:mb-0 inline-flex items-center justify-center"
                            >
                                {
                                    IsLoading ? (
                                        <svg
                                            aria-hidden="true"
                                            role="status"
                                            className={`inline mr-3 w-8 h-8 animate-spin`}
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"
                                            />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    ) : (<>Subir</>)
                                }
                            </button>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default SubirFacturas
