import React, { useContext, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import { StyleInput, StyleInputError } from "../Constantes";
import MesageWarningTable from "./MesageWarningTable";
import { toast } from "react-toastify";
import LengthCounter from "./LengthCounter";

const InformacionAdicional = ({ Contexto }) => {
  const [IsLoading, setIsLoading] = useState(false);
  const {
    InformacionAdicionalList,
    DestinatariosList,
    setInformacionAdicionalList,
    DetalleList,
    DataFromAdd,
  } = useContext(Contexto);

  const formik = useFormik({
    initialValues: {
      nombre: "",
      valor: "",
    },
    validationSchema: Yup.object({
      nombre: Yup.string().required("CAMPO REQUERIDO").max(300),
      valor: Yup.string().required("CAMPO REQUERIDO").max(300),
    }),
    onSubmit: async (obj) => {
      try {
        setIsLoading(true);
        formik.setFieldValue("nombre", "");
        formik.setFieldValue("valor", " ");

        if (obj.nombre.trim() === "" || obj.valor.trim() === "") {
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
          return toast.info(
            "Asegurate de ingresar un nombre o detalle en informacion adicional",
            { autoClose: 2000, closeOnClick: true }
          );
        }

        setInformacionAdicionalList([{ ...obj }, ...InformacionAdicionalList]);
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      } catch (error) {
        // console.log(error);
      }
    },
  });

  const nombreInfo = formik.values.nombre.length;
  const nombreInfoDetalle = formik.values.valor.length;


  const handleDeleteElement = (index) => {
    InformacionAdicionalList.splice(index, 1);
    setInformacionAdicionalList([...InformacionAdicionalList]);
  };

  return (
    <>
      {DetalleList.length > 0 ? (
        <div className=" flex-wrap justify-between mb-0 my-2  w-full overflow-x-auto  ">
          <form onSubmit={formik.handleSubmit}>
            <div className="grid md:grid-cols-3 md:gap-6 ">
              <div className="relative z-0 mb-2 w-full group">
                <label className="block text-sm font-medium text-black dark:text-white">
                  {" "}
                  Nombre:{" "}  <span className="text-gray-500"> {nombreInfo} / 300</span>
                </label>
                <input
                  type="text"
                  className={
                    formik.touched.nombre && formik.errors.nombre
                      ? StyleInputError
                      : StyleInput
                  }
                  placeholder=" "
                  id="nombre"
                  name="nombre"
                  value={formik.values.nombre}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  maxLength="300"
                />
              </div>
              <div className="relative z-0 mb-2 w-full group">
                <label className="block text-sm font-medium text-black dark:text-white">
                  Detalle:  <span className="text-gray-500"> {nombreInfoDetalle} / 300</span>
                </label>
                <input
                  type="text"
                  className={
                    formik.touched.valor && formik.errors.valor
                      ? StyleInputError
                      : StyleInput
                  }
                  placeholder=" "
                  id="valor"
                  name="valor"
                  value={formik.values.valor}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  maxLength="300"
                />
              </div>
              <div className="relative z-0 mb-2 pt-3 w-full group">
                <button
                  disabled={IsLoading}
                  type="submit"
                  className=" inline-block mt-0 text-white bg-blue-600 w-full lg:w-28  hover:bg-blue-700  font-semibold p-2 text-[0.813rem] border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:shadow-outline-grayduration-150
              ease-in-out"
                >
                  {IsLoading ? (
                    "Cargando..."
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="inline mr-1   -ml-1 w-5 h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>{" "}
                      AGREGAR
                    </>
                  )}
                </button>
              </div>
            </div>
          </form>

 
            <div className=" relative overflow-auto border rounded-md dark:border-gray-600">
              <table className="w-full text-sm text-center divide-y divide-gray-300 dark:divide-gray-600 text-gray-500 dark:text-gray-400 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr className="">
                    <th className="px-1 py-1 uppercase">Nombre</th>
                    <th className="px-1 py-1 uppercase">Detalle</th>
                    <th className="px-1 py-1"></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-300  dark:divide-gray-600">
                  {InformacionAdicionalList.length === 0 ? (
                    <MesageWarningTable
                      colSpan="3"
                      message="No se ha ingresado información"
                    />
                  ) : null}
                 
                  {InformacionAdicionalList.map((el, index) => (
                    <tr
                      key={index}
                      className="bg-white dark:bg-gray-800 text-center"
                    >
                      <td className="py-1 px-1"> {el.nombre}</td>
                      <td className="py-1 px-1"> {el.valor}</td>
                      <td className="py-1 px-1">
                        {" "}
                        <button
                          className=""
                          onClick={() => handleDeleteElement(index)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
        
        </div>
      ) : (
        <></>
      )}

      {DestinatariosList.length > 0 ? (
        <div className=" flex-wrap justify-between mb-0 my-2  w-full    overflow-x-auto  ">
          <form onSubmit={formik.handleSubmit}>
            <div className="grid md:grid-cols-3 md:gap-6 ">
              <div className="relative z-0 mb-2 w-full group">
                <label className="block text-sm font-medium text-black dark:text-white">
                  {" "}
                  Nombre:{" "}  <span className="text-gray-500"> {nombreInfo} / 300</span>
                </label>
                <input
                  type="text"
                  className={
                    formik.touched.nombre && formik.errors.nombre
                      ? StyleInputError
                      : StyleInput
                  }
                  placeholder=" "
                  id="nombre"
                  name="nombre"
                  value={formik.values.nombre}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  maxLength="30"
                />
              </div>
              <div className="relative z-0 mb-2 w-full group">
                <label className="block text-sm font-medium text-black dark:text-white">
                  Detalle:  Detalle: <span className="text-gray-500"> {nombreInfoDetalle} / 300</span>
                </label>
                <input
                  type="text"
                  className={
                    formik.touched.valor && formik.errors.valor
                      ? StyleInputError
                      : StyleInput
                  }
                  placeholder=" "
                  id="valor"
                  name="valor"
                  value={formik.values.valor}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  maxLength="300"
                />
              </div>
              <div className="relative z-0 mb-2 pt-3 w-full group">
                <button
                  disabled={IsLoading}
                  type="submit"
                  className=" inline-block mt-0 text-white bg-blue-600 w-full lg:w-28  hover:bg-blue-700  font-semibold p-2 text-[0.813rem] border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:shadow-outline-grayduration-150
              ease-in-out"
                >
                  {IsLoading ? (
                    "Cargando..."
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="inline mr-1   -ml-1 w-5 h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>{" "}
                      AGREGAR
                    </>
                  )}
                </button>
              </div>
            </div>
          </form>

         
            <div className=" relative overflow-auto border rounded-md dark:border-gray-600">
              <table className="w-full text-sm text-center divide-y divide-gray-300 dark:divide-gray-600 text-gray-500 dark:text-gray-400 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr className="">
                    <th className="px-1 py-1 uppercase">Nombre</th>
                    <th className="px-1 py-1 uppercase">Detalle</th>
                    <th className="px-1 py-1"></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-300  dark:divide-gray-600">
                  {InformacionAdicionalList.length === 0 ? (
                    <MesageWarningTable
                      colSpan="3"
                      message="No se ha ingresado información"
                    />
                  ) : null}
                  {InformacionAdicionalList.map((el, index) => (
                    <tr
                      key={index}
                      className="bg-white dark:bg-gray-800 text-center"
                    >
                      <td className="py-1 px-1"> {el.nombre}</td>
                      <td className="py-1 px-1"> {el.valor}</td>
                      <td className="py-1 px-1">
                        {" "}
                        <button
                          className=""
                          onClick={() => handleDeleteElement(index)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
         
        </div>
      ) : (
        <></>
      )}

      {DataFromAdd.length > 0 ? (
        <div className=" flex-wrap justify-between mb-0 my-2  w-full    overflow-x-auto  ">
          <form onSubmit={formik.handleSubmit}>
            <div className="grid md:grid-cols-3 md:gap-6 ">
              <div className="relative z-0 mb-2 w-full group">
                <label className="block text-sm font-medium text-black dark:text-white">
                  {" "}
                  Nombre:{" "}
                </label>
                <input
                  type="text"
                  className={
                    formik.touched.nombre && formik.errors.nombre
                      ? StyleInputError
                      : StyleInput
                  }
                  placeholder=" "
                  id="nombre"
                  name="nombre"
                  value={formik.values.nombre}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  maxLength="30"
                />
              </div>
              <div className="relative z-0 mb-2 w-full group">
                <label className="block text-sm font-medium text-black dark:text-white">
                  Detalle:
                </label>
                <input
                  type="text"
                  className={
                    formik.touched.valor && formik.errors.valor
                      ? StyleInputError
                      : StyleInput
                  }
                  placeholder=" "
                  id="valor"
                  name="valor"
                  value={formik.values.valor}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  maxLength="300"
                />
              </div>
              <div className="relative z-0 mb-2 pt-3 w-full group">
                <button
                  disabled={IsLoading}
                  type="submit"
                  className=" inline-block mt-0 text-white bg-blue-600 w-full lg:w-28  hover:bg-blue-700  font-semibold p-2 text-[0.813rem] border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:shadow-outline-grayduration-150
              ease-in-out"
                >
                  {IsLoading ? (
                    "Cargando..."
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="inline mr-1   -ml-1 w-5 h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>{" "}
                      AGREGAR
                    </>
                  )}
                </button>
              </div>
            </div>
          </form>

       
            <div className=" relative overflow-auto border rounded-md dark:border-gray-600">
              <table className="w-full text-sm text-center divide-y divide-gray-300 dark:divide-gray-600 text-gray-500 dark:text-gray-400 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr className="">
                    <th className="px-1 py-1 uppercase">Nombre</th>
                    <th className="px-1 py-1 uppercase">Detalle</th>
                    <th className="px-1 py-1"></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-300  dark:divide-gray-600">
                  {InformacionAdicionalList.length === 0 ? (
                    <MesageWarningTable
                      colSpan="3"
                      message="No se ha ingresado información"
                    />
                  ) : null}
                  {InformacionAdicionalList.map((el, index) => (
                    <tr
                      key={index}
                      className="bg-white dark:bg-gray-800 text-center"
                    >
                      <td className="py-1 px-1"> {el.nombre}</td>
                      <td className="py-1 px-1"> {el.valor}</td>
                      <td className="py-1 px-1">
                        {" "}
                        <button
                          className=""
                          onClick={() => handleDeleteElement(index)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
       
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default InformacionAdicional;
