import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import EmisionElectronica from "./EmisionElectronica";
import CargaMasiva from "./CargaMasiva";
import Admin from "./Admin";
import TutorialesModal from "./TutorialesModal";
import ReportesMenu from "./ReportesMenu";
import SRI from "./SRI";
import Suscripciones from "./Suscripciones";
import { InventarioOption } from "./InventarioOption";
import ConsultaContribuyente from "./ConsultaContribuyente";

const MenuGeneral = (props) => {
  const permisos = useSelector((store) => store.permisosEmpresa.permisos);

  const [reporteEmison, setReporteEmison] = useState([]);
  const [emisionelectronica, setEmisionelectronica] = useState([]);
  const [cargaMasiva, setCargaMasiva] = useState([]);
  const [consulta, setConsulta] = useState([]);
  const [inventarioModulo, setInventarioModulo] = useState([]);
  const [Inventario, setInventario] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [administracion, setAdministracion] = useState([]);

  const jsonpermiso = {
    activo: true,
    codigo: "25",
    descripcion: "EDITAR USUARIOS",
    idFormulario: "d02c649f-0f96-4510-b499-e7af7409249c",
    modulo: "admin",
    nombre: "EDITAR USUARIOS",
    rolId: "80dd2f96-00f0-4a19-aa3d-01ed447a2970",
  };

  const consultaContribuyente = {
    activo: true,
    codigo: "89",
    descripcion: "Consulta Contribuyente",
    idFormulario: "d02c649f-0f96-4510-b419-e7af7409249c",
    modulo: "consulta",
    nombre: "Consulta Contribuyente",
    rolId: "80dd2f96-00f0-4a19-aa3d-01ed447a2970",
  };

  useEffect(() => {
    const permisosActualizados = [...permisos];
    const permisoExistente = permisosActualizados.filter(
      (permiso) => permiso.idFormulario !== jsonpermiso.idFormulario
    );

    permisoExistente.push(jsonpermiso);
    permisoExistente.push(consultaContribuyente);
    setConsulta(permisoExistente.filter((obj) => obj.codigo === "36"));

    setReporteEmison(
      permisoExistente.filter((obj) => obj.modulo === "reporteEmison")
    );

    setReporteEmison(
      permisoExistente.filter((obj) => obj.modulo === "reporteEmison")
    );
    setEmisionelectronica(
      permisoExistente.filter((obj) => obj.modulo === "emisionElectronica")
    );
    setCargaMasiva(
      permisoExistente.filter((obj) => obj.modulo === "emisionMasiva")
    );
    setInventario(
      permisoExistente.filter((obj) => obj.modulo === "inventario")
    );
    setAdmin(permisoExistente.filter((obj) => obj.modulo === "admin"));
    setInventarioModulo(permisoExistente.filter((obj) => obj.codigo === "37"));
    setAdministracion(
      permisoExistente.filter((obj) => obj.modulo === "administracion")
    );
  }, [permisos]);

  return (
    <>
      <ul className="pt-6 text-white ">
        <NavLink exact="true" to="/" className={""}>
          <li
            className=" text-sm flex items-center w-full group   hover:text-white gap-x-4 cursor-pointer p-2 hover:bg-white rounded-md"
            title={!props.OpenMenu ? "Dashboard" : ""}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6 group-hover:text-black"
            >
              <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
              <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
            </svg>

            <span
              className={`${!props.OpenMenu && "hidden"
                } origin-left duration-200 group-hover:text-black`}
            >
              Inicio
            </span>
            <span
              className={`${!props.openMobil && "hidden"
                } origin-left duration-200`}
            >
              Inicio
            </span>
          </li>
        </NavLink>
      </ul>
      <ul className="text-white">
        {Inventario === undefined ? null : Inventario.length > 0 ? (
          <Admin
            items={Inventario}
            modulo="Base de datos"
            OpenMenu={props.OpenMenu}
            setOpenMenu={props.setOpenMenu}
            openMobil={props.openMobil}
            setOpenMobil={props.setOpenMobil}
            openItem={props.openItem}
            setOpenItem={props.setOpenItem}
            toggleItem={props.toggleItem}
          />
        ) : null}

        {inventarioModulo === undefined ? null : inventarioModulo.length > 0 ? (
          <InventarioOption
            OpenMenu={props.OpenMenu}
            modulo="Inventario"
            setOpenMenu={props.setOpenMenu}
            openMobil={props.openMobil}
            setOpenMobil={props.setOpenMobil}
            openItem={props.openItem}
            setOpenItem={props.setOpenItem}
            toggleItem={props.toggleItem}
            setSubMenu={props.setSubMenu}
            subMenu={props.subMenu}
          />
        ) : null}

        {emisionelectronica === undefined ? null : emisionelectronica.length >
          0 ? (
          <EmisionElectronica
            OpenMenu={props.OpenMenu}
            setOpenMenu={props.setOpenMenu}
            items={emisionelectronica}
            modulo="Emisión Documentos"
            openMobil={props.openMobil}
            setOpenMobil={props.setOpenMobil}
            openItem={props.openItem}
            setOpenItem={props.setOpenItem}
            toggleItem={props.toggleItem}
          />
        ) : null}

        {cargaMasiva === undefined ? null : cargaMasiva.length >
          0 ? (
          <CargaMasiva
            OpenMenu={props.OpenMenu}
            setOpenMenu={props.setOpenMenu}
            items={cargaMasiva}
            modulo="Emisión Masiva"
            openMobil={props.openMobil}
            setOpenMobil={props.setOpenMobil}
            openItem={props.openItem}
            setOpenItem={props.setOpenItem}
            toggleItem={props.toggleItem}
          />
        ) : null}

        <ReportesMenu
          OpenMenu={props.OpenMenu}
          modulo="Reportes"
          setOpenMenu={props.setOpenMenu}
          openMobil={props.openMobil}
          setOpenMobil={props.setOpenMobil}
          openItem={props.openItem}
          setOpenItem={props.setOpenItem}
          toggleItem={props.toggleItem}
        />

        {admin === undefined ? null : admin.length > 0 ? (
          <Admin
            items={admin}
            modulo="Mantenimiento"
            OpenMenu={props.OpenMenu}
            setOpenMenu={props.setOpenMenu}
            openMobil={props.openMobil}
            setOpenMobil={props.setOpenMobil}
            openItem={props.openItem}
            setOpenItem={props.setOpenItem}
            toggleItem={props.toggleItem}
          />
        ) : null}
        {administracion === undefined ? null : administracion.length > 0 ? (
          <Admin
            items={administracion}
            modulo="Administración"
            OpenMenu={props.OpenMenu}
            setOpenMenu={props.setOpenMenu}
            openMobil={props.openMobil}
            setOpenMobil={props.setOpenMobil}
            openItem={props.openItem}
            setOpenItem={props.setOpenItem}
            toggleItem={props.toggleItem}
          />
        ) : null}
      </ul>

      {consulta === undefined ? null : consulta.length > 0 ? (
        <ConsultaContribuyente
          OpenMenu={props.OpenMenu}
          setOpenMenu={props.setOpenMenu}
          items={consulta}
          modulo="Consulta"
          openMobil={props.openMobil}
          setOpenMobil={props.setOpenMobil}
          openItem={props.openItem}
          setOpenItem={props.setOpenItem}
          toggleItem={props.toggleItem}
        />
      ) : null}

      <SRI
        items={Inventario}
        modulo="SRI"
        OpenMenu={props.OpenMenu}
        setOpenMenu={props.setOpenMenu}
        openMobil={props.openMobil}
        setOpenMobil={props.setOpenMobil}
        openItem={props.openItem}
        setOpenItem={props.setOpenItem}
        toggleItem={props.toggleItem}
      />
    </>
  );
};

export default MenuGeneral;
