import React from 'react';
import ReporteDocGeneradoProvider from './ReporteDocGeneradoProvider';
import ListaReporteDocGenerado from './ListaDocGenerado';
import { FromDocGenerado } from './FromDocGenerado';

const ReporteDocGeneradoCliente = () => {
    return (
        <div className=" mx-4 my-3" >
            <div className=" dark:bg-gray-800 sm:rounded-lg " >
                <ReporteDocGeneradoProvider>
                    <FromDocGenerado />
                    <ListaReporteDocGenerado />
                </ReporteDocGeneradoProvider>
            </div>
        </div>
    );
};

export default ReporteDocGeneradoCliente;