import { FormProvider, useForm } from "react-hook-form";
import { AddIcon, UploadIcon, DownloadIcon } from "../../../Icons/ButtonIcons";
import { useState } from "react";

//Componente Modelo del encabezado de un modulo del Inventario con los botones: Agregar / Importar / Exportar
export const FormBaseInventario = ({
  submitFn,
  children,
  subtitle,
  title,
  valueProblem,
  isSending,
  exportFn,
  importFn,
}) => {
  const methods = useForm();
  const [fileInputKey, setFileInputKey] = useState(Date.now());

  const saveForm = async (data) => {
    submitFn(data);
    methods.reset();
    if (valueProblem.length > 0) {
      for (const value of valueProblem) methods.setValue(value, "");
    }
  };

  const importNormal =
    "p-[11px] rounded-lg bg-blue-600 cursor-pointer hover:bg-blue-700 pl-5 pr-5 flex flex-row gap-2 items-center";

  const importAndResetInput = (e) => {
    importFn(e);
    setFileInputKey(Date.now());
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(saveForm)}
        autoComplete="off"
        className="w-full bg-white dark:bg-gray-900 rounded-lg shadow-md  p-4 flex flex-col gap-4"
      >
        <h1 className="font-semibold dark:text-white text-xl p-2 pr-0 pl-0 border-b border-gray-200">
          {title}
        </h1>
        <div>
          <p className="bg-[#003B5B] text-white flex items-center flex-row gap-2 font-semibold rounded-t-lg p-3">
            {subtitle}
          </p>
          <div className="w-full border border-gray-300 dark:border-gray-600 dark:bg-gray-700 rounded-b-lg p-6 pl-8 pr-8">
            <div className="grid lg:grid-cols-4 w-full gap-4">{children}</div>
          </div>
        </div>
        <div className="flex flex-row gap-1 text-white font-semibold h-10 text-[12px]">
          <button
            type="submit"
            disabled={isSending}
            className="p-3 rounded-lg bg-blue-600 hover:bg-blue-700 pr-5 pl-5 flex flex-row gap-2 items-center"
          >
            <span>
              <AddIcon />
            </span>
            Agregar
          </button>
          <div className="h-fit w-fit">
            <label htmlFor="importInput" className={importNormal}>
              <span>
                <UploadIcon />
              </span>
              Importar
            </label>
            <input
              key={fileInputKey}
              className="absolute -left-[9999px]"
              id="importInput"
              accept=".xlsx"
              onChange={importAndResetInput}
              type="file"
            />
          </div>
          <button
            type="button"
            onClick={exportFn}
            className="p-3 md:pl-8 whitespace-nowrap md:pr-8 rounded-lg bg-gray-600 hover:bg-gray-700 flex flex-row gap-2 items-center"
          >
            {" "}
            <span>
              <DownloadIcon />
            </span>
            <span className="hide-on-small">Descargar datos</span>
          </button>
        </div>
      </form>
    </FormProvider>
  );
};
