import { useContext, useState } from "react";
import { InputGlobal } from "../../context/inputContext";
import {
  AddIcon,
  UploadIcon,
  DownloadIcon,
  OffIcon,
} from "../../../../Icons/ButtonIcons";
import * as XLSX from "xlsx";
import { SelectGlobal } from "../../context/selectContext";
import { CategoriaContext } from "../context/CategoriaContext";
import { FormProvider, useForm } from "react-hook-form";
import { v4 as uuidv } from "uuid";
import { useStore } from "zustand";
import {
  ModalCategoriasStatus,
  useModalCategoriaStore,
} from "./ModalCategoriaContext";
import { AlfaNumerico, SoloLetras } from "../../regex.d";
import { useSelector } from "react-redux";
import useGlobalToastify from "../../../../features/hooks/GlobalToastify/useGlobalToastify";
import { toast } from "react-toastify";
import { ModalSubirExcel } from "../../Shared/ModalSubirExcel";
import { useProgressBar } from "../../hooks/useProgressBar";
import { GenerarExcelCategorias } from "../../../../services/InventarioService";

export const CrearCategoriaForm = ({ CheckedHookCategoria, categorias }) => {
  const { addCategoria, uploadExcelCategorias, getCategorias } =
    useContext(CategoriaContext);
  const { changeCategoriaModal } = useStore(useModalCategoriaStore);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const empresa = useSelector((state) => state.empresa.empresa);
  const { WarningToast, ErrorToast } = useGlobalToastify();
  const methods = useForm();
  const [isSending, setIsSending] = useState(false);

  const {
    isUploading,
    setIsUploading,
    progress,
    updateProgressBar,
    setProgress,
  } = useProgressBar();

  const createCategory = async (data) => {
    const newCategoria = {
      idCategoriaProducto: uuidv(),
      estado: true,
      codigo: data.codigo.trimEnd().trimStart(),
      nombre: data.nombreCategoria.trimEnd().trimStart(),
      idEmpresa: empresa.idEmpresa,
    };
    try {
      setIsSending(true);
      await toast.promise(addCategoria(newCategoria), {
        pending: "Guardando categoria...",
        success: "¡La categoria fue guardada con éxito!",
        error: {
          render({ data }) {
            return (
              data?.response?.data?.message ||
              "Hubo un error al registrar la categoria."
            );
          },
        },
      });
      methods.reset();
    } catch (err) {
      console.log(err);
    } finally {
      setIsSending(false);
    }
  };

  const desactiveCategory = () => {
    changeCategoriaModal(ModalCategoriasStatus.desabilitar);
  };

  const handleFileImportCategoria = (e) => {
    const importedFile = e.target.files[0];
    const reader = new FileReader();
    if (importedFile.length === 0) {
      return;
    }
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const propiedadesRequeridas = ["Código Categoría", "Nombre Categoría"];

      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
      const xlData = XLSX.utils.sheet_to_json(worksheet, { header: 0 });

      let isValid = true;

      for (const prop of propiedadesRequeridas) {
        if (!headers.includes(prop)) {
          isValid = false;
        }
      }
      if (isValid === false) {
        ErrorToast(
          "La plantilla de excel no cumple con los campos requeridos."
        );
        return;
      }

      let percentageIncrement = 0;
      let newCategorias = [];
      let categoriasNoUploaded = [];
      const totalIteraciones =
        Math.round(xlData.length / 10) < 1 ? 1 : Math.round(xlData.length / 10);

      percentageIncrement = 100 / totalIteraciones;
      for (const categoria of xlData) {
        const hasAllRequiredValues = propiedadesRequeridas.every(
          (prop) =>
            categoria[prop] !== "" &&
            categoria[prop] !== null &&
            categoria[prop] !== undefined
        );

        if (!hasAllRequiredValues) {
          categoriasNoUploaded.push(categoria);
          continue;
        } else {
          const newCategoria = {
            idCategoriaProducto: uuidv(),
            idEmpresa: empresa.idEmpresa,
            codigo: String(categoria["Código Categoría"]),
            nombre: categoria["Nombre Categoría"],
          };
          newCategorias.push(newCategoria);
        }
      }
      setIsUploading(true);
      for (let i = 0; i < totalIteraciones; i++) {
        const batch = newCategorias.slice(i * 10, (i + 1) * 10);
        try {
          await uploadExcelCategorias(batch);
          updateProgressBar(percentageIncrement);
        } catch (err) {
          console.log(err);
        }
      }
      await getCategorias();
      setIsUploading(false);
      setProgress(0);
      if (categoriasNoUploaded.length > 0) {
        WarningToast(
          "No se subieron algunas categorias que no cumplieron los requerimientos"
        );
      }
    };
    reader.readAsArrayBuffer(importedFile);
    setFileInputKey(Date.now());
  };

  const handleFileExportCategorias = async () => {
    try {
      const excelBase64 = await GenerarExcelCategorias(empresa.idEmpresa);
      const excelBinary = atob(excelBase64);
      const byteNumbers = new Array(excelBinary.length);
      for (let i = 0; i < excelBinary.length; i++) {
        byteNumbers[i] = excelBinary.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const blobUrl = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = "Categorias.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (err) {
      console.error("Error al procesar el archivo Excel:", err);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(createCategory)}
          autoComplete="off"
          className="w-full bg-white dark:bg-gray-900 rounded-lg  p-4 shadow-md flex flex-col gap-4"
        >
          <h1 className="font-semibold dark:text-white text-xl p-2 pr-0 pl-0 border-b border-gray-200">
            Registro de Categorías
          </h1>
          <div>
            <p className="bg-[#003B5B] text-white  flex items-center flex-row gap-1 font-semibold rounded-t-lg p-3">
              <span>
                <svg
                  fill="#ffffff"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                  width={"26"}
                  height={"26"}
                  stroke="#ffffff"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g>
                      {" "}
                      <path d="M61.8,29.4l8.9,8.9l0,0c2,1.9,2,5.1,0,7l0,0L47.5,68.4V47.3V36.6l7.2-7.3C56.6,27.4,59.9,27.4,61.8,29.4z"></path>{" "}
                    </g>{" "}
                    <path d="M37.5,20H25c-2.8,0-5,2.2-5,5v43.8C20,75,25,80,31.2,80s11.2-5,11.2-11.2V25C42.5,22.2,40.2,20,37.5,20z M31.2,73.8c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5S34,73.8,31.2,73.8z"></path>{" "}
                    <g>
                      {" "}
                      <path d="M75,57.5h-8.8l-6,6H74L73.9,74H49.8l-6,6H75c2.8,0,5-2.2,5-5V62.5C80,59.8,77.8,57.5,75,57.5L75,57.5z"></path>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </span>
              Formulario de Creación
            </p>
            <div className="w-full border border-gray-300 dark:border-gray-600 dark:bg-gray-700  rounded-b-lg p-6 pl-8 pr-8">
              <div className="grid lg:grid-cols-4 w-full gap-4">
                <InputGlobal
                  title={"Código"}
                  isHook={true}
                  max={13}
                  isPlaceHolder={"Digite el código de la categoria"}
                  name={"codigo"}
                  validations={{
                    required: true,
                    pattern: AlfaNumerico,
                    maxLength: 13,
                  }}
                />
                <InputGlobal
                  title={"Nombre de la Categoría"}
                  max={50}
                  isPlaceHolder={"Digite el nombre de la Categoria"}
                  isHook={true}
                  validations={{
                    required: true,
                    maxLength: 50,
                  }}
                  name={"nombreCategoria"}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-1 text-white font-semibold text-[12px]">
            <button
              type="submit"
              disabled={isSending}
              className="p-3 rounded-lg bg-blue-600 hover:bg-blue-700 h-fit pr-5 pl-5 flex flex-row gap-2 items-center"
            >
              <span>
                <AddIcon />
              </span>
              Agregar
            </button>
            <div className="h-fit w-fit">
              <label
                htmlFor="importInput"
                className="p-[12px] cursor-pointer rounded-lg bg-blue-600 hover:bg-blue-700 pl-5 pr-5 flex flex-row gap-2 items-center"
              >
                <span>
                  <UploadIcon />
                </span>
                Importar
              </label>
              <input
                key={fileInputKey}
                className="absolute -left-[9999px]"
                id="importInput"
                accept=".xlsx"
                onChange={handleFileImportCategoria}
                type="file"
              />
            </div>
            <button
              type="button"
              onClick={handleFileExportCategorias}
              className="p-3 md:pl-8 md:pr-8 rounded-lg bg-gray-600 hover:bg-gray-700 whitespace-nowrap flex flex-row gap-2 items-center"
            >
              {" "}
              <span>
                <DownloadIcon />
              </span>
              <span className="hide-on-small">Descargar datos</span>
            </button>
            {CheckedHookCategoria.IsCheckedAll === true &&
              categorias.length > 0 && (
                <button
                  type="button"
                  onClick={() => desactiveCategory()}
                  className="p-[11px] md:pl-8 md:pr-8 rounded-lg bg-gray-600 hover:bg-gray-700 h-fit flex flex-row gap-2 items-center"
                >
                  {" "}
                  <span>
                    <OffIcon w={"20"} h={"20"} />
                  </span>
                  {<span className="hide-on-small">Deshabilitar</span>}
                </button>
              )}
          </div>
        </form>
      </FormProvider>
      <ModalSubirExcel
        isVisible={isUploading}
        progress={progress}
        elemento={"Categorias"}
      />
    </>
  );
};
