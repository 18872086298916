import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  empresas: [],
  empresa: {},
};

const slice = createSlice({
  name: "empresa",
  initialState,
  reducers: {
    initializeApp: () => initialState,
    cargarEmpresas: (state, action) => {
      if (action.payload != null) {
        if (action.payload.length > 0) {
          state.empresas = action.payload;
          state.empresa = action.payload[0];
        }
      }
    },
    seleccionarEmpresa: (state, action) => {
      if (action.payload != null) {
        state.empresa = state.empresas.find(
          (obj) => obj.idEmpresa === action.payload
        );
      }
    },
    actualizarEmpresa: (state, action) => {
      if (action.payload != null) {
        return {
          ...state,
          empresa: {
            ...state.empresa,
            idEmpresa: action.payload.IdEmpresa,
            personaNatural: action.payload.PersonaNatural,
            nombreComercial: action.payload.NombreComercial,
            direccionMatriz: action.payload.DireccionMatriz,
            correo: action.payload.Correo,
            contribuyenteEspecial: action.payload.ContribuyenteEspecial,
            contribuyenteRimpe: action.payload.ContribuyenteRimpe,
            obligadoContabilidad: action.payload.ObligadoContabilidad,
            agenteRetencion: action.payload.AgenteRetencion,
            emisor: action.payload.Emisor,
            numeroCelular: action.payload.NumeroCelular,
            activo: action.payload.Activo,
            puntoventa: action.payload.Puntoventa,
            identificacionContador: action.payload.identificacionContador,
            razonSocialContador: action.payload.razonSocialContador,
            correoContador: action.payload.correoContador,
            numeroCelularContador: action.payload.numeroCelularContador,
          },
        };
      }
      return state;
    },
  },
});

export const { cargarEmpresas, seleccionarEmpresa, actualizarEmpresa } =
  slice.actions;

export default slice.reducer;
