import { useContext, useRef, useState } from "react";
import { FormProvider } from "react-hook-form";
import { IngresoProviderContext } from "./Context/IngresoProvider";
import IngresoGeneral from "./IngresoGeneral";
import IngresoDetalle from "./IngresoDetalle";
import { v4 as uuidv } from "uuid";
import { TransaccionesContext } from "../../TransaccionesEmitidas/context/TransaccionesContext";
import { TransaccionButtons } from "../../../Shared/TransaccionButtons";
import useGlobalToastify from "../../../../../features/hooks/GlobalToastify/useGlobalToastify";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export const padNumber = (num, size) => {
  let s = "0000" + num;
  return s.substr(s.length - size);
};

export default function IngresoForm() {
  const {
    methods,
    rows,
    setRows,
    setIsOpen,
    setIsSending,
    isSending,
    secuencial,
    getSecuencial,
  } = useContext(IngresoProviderContext);

  const inputRef = useRef();
  const empresa = useSelector((state) => state.empresa.empresa);
  const { addTransaccion } = useContext(TransaccionesContext);
  const { ErrorToast } = useGlobalToastify();

  const Agg = async (data) => {
    if (
      rows.some(
        (row) =>
          row.codigo === "" || row.codigo === null || row.codigo === undefined
      )
    ) {
      ErrorToast("Debes seleccionar un producto en cada registro.");
      return;
    }
    if (rows.length === 0) {
      ErrorToast("No puedes generar una transacción vacia.");
      return;
    }

    const idTransaccion = uuidv();

    const newIngreso = {
      idTransaccion: idTransaccion,
      idEmpresa: empresa.idEmpresa,
      idTipoTransaccion: "8537215C-11CF-4A87-AF24-4AB5F8EBF66E",
      idSeccionOrigen: data.ubicacion.idSeccion,
      codigo: String(secuencial.codigo),
      detalle: data.detalle,
      fechaCreacion: data.fechaCreacion,
      productos: rows.map((registro) => ({
        idDetalleTransaccion: uuidv(),
        idTransaccion: idTransaccion,
        idProducto: registro.codigo.idProducto,
        cuentaAnalitica: registro.cuenta,
        cantidad: Number(registro.cantidad),
        costoDecimal: Number(registro.costo),
        lote: registro?.lote,
        fechaCaducidad: registro?.fechaCaducidad,
      })),
    };

    try {
      setIsSending(true);
      await toast.promise(addTransaccion(newIngreso), {
        pending: "Realizando el Ingreso...",
        success: "¡El ingreso se realizo con éxito!",
        error: {
          render({ data }) {
            return (
              data?.response?.data?.message ||
              "Hubo un error al realizar el ingreso."
            );
          },
        },
      });
    } finally {
      setIsSending(false);
    }
    methods.reset();
    methods.setValue("ubicacion", "");
    getSecuencial();

    setRows([
      {
        codigo: "",
        cuenta: "",
        cantidad: "",
        costo: "",
        lote: "",
        fechaCaducidad: "",
      },
    ]);
  };

  const DeleteData = () => {
    setRows([
      {
        codigo: "",
        cuenta: "",
        cantidad: "",
        costo: "",
        lote: "",
        fechaCaducidad: "",
      },
    ]);
    methods.reset();
    methods.setValue("ubicacion", "");
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(Agg)}
          autoComplete="off"
          className="flex flex-col gap-2"
        >
          <div className="bg-white dark:bg-gray-900 rounded-lg p-4 pb-0">
            <div className="bg-gray-200 dark:bg-gray-700 rounded-lg p-4 flex justify-between items-center">
              <p className="font-semibold text-xl dark:text-white ml-3">
                Transacción
              </p>
              <div className="flex items-center space-x-3">
                <p className="hide-on-small dark:text-white">Ingreso</p>
                <input
                  type="text"
                  ref={inputRef}
                  placeholder="Número de transacción"
                  {...methods.register("numeroTransaccion")}
                  className="border border-gray-400 bg-white rounded-md text-center p-1 w-full"
                  value={secuencial.transaccion}
                />
              </div>
            </div>
          </div>
          <div className="p-4 bg-white dark:bg-gray-900 rounded-lg">
            <div className="flex flex-col gap-4">
              <IngresoGeneral />
              <IngresoDetalle methods={methods} />
            </div>
            <TransaccionButtons DeleteData={DeleteData} isSending={isSending} />
          </div>
        </form>
      </FormProvider>
    </>
  );
}
