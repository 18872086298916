import React, { useState, useContext, useMemo } from "react";
import useGlobalToastify from "../../../features/hooks/GlobalToastify/useGlobalToastify";
import { EmitirDocContext } from "../../EmisionElectronico/EmitirRetencion/EmitirDocProvider";
import {
    TableStyle,
    TableStyleDiv,
    TableStyleTBody,
    TableStyleTHead,
} from "../../../features/Constantes";

const RetencionesTabla = () => {
    const {
        groupedData
    } = useContext(EmitirDocContext);

    const { ErrorToast } = useGlobalToastify();

    const [pagina, setPagina] = useState(1);
    const [elementosPorPagina, setElementosPorPagina] = useState(25);


    const detallesFlat = useMemo(() => {
        return groupedData.flatMap((factura) =>
            factura.DOCSSUSTENTO.map((detalle) => ({
                ...factura,
                ...detalle,
            }))
        );
    }, [groupedData]);


    const totalPaginas = useMemo(
        () => Math.ceil(detallesFlat.length / elementosPorPagina),
        [detallesFlat, elementosPorPagina]
    );

    const handleClickNextPage = () => {
        if (pagina < totalPaginas) setPagina(pagina + 1);
    };

    const handleClickPreviousPage = () => {
        if (pagina > 1) setPagina(pagina - 1);
    };

    const handleClickFirstPage = () => setPagina(1);
    const handleClickLastPage = () => setPagina(totalPaginas);

    const handleChangeElementosPorPagina = (e) => {
        setElementosPorPagina(Number(e.target.value));
        setPagina(1);
    };

    const getPaginatedData = (data) => {
        const startIndex = (pagina - 1) * elementosPorPagina;
        return data.slice(startIndex, startIndex + elementosPorPagina);
    };


    const dataToPaginate = getPaginatedData(detallesFlat);

    if (groupedData.length === 0) {
        ErrorToast('No se encontraron datos.');
        return null;
    }

    const totalRetenciones = groupedData.length;
    const totalLineas = detallesFlat.length;

    const totales = detallesFlat.reduce(
        (acc, detalle) => {
            acc.baseImponibleRenta += detalle.RENTA?.[0]?.BASEIMPONIBLERENTA || 0;
            acc.valorRetenidoRenta += detalle.RENTA?.[0]?.VALORRETRENTA || 0;
            acc.baseImponibleIVA += detalle.IVA?.[0]?.BASEIMPONIBLEIVA || 0;
            acc.valorRetenidoIVA += detalle.IVA || 0;
            return acc;
        },
        {
            baseImponibleRenta: 0,
            valorRetenidoRenta: 0,
            baseImponibleIVA: 0,
            valorRetenidoIVA: 0,
        }
    );

    const tableHeightClass = groupedData.length < 5 ? "h-auto" : "h-[65vh]";
    
    return (
        <div className="my-6">
            <div className="flex items-center mb-4">
                <h1 className="mr-2 text-gray-700">Mostrar</h1>
                <select
                    value={elementosPorPagina}
                    onChange={handleChangeElementosPorPagina}
                    className="border-gray-300 border px-2 py-1 rounded-md text-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                    {[25, 50, 75, 100, 200, 300, 400, 500, 1000].map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
                <h1 className="ml-2 text-gray-700">Registros</h1>
            </div>

            <h1 className="font-bold text-xl pb-4">Detallado</h1>
            <div className={`${tableHeightClass} ${TableStyleDiv}`}>
                <table className={TableStyle}>
                    <thead className={TableStyleTHead + " bg-[#d9d9d9]"}>
                        <tr>
                            {[
                                'Fecha Retención', 'Establecimiento', 'Pto Emisión', 'Secuencial', 'Cod. Doc. Sustento',
                                'N. Doc. Sustento', 'N. Identificación Sujeto', 'Razón Social Sujeto Retenido',
                                'Dirección Doc. Sustento', 'Correo Doc. Sustento', 'Fecha Emisión', 'Fecha Contable',
                                'N. Autorización', 'Es Parte Relacionada', 'Cod. Sustento',
                                'Código Renta', 'Base Imponible Renta', 'Código IVA Renta', '% Retener Renta',
                                'Valor Retenido Renta', 'Base Imponible Iva', '% Retener IVA', 'Valor Retenido IVA', 'Cod. Forma Pago',
                                'Inf. Adicional Nombre 1', 'Inf. Adicional Valor 1', 'Inf. Adicional Nombre 2',
                                'Inf. Adicional Valor 2', 'Inf. Adicional Nombre 3',
                                'Inf. Adicional Valor 3',
                            ].map((header) => (
                                <th
                                key={header}
                                className="px-4 py-2 border border-gray-300"
                            >
                                {header}
                            </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className={TableStyleTBody}>
                        {dataToPaginate.map((detalle, index) => (
                            <tr
                                key={index}
                                className="hover:bg-gray-100 border-b border-gray-300"
                            >
                                {/* {row.DOCSSUSTENTO.map((detalle, idx) => ( */}
                                    {/* <tr key={index} className="hover:bg-gray-100 border-b border-gray-300" > */}
                                        <td className="px-4 py-2 text-xs">{detalle.FECHARETENCION || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.ESTABLECIMIENTO || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.PTOEMISION || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.SECUENCIAL || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.CODSUSTENTO || ''} </td>
                                        <td className="px-4 py-2 text-xs">{detalle.NUMDOCSUSTENTO || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.IDENTIFICACIONSUJETO || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.RAZONSOCIAL || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.DIRECCIONCODSUSTENTO || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.CORREODOCSUSTENTO || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.FECHAEMISION || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.FECHACONTABLE || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.NUMAUTDOCSUSTENTO || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.PARTEREL || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.CODDOCSUSTENTO || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.RENTA?.[0]?.CODIGORENTA || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.RENTA?.[0]?.BASEIMPONIBLERENTA ? detalle.RENTA?.[0]?.BASEIMPONIBLERENTA.toFixed(3) :0 || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.RENTA?.[0]?.CODIGOIVARENTA || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.RENTA?.[0]?.PORCENTAJERETRENTA || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.RENTA?.[0]?.VALORRETRENTA ? detalle.RENTA?.[0]?.VALORRETRENTA.toFixed(3) : 0 || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.IVA?.[0]?.BASEIMPONIBLEIVA ? detalle.IVA?.[0]?.BASEIMPONIBLEIVA .toFixed(3) : 0 || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.IVA?.[0]?.PORCENTAJERETIVA || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.IVA?.[0]?.VALORRETIVA ? detalle.IVA?.[0]?.VALORRETIVA.toFixed(3) : 0 || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.PAGO || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[0]?.INFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[0]?.VALORINFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[1]?.INFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[1]?.VALORINFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[2]?.INFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[2]?.VALORINFADICIONAL || ''}</td>

                                    {/* </tr> */}
                                {/* ))} */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="mt-6 p-4 rounded-md">
                <h1 className="font-bold text-xl pb-4">Consolidado</h1>
                <table className={TableStyle}>
                    <thead className={TableStyleTHead + " bg-[#d9d9d9]"}>
                        <tr>
                            <th>BASE IMPONIBLE RENTA</th>
                            <th>VALOR RETENIDO RENTA</th>
                            <th>BASE IMPONIBLE IVA</th>
                            <th>VALOR RETENIDO IVA</th>
                        </tr>
                    </thead>
                    <tbody className={TableStyleTBody}>
                        <tr>
                            <td>${totales.baseImponibleRenta.toFixed(2)}</td>
                            <td>${totales.valorRetenidoRenta.toFixed(2)}</td>
                            <td>${totales.baseImponibleIVA.toFixed(2)}</td>
                            <td>${totales.baseImponibleIVA.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="flex items-center justify-between mt-4">
                <span className="text-gray-800">
                    Mostrando{" "}
                    <span className="font-bold">
                        {(pagina - 1) * elementosPorPagina + 1}
                    </span>{" "}
                    -{" "}
                    <span className="font-bold">
                        {pagina * elementosPorPagina > totalLineas
                            ? totalLineas
                            : pagina * elementosPorPagina}
                    </span>{" "}
                    líneas de{" "}
                    <span className="font-bold">
                        {totalRetenciones}
                    </span>{" "}
                    {totalRetenciones === 1 ? "retencion" : "retenciones"}
                </span>

                <div className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                    <PaginationButton
                        label="<<"
                        onClick={handleClickFirstPage}
                        disabled={pagina === 1}
                        first
                    />
                    <PaginationButton
                        label="<"
                        onClick={handleClickPreviousPage}
                        disabled={pagina === 1}
                    />
                    <PaginationButton
                        label=">"
                        onClick={handleClickNextPage}
                        disabled={pagina === totalPaginas}
                    />
                    <PaginationButton
                        label=">>"
                        onClick={handleClickLastPage}
                        disabled={pagina === totalPaginas}
                        last
                    />
                </div>
            </div>
        </div>
    );
};

const PaginationButton = ({ label, onClick, disabled, first, last }) => {
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={`
                ${first ? 'rounded-l-md' : ''}
                ${last ? 'rounded-r-md' : ''}
                ${disabled ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'bg-white hover:bg-gray-50'}
                px-3 py-2 border border-gray-300 text-sm font-medium
                ${disabled ? 'text-gray-400' : 'text-gray-500'}
            `}
        >
            {label}
        </button>
    );
};


export default RetencionesTabla;
