import React from "react";
import SubirFacturas from "./SubirFacturas";
import EmitirFacturaProvider from "../../EmisionElectronico/EmitirFactura/EmitirFacturaProvider";
import ReembolsoProvider from "../../../features/hooks/Reembolso/ReembolsoProvider";
import FacturaExportacionProvider from "../../EmisionElectronico/EmitirFactura/hooks/FacturaExportacion/FacturaExportacionProvider";

const SubirFacturasGeneral = () => {
  return (
    <div >
      <ReembolsoProvider>
        <FacturaExportacionProvider>
          <EmitirFacturaProvider>
            <SubirFacturas />
          </EmitirFacturaProvider>
        </FacturaExportacionProvider>
      </ReembolsoProvider>
    </div>
  );
};

export default SubirFacturasGeneral;
