import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const ReportesMenu = (props) => {
  const isAdmin = useSelector((store) => store.app.user.esAdministradorSistema);

  const [isPagesMenuOpen, setIsPagesMenuOpen] = useState(false);

  const [isPagesMenuOpen2, setIsPagesMenuOpen2] = useState(false);

  const [isPagesMenuOpen3, setIsPagesMenuOpen3] = useState(false);

  const [isPagesSubMenu, setIsPagesSubMenu] = useState(false);

  const AbrirSubMenu = () => {
    setIsPagesSubMenu(!isPagesSubMenu);
  };

  const permisos = useSelector((store) => store.permisosEmpresa.permisos);

  // const hanldeclick = () => {
  //   setIsPagesMenuOpen(!isPagesMenuOpen);
  // };

  const hanldeclick2 = () => {
    setIsPagesMenuOpen2(!isPagesMenuOpen2);
  };

  // const hanldeclick3 = () => {
  //   setIsPagesMenuOpen3(!isPagesMenuOpen3);
  // };
  let activeClassName =
    "w-full inline-block pl-12 pr-2 bg-[#5F7F93] text-white truncate py-3 transition-colors duration-300 dark:hover:text-gray-200 ";

  const MenuItems = () => (
    <>
      {" "}
      {permisos.some((el) => el.codigo === "03") ? (
        <NavLink
          title="Comprobantes emitidos"
          className={({ isActive }) =>
            isActive
              ? activeClassName
              : "py-3 pl-12 pr-2 transition-colors hover:text-white  hover:bg-[#5F7F93] w-full duration-300 inline-block dark:hover:text-gray-200 "
          }
          onClick={props.OpenMenu ? AbrirSubMenu : AbrirSubMenu}
          exact="true"
          to="/emision/reporte/general"
        >
          <div className="flex items-center">
            <span className="truncate">Comprobantes emitidos</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 ml-2 "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          </div>
        </NavLink>
      ) : null}
      {isPagesSubMenu && (
        <NavLink
          title="Comprobantes emitidos"
          className={({ isActive }) =>
            isActive
              ?  "w-full inline-block pl-20 text-white  pr-2 bg-[#5F7F93] py-3 transition-colors duration-300  dark:hover:text-gray-200 "
              : "py-3 pl-20 pr-2 transition-colors hover:bg-[#5F7F93] hover:text-white  w-full duration-300 inline-block dark:hover:text-gray-200 "
          }
          exact="true"
          to="/emision/generar/masivo"
        >
          <div className="flex items-center">
            <span className="truncate">Reporte masivo</span>
          </div>
        </NavLink>
      )}
      <NavLink
       title="Comprobantes recibidos"
        className={({ isActive }) =>
          isActive
            ? activeClassName
            : "  py-3 px-12 transition-colors hover:bg-[#5F7F93]  hover:text-white  hover:   w-full duration-300 inline-block truncate  dark:hover:text-gray-200"
        }
        exact="true"
        to="/emision/reporte/cliente"
      >
        Comprobantes recibidos
      </NavLink>
      {/* {permisos.some((el) => el.codigo === "03") ? (
        <NavLink
          className={({ isActive }) =>
            isActive
              ? activeClassName
              : "  py-3 px-12 transition-colors hover:bg-[#5F7F93] hover:   w-full duration-300 inline-block   dark:hover:text-gray-200"
          }
          exact="true"
          to="/emision/generar/masivo"
        >
          Reportes masivos
        </NavLink>
      ) : null} */}
    </>
  );

  return (
    <ul className="pt-2 text-white" title={!props.OpenMenu ? "Emision Documentos" : ""}>
      {props.OpenMenu ? (
        <Menu>
          <Menu.Button
            className={"w-full"}
            onClick={props.OpenMenu ? () => props.toggleItem("item2") : <></>}
          >
            <li className=" text-sm flex justify-between items-center  hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md">
              <div className="flex gap-x-4 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
                  />
                </svg>

                <span
                  className={`${
                    !props.OpenMenu && "hidden"
                  } origin-left duration-200`}
                >
                  {props.modulo}
                </span>
              </div>

              <span
                className={`${
                  !props.OpenMenu && "hidden"
                } origin-left duration-200`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className={`w-4 h-4 ${
                    isPagesMenuOpen
                      ? "rotate-180 duration-300"
                      : "rotate-0 duration-300"
                  }`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
            </li>
          </Menu.Button>
        </Menu>
      ) : (
        <Menu>
          <Menu.Button className={"w-full lg:table-cell hidden"}>
            <li className="  text-sm flex items-center text-gray-300 hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
                />
              </svg>
              <span
                className={`${
                  !props.OpenMenu && "hidden"
                } origin-left duration-200`}
              >
                {props.modulo}
              </span>
              <span
                className={`${
                  !props.OpenMenu && "hidden"
                } origin-left duration-200`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className={`w-4 h-4 ${
                    isPagesMenuOpen
                      ? "rotate-180 duration-300"
                      : "rotate-0 duration-300"
                  }`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
            </li>
          </Menu.Button>
          {props.OpenMenu ? (
            <></>
          ) : (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className=" ml-16 -mt-[40px] text-black z-50 absolute w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <MenuItems />
                </div>
              </Menu.Items>
            </Transition>
          )}
        </Menu>
      )}

      {props.openMobil ? (
        <ul className="" onClick={hanldeclick2}>
          <li className=" text-sm flex items-center text-gray-300 hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
              />
            </svg>

            <span
              className={`${!props.OpenMenu && ""} origin-left duration-200`}
            >
              {props.modulo}
            </span>
            <span
              className={`${!props.OpenMenu && ""} origin-left duration-200`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className={`w-4 h-4 ${
                  isPagesMenuOpen2
                    ? "rotate-180 duration-300"
                    : "rotate-0 duration-300"
                }`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </li>
        </ul>
      ) : null}

      {/* <button onClick={props.OpenMenu ? hanldeclick : null} className="w-full">
    
  </button> */}
      {props.openItem === "item2" && (
        <ul
          className={` mt-2 bg-[#003B5B] text-white  overflow-hidden text-sm font-medium  duration-1000 delay-300 rounded-md shadow-inner  dark:text-gray-400 dark:bg-gray-900 ${
            !props.OpenMenu && "hidden"
          }`}
          aria-label="submenu"
        >
          <MenuItems />
        </ul>
      )}

      {isPagesMenuOpen2 ? (
        <ul
          className={`p-2 mt-2 bg-[#003B5B]   overflow-hidden text-sm font-medium text-white duration-1000 delay-300 rounded-md shadow-inner  dark:text-gray-400 dark:bg-gray-900
        `}
          aria-label="submenu"
        >
          <MenuItems />
        </ul>
      ) : null}
    </ul>
  );
};

export default ReportesMenu;
