import React from "react";
const colores = {
  primary: "bg-blue-600 hover:bg-blue-700",
  secondary: "bg-[#04BC53] hover:bg-green-500",
  descargas: "bg-gray-400 hover:bg-gray-600",
  // subir: "bg-gray-300 hover:bg-gray-400"
};
export const ButtonDefault = ({
  svg,
  onClick,
  title,
  color,
  type,
  ...props
}) => {
  const colorSeleccionado =
    color && colores[color] ? colores[color] : colores.azul;

  return (
    <button
      type={type || "button"}
      className={`${colorSeleccionado}  gap-x-1 lg:w-auto md:w-auto flex items-center w-full text-white px-4 py-2 h-fit rounded-lg`}
      onClick={onClick}
      {...props}
    >
      {svg}
      {title}
    </button>
  );
};
