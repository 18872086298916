import { TransaccionesProvider } from "../../TransaccionesEmitidas/context/TransaccionesContext";
import IngresoProvider from "./Context/IngresoProvider";
import IngresoForm from "./IngresoForm";
import ModalIngreso from "./ModalIngreso";

export default function Ingreso() {
  return (
    <TransaccionesProvider>
        <IngresoProvider>
          <IngresoForm />
          <ModalIngreso />
        </IngresoProvider>
    </TransaccionesProvider>
  );
}
