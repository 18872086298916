import { Menu, Transition } from "@headlessui/react";
import { useState, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { InventarioSubMenus } from "./options/InventarioModule.d";
import { OptionOpenMenu } from "./shared/OptionOpenMenu";
import { OptionCloseMenu } from "./shared/OptionCloseMenu";
export const InventarioOption = ({
  OpenMenu,
  modulo,
  setOpenMenu,
  openMobil,
  setOpenMobil,
  openItem,
  setOpenItem,
  toggleItem,
  setSubMenu,
  subMenu,
}) => {
  const [isPagesMenuOpen, setPageOpen] = useState(false);

  const [isPagesMenuOpen2, setIsPagesMenuOpen2] = useState(false);

  const hanldeclick2 = () => {
    setIsPagesMenuOpen2(!isPagesMenuOpen2);
  };

  const OpenedMenu = () => {
    setPageOpen(!isPagesMenuOpen);
    toggleItem("item9");
  };

  return (
    <>
      <ul className="pt-2" title={!OpenMenu ? "Inventarios" : ""}>
        {OpenMenu ? (
          <Menu>
            <Menu.Button
              className={"w-full"}
              onClick={OpenMenu ? () => OpenedMenu() : <></>}
            >
              <li className=" text-sm flex justify-between items-center text-white hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md">
                <div className="flex gap-x-4 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-6 h-6 mt-2 ml-1"
                  >
                    <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2zm3.564 1.426L5.596 5 8 5.961 14.154 3.5zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464z" />
                  </svg>

                  <span
                    className={`${
                      !OpenMenu && "hidden"
                    } origin-left duration-200`}
                  >
                    {modulo}
                  </span>
                </div>

                <span
                  className={`${
                    !OpenMenu && "hidden"
                  } origin-left duration-200`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className={`w-4 h-4 ${
                      isPagesMenuOpen
                        ? "rotate-180 duration-300"
                        : "rotate-0 duration-300"
                    }`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </span>
              </li>
            </Menu.Button>
          </Menu>
        ) : (
          <Menu>
            <Menu.Button className={"w-full lg:table-cell hidden"}>
              <li className="  text-sm flex items-center text-gray-300 hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-6 mt-2 ml-1"
                >
                  <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2zm3.564 1.426L5.596 5 8 5.961 14.154 3.5zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464z" />
                </svg>

                <span
                  className={`${
                    !OpenMenu && "hidden"
                  } origin-left duration-200`}
                >
                  {modulo}
                </span>
                <span
                  className={`${
                    !OpenMenu && "hidden"
                  } origin-left duration-200`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className={`w-4 h-4 ${
                      isPagesMenuOpen
                        ? "rotate-180 duration-300"
                        : "rotate-0 duration-300"
                    }`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </span>
              </li>
            </Menu.Button>
            {OpenMenu ? (
              <></>
            ) : (
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className=" ml-16 -mt-[40px] z-50 absolute w-64 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <NavLink
                    exact="true"
                    className={
                      "py-3 px-12 text-black transition-colors hover:bg-[#5F7F93] hover:   w-full duration-300 inline-block   dark:hover:text-gray-200"
                    }
                    to={"/inventario/configuracion"}
                  >
                    Configuracion
                  </NavLink>
                  {InventarioSubMenus.map((menu, i) => (
                    <OptionCloseMenu
                      key={i}
                      isPagesMenuOpen={isPagesMenuOpen}
                      subMenu={subMenu}
                      setSubMenu={setSubMenu}
                      Data={menu}
                    />
                  ))}
                </Menu.Items>
              </Transition>
            )}
          </Menu>
        )}

        {openMobil ? (
          <ul className="" onClick={hanldeclick2}>
            <li className=" text-sm flex items-center text-gray-300 hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6 mt-2 ml-1"
              >
                <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2zm3.564 1.426L5.596 5 8 5.961 14.154 3.5zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464z" />
              </svg>
              <span className={`${!OpenMenu && ""} origin-left duration-200`}>
                {modulo}
              </span>
              <span className={`${!OpenMenu && ""} origin-left duration-200`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className={`w-4 h-4 ${
                    isPagesMenuOpen2
                      ? "rotate-180 duration-300"
                      : "rotate-0 duration-300"
                  }`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
            </li>
          </ul>
        ) : null}

        {openItem === "item9" && (
          <ul
            className={` mt-2 bg-[#003B5B] w-full    overflow-hidden text-sm font-medium text-white duration-1000 delay-300 rounded-md shadow-inner  dark:text-gray-400 dark:bg-gray-900 ${
              !OpenMenu && "hidden"
            }`}
            aria-label="submenu"
          >
            <NavLink
              className={
                "py-3 px-12 transition-colors hover:bg-[#5F7F93] hover:   w-full duration-300 inline-block   dark:hover:text-gray-200"
              }
              exact="true"
              to={"/inventario/configuracion"}
            >
              Configuracion
            </NavLink>
            {InventarioSubMenus.map((menu, i) => (
              <OptionOpenMenu
                key={i}
                isPagesMenuOpen={isPagesMenuOpen}
                subMenu={subMenu}
                setSubMenu={setSubMenu}
                OpenMenu={OpenMenu}
                Data={menu}
              />
            ))}
          </ul>
        )}
      </ul>
    </>
  );
};
