import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import debounce from "lodash.debounce";

export const AutoCompleteProduct = ({
  value,
  onChange,
  index,
  productos,
  noOption,
  required,
  disabled,
}) => {
  const [selectedOption, setSelectedOption] = useState(value || null);

  const loadOptions = debounce((inputValue, callback) => {
    setTimeout(() => {
      const filteredOptions = productos.filter(
        (option) =>
          String(option.codigo)
            .toLowerCase()
            .includes(inputValue.toLowerCase()) ||
          option.nombre.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(filteredOptions);
    }, 500);
  }, 400);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(index, selectedOption);
  };

  useEffect(() => {
    setSelectedOption(value || null);
  }, [value]);

  return (
    <AsyncSelect
      cacheOptions
      menuPlacement="auto"
      isDisabled={disabled}
      menuPosition="fixed"
      defaultOptions={productos}
      loadOptions={loadOptions}
      styles={{
        container: (styles) => ({
          ...styles,
          width: "300px",
          margin: "auto",
        }),
        control: (provided, state) => ({
          ...provided,
          border: "none",
          boxShadow: state.isFocused ? "none" : provided.boxShadow,
          backgroundColor: "transparent",
          borderColor:
            required && !selectedOption ? "red" : provided.borderColor,
        }),
        indicatorSeparator: () => ({
          display: "none",
        }),
        placeholder: (styles) => ({ ...styles }),
        option: (styles) => ({
          ...styles,
        }),
        menu: (styles) => ({
          ...styles,
        }),
        singleValue: (styles) => ({
          ...styles,
          color: window.matchMedia("(prefers-color-scheme: dark)").matches
            ? "white"
            : "black",
        }),
      }}
      value={selectedOption}
      onChange={handleChange}
      noOptionsMessage={() => noOption}
      isClearable
      getOptionLabel={(e) =>
        `${String(e["codigo"]).padStart(3, "0")} - ${String(e["nombre"])}`
      }
      getOptionValue={(e) => e["nombre"]}
      placeholder={`Código / Producto`}
    />
  );
};
