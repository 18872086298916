import { NavLink } from "react-router-dom";

export const TransaccionesMenu = () => {
  return (
    <section
      style={{ padding: "10px" }}
      className="w-full lg:h-[400px] h-fit relative bg-white dark:bg-gray-900 rounded-lg"
    >
      <div
        className="bg-[#003B5B] rounded-lg p-12"
        style={{ height: "240px", width: "100%" }}
      >
        <h1 className="text-3xl text-white font-semibold">Transacciones</h1>
        <div className="grid sm:grid-cols-1 mt-12 gap-6 lg:grid-cols-4 bg-transparent h-full">
          <NavLink
            exact="true"
            to={"/inventario/operaciones/transacciones/ingresos"}
            className="dark:bg-gray-700 gap-3 flex dark:text-white dark:hover:text-black font-semibold flex-col text-center bg-gray-50 hover:bg-[#E5F4FD] items-center justify-center rounded-lg shadow-xl hover:drop-shadow-xl divide-y divide-gray-200  hover:scale-105 duration-300 transition ease-in-out delay-150"
            style={{ minHeight: "150px", height: "180px" }}
          >
            <svg
              fill="currentColor"
              viewBox="0 0 1024 1024"
              height="80"
              width="80"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M157.867 554.667h-76.8c-29.867 0-51.2 21.333-51.2 51.2v273.067c0 29.867 21.333 51.2 51.2 51.2h76.8c29.867 0 51.2-21.333 51.2-51.2V605.867c0-25.6-21.333-51.2-51.2-51.2zm8.533 324.266c0 4.267-4.267 8.533-8.533 8.533h-76.8c-4.267 0-8.533-4.267-8.533-8.533V605.866c0-4.267 4.267-8.533 8.533-8.533h76.8c4.267 0 8.533 4.267 8.533 8.533v273.067zm251.733-371.2h-76.8c-29.867 0-51.2 21.333-51.2 51.2v328.533c0 29.867 21.333 51.2 51.2 51.2h76.8c29.867 0 51.2-21.333 51.2-51.2v-332.8c0-25.6-21.333-46.933-51.2-46.933zm8.534 375.467c0 4.267-4.267 8.533-8.533 8.533h-76.8c-4.267 0-8.533-4.267-8.533-8.533V554.667c0-4.267 4.267-8.533 8.533-8.533h76.8c4.267 0 8.533 4.267 8.533 8.533V883.2zM678.4 456.533h-76.8c-29.867 0-51.2 21.333-51.2 51.2v384c0 29.867 21.333 51.2 51.2 51.2h76.8c29.867 0 51.2-21.333 51.2-51.2v-384c0-29.867-25.6-51.2-51.2-51.2zm8.533 435.2c0 4.267-4.267 8.533-8.533 8.533h-76.8c-4.267 0-8.533-4.267-8.533-8.533v-384c0-4.267 4.267-8.533 8.533-8.533h76.8c4.267 0 8.533 4.267 8.533 8.533v384zm251.734-486.4h-76.8c-29.867 0-51.2 21.333-51.2 51.2v430.933c0 29.867 21.333 51.2 51.2 51.2h76.8c29.867 0 51.2-21.333 51.2-51.2V456.533c0-25.6-25.6-51.2-51.2-51.2zm8.533 482.134c0 4.267-4.267 8.533-8.533 8.533h-76.8c-4.267 0-8.533-4.267-8.533-8.533V456.534c0-4.267 4.267-8.533 8.533-8.533h76.8c4.267 0 8.533 4.267 8.533 8.533v430.933zm25.6-780.8s-4.267 0 0 0c-4.267 0-4.267 0 0 0H870.4c-12.8 0-21.333 8.533-21.333 21.333s8.533 21.333 21.333 21.333h42.667c-268.8 149.333-563.2 234.667-870.4 251.733-12.8 0-21.333 12.8-21.333 21.333 0 12.8 8.533 21.333 21.333 21.333 324.267-17.067 627.2-106.667 908.8-264.533v42.667c0 12.8 8.533 21.333 21.333 21.333 12.8 0 21.333-8.533 21.333-21.333v-98.133c0-8.533-8.533-17.067-21.333-17.067z"></path>
              </g>
            </svg>
            Ingresos
          </NavLink>
          <NavLink
            exact="true"
            to={"/inventario/operaciones/transacciones/egresos"}
            className="dark:bg-gray-700 gap-3 flex flex-col dark:hover:text-black dark:text-white font-semibold text-center bg-gray-50 hover:bg-[#E5F4FD]  items-center justify-center rounded-lg shadow-xl hover:drop-shadow-xl divide-y divide-gray-200  hover:scale-105 duration-300 transition ease-in-out delay-150"
            style={{ minHeight: "150px", height: "180px" }}
          >
            <svg
              fill="currentColor"
              viewBox="0 0 1024 1024"
              height="80"
              width="80"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M951.467 554.667h-76.8c-29.867 0-51.2 21.333-51.2 51.2v285.867c0 29.867 21.333 51.2 51.2 51.2h76.8c29.867 0 51.2-21.333 51.2-51.2V605.867c0-25.6-21.333-51.2-51.2-51.2zM960 891.733c0 4.267-4.267 8.533-8.533 8.533h-76.8c-4.267 0-8.533-4.267-8.533-8.533V605.866c0-4.267 4.267-8.533 8.533-8.533h76.8c4.267 0 8.533 4.267 8.533 8.533v285.867zM678.4 503.467h-76.8c-29.867 0-51.2 21.333-51.2 51.2v345.6c0 29.867 21.333 51.2 51.2 51.2h76.8c29.867 0 51.2-21.333 51.2-51.2v-345.6c0-25.6-21.333-51.2-51.2-51.2zm8.533 401.066c0 4.267-4.267 8.533-8.533 8.533h-76.8c-4.267 0-8.533-4.267-8.533-8.533V554.666c0-4.267 4.267-8.533 8.533-8.533h76.8c4.267 0 8.533 4.267 8.533 8.533v349.867zm-273.066-460.8h-76.8c-29.867 0-51.2 21.333-51.2 51.2v409.6c0 29.867 21.333 51.2 51.2 51.2h76.8c29.867 0 51.2-21.333 51.2-51.2v-409.6c0-29.867-21.333-51.2-51.2-51.2zm8.533 460.8c0 4.267-4.267 8.533-8.533 8.533h-76.8c-4.267 0-8.533-4.267-8.533-8.533v-409.6c0-4.267 4.267-8.533 8.533-8.533h76.8c4.267 0 8.533 4.267 8.533 8.533v409.6zm-273.067-512h-76.8c-29.867 0-51.2 21.333-51.2 51.2v460.8c0 29.867 21.333 51.2 51.2 51.2h76.8c29.867 0 51.2-21.333 51.2-51.2v-460.8c0-29.867-21.333-51.2-51.2-51.2zm8.534 512c0 4.267-4.267 8.533-8.533 8.533h-76.8c-4.267 0-8.533-4.267-8.533-8.533v-460.8c0-4.267 4.267-8.533 8.533-8.533h76.8c4.267 0 8.533 4.267 8.533 8.533v460.8zm-128-759.466C307.2 307.2 610.134 401.067 930.134 422.4l-34.133 38.4c-8.533 8.533-8.533 21.333 0 29.867 4.267 4.267 8.533 4.267 12.8 4.267s12.8-4.267 17.067-8.533l68.267-72.533s0-4.267 4.267-4.267v-25.602s0-4.267-4.267-4.267l-68.267-72.533c-8.533-8.533-21.333-8.533-29.867 0s-8.533 21.333 0 29.867l29.867 34.133c-307.2-12.8-601.6-106.667-870.4-260.267-12.8-8.533-25.6-4.267-29.867 4.267-8.533 12.8-4.267 25.6 4.267 29.867z"></path>
              </g>
            </svg>
            Egresos
          </NavLink>
          <NavLink
            exact="true"
            to={"/inventario/operaciones/transacciones/transferencias"}
            className="dark:bg-gray-700 gap-3 flex flex-col dark:hover:text-black dark:text-white font-semibold text-center bg-gray-50 hover:bg-[#E5F4FD] items-center justify-center rounded-lg shadow-xl hover:drop-shadow-xl divide-y divide-gray-200 hover:scale-105 duration-300 transition ease-in-out delay-150"
            style={{ minHeight: "150px", height: "180px" }}
          >
            <svg
              fill="currentColor"
              height="80"
              width="80"
              viewBox="0 0 490 490"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <g>
                  {" "}
                  <path d="M480.17,13.992H282.281c-5.434,0-9.83,4.396-9.83,9.83v67.995V221.72c0,5.433,4.396,9.83,9.83,9.83H480.17 c5.434,0,9.83-4.397,9.83-9.83V91.817V23.822C490,18.387,485.604,13.992,480.17,13.992z M292.111,33.652H470.34v48.335H292.111 V33.652z M470.34,211.89H292.111V101.647H470.34V211.89z"></path>{" "}
                  <path d="M207.73,258.449H9.83c-5.433,0-9.83,4.397-9.83,9.83v67.995v129.905c0,5.433,4.397,9.83,9.83,9.83H207.73 c5.433,0,9.83-4.397,9.83-9.83V336.274v-67.995C217.56,262.846,213.163,258.449,207.73,258.449z M19.66,278.109h178.239v48.335 H19.66V278.109z M197.899,456.348H19.66V346.104h178.239V456.348z"></path>{" "}
                  <path d="M418.761,298.508l-35.174,35.519l13.978,13.843l18.355-18.543v79.405H312.616v19.66h113.133c5.433,0,9.83-4.397,9.83-9.83 v-89.235l18.355,18.543l13.977-13.843l-35.174-35.519C429.033,294.784,422.466,294.784,418.761,298.508z"></path>{" "}
                  <path d="M64.262,194.409c2.486,0,4.972-0.941,6.883-2.813l36.181-35.518l-13.766-14.035l-19.468,19.11V81.266h103.302v-19.66 H64.262c-5.434,0-9.83,4.397-9.83,9.83v89.72l-19.478-19.114l-13.766,14.035l36.192,35.518 C59.288,193.469,61.775,194.409,64.262,194.409z"></path>{" "}
                  <rect
                    x="390.576"
                    y="47.533"
                    width="50.917"
                    height="19.66"
                  ></rect>{" "}
                  <rect
                    x="126.899"
                    y="292.451"
                    width="50.917"
                    height="19.66"
                  ></rect>{" "}
                </g>{" "}
              </g>
            </svg>
            Transferencia
          </NavLink>
          <NavLink
            exact="true"
            to={"/inventario/operaciones/transacciones/ajuste-inventario"}
            className="dark:bg-gray-700 gap-3 flex flex-col dark:hover:text-black dark:text-white font-semibold text-center bg-gray-50 hover:bg-[#E5F4FD] items-center justify-center rounded-lg shadow-xl hover:drop-shadow-xl divide-y divide-gray-200 hover:scale-105 duration-300 transition ease-in-out delay-150"
            style={{ minHeight: "150px", height: "180px" }}
          >
            <svg
              fill="currentColor"
              height="80"
              width="80"
              viewBox="0 0 1024 1024"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M951.467 721.067C908.8 678.4 853.334 665.6 802.134 674.134l-409.6-409.6c8.533-51.2-4.267-110.933-46.933-149.333-42.667-46.933-110.933-64-174.933-42.667 0 0-4.267 0-4.267 4.267-4.267 0-4.267 4.267-8.533 4.267-8.533 8.533-8.533 21.333 0 29.867l59.733 59.733c4.267 4.267 4.267 12.8 0 17.067l-29.867 29.867c-8.533 4.267-12.8 4.267-17.067 0l-59.733-55.467c-8.533-8.533-21.333-8.533-29.867 0 0 0 0 4.267-4.267 4.267 0 0 0 4.267-4.267 4.267-21.333 59.733-8.533 128 38.4 174.933 34.133 34.133 76.8 46.933 115.2 46.933 12.8 0 21.333 0 34.133-4.267l409.6 409.6c-12.8 51.2 4.267 110.933 46.933 149.333 29.867 29.867 72.533 46.933 119.467 46.933 21.333 0 38.4-4.267 55.467-8.533 0 0 4.267 0 4.267-4.267 4.267 0 4.267-4.267 8.533-4.267 8.533-8.533 8.533-21.333 0-29.867L849.066 896c-4.267-4.267-4.267-4.267-4.267-8.533s0-8.533 4.267-8.533l29.867-29.867c4.267-4.267 4.267-4.267 8.533-4.267s4.267 0 8.533 4.267l55.467 55.467c8.533 8.533 21.333 8.533 29.867 0 0 0 0-4.267 4.267-4.267 4.267-4.267 4.267-4.267 4.267-8.533 25.6-59.733 8.533-128-38.4-170.667zM140.8 315.733c-25.6-25.6-38.4-64-34.133-98.133l29.867 29.867c12.8 8.533 25.6 17.067 42.667 17.067 12.8 0 29.867-4.267 38.4-17.067l29.867-29.867c21.333-21.333 21.333-55.467 0-76.8l-34.133-34.133c38.4-4.267 72.533 8.533 102.4 34.133 46.933 46.933 46.933 128 0 174.933-46.933 51.2-123.733 51.2-174.933 0zm166.4 59.734c12.8-8.533 29.867-17.067 38.4-29.867 12.8-12.8 21.333-25.6 29.867-42.667l384 384C746.667 695.466 729.6 704 716.8 716.8s-21.333 25.6-29.867 38.4L307.2 375.467zm580.267 426.666c-12.8 0-29.867 4.267-38.4 17.067L819.2 849.067c-8.533 8.533-17.067 25.6-17.067 38.4s4.267 29.867 17.067 38.4l29.867 29.867c-38.4 4.267-72.533-8.533-102.4-34.133-46.933-46.933-46.933-128 0-174.933s128-46.933 174.933 0c25.6 25.6 38.4 64 34.133 102.4l-29.867-29.867c-8.533-8.533-21.333-17.067-38.4-17.067z"></path>
                <path d="M610.133 409.6c4.267 0 12.8 0 17.067-4.267L802.133 230.4l34.133 34.133-174.933 174.933c-8.533 8.533-8.533 21.333 0 29.867 4.267 4.267 8.533 4.267 17.067 4.267 4.267 0 12.8 0 17.067-4.267l196.267-192 68.267-25.6c4.267-4.267 12.8-8.533 12.8-12.8l29.867-85.333c4.267-8.533 0-17.067-4.267-21.333l-59.733-64c-4.267-4.267-12.8-8.533-21.333-4.267l-85.333 29.867c-4.267 4.267-12.8 8.533-12.8 12.8l-25.6 72.533c-4.267 0-4.267 4.267-4.267 4.267l-192 192c-8.533 8.533-8.533 21.333 0 29.867 4.267 4.267 8.533 4.267 12.8 4.267zm243.2-277.333l64-21.333 38.4 38.4-21.333 64-64 21.333-38.4-38.4 21.333-64zm-384 558.933L209.066 951.467c-8.533 8.533-17.067 8.533-21.333 4.267l-81.067-81.067c-4.267-4.267 0-12.8 4.267-21.333l256-256c8.533-8.533 8.533-21.333 0-29.867s-21.333-8.533-29.867 0l-256 256c-25.6 25.6-25.6 59.733-4.267 81.067l81.067 81.067c8.533 8.533 21.333 12.8 34.133 12.8 17.067 0 34.133-8.533 46.933-21.333l260.267-260.267c8.533-8.533 8.533-21.333 0-29.867s-21.333-4.267-29.867 4.267z"></path>
              </g>
            </svg>
            Ajuste de Inventario
          </NavLink>
        </div>
      </div>
    </section>
  );
};
