import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import DashboarItem from "./DashboarItem";
import ModalInfo from "./ModalInfo";
import factura from "../../img/contribuyente.png";
import ModalComunicado from "./ModalComunicado";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";
import CardDasboard from "../../features/components/CardDasboard";
import "react-datepicker/dist/react-datepicker.css";
import { DocumentosEmitidosGraf } from "./Indicadores/DocumentosEmitidosGraf";
import DatePicker from "react-datepicker";
import { Bar } from "react-chartjs-2";
import * as Yup from "yup";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { getList, getListV2 } from "../../services";
import { StyleSelect } from "../../features/Constantes";
import { rellenarCeros } from "../../services/Utilitario";
import { Spinner } from "../../shared/components/Spinners/Spinner";
import ModalVigencia from "./ModalVigencia";
import { useFormik } from "formik";
import { es } from "date-fns/locale";

const Dashboard = () => {
  const permisos = useSelector((store) => store.permisosEmpresa.permisos);
  const [IsDownSRI, setIsDownSRI] = useState(process.env.REACT_APP_SRI_IS_DOWN);
  const [Establecimientos, setEstablecimientos] = useState([]);
  const [PuntosEmision, setPuntosEmision] = useState([]);
  const [Comunicado, setComunicado] = useState(
    process.env.REACT_APP_COMUNICATE_INTUITO
  );
  const [dashBoardState, setDashBoardState] = useState("accesos");
  const empresa = useSelector((store) => store.empresa.empresa);
  const splideRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  ChartJS.register(
    CategoryScale,
    ArcElement,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  useEffect(() => {
    if (splideRef.current) {
      const splideInstance = splideRef.current.splide;
      const prevArrow = document.querySelector(".custom-prev-arrow");
      const nextArrow = document.querySelector(".custom-next-arrow");
      if (prevArrow && nextArrow) {
        prevArrow.addEventListener("click", () => splideInstance.go("-1"));
        nextArrow.addEventListener("click", () => splideInstance.go("+1"));
      }
    }
  }, []);

  useEffect(() => {
    if (Comunicado === "1") {
      document.getElementById("ComunicadoButton").click();
      setComunicado("0");
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      establecimiento: "",
      puntoEmision: "",
    },
    validationSchema: Yup.object({
      establecimiento: Yup.string().max(3, "Tamaño del campo 3"),
      puntoEmision: Yup.string().max(300, "Tamaño del campo 3"),
    }),
    onSubmit: async (obj) => {
      try {
        console.log("hola");
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleChangePuntoEmision = (e) => {
    formik.setFieldValue("secuencial", "");
    formik.handleChange(e);
  };

  const handleChangeEstablecimiento = async (e) => {
    try {
      const index = e.target.selectedOptions[0].getAttribute("data-index");

      const establecimiento = Establecimientos[index];

      if (index == null) {
        formik.setFieldValue("puntoEmision", "");
      } else {
        const GetAllPuntosEmision = await getListV2(
          establecimiento,
          "GetListPuntosEmision"
        );

        setPuntosEmision(GetAllPuntosEmision._embedded);
      }
    } catch (ex) {
      //console.log(ex);
    } finally {
      formik.setFieldValue("secuencial", "");
      formik.handleChange(e);
    }
  };

  useEffect(() => {
    (async () => {
      const GetAllEstablecimientos = await getListV2(
        empresa,
        "ObtenerEstablecimientosPorEmpresa"
      );
      setEstablecimientos(GetAllEstablecimientos._embedded);
    })();
  }, []);

  const [form, setForm] = useState({});
  const [chartData, setChartData] = useState();
  const [empty] = useState({
    labels: [],
    datasets: [
      {
        label: "Cantidad Total de ventas",
        data: [],
        backgroundColor: ["#00314D", "#d5d6d7"],
        borderColor: ["white", "white"],
        borderWidth: 1,
      },
    ],
  });

  const [dateRange, setDateRange] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (empresa.idEmpresa) {
      apiData();
    }
  }, [
    empresa,
    dateRange,
    formik.values.establecimiento,
    formik.values.puntoEmision,
  ]);

  const handleMonthChange = (selectedOption) => {
    setDateRange(selectedOption);
  };

  const apiData = async () => {
    try {
      setLoading(true);

      const selectedDate = new Date(dateRange);

      const fechaInicio = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        1
      )
        .toISOString()
        .split("T")[0];

      const fechaFin = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        0
      )
        .toISOString()
        .split("T")[0];

      let response = await getList(
        `api/dashboard/Reportes?idEmpresa=${empresa.idEmpresa}&FechaInicio=${fechaInicio}&FechaFin=${fechaFin}&Establecimiento=${formik.values.establecimiento}&PtoEmision=${formik.values.puntoEmision}`
      );

      setForm(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (form.dias && form.dias.length > 0) {
      const labels = form.dias.map((item) => {
        const date = new Date(item.dia + "T00:00:00");
        return date.getUTCDate();
      });

      const totals = form.dias.map((item) => item.total);
      const data = {
        labels: labels,
        datasets: [
          {
            label: "Total Ventas",
            data: totals,
            backgroundColor: ["#00314D"],
            borderColor: ["white", "white"],
            borderWidth: 1,
            cutout: "65%",
          },
        ],
      };

      setChartData(data);
    } else {
      const labels = [];
      const totals = [];
      const data = {
        labels: labels,
        datasets: [
          {
            label: "Cantidad Total de ventas",
            data: totals,
            backgroundColor: ["#00314D", "#d5d6d7"],
            borderColor: ["white", "white"],
            borderWidth: 1,
          },
        ],
      };
      setChartData(data);
    }
  }, [form]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxRotation: 45,
          minRotation: 0,
        },
        title: {
          display: true,
          text: "Días",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Dólar",
        },
      },
    },
    elements: {
      bar: {
        barPercentage: 0.8,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
  };

  const boolVigencia = empresa.vigencia ?? false;
  const [isOpenVigencia, setIsOpenVigencia] = useState(boolVigencia);

  const toggleModalVigencia = () => {
    setIsOpenVigencia(!isOpenVigencia);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (IsDownSRI === "1") {
      toggleModal();
    }
  });

  useEffect(() => {
    if (IsDownSRI === "1") {
      toggleModal();
    }
  });

  return (
    <div className="lg:mx-4 md:mx-4 lg:my-2 md:my-2">
      <ModalVigencia
        isOpen={isOpenVigencia}
        toggleModal={toggleModalVigencia}
        message={empresa.message}
      />
      <ModalInfo isOpen={isOpen} toggleModal={toggleModal} />
      <ModalComunicado />
      <button
        className=" hidden hover:bg-gray-200  dark:hover:bg-gray-700  rounded-lg p-2  text-sm font-medium leading-5  dark:text-gray-400 focus:outline-none focus:shadow-outline-grayduration-150 ease-in-out"
        data-bs-toggle="modal"
        data-bs-target="#SriIsDownModal"
        id="SriIsDownModalButton"
      ></button>
      <button
        className=" hidden hover:bg-gray-200  dark:ho|ver:bg-gray-700  rounded-lg p-2  text-sm font-medium leading-5  dark:text-gray-400 focus:outline-none focus:shadow-outline-grayduration-150 ease-in-out"
        data-bs-toggle="modal"
        data-bs-target="#Comunicado"
        id="ComunicadoButton"
      ></button>

      <div className="pt-8 flex flex-col gap-3 dark:bg-gray-800  sm:rounded-lg sm:p-6">
        <h3 className="text-2xl bg-white dark:bg-gray-700 p-8 font-medium text-center leading-6 rounded-lg text-blue-600 dark:text-white select-none">
          <span className="text-xl text-[#00314D] dark:text-gray-300">
            Bienvenido(a) al facturador de{" "}
            <span className="text-black dark:text-gray-300">
              {empresa.razonSocial}
            </span>
          </span>
        </h3>
        <div className=" my-3 bg-white dark:bg-gray-700 rounded-lg ">
          <nav className="flex flex-row w-full h-full text-[14px]">
            <button
              className={`border border-t-4 border-l-0 border-r-0 flex items-center ${
                dashBoardState === "accesos"
                  ? " border-t-[#00314D] dark:border-t-[#00314D] border-b-0"
                  : "border-t-white dark:border-gray-600"
              } p-4 pl-8 pr-8 justify-center whitespace-nowrap h-full dark:text-gray-300`}
              onClick={() => setDashBoardState("accesos")}
            >
              Accesos Rapidos
            </button>
            <button
              className={`border border-t-4 flex items-center ${
                dashBoardState === "indicadores"
                  ? "border-t-[#00314D] dark:border-t-[#00314D] border-b-0 border-r"
                  : "border-t-white border-r-0 dark:border-gray-600"
              } p-4 pl-8 pr-8 justify-center h-full dark:text-gray-300`}
              onClick={() => setDashBoardState("indicadores")}
            >
              Indicadores
            </button>
            <div className="border-b dark:border-gray-600 flex-1 w-full"></div>
          </nav>
          {dashBoardState === "accesos" && (
            <section className="p-4">
              <div className="my-4 ">
                <div className="border-b border-b-gray-400/50  ">
                  <p className="font-semibold text-lg mx-6 dark:text-white">
                    ¿Qué deseas hacer ahora?
                  </p>
                  <div
                    className={`grid grid-cols-1 gap-6 my-auto p-6  sm:grid-cols-1 md:grid-cols-2  ${
                      Object.keys(empresa).length === 0
                        ? "lg:grid-cols-2"
                        : "lg:grid-cols-4"
                    }  `}
                  >
                    {Object.keys(empresa).length === 0 ? (
                      <>
                        <DashboarItem
                          isActive={true}
                          title={"Consultar documentos electrónicos Recibidos"}
                          route={"/emision/reporte/cliente"}
                          svg={
                            <svg>
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                              <path d="M12 21h-5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v4.5" />
                              <path d="M16.5 17.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0" />
                              <path d="M18.5 19.5l2.5 2.5" />
                            </svg>
                          }
                        />
                        <DashboarItem
                          isActive={true}
                          title={"Editar Usuario"}
                          route={"/emision/reporte/editar-usuario"}
                          svg={
                            <svg>
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                              <path d="M16 19h6" />
                              <path d="M19 16v6" />
                              <path d="M6 21v-2a4 4 0 0 1 4 -4h4" />
                            </svg>
                          }
                        />
                      </>
                    ) : (
                      <>
                        <DashboarItem
                          isActive={
                            permisos.find((el) => el.codigo === "13")
                              ? true
                              : false
                          }
                          title={"Crear una factura electrónica"}
                          route={"/emision/generar/factura"}
                          svg={
                            <svg>
                              <path d="M20 11.08V8l-6-6H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h6" />
                              <path d="M14 3v5h5M18 21v-6M15 18h6" />
                            </svg>
                          }
                        />
                        <DashboarItem
                          isActive={
                            permisos.find((el) => el.codigo === "03")
                              ? true
                              : false
                          }
                          title={
                            "Consultar documentos (comprobantes) electrónicos"
                          }
                          route={"/emision/reporte/general"}
                          svg={
                            <svg>
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                              <path d="M12 21h-5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v4.5" />
                              <path d="M16.5 17.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0" />
                              <path d="M18.5 19.5l2.5 2.5" />
                            </svg>
                          }
                        />
                        <DashboarItem
                          isActive={
                            permisos.find((el) => el.codigo === "12")
                              ? true
                              : false
                          }
                          title={"Registrar contribuyentes"}
                          route={"/emision/reporte/contribuyentes"}
                          svg={
                            <svg>
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                              <path d="M16 19h6" />
                              <path d="M19 16v6" />
                              <path d="M6 21v-2a4 4 0 0 1 4 -4h4" />
                            </svg>
                          }
                        />

                        <DashboarItem
                          isActive={
                            permisos.find((el) => el.codigo === "23")
                              ? true
                              : false
                          }
                          title={"Crear Productos/Servicios"}
                          route={"/emision/reporte/servicios"}
                          svg={
                            <svg>
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M12.5 21h-3.926a3 3 0 0 1 -2.965 -2.544l-1.255 -8.152a2 2 0 0 1 1.977 -2.304h11.339a2 2 0 0 1 1.977 2.304l-.263 1.708" />
                              <path d="M16 19h6" />
                              <path d="M19 16v6" />
                              <path d="M9 11v-5a3 3 0 0 1 6 0v5" />
                            </svg>
                          }
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="relative ">
                <button className=" ml-0 custom-prev-arrow lg:flex   hidden justify-center absolute top-[45%] z-10 border p-2  rounded-lg   text-sm font-semibold outline-2 outline-offset-2 transition-colors bg-white text-black  active:bg-gray-200 active:text-white/80">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M20.25 12a.75.75 0 01-.75.75H6.31l5.47 5.47a.75.75 0 11-1.06 1.06l-6.75-6.75a.75.75 0 010-1.06l6.75-6.75a.75.75 0 111.06 1.06l-5.47 5.47H19.5a.75.75 0 01.75.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                <p className="font-semibold text-lg mx-6 dark:text-white">
                  Mantente al dia con las novedades de Firma y Facturador
                </p>

                <Splide
                  ref={splideRef}
                  className="my-carousel m-0 p-0  bg-transparent lg:mx-4 md:px-4  "
                  options={{
                    rewind: true,
                    autoplay: true,
                    loop: true,
                    smartSpeed: 1000,
                    padding: "0rem",
                    margin: "0rem",
                    gap: "2rem",
                    pagination: true,
                    arrows: false,
                    perPage: 2,
                    breakpoints: {
                      640: {
                        perPage: 1,
                      },
                      768: {
                        perPage: 2,
                      },
                      1024: {
                        perPage: 2,
                      },
                    },
                  }}
                >
                  <SplideSlide>
                    <CardDasboard
                      text={"¿Cómo firmar desde tú móvil?"}
                      link={
                        "https://www.instagram.com/reel/Cvh2tket47h/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                      }
                      description={
                        "Firma documentos digitales de manera fácil y segura desde tu dispositivo móvil."
                      }
                    />
                  </SplideSlide>
                  <SplideSlide>
                    <CardDasboard
                      text={"Actualiza tu firma en el facturador"}
                      link={
                        "https://www.instagram.com/reel/C1KrdGKRsf1/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                      }
                      description={
                        "Garantiza la validez y seguridad de tus transacciones digitales manteniendo actualizada tu firma electrónica."
                      }
                    />
                  </SplideSlide>
                  <SplideSlide>
                    <CardDasboard
                      text={"¿Cómo realizar facturas electrónicas?"}
                      link={
                        "https://www.instagram.com/reel/Cvds8XitFXm/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                      }
                      description={
                        "Aprende a generar facturas electrónicas con autorización del SRI."
                      }
                    />
                  </SplideSlide>
                  <SplideSlide>
                    <CardDasboard
                      text={"Anulación de facturas electrónicas"}
                      link={
                        "https://www.instagram.com/reel/CxTNIPBrxNq/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                      }
                      description={
                        "Anula tus facturas previamente autorizadas en la plataforma del SRI, corrigiendo errores contables."
                      }
                    />
                  </SplideSlide>
                </Splide>
                <button className=" custom-next-arrow lg:flex hidden justify-center absolute top-[45%] right-0  z-10 border p-2  rounded-lg   text-sm font-semibold outline-2 outline-offset-2 transition-colors bg-white text-black  active:bg-gray-200 active:text-white/80">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6 rotate-180"
                  >
                    <path
                      fillRule="evenodd"
                      d="M20.25 12a.75.75 0 01-.75.75H6.31l5.47 5.47a.75.75 0 11-1.06 1.06l-6.75-6.75a.75.75 0 010-1.06l6.75-6.75a.75.75 0 111.06 1.06l-5.47 5.47H19.5a.75.75 0 01.75.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </section>
          )}
          {dashBoardState === "indicadores" && (
            <main className="flex flex-col gap-3 py-4 ">
              <nav className="p-4 bg-gray-50 border dark:bg-gray-800 dark:border-0 grid md:grid-cols-3 w-full grid-cols-1 md:gap-1 gap-3 text-[14px]">
                <div className="relative z-20 lg:mb-0 flex flex-col items-center gap-1  w-fit group">
                  <label
                    htmlFor="fechaInicio"
                    className="peer-focus:font-semibold self-start  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                  >
                    Seleccione mes
                  </label>
                  <DatePicker
                    selected={dateRange}
                    onChange={(e) => handleMonthChange(e)}
                    dateFormat="MMMM - yyyy"
                    className={
                      "border-gray-300 dark:text-gray-400 w-[250px] text-xs dark:bg-transparent dark:border-gray-600  pl-2  my-auto focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1 ring-0  border rounded-md py-2"
                    }
                    showMonthYearPicker
                    showFullMonthYearPicker
                    dropdownMode="select"
                    locale={es}
                  />
                </div>
                <div className="relative z-0 lg:mb-0  w-full group">
                  <label
                    htmlFor="fechaInicio"
                    className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                  >
                    Establecimiento
                  </label>
                  <select
                    onChange={handleChangeEstablecimiento}
                    type="text"
                    name="establecimiento"
                    id="establecimiento"
                    className={
                      "peer pl-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 block form-control border py-1.5 rounded-md sm:text-sm border-gray-300 focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1 w-[250px]"
                    }
                    placeholder=" "
                    defaultValue={formik.values.establecimiento}
                    onBlur={formik.handleBlur}
                  >
                    <option key="default" value="">
                      TODOS
                    </option>
                    {Establecimientos.map((el, index) => {
                      return (
                        <option
                          key={el.idEstablecimiento}
                          data-id-establecimiento={el.idEstablecimiento}
                          data-index={index}
                          value={el.codigo}
                        >
                          {rellenarCeros(el.codigo)}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="relative z-0  lg:mb-0  max-w-[200px] group">
                  <label
                    htmlFor="fechaInicio"
                    className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                  >
                    Punto emisión
                  </label>
                  {formik.values.establecimiento !== "" ? (
                    <select
                      type="text"
                      className={
                        "peer pl-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 block form-control border py-1.5 rounded-md sm:text-sm border-gray-300 focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1 w-[200px]"
                      }
                      placeholder=" "
                      name="puntoEmision"
                      id="puntoEmision"
                      defaultValue={formik.values.puntoEmision}
                      onChange={handleChangePuntoEmision}
                      onBlur={formik.handleBlur}
                    >
                      <option key="default" value="">
                        TODOS
                      </option>
                      {PuntosEmision.map((el) => {
                        return (
                          <option key={el.idPuntoEmision} value={el.codigo}>
                            {rellenarCeros(el.codigo)}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    <select
                      className={StyleSelect + " bg-gray-600 text-white"}
                      placeholder=" "
                      disabled
                    >
                      <option key="default" value="">
                        TODOS
                      </option>
                    </select>
                  )}
                </div>
              </nav>
              <div className="flex-1 px-8 py-2 grid gap-6 md:grid-cols-4 md:grid-rows-3 grid-cols-1">
                <DocumentosEmitidosGraf data={form} />
                <section className=" col-span-1 p-4 border dark:border-gray-600 flex flex-col gap-4 ">
                  <h1 className="text-lg font-semibold ml-3 dark:text-gray-300">
                    Total
                  </h1>
                  <div className="flex flex-col items-center justify-center gap-2 p-5 rounded-xl w-full h-full relative dark:border-0 border bg-gray-50 dark:bg-gray-800 text-white">
                    <p className="text-black dark:text-white text-3xl font-semibold">
                      {form.cantidadEmitidos}
                    </p>
                    <p className="text-gray-500 font-[Inter] text-center text-xl">
                      Comprobantes emitidos
                    </p>
                  </div>
                </section>
                <section className="md:row-span-2 md:col-span-4 p-3 flex h-fit min-h-[500px] flex-col gap-3 border dark:border-gray-600">
                  {!loading ? (
                    <>
                      <h1 className="text-lg font-semibold p-2 pl-6 dark:text-gray-300">
                        Ventas del Mes
                      </h1>
                      <div className="flex md:flex-row flex-col-reverse">
                        <div className="chart-container">
                          {chartData && chartData.labels?.length > 0 ? (
                            <Bar data={chartData} options={options} />
                          ) : (
                            <Bar data={empty} options={options} />
                          )}
                        </div>
                        <div className="flex-1 ml-3 grid grid-cols-1 gap-3 p-6">
                          <div className="rounded-xl font-[Inter] border text-center flex flex-wrap items-center gap-2 justify-center text-lg dark:border-0 bg-gray-50 dark:bg-gray-800 py-4 px-8">
                            <svg
                              fill="#000000"
                              height={"25"}
                              width={"25"}
                              viewBox="0 0 1920 1920"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g
                                id="SVGRepo_tracerCarrier"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <path
                                  d="M1706.235 1807.059H350.941V112.94h903.53v451.765h451.764v1242.353Zm-338.823-1670.74 315.443 315.447h-315.443V136.32Zm402.182 242.487L1440.372 49.58C1408.296 17.62 1365.717 0 1320.542 0H238v1920h1581.175V498.635c0-45.176-17.618-87.755-49.58-119.83ZM576.823 1242.353h790.589v-112.94H576.823v112.94Zm0-451.765h903.53V677.647h-903.53v112.941Zm0 677.647h451.765v-112.941H576.823v112.941Zm0-451.764h677.648V903.53H576.823v112.941Zm0-451.765h451.765V451.765H576.823v112.941Z"
                                  fill-rule="evenodd"
                                ></path>{" "}
                              </g>
                            </svg>
                            <span className="font-semibold">Factura:</span>
                            <span className="whitespace-nowrap">
                              $
                              {form?.totalVentasFacturas?.toLocaleString(
                                "es-EC",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              ) ?? 0.0}
                            </span>
                          </div>
                          <div className="rounded-xl font-[Inter] border dark:border-0 flex flex-wrap items-center gap-2 justify-center bg-gray-50 text-center text-lg dark:bg-gray-800 py-1 px-3">
                            <svg
                              fill="#000000"
                              height={"25"}
                              width={"25"}
                              viewBox="0 0 1920 1920"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g
                                id="SVGRepo_tracerCarrier"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <path
                                  d="M1706.235 1807.059H350.941V112.94h903.53v451.765h451.764v1242.353Zm-338.823-1670.74 315.443 315.447h-315.443V136.32Zm402.182 242.487L1440.372 49.58C1408.296 17.62 1365.717 0 1320.542 0H238v1920h1581.175V498.635c0-45.176-17.618-87.755-49.58-119.83ZM576.823 1242.353h790.589v-112.94H576.823v112.94Zm0-451.765h903.53V677.647h-903.53v112.941Zm0 677.647h451.765v-112.941H576.823v112.941Zm0-451.764h677.648V903.53H576.823v112.941Zm0-451.765h451.765V451.765H576.823v112.941Z"
                                  fill-rule="evenodd"
                                ></path>{" "}
                              </g>
                            </svg>
                            <span className="font-semibold">
                              Nota de Credito:
                            </span>
                            <span className="whitespace-nowrap">
                              $
                              {(form?.totalDevolucionesNC)?.toLocaleString(
                                "es-EC",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              ) ?? 0.0}
                            </span>
                          </div>
                          <div className="rounded-xl font-[Inter] border dark:border-0  flex flex-wrap items-center gap-2 justify-center bg-gray-50 text-center text-lg dark:bg-gray-800 py-1 px-3">
                            <span className="font-semibold">Total Factura - NC:</span>
                            <span className="whitespace-nowrap">
                              $
                              {(form?.totalVentas)?.toLocaleString("es-EC", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) ?? 0.0}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="h-[500px] flex self-center justify-center items-center">
                      <Spinner />
                    </div>
                  )}
                </section>
              </div>
            </main>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
