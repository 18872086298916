export const ValoracionRows = [
  {
    row: "Inventario Inicial",
    subRows: ["Cantidad", "Costo Promedio", "Total"],
  },
  {
    row: "Compras",
    subRows: ["Cantidad", "Total"],
  },
  {
    row: "",
    subRows: ["Costo Prom Mes"],
  },
  {
    row: "Devolución Compras",
    subRows: ["Cantidad", "Valor"],
  },
  {
    row: "Ventas",
    subRows: ["Cantidad", "Valor"],
  },
  {
    row: "Devolución Ventas",
    subRows: ["Cantidad", "Total"],
  },
  {
    row: "Ingresos de Inventario",
    subRows: ["Cantidad", "Total"],
  },
  {
    row: "Egresos de Inventario",
    subRows: ["Cantidad", "Valor"],
  },
  {
    row: "Inventario Final",
    subRows: ["Cantidad", "Valor"],
  },
];

export const ValoracionRowsAvailable = [
  {
    row: "Inventario Inicial",
    subRows: ["Cantidad", "Costo Promedio", "Total"],
  },
  {
    row: "",
    subRows: ["Costo Prom Mes"],
  },
  {
    row: "Ingresos de Inventario",
    subRows: ["Cantidad", "Total"],
  },
  {
    row: "Egresos de Inventario",
    subRows: ["Cantidad", "Valor"],
  },
  {
    row: "Inventario Final",
    subRows: ["Cantidad", "Valor"],
  },
];
