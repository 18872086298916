import { useDispatch } from "react-redux";
import { DeleteIcon, EditIcon } from "../../../../Icons/ButtonIcons";
import { ModalStatusBodega, changeBodegaModal } from "./modalCrearBodega.slice";

//Fila de la tabla - Registro de una Bodega

export const CrearBodegaItem = ({ Bodega, bodegasChecked, handleClick }) => {
  const dispatch = useDispatch();

  return (
    <tr
      className={`bg-white dark:bg-gray-700 text-center dark:text-white font-normal border-y-2 border-gray-300 dark:border-gray-600`}
    >
      <th style={{ padding: "15px" }}>
        <input
          type="checkbox"
          checked={
            bodegasChecked.find((bog) => bog === Bodega.idBodega) ? true : false
          }
          onChange={() => {
            handleClick(Bodega.idBodega);
          }}
        />
      </th>
      <th className="font-normal">{Bodega.codigo}</th>
      <th className="font-normal">{Bodega.nombre}</th>
      <th className="font-normal">{Bodega.direccion}</th>
      <th className="font-normal">{`${String(
        Bodega["codigoEstablecimiento"]
      ).padStart(3, "0")} - ${
        Bodega["direccionEstablecimiento"]
          ? Bodega["direccionEstablecimiento"]
          : ""
      }`}</th>
      <th
        style={{ padding: "15px" }}
        className="flex justify-center flex-row gap-2"
      >
        <button
          onClick={() =>
            dispatch(
              changeBodegaModal({
                modalStatus: ModalStatusBodega.editar,
                Bodega,
              })
            )
          }
        >
          <EditIcon />
        </button>
        <button
          onClick={() =>
            dispatch(
              changeBodegaModal({
                modalStatus: ModalStatusBodega.eliminar,
                Bodega,
              })
            )
          }
        >
          <DeleteIcon />
        </button>
      </th>
    </tr>
  );
};
