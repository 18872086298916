import { useContext } from "react";
import { FormProvider } from "react-hook-form";
import { AjusteInventarioProviderContext } from "./Context/AjusteInventarioProvider";
import AjusteInventarioGeneral from "./AjusteInventarioGeneral";
import AjusteInventarioDetalle from "./AjusteInventarioDetalle";
import { TransaccionButtons } from "../../../Shared/TransaccionButtons";
import ModalAjusteInventarioAlertaStock from "./ModalAjusteInventarioAlertaStock";

export default function AjusteInventarioForm() {
  const {
    methods,
    validateAjuste,
    setRows,
    secuencial,
    rowBase,
    isSending,
    isOpen,
  } = useContext(AjusteInventarioProviderContext);

  const DeleteData = () => {
    setRows([{ ...rowBase }]);
    methods.reset();
    methods.setValue("ubicacion", "");
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(validateAjuste)}
          autoComplete="off"
          className="flex flex-col gap-2"
        >
          <div className="bg-white dark:bg-gray-900 p-4 pb-0 rounded-lg">
            <div className="bg-gray-200 rounded-lg p-4 flex dark:bg-gray-700 justify-between items-center">
              <p className="font-semibold text-xl ml-3 dark:text-white">
                Transacción
              </p>
              <div className="flex items-center space-x-3">
                <p className="hide-on-small dark:text-white">Ajuste</p>
                <input
                  type="text"
                  placeholder="Número de transacción"
                  {...methods.register("numeroTransaccion")}
                  className="border border-gray-400 bg-white rounded-md text-center p-1 w-full"
                  value={secuencial.transaccion}
                />
              </div>
            </div>
          </div>
          <div className="p-4 bg-white dark:bg-gray-900 rounded-lg">
            <div className="flex flex-col gap-4">
              <AjusteInventarioGeneral />
              <AjusteInventarioDetalle methods={methods} />
            </div>
            <TransaccionButtons DeleteData={DeleteData} isSending={isSending} />
          </div>
        </form>
      </FormProvider>
      {isOpen && <ModalAjusteInventarioAlertaStock />}
    </>
  );
}
