import { useState } from "react"

export const useAvailableRows = (initialRows, rows) => {
    const [availableRows, setAvailableRows] = useState(initialRows)

    const handleClick = (selectedRow) => {
        const isAvailable = availableRows.find(row => row.row === selectedRow);
        if (isAvailable) {
            setAvailableRows(prevState => prevState.filter(row => row.row !== selectedRow));
        } else {
            setAvailableRows(prevState => {
                const updatedRows = [...prevState, rows.find(row => row.row === selectedRow)];
                return updatedRows.sort((a, b) => {
                    const indexA = rows.findIndex(row => row.row === a.row);
                    const indexB = rows.findIndex(row => row.row === b.row);
                    return indexA - indexB;
                });
            });
        }
    }

    return {
        availableRows,
        handleClick
    }

}