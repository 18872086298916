import { useContext, useState } from "react";
import { useStore } from "zustand";
import { OffIcon } from "../../../../Icons/ButtonIcons";
import { useModalProductoStore } from "./ModalProductoContext";
import { ProductoContext } from "../context/ProductContext";
import { toast } from "react-toastify";

export const ModalDesabilitarProducto = () => {
  const { CancelProductos, CleanChecks } = useContext(ProductoContext);
  const { turnProductoModalOff } = useStore(useModalProductoStore);
  const [isDisabling, setIsDisabling] = useState(false);

  const doProductoDesactive = async () => {
    setIsDisabling(true);
    await toast.promise(CancelProductos(), {
      pending: "Desactivando los productos...",
      success: "¡Los productos fueron desactivadas con éxito!",
      error: "Hubo un error al desactivar los productos.",
    });
    CleanChecks();
    setIsDisabling(false);
    turnProductoModalOff();
  };

  return (
    <div
      onClick={() => turnProductoModalOff()}
      className="fixed bg-black/25 flex items-center top-0 left-0 z-[100] justify-center w-full h-full"
    >
      <section
        style={{
          padding: "38px",
          height: "fit-content",
          position: "relative",
        }}
        onClick={(e) => e.stopPropagation()}
        className="shadow-xl rounded-lg flex bg-white dark:bg-gray-900 md:flex-row flex-col md:gap-6 gap-3 items-center  lg:w-[600px] md:w-[450px] w-[375px]"
      >
        <div className="text-[#003B5B] h-full items-center justify-center">
          <OffIcon w={"100"} h={"100"} />
        </div>
        <div className="flex flex-col gap-5 items-center">
          <h1 className="text-md font-semibold text-center dark:text-white lg:whitespace-nowrap">
            ¿Estás seguro que deseas desactivar todos los Productos?
          </h1>
          <div className="flex flex-row-reverse gap-8 text-white">
            <button
              onClick={() => doProductoDesactive()}
              disabled={isDisabling}
              className="bg-[#003B5B] hover:bg-[#112935] p-8 pt-3 pb-3 h-8 text-[12px] w-28 flex items-center justify-center rounded-lg whitespace-nowrap"
            >
              Si, desactivalos
            </button>
            <button
              onClick={() => turnProductoModalOff()}
              className="bg-gray-600 p-8 pt-2 pb-2 h-8 text-[12px] hover:bg-gray-700 flex items-center justify-center w-28 rounded-lg whitespace-nowrap"
            >
              No, mantenlos
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};
