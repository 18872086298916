import { useContext, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { ProductGeneralForm } from "./ProductGeneralForm";
import { ProductFinanzasForm } from "./ProductFinanzasForm";
import { ProductoContext } from "../context/ProductContext";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv } from "uuid";
import { ObtenerConfiguracionInventarioEmpresa } from "../../../../services/InventarioService";
import { toast } from "react-toastify";
import {
  BackIcon,
  CancelIcon,
  SaveIcon,
  NextIcon,
} from "../../../../Icons/ButtonIcons";
import { NuevoProductoCompuestoForm } from "./NuevoProductoCompuestoForm";
import useGlobalToastify from "../../../../features/hooks/GlobalToastify/useGlobalToastify";
import { useSelector } from "react-redux";

export const NuevoProductoSection = () => {
  const [imagesUploaded, setImagesUploaded] = useState([]);
  const [mappedImages, setMappedImages] = useState([]);
  const empresa = useSelector((state) => state.empresa.empresa);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [productosCompuestos, setProductosCompuestos] = useState([]);
  const [principalImage, setPrincipalImage] = useState({
    index: 0,
    id: "",
  });
  const [startCarrusel, setStartCarrusel] = useState(0);
  const [endCarrusel, setEndCarrusel] = useState(3);
  const [isError, setIsError] = useState(false);
  const { addProducto } = useContext(ProductoContext);
  const { ErrorToast } = useGlobalToastify();
  const navigate = useNavigate();
  const [isSending, setIsSending] = useState(false);

  const handleFileChange = (e) => {
    const files = e.target.files;
    const remainingCapacity = 7 - imagesUploaded.length;
    const filesToAdd = Array.from(files).slice(0, remainingCapacity);
    const mappedFiles = [];
    if (files.length > 7) {
      setIsError("El numero maximo de imagenes permitidas es 7.");
      return;
    } else {
      let filesSize = 0;
      for (const file of filesToAdd) {
        filesSize += file.size;
      }
      if (filesSize > 50 * Math.pow(1024, 2)) {
        setIsError("No se pueden subir imágenes de más de 50MB");
        return;
      }
      setIsError(false);

      filesToAdd.forEach((file, i) => {
        const idImagenProducto = uuidv();
        mappedFiles.push({
          idImagenProducto,
          archivo: file,
          isPrincipal: false,
        });
      });

      setMappedImages((prevState) => [...prevState, ...mappedFiles]);

      const readFile = (index) => {
        if (index >= filesToAdd.length || imagesUploaded.length >= 7) {
          return;
        }
        const file = filesToAdd[index];

        if (!(file instanceof Blob)) {
          return;
        }

        const idImagenProducto = mappedFiles[index].idImagenProducto;
        const reader = new FileReader();

        reader.onloadend = () => {
          setImagesUploaded((prevState) => [
            ...prevState,
            {
              idImagenProducto,
              base64: reader.result,
            },
          ]);
          readFile(index + 1);
        };

        reader.readAsDataURL(file);
      };

      readFile(0);
    }
    setFileInputKey(Date.now());
  };

  const GoNextImage = () => {
    if (endCarrusel < imagesUploaded.length) {
      setStartCarrusel((prevState) => prevState + 1);
      setEndCarrusel((prevState) => prevState + 1);
    } else {
      setStartCarrusel(0);
      setEndCarrusel(3);
    }
  };

  const GoBackImage = () => {
    if (startCarrusel > 0) {
      setStartCarrusel((prevState) => prevState - 1);
      setEndCarrusel((prevState) => prevState - 1);
    } else {
      setStartCarrusel(imagesUploaded.length - 3);
      setEndCarrusel(imagesUploaded.length);
    }
  };

  const DeleteImage = (i) => {
    setImagesUploaded((prevState) => {
      const filteredImages = prevState.filter((image, index) => index !== i);
      if (principalImage.index === i)
        setPrincipalImage({
          id: "",
          index: 0,
        });
      if (principalImage.index > i && principalImage.index > 0) {
        setPrincipalImage((prevState) => ({
          id: "",
          index: prevState.index - 1,
        }));
      }
      return filteredImages;
    });
    setMappedImages((prevState) => {
      const filteredFiles = prevState.filter((image, index) => index !== i);
      return filteredFiles;
    });
    if (startCarrusel > 0) {
      setStartCarrusel((prevState) => prevState - 1);
    }
    if (endCarrusel > 3) {
      setEndCarrusel((prevState) => prevState - 1);
    }
  };

  const methods = useForm();

  const watchTipoProducto = methods.watch("tipoProducto");

  const CreateNewProduct = async (data) => {
    if (
      data.tipoProducto === "3af8aada-beb6-486b-bc0f-7afd0c0c28e1" &&
      productosCompuestos.length === 0
    ) {
      ErrorToast(
        "Un Producto Compuesto debe contener al menos un producto simple."
      );
      return;
    }

    delete data["producto"];
    delete data["cantidadCompuesto"];

    const newProduct = {
      idProducto: uuidv(),
      idEmpresa: empresa.idEmpresa,
      idTipoProducto: data.tipoProducto,
      estado: true,
      idUsoProducto: data.usoProducto,
      idUnidadesMedida: data.medidaProducto,
      idImpuestoDetalle: data.impuestos,
      idTerminoProducto: data.termino,
      idCategoriaProducto: data.categoria.idCategoriaProducto,
      codigo: data.codigo.trimEnd().trimStart(),
      detalle: data.detalleProducto.trimEnd().trimStart(),
      isMinimoStock: data.isMinimoStock,
      isCompuesto:
        data.tipoProducto === "3af8aada-beb6-486b-bc0f-7afd0c0c28e1" &&
        productosCompuestos.length > 0
          ? true
          : false,
      alertaStock: data.alertaStock ? data.alertaStock : 0,
      codigoBarra: data.codigoBarras.trimEnd().trimStart(),
      subProductos:
        data.tipoProducto === "3af8aada-beb6-486b-bc0f-7afd0c0c28e1" &&
        productosCompuestos.length > 0
          ? productosCompuestos
          : null,
      costo: parseFloat(data.costo).toFixed(6),
      precioVenta: parseFloat(data.precioVenta).toFixed(2),
      isFechaCaducidad: data.controlFechaCaducidad
        ? data.controlFechaCaducidad
        : false,
      isLote: data.controlLote ? data.controlLote : false,
      imagenes:
        mappedImages.length > 0
          ? mappedImages.map((image, i) => {
              if (principalImage.id)
                if (image.idImagenProducto === principalImage.id) {
                  return {
                    ...image,
                    isPrincipal: true,
                  };
                } else
                  return {
                    ...image,
                    isPrincipal: false,
                  };
              else {
                if (i === 0) {
                  return {
                    ...image,
                    isPrincipal: true,
                  };
                } else
                  return {
                    ...image,
                    isPrincipal: false,
                  };
              }
            })
          : [],
      nombre: data.nombreProducto.trimEnd().trimStart(),
    };
    try {
      setIsSending(true);
      await toast.promise(addProducto(newProduct), {
        pending: "Guardando producto...",
        success: "¡El producto fue guardado con éxito!",
        error: {
          render({ data }) {
            return (
              data?.response?.data?.message ||
              "Hubo un error al registrar el producto."
            );
          },
        },
      });
      navigate("/inventario/productos/crear");
    } catch (err) {
      console.log(err);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <section className="w-full h-fit p-8 bg-white dark:bg-gray-900 rounded-lg shadow-md">
        <div className="bg-gray-200 dark:text-white dark:bg-gray-700 rounded-lg text-[12px] w-full flex flex-col justify-center p-6">
          <h1 className="font-semibold text-2xl font-[Outfit]">
            Información del Producto
          </h1>
          <h4>Agregar Información</h4>
        </div>
        <div className="w-full flex flex-col items-center gap-6 justify-center p-6">
          <div
            className={`w-full flex flex-col ${
              imagesUploaded.length === 7 && "mb-6"
            } items-center gap-4 justify-center`}
          >
            {imagesUploaded.length === 0 && (
              <div className="flex opacity-50 items-center justify-center md:w-96 w-64 md:h-64 h-48 bg-gray-300 rounded dark:bg-gray-700">
                <svg
                  class="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>
            )}
            {imagesUploaded.length === 0 && (
              <div className="flex lg:flex-row gap-4 flex-col">
                <div className="flex opacity-50 items-center justify-center w-64 h-48 bg-gray-300 rounded dark:bg-gray-700">
                  <svg
                    class="w-10 h-10 text-gray-200 dark:text-gray-600"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 18"
                  >
                    <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                  </svg>
                </div>
                <div className="flex opacity-50  items-center justify-center w-64 h-48 bg-gray-300 rounded dark:bg-gray-700">
                  <svg
                    class="w-10 h-10 text-gray-200 dark:text-gray-600"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 18"
                  >
                    <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                  </svg>
                </div>
                <div className="flex opacity-50  items-center justify-center w-64 h-48 bg-gray-300 rounded dark:bg-gray-700">
                  <svg
                    class="w-10 h-10 text-gray-200 dark:text-gray-600"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 18"
                  >
                    <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                  </svg>
                </div>
              </div>
            )}
            {imagesUploaded.length > 0 && (
              <img
                src={imagesUploaded[principalImage.index]?.base64}
                alt="Imagen de tu Producto"
                className="w-96 h-64 rounded-lg"
              />
            )}
            {imagesUploaded.length > 0 && (
              <div className="flex md:flex-row items-center flex-col gap-4">
                {imagesUploaded.length > 3 && (
                  <button
                    id="back"
                    className="opacity-60 hover:scale-90"
                    onClick={() => GoBackImage()}
                  >
                    <BackIcon />
                  </button>
                )}
                {imagesUploaded
                  .map((image, i) => ({
                    ...image,
                    index: i,
                  }))
                  .slice(startCarrusel, endCarrusel)
                  .map((image, i) => (
                    <div
                      key={image.idImagenProducto}
                      className="w-fit h-fit rounded-lg relative"
                    >
                      <img
                        src={image.base64}
                        onClick={() =>
                          setPrincipalImage({
                            index: image.index,
                            id: image.idImagenProducto,
                          })
                        }
                        alt="Imagen de tu Producto"
                        className="w-64 h-48 rounded-lg"
                      />
                      <button
                        onClick={() => DeleteImage(image.index)}
                        className="absolute text-white top-2 right-2"
                      >
                        <CancelIcon />
                      </button>
                    </div>
                  ))}
                {imagesUploaded.length > 3 && (
                  <button
                    id="next"
                    className="opacity-60 hover:scale-90"
                    onClick={() => GoNextImage()}
                  >
                    <NextIcon />
                  </button>
                )}
              </div>
            )}
          </div>
          {isError && (
            <span className="text-red-600 font-semibold text-[15px]">
              {isError}
            </span>
          )}
          {imagesUploaded.length < 7 && (
            <div className="file-upload">
              <label htmlFor="fileInput" className="custom-file-upload">
                <span className="text-white" title="Maximo 7 imagenes">
                  Subir Imagenes
                </span>
              </label>
              <input
                id="fileInput"
                key={fileInputKey}
                accept=".jpg,.png,.jpeg,.gif,.svg,.webp,.jfif,.avif"
                onChange={handleFileChange}
                type="file"
                multiple
              />
            </div>
          )}
        </div>
        <form
          onSubmit={methods.handleSubmit(CreateNewProduct)}
          autoComplete="off"
          className="w-full flex flex-col gap-5"
        >
          <ProductGeneralForm />
          <ProductFinanzasForm productosCompuestos={productosCompuestos} />
          {watchTipoProducto === "3af8aada-beb6-486b-bc0f-7afd0c0c28e1" && (
            <NuevoProductoCompuestoForm
              productosCompuestos={productosCompuestos}
              setProductosCompuestos={setProductosCompuestos}
            />
          )}
          <div className="flex flex-row h-fit gap-4 w-full mt-6 justify-end items-center text-white">
            <NavLink
              exact="true"
              to={"/inventario/productos/crear"}
              className="bg-gray-600 p-5 h-8 text-[12px] hover:bg-gray-700 gap-2 flex items-center justify-center w-28 rounded-lg"
            >
              Cancelar
              <CancelIcon />
            </NavLink>
            <button
              type="submit"
              disabled={isSending}
              className="bg-[#04BC53] hover:bg-green-500 p-5 h-8 text-[12px] gap-2  w-28 flex items-center justify-center rounded-lg"
            >
              Guardar
              <SaveIcon />
            </button>
          </div>
        </form>
      </section>
    </FormProvider>
  );
};
