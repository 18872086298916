import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { getListV2, PostElement } from "../../../services";
import {
  StyleInput,
  StyleLabel,
  StyleInputError,
  StyleLabelError,
} from "../../../features/Constantes";
import { LargeModalForm } from "../../../features/components/LargeModalForm";
import { UTF8RegExp } from "../../../services/Utilitario";
import { ReporteServicioContext } from "./ReporteServicioProvider";
import InformacionAdicional from "./InformacionAdicional";
import useGlobalToastify from "../../../features/hooks/GlobalToastify/useGlobalToastify";
import LengthCounter from "../../../features/components/LengthCounter";

import { toast } from "react-toastify";
import InputNumber from "../../../features/components/common/InputNumber";

const ModalAdd = () => {
  const initData = {
    activo: "True",
    codigoAuxiliar: "",
    codigoPrincipal: "",
    descripcion: "",
    precioUnitario: 0,
    stock: 0,
    tipo: "1",
  };

  const { formik } = useContext(ReporteServicioContext);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
    watch,
  } = useForm({ mode: "onSubmit" });

  const [ImpLista, setImpLista] = useState([]);
  const [TypeForAdd, setTypeForAdd] = useState("1");
  const [IsLoading, setIsLoading] = useState(false);
  const [InformacionAdicionalList, setInformacionAdicionalList] = useState([]);

  const empresa = useSelector((store) => store.empresa.empresa);

  const { ErrorToast } = useGlobalToastify();

  useEffect(() => {
    Object.values(errors)[0] && ErrorToast(Object.values(errors)[0].message);
  }, [errors, ErrorToast]);

  useEffect(() => {
    if (ImpLista.length > 0) {
      setValue("idImpuestoIva", ImpLista[0].idImpuestoDetalle);
      setValue("idEmpresa", empresa.idEmpresa);
    }
  }, [ImpLista, empresa, setValue]);

  useEffect(() => {
    (async () => {
      let impList = await getListV2(
        empresa,
        "GetAllimpDetalles",
        "?activo=true&idImpuesto=1"
      );
      setImpLista(impList._embedded);
    })();
  }, []);

  const handleSubmitModal = async () => {
    try {
      setIsLoading(true);
      let values = { ...getValues() };
      values.descripcion = values.descripcion.trim();
      values.codigoAuxiliar = values.codigoAuxiliar.trim()

      const res = await getListV2(
        empresa,
        "ExisteServicio",
        `?codigo=${values.codigoPrincipal}`
      );

      if (res) {
        toast.info("Un producto con ese código principal ya existe!", {
          autoClose: 2000,
          closeOnClick: true,
        });
        return
      }

      // if (values.precioUnitario === 0) {
      //   return toast.info("Asegurate de ingresar un Subtotal unitario", {
      //     autoClose: 2000,
      //     closeOnClick: true,
      //   });
      // }

      values.InformacionAdicional = InformacionAdicionalList;
      values.precioUnitario = values?.precioUnitario ?? 0
      values.stock = values.stock === "" ? 0 : values.stock;
      values.codigoAuxiliar =
        values.codigoAuxiliar === "" ? null : values.codigoAuxiliar;
      values.descripcion = values.descripcion.replaceAll(/(\r\n|\n|\r)/gm, " ");
      values.activo = 1;
      
      const postRes = await PostElement(empresa, "PostServicio", values);
      const toasPro = () =>
        new Promise((resolve) => setInterval(() => resolve(postRes), 2000));

      await toast.promise(toasPro(), {
        pending: {
          render() {
            return "Guardando...";
          },
          isLoading: true,
        },
        success: {
          render() {
            setInformacionAdicionalList([]);
            document.getElementById("addProductoModalclose").click();
            formik.handleSubmit();
            return "Producto o Servicio agregado con exito.";
          },
          autoClose: 2000,
          closeOnClick: true,
        },
        error: {
          render() {
            return "Algo ha sucedido, intente nuevamente.";
          },
          autoClose: 2000,
          closeOnClick: true,
        },
      });
    } catch (ex) {
      console.error(ex);
    } finally {
      setIsLoading(false)
    }
  };

  const handleCloseModal = () => reset({ ...initData });
  const [Open, SetOpen] = useState(false);
  return (
    <LargeModalForm
      IsLoading={IsLoading}
      onClose={handleCloseModal}
      buttonModal={"addProductoModal"}
      title="Crear Producto / Servicio"
      subTitle="Agrega información"
      submitModal={handleSubmit(handleSubmitModal)}
      submitText={"Agregar"}
    >
      <div className="relative z-0 mb-2 w-full group">
        <div className="w-full grid md:grid-cols-3 md:gap-4 mb-2 my-4 ">
          <div className="relative z-0 w-full group">
          <label
              htmlFor="codigoPrincipal"
              className={errors.codigoPrincipal ? StyleLabelError : StyleLabel}
            >
              Código principal *
            </label>
            <input
              type="text"
              name="codigoPrincipal"
              id="codigoPrincipal"
              className={errors.codigoPrincipal ? StyleInputError : StyleInput}
              {...register("codigoPrincipal", { required: { value: true } })}
              maxLength={25}
              minLength={1}
            />
       
          </div>
          <div className="relative z-0 mb-2 w-full group">
          <label
              htmlFor="direccion"
              className={errors.codigoAuxiliar ? StyleLabelError : StyleLabel}
            >
              Código auxiliar
            </label>
            <input
              type="text"
              name="codigoAuxiliar"
              id="codigoAuxiliar"
              className={errors.codigoAuxiliar ? StyleInputError : StyleInput}
              {...register("codigoAuxiliar")}
              maxLength={25}
              minLength={1}
            />
          
          </div>

          <div className="relative z-0 mb-2 w-full group">
          <label
              htmlFor="tipo"
              className={errors.tipo ? StyleLabelError : StyleLabel}
            >
              Tipo
            </label>
            <select
              name="tipo"
              id="tipo"
              className={errors.tipo ? StyleInputError : StyleInput}
              x-model="item.gst"
              {...register("tipo", {
                required: {
                  value: true,
                  message: "Precio Unitario es requerido",
                },
              })}
              onChange={(e) => setTypeForAdd(e.target.value)}
            >
              <option value="1">PRODUCTO</option>
              <option value="2">SERVICIO</option>
            </select>
          
          </div>
        </div>

        <div className="w-full grid md:grid-cols-3 md:gap-4 mb-2 ">
          <div className="relative z-0 mb-2 w-full group">
          <label
              htmlFor="idImpuestoIva"
              className={errors.idImpuestoIva ? StyleLabelError : StyleLabel}
            >
              IVA
            </label>
            <select
              name="idImpuestoIva"
              id="idImpuestoIva"
              className={StyleInput}
              x-model="item.gst"
              {...register("idImpuestoIva")}
            >
              {ImpLista.map((el, key) => {
                return (
                  <option key={key} value={el.idImpuestoDetalle}>
                    {el.detalle}
                  </option>
                );
              })}
            </select>
           
          </div>
          <div className="relative z-0 mb-2 w-full group">
          <label
              htmlFor="precioUnitario"
              className={errors.precioUnitario ? StyleLabelError : StyleLabel}
            >
              Subtotal unitario *
            </label>
            <InputNumber
              identifier="precioUnitario"
              value={getValues("precioUnitario")}
              onChange={(e) => {
                setValue("precioUnitario", e.target.value);
              }}
              decimalMax={6}
              className={errors.precioUnitario ? StyleInputError : StyleInput}
            />
           
          </div>
        </div>
        <div className="w-full grid md:grid-cols-2 md:gap-4  bg-red ">
          <div className="relative z-0 col-span-2  w-full group ">
          <label
              htmlFor="direccion"
              className={
                errors.descripcion
                  ? StyleLabelError + " -translate-y-28"
                  : StyleLabel + " -translate-y-28"
              }
            >
              Descripción *
              <LengthCounter itemLength={watch("descripcion") || ""} />
            </label>
            <textarea
              name="descripcion"
              id="descripcion"
              rows="4"
              className={
                errors.descripcion
                  ? StyleInputError + " resize-none "
                  : StyleInput + " resize-none overflow-hidden "
              }
              {...register("descripcion", { required: { value: true } })}
              maxLength={300}
              minLength={1}
            />
            
          </div>
        </div>
        <div>
          <div>
            <p
              onClick={() => SetOpen(!Open)}
              className="text-lg font-semibold p-2 border-b border-b-gray-400 select-none cursor-pointer dark:text-white flex gap-x-2  items-center   font-body"
            >
              Información adicional (Opcional){" "}
              {!Open ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              )}
            </p>
            {Open ? (
              <InformacionAdicional
                InformacionAdicionalList={InformacionAdicionalList}
                setInformacionAdicionalList={setInformacionAdicionalList}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </LargeModalForm>
  );
};

export default ModalAdd;
