import axios from "axios";
//axios.defaults.baseURL = `https://localhost:44310/`;
//axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
//axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
    document.cookie = "idsrv=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
  }
};
export async function getUser() {

  try {
    const { data } = await axios.get(
      `${axios.defaults.baseURL}api/cuentas/usuario`
    );
    return data;
  } catch (error) {
    localStorage.clear();
    deleteAllCookies();
    sessionStorage.clear();
  }

}

// api.js (o el archivo donde tienes la función getUser)

// export async function getUser() {

//   try {

//     const { data } = await axiosInstance.get('api/cuentas/usuario');
//     return data;
//   } catch (error) {
//     console.error('Error al obtener el usuario:', error);
//     throw error;
//   }
// }

export async function login(usuario, password) {
  const data = await axios.post(`/api/cuentas/login`, {
    usuario: usuario,
    password: password,
  });
  return data;
}

export async function post(url, obj, cancelToken = null) {
  try {
    // console.log(cancelToken)
    const data = await axios.post(url.replaceAll("http://", "https://"), obj, {
      timeout: 1500000,
      cancelToken: cancelToken,
    });
    return data;
  } catch (ex) {
    throw ex;
  }
}

export async function postData(url, obj) {
  const data = await axios.post(url.replaceAll("http://", "https://"), obj, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return data;
}

export async function getListV3(enlace, parametro = undefined) {
  const resp = await axios.get(enlace.replaceAll("http://", "https://"), {
    params: parametro,
  });
  return resp.data;
}

export async function getListV9(enlace, parametro = undefined) {
  const resp = await axios.get(
    `${enlace.replaceAll("http://", "https://")}${
      parametro === undefined ? "" : parametro
    }`
  );
  return resp.data;
}

export async function getListV4(obj, enlace, parametro = undefined) {
  const resp = await axios.get(
    `${obj._links
      .find((obj) => obj.descripcion === enlace)
      .enlace.replaceAll("http://", "https://")}${
      parametro === undefined ? "" : parametro
    }`
  );

  return resp;
}

export async function getListV2(obj, enlace, parametro = undefined) {
  try {
    const resp = await axios.get(
      `${obj._links
        .find((obj) => obj.descripcion === enlace)
        .enlace.replaceAll("http://", "https://")}${
        parametro === undefined ? "" : parametro
      }`
    );
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

export async function PutElement(obj, enlace, data) {
  try {
    // console.log("obj",obj)
    const resp = await axios.put(
      `${obj._links
        .find((obj) => obj.descripcion === enlace)
        .enlace.replaceAll("http://", "https://")}`,
      data
    );
    return resp.data;
  } catch (ex) {
    //console.log(ex)
    throw ex;
  }
}

export async function PutElementV1(enlace, data) {
  try {
    const resp = await axios.put(
      enlace.replaceAll("http://", "https://"),
      data
    );
    return resp;
  } catch (error) {
    console.error(error);
  }
}

export async function PostElement(obj, enlace, data) {
  const resp = await axios.post(
    `${obj._links
      .find((obj) => obj.descripcion === enlace)
      .enlace.replaceAll("http://", "https://")}`,
    data
  );
  return resp.data;
}

export async function getList(url) {
  const resp = await axios.get(url.replaceAll("http://", "https://"));
  return resp.data;
}

/*
export async function getList(url) {
    const  { data }  = await axios.get(url)
    return {
        _links : data._links,
        _embedded: data._embedded.map(obj => {
            return {
                ...obj._embedded,
                _links: obj._links
            }
        })
    };
}*/
