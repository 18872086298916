import { useContext } from "react";
import { IngresoProviderContext } from "./Context/IngresoProvider";
import ModalComponent from "../../../../../features/components/ModalComponents/ModalComponent";
import { NavLink } from "react-router-dom";
export default function ModalIngreso() {

  const { setIsOpen, isOpen } = useContext(IngresoProviderContext);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ModalComponent
        dimensiones="mediano"
        isOpen={isOpen}
        onClose={toggleModal}
      >
        <div>

          <div className="bg-white inline-block border p-3 rounded-2xl shadow-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="text-[#003B5B] w-8 h-8 "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m4.5 12.75 6 6 9-13.5"
              />
            </svg>
          </div>


          <p className=" text-xl">La transacción se generó correctamente</p>
          <button>
            <NavLink
              to="/inventario/operaciones/transaccionesEmitidas"
              className="bg-[#003B5B] inline-block text-white  py-2 px-4 rounded-lg mt-4"
            >
              Consultar transacciones
            </NavLink>
          </button>
        </div>
      </ModalComponent>
    </>
  );
}
