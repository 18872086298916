import { createContext, useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { getFechaInicio } from "../../../../../../services/Utilitario";
import { useSelector } from "react-redux";
import { ObtenerSecuencialPerTransaccion } from "../../../../../../services/InventarioService";
import { v4 as uuidv, validate } from "uuid";
import { ObtenerProductosAjuste } from "../../../../../../services/InventarioService";
import { toast } from "react-toastify";
import { TransaccionesContext } from "../../../TransaccionesEmitidas/context/TransaccionesContext";
import useGlobalToastify from "../../../../../../features/hooks/GlobalToastify/useGlobalToastify";

export const AjusteInventarioProviderContext = createContext();

const AjusteInventarioProvider = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const empresa = useSelector((state) => state.empresa.empresa);
  const [dataToConfirm, setDataToConfirm] = useState(null);
  const [alertaStockProducts, setAlertaStockProducts] = useState([]);
  const [productosPerSecciones, setproductosPerSecciones] = useState([]);
  const [secuencial, setSecuencial] = useState(0);
  const { addTransaccion } = useContext(TransaccionesContext);
  const [isSending, setIsSending] = useState(false);
  const { ErrorToast } = useGlobalToastify();

  const methods = useForm({
    defaultValues: {
      fechaCreacion: getFechaInicio(),
      tipoOperacion: "Ajuste de Inventario",
    },
  });
  useEffect(() => {
    if (empresa && empresa.idEmpresa) getSecuencial();
  }, [empresa]);

  useEffect(() => {
    if (empresa.idEmpresa && methods.watch("ubicacion")) {
      ObtenerProductosAjuste(
        "/api/producto/obtener-productos-transaccion",
        empresa.idEmpresa,
        methods.watch("ubicacion").idSeccion
      ).then((response) => {
        setproductosPerSecciones(response.data);
      });
    } else {
      setproductosPerSecciones([]);
    }
  }, [methods.watch("ubicacion"), empresa]);

  const getSecuencial = () => {
    ObtenerSecuencialPerTransaccion(
      empresa.idEmpresa,
      "385E6EFA-EF4C-4041-B316-3A9E3DBEA0B7"
    ).then((response) => setSecuencial(response.data));
  };

  const [rowBase, setRowBase] = useState({
    codigo: "",
    cuenta: "",
    stock: "",
    ajuste: "",
  });

  const [rows, setRows] = useState([{ ...rowBase }]);

  const validateAjuste = async (data) => {
    if (
      rows.some(
        (row) =>
          row.codigo === "" || row.codigo === null || row.codigo === undefined
      )
    ) {
      ErrorToast("Debes seleccionar un producto en cada registro.");
      return;
    }
    if (rows.length === 0) {
      ErrorToast("No puedes generar una transacción vacia.");
      return;
    }

    const productosUnicos = new Set();

    for (const row of rows) {
      if (
        row.codigo.isMinimoStock === true &&
        row.codigo.alertaStock !== 0 &&
        row.codigo.stock >= row.codigo.alertaStock
      ) {
        productosUnicos.add(row.codigo);
      }
    }

    const productosUnicosArray = Array.from(productosUnicos);
    const productosConAlerta = [];

    for (const producto of productosUnicosArray) {
      const valorAjuste = rows.filter(
        (r) => r.codigo.idProducto === producto.idProducto
      )[
        rows.filter((r) => r.codigo.idProducto === producto.idProducto).length -
          1
      ]?.ajuste;

      if (valorAjuste < producto.alertaStock) {
        productosConAlerta.push({
          producto: producto.nombre,
          valorAjuste: valorAjuste,
          alertaStock: producto.alertaStock,
        });
      }
    }

    if (productosConAlerta.length > 0) {
      setAlertaStockProducts(productosConAlerta);
      setDataToConfirm(data);
      setIsOpen(true);
      return;
    }

    handleAjuste(data);
  };

  const handleAjuste = async (data) => {
    const idTransaccion = uuidv();
    const newAjusteInventario = {
      idTransaccion: idTransaccion,
      idEmpresa: empresa.idEmpresa,
      idTipoTransaccion: "385E6EFA-EF4C-4041-B316-3A9E3DBEA0B7",
      idSeccionOrigen: data.ubicacion.idSeccion,
      codigo: secuencial.codigo,
      detalle: data.detalle,
      fechaCreacion: data.fechaCreacion,
      productos: rows.map((registro) => ({
        idDetalleTransaccion: uuidv(),
        idTransaccion: idTransaccion,
        idProducto: registro.codigo.idProducto,
        cuentaAnalitica: registro.cuenta,
        cantidad: registro.ajuste,
        costoDecimal: registro.codigo.costo,
      })),
    };
    try {
      setIsSending(true);
      await toast.promise(addTransaccion(newAjusteInventario), {
        pending: "Realizando el Ajuste...",
        success: "¡El ajuste se realizo con éxito!",
        error: {
          render({ data }) {
            return (
              data?.response?.data?.message ||
              "Hubo un error al realizar el ajuste."
            );
          },
        },
      });
    } finally {
      setIsSending(false);
    }
    methods.reset();
    methods.setValue("ubicacion", "");
    getSecuencial();
    setRows([{ ...rowBase }]);
  };

  const ChangeRow = (row) => {
    const IsInclude = Object.keys(rowBase).includes(row);
    if (IsInclude) {
      const updatedRowBase = { ...rowBase };
      delete updatedRowBase[row];
      setRowBase(updatedRowBase);
    } else {
      const updatedRowBase = { ...rowBase };
      updatedRowBase[row] = "";
      setRowBase(updatedRowBase);
    }
  };

  return (
    <AjusteInventarioProviderContext.Provider
      value={{
        rows,
        secuencial,
        isSending,
        setIsSending,
        validateAjuste,
        handleAjuste,
        setAlertaStockProducts,
        setDataToConfirm,
        dataToConfirm,
        alertaStockProducts,
        setRows,
        methods,
        rowBase,
        getSecuencial,
        ChangeRow,
        productosPerSecciones,
        setIsOpen,
        isOpen,
      }}
    >
      {props.children}
    </AjusteInventarioProviderContext.Provider>
  );
};

export default AjusteInventarioProvider;
