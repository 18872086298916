import { useContext } from "react";
import { ConsultaContext } from "../Provider/ConsultaProvider";
import { SyncLoader } from "react-spinners";

export default function ConsultaForm({ methods }) {
  const {
    setIdentificacion,
    identificacion,
    Message,
    Error,
    handleSubmit,
    Loading,
  } = useContext(ConsultaContext);

  const handleKeySearch = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      methods.handleSubmit(handleSubmit)();
    }
  };

  return (
    <section className="w-full h-[600px] relative flex items-center justify-center">
      <form
        className="md:w-[650px] w-[375px] overflow-hidden bg-[#00314D] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white p-14 px-10 rounded-lg flex md:flex-row md:gap-0 flex-col-reverse gap-4 justify-around items-center"
        style={{ "boxShadow": "10px 10px 25px rgba(0, 0, 0, 0.5)" }}
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <div className="flex flex-col gap-2">
          <h1 className="whitespace-nowrap font-semibold">
            Consultar información del Contribuyente
          </h1>
          <div className={`text-xs`}>
            <label
              htmlFor={"RUC"}
              className={
                "w-full my-auto font-normal flex flex-col gap-1 whitespace-nowrap"
              }
            >
              <p className="flex items-center gap-x-2 text-gray-300">RUC:</p>
              <div className="relative top-6">
                <span
                  type="button"
                  className="absolute z-10  w-auto  ml-2 left-0 bottom-0 top-0 right-0 flex items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-4 h-4 text-gray-500"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
              <input
                  type={"text"}
                  placeholder={"1111111111001"}
                  onChange={(e) => setIdentificacion(e.target.value)}
                  maxLength={13}
                  autoComplete="off"
                  onKeyPress={handleKeySearch}
                  value={identificacion}
                  className={`border-gray-300 text-black focus:outline-none left-0 relative focus:border-blue-500 focus:ring-blue-500 focus:ring-1 ring-0 w-full border rounded-md pl-7
                 text-sm h-[39px] space-x-0`}
                  id={"RUC"}
                />
            </label>
          </div>
          <button
            type="submit"
            disabled={Loading}
            className="border bg-white  hover:bg-gray-200 text-black rounded-md transition-colors duration-300 py-1.5 px-4 mt-3 flex items-center space-x-1 text-xs mx-auto "
          >
            <p>{"Consultar"}</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-4 h-4 text-gray-500"
            >
              <path
                fillRule="evenodd"
                d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div className="flex flex-col items-center justify-start relative">
          <span className="mb-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="120"
              height="120"
              fill="currentColor"
              className="bi bi-person-lines-fill"
              viewBox="0 0 16 16"
            >
              <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z" />
            </svg>
          </span>
          {Loading && (
            <span className="absolute -bottom-3">
              <SyncLoader color="#d8d4d4" />
            </span>
          )}
         {Message !== null && <span
            className={`text-sm whitespace-nowrap -bottom-3 absolute p-1 rounded-md ${
              Error ? "bg-red-600" : "bg-green-500"
            }`}
          >
            {Message}
          </span>}
        </div>
      </form>
    </section>
  );
}
