import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import NumberFormat from "react-number-format";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import EstadoTable from "../../../features/components/EstadoTable";

import { ReporteFacturaContext } from "./ReporteFacturaProvider";
import MesageWarningTable from "../../../features/components/MesageWarningTable";
import { getListV3, post } from "../../../services";
import { rellenarCeros } from "../../../services/Utilitario";
import {
  TableStyle,
  TableStyleDiv,
  TableStyleTBody,
  TableStyleTHead,
} from "../../../features/Constantes";
import { useSelector } from "react-redux";
import DocumentOrder from "./components/DocumentOrder";
import { toast } from "react-toastify";

import logoPdf from "../../../img/PDF.svg";
import logoBuscar from "../../../img/buscar.png";

import enviado from "../../../img/correo-enviado.svg";
import sin_correo from "../../../img/corre-sinCorreo.svg";
import no_enviado from "../../../img/correo_NoEnviado.svg";
import { ButtonDefault } from "../../../features/components/ButtonDefault";
import ModalComponent from "../../../features/components/ModalComponets/ModalComponent";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ListaFacturas = () => {
  const {
    documentos,
    Paginacion,
    setPaginacion,
    formik,
    setInfoTrazabilidad,
    setEditCorreos,
    setCorreosId,
    start,
    setStart,
    setAnularElement,
    setAutorizarElement,
    CheckedProp,
    setDocumentos,
    IconOrder,
    setIconOrder,
    initialStatePaginacion,
    cargandoData,
    cargandoData2,
    setvalidation
  } = useContext(ReporteFacturaContext);

  const isAdmin = useSelector((store) => store.app.user.esAdministradorSistema);
  const permisosInEmpresa = useSelector(
    (store) => store.permisosEmpresa.permisos
  );

  // const SearchEmpresa = () =>{
  //   //const empresas = useSelector((store) => store.app.user.esAdministradorSistema);

  //   const empresa = useSelector((store) => store.empresa.empresa);
  //   console.log(empresa)
  // }

  const empresa = useSelector((store) => store.empresa.empresa);
  const [statusMessage, setStatusMessage] = useState(false);

  const [IsLoading, setIsLoading] = useState(false);

  const handleAnularFactura = (element) => setAnularElement(element);

  const handleAutorizarFactura = (element) => setAutorizarElement(element);

  const handleReprocesarComprobante = async (
    idDocumento,
    idEmpresa,
    claveAcceso,
    idDocumentoElectronico,
    url,
    emisor
  ) => {
    setIsLoading(true);
    var obj = {
      idDocumento: idDocumento,
      idEmpresa: idEmpresa,
      claveAcceso: claveAcceso,
      idDocumentoElectronico: idDocumentoElectronico,
      ruc: empresa.ruc,
      url: url,
      emisor: empresa.razonSocial,
      nombreEmpresa: emisor,
    };

    try {
      const req = await post("api/DocumentoElectronico/reprocesarXml", obj);

      const toastPromise = () =>
        new Promise((resolve) => setInterval(() => resolve(req), 2000));

      await toast.promise(toastPromise(), {
        pending: {
          render() {
            setIsLoading(true);
            return "Reenviando...";
          },
        },
        success: {
          render() {
            setIsLoading(false);
            return "Documento reenviado con exito.";
          },
          autoClose: 2000,
          closeOnClick: true,
        },
        error: {
          render() {
            setIsLoading(false);
            return "Algo ha pasado, intente nuevamente.";
          },
          autoClose: 2000,
          closeOnClick: true,
        },
      });
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const descargarXml = async (idEmpresa, idDoc) => {
    const res = await getListV3(`api/FacturasEmitidas/descargarXml`, {
      idEmpresa: idEmpresa,
      idDoc: idDoc,
    });

    var link = document.createElement("a");
    document.body.appendChild(link);
    link.setAttribute("type", "hidden");
    link.href = "data:application/octet-stream;base64," + res.archivo;
    let filename = res.path + ".xml";
    link.download = filename;
    link.click();
    document.body.removeChild(link);
    //console.log(idEmpresa, idDoc);
  };

  const descargarPdf = async (idEmpresa, idDoc) => {
    setIsLoading(true);
    const res = await getListV3(`api/FacturasEmitidas/descargarPdf`, {
      idEmpresa: idEmpresa,
      idDoc: idDoc,
    });
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.setAttribute("type", "hidden");
    link.href = "data:application/octet-stream;base64," + res.archivo;
    let filename = res.path + ".pdf";
    link.download = filename;
    link.click();
    document.body.removeChild(link);
    setTimeout(() => setIsLoading(false), 2000);
  };

  const descargarPdfPV = async (idEmpresa, idDoc) => {
    try {
      const res = await getListV3("api/FacturasEmitidas/descargarPdfPV", {
        idEmpresa: idEmpresa,
        idDoc: idDoc,
      });

      const blob = new Blob([base64ToArrayBuffer(res.archivo)], {
        type: "application/pdf",
      });
      const pdfUrl = URL.createObjectURL(blob);

      const newWindow = window.open(pdfUrl, "_blank");

      if (!newWindow || newWindow.closed) {
        console.error(
          "La ventana emergente fue bloqueada. Asegúrate de habilitar las ventanas emergentes en tu navegador."
        );
      }
    } catch (error) {
      console.error("Error al abrir el PDF en una nueva pestaña:", error);
    }
  };

  // Convierte una cadena Base64 en un búfer de datos
  function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes;
  }

  // console.log(documentos)

  const handleClickNextPage = () => {
    setStart(start + 1);
    setPaginacion({ ...Paginacion, pagina: Paginacion.pagina + 1 });
    formik.handleSubmit(formik.values);
  };

  const handleClickBeforePage = () => {
    setStart(start - 1);
    setPaginacion({ ...Paginacion, pagina: Paginacion.pagina - 1 });
    formik.handleSubmit(formik.values);
  };

  const handleClickLastPage = () => {
    setPaginacion({ ...Paginacion, pagina: Paginacion._totalpaginas });
    formik.handleSubmit(formik.values);
  };

  const handleClickFirstPage = () => {
    setPaginacion({ ...Paginacion, pagina: 1 });
    formik.handleSubmit(formik.values);
  };

  const VerCorreos = (obj) => {
    setEditCorreos(obj.emails.replaceAll(";", ",").split(","));
    setCorreosId(obj.idDocumentoElectronico);
  };

  const orderBySecuencialDesc = () => {
    let desc = documentos.sort(
      (a, b) => Number(a.secuencial) - Number(b.secuencial)
    );

    setIconOrder(2);
    setDocumentos([...desc]);
  };

  const orderBySecuencialAsc = () => {
    let asc = documentos
      .sort((a, b) => Number(a.secuencial) - Number(b.secuencial))
      .reverse();

    setIconOrder(1);
    setDocumentos([...asc]);
  };

  const orderByFechaEmisionDesc = () => {
    let desc = documentos.sort((a, b) => {
      return new Date(b.fechaEmision) - new Date(a.fechaEmision);
    });

    setIconOrder(2);
    setDocumentos([...desc]);
  };

  const orderByFechaEmisionAsc = () => {
    let asc = documentos.sort((a, b) => {
      return new Date(a.fechaEmision) - new Date(b.fechaEmision);
    });

    setIconOrder(1);
    setDocumentos([...asc]);
  };

  const orderByEstadoDocumentoDesc = () => {
    let desc = documentos.sort((a, b) => {
      const nombreA =
        a.idEstadoDocumentoElectronicoNavigation.nombre.toUpperCase();
      const nombreB =
        b.idEstadoDocumentoElectronicoNavigation.nombre.toUpperCase();
      if (nombreA < nombreB) {
        return 1;
      }
      if (nombreA > nombreB) {
        return -1;
      }
      return 0;
    });

    setIconOrder(2);
    setDocumentos([...desc]);
  };

  const orderByEstadoDocumentoAsc = () => {
    let asc = documentos.sort((a, b) => {
      const nombreA =
        a.idEstadoDocumentoElectronicoNavigation.nombre.toUpperCase();
      const nombreB =
        b.idEstadoDocumentoElectronicoNavigation.nombre.toUpperCase();
      if (nombreA < nombreB) {
        return -1;
      }
      if (nombreA > nombreB) {
        return 1;
      }
      return 0;
    });

    setIconOrder(1);
    setDocumentos([...asc]);
  };

  const orderByImporteTotalDesc = () => {
    let desc = documentos.sort((a, b) => {
      return b.importeTotal - a.importeTotal;
    });

    setIconOrder(2);
    setDocumentos([...desc]);
  };

  const orderByImporteTotalAsc = () => {
    let asc = documentos.sort((a, b) => {
      return a.importeTotal - b.importeTotal;
    });

    setIconOrder(1);
    setDocumentos([...asc]);
  };

  const orderByRazonSocialDesc = () => {
    let desc = documentos.sort((a, b) => {
      const razonSocialA =
        a.idContribuyenteNavigation.razonSocial.toUpperCase();
      const razonSocialB =
        b.idContribuyenteNavigation.razonSocial.toUpperCase();
      if (razonSocialA < razonSocialB) {
        return 1;
      }
      if (razonSocialA > razonSocialB) {
        return -1;
      }
      return 0;
    });

    setIconOrder(2);
    setDocumentos([...desc]);
  };

  const orderByRazonSocialAsc = () => {
    let asc = documentos.sort((a, b) => {
      const razonSocialA =
        a.idContribuyenteNavigation.razonSocial.toUpperCase();
      const razonSocialB =
        b.idContribuyenteNavigation.razonSocial.toUpperCase();
      if (razonSocialA < razonSocialB) {
        return -1;
      }
      if (razonSocialA > razonSocialB) {
        return 1;
      }
      return 0;
    });

    setIconOrder(1);
    setDocumentos([...asc]);
  };

  const orderByTipoDocumentoDesc = () => {
    let desc = documentos.sort((a, b) => {
      const tipoDocumentoA = getTipoDocumentoCode(a.codigoTipoDocumento);
      const tipoDocumentoB = getTipoDocumentoCode(b.codigoTipoDocumento);
      if (tipoDocumentoA < tipoDocumentoB) {
        return 1;
      }
      if (tipoDocumentoA > tipoDocumentoB) {
        return -1;
      }
      return 0;
    });

    setIconOrder(2);
    setDocumentos([...desc]);
  };

  const orderByTipoDocumentoAsc = () => {
    let asc = documentos.sort((a, b) => {
      const tipoDocumentoA = getTipoDocumentoCode(a.codigoTipoDocumento);
      const tipoDocumentoB = getTipoDocumentoCode(b.codigoTipoDocumento);
      if (tipoDocumentoA < tipoDocumentoB) {
        return -1;
      }
      if (tipoDocumentoA > tipoDocumentoB) {
        return 1;
      }
      return 0;
    });

    setIconOrder(1);
    setDocumentos([...asc]);
  };

  const getTipoDocumentoCode = (codigoTipoDocumento) => {
    switch (codigoTipoDocumento) {
      case "01":
        return "FA";

      case "07":
        return "RE";

      case "04":
        return "NC";

      case "05":
        return "ND";

      case "06":
        return "GR";

      case "03":
        return "LC";

      default:
        return "N/A";
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };


  const onclick = (obj) => {
    setvalidation(true)
    formik.handleSubmit(obj);

  }

  return (
    <>
      <ModalComponent isOpen={isOpen} onClose={toggleModal} title={""}>
        <div className="">
          <div className="flex justify-center items-center space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-10  text-yellow-400"
            >
              <path
                fillRule="evenodd"
                d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                clipRule="evenodd"
              />
            </svg>

            <p className="font-semibold text-lg">Advertencia</p>
          </div>
          <div className="mt-3 my-10">
            <p>
              Tu comprobante tiene fecha de autorizado{" "}
              <strong> fuera del rango de 72 horas</strong>, para evitar futura
              sanción por el SRI, se recomienda regularizar.
            </p>

            <p>Por favor gestionar con tu contador.</p>
            <p> Para más detalle consultar o revisar resolución   SRI:</p>
            <p className="font-semibold pt-2">
              NAC-DGERCGC22-00000064 y
              <br></br>
              NACDGERCGC18-00000233
            </p>
          </div>
        </div>
      </ModalComponent>
      <div className="bg-white dark:bg-gray-700  p-3 mt-4 rounded-lg">
        <div className="flex justify-between items-center mr-2">
          <div className=" inline-flex z-0 mx-4 my-3  group overflow-x-auto">
            <h1 className="float-left  mr-2 dark:text-white text-black">
              Mostrar
            </h1>
            <select
              type="text"
              name="Cantidad"
              id="Cantidad"
              value={formik.values.Cantidad}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="origin-left border-gray-300 border pr-2 pl-1  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              placeholder=" "
            >
              <option key="50" value="50">
                50
              </option>
              <option key="75" value="75">
                75
              </option>
              <option key="100" value="100">
                100
              </option>
              <option key="200" value="200">
                200
              </option>
              <option key="300" value="300">
                300
              </option>
              <option key="400" value="400">
                400
              </option>
              <option key="500" value="500">
                500
              </option>
              <option key="1000" value="1000">
                1000
              </option>
            </select>
            <h1 className="float-left  ml-2 dark:text-white text-black">
              Registros
            </h1>
          </div>
          <div>
            <form
              onSubmit={(e) => {
                setPaginacion(Paginacion);
                onclick(e);
                setStart(0);
              }}
            >
              <div className="group relative flex justify-center items-center bg-[#BC0404] flex p-2 rounded-lg items-center relative">
                <button
                  className="flex justify-center mx-auto items-center my-auto"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`w-5 h-5 text-white ${cargandoData2 ? "animate-spin duration-200 transition-colors" : ""
                      }`}   >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                </button>
                <span className="absolute bottom-full mb-2 hidden group-hover:block bg-gray-700 text-white text-xs rounded-lg px-2 py-1">
                  Actualizar
                </span>
              </div>
            </form>
          </div>
        </div>

        <div className="px-4 sm:px-6 lg:px-8">
          <div className=" flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle px-1">
                <div className={TableStyleDiv + " whitespace-nowrap"}>
                  <table className={TableStyle}>
                    <thead className={TableStyleTHead + " bg-[#d9d9d9]"}>
                      <tr>
                        <th
                          scope="col"
                          className=" px-3 py-2 text-sm text-black font-semibold lg:table-cell"
                        >
                          <div className="flex items-center">
                            <input
                              id="checkbox-table-search-2"
                              type="checkbox"
                              className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              checked={CheckedProp.IsCheckedAll}
                              onChange={() => {
                                CheckedProp.setIsCheckedAll(
                                  !CheckedProp.IsCheckedAll
                                );
                                CheckedProp.handleSelectAll(
                                  !CheckedProp.IsCheckedAll
                                );
                              }}
                            />
                            <label
                              htmlFor="checkbox-table-search-2"
                              className="sr-only"
                            >
                              Seleccionar todos
                            </label>
                          </div>
                        </th>

                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3  text-sm font-semibold  sm:pl-6 lg:hidden "
                        >
                          Info Documento
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3  text-sm font-semibold  sm:pl-6 lg:table-cell hidden"
                        >
                          Tipo
                          <DocumentOrder
                            onClick={
                              IconOrder === 1
                                ? orderByTipoDocumentoDesc
                                : orderByTipoDocumentoAsc
                            }
                            Status={IconOrder}
                          />
                        </th>
                        <th className=" px-3 py-3.5  text-sm  font-semibold  lg:table-cell hidden">
                          <div className="flex items-center justify-center space-x-1  ">
                            <p>Documento</p>
                            <DocumentOrder
                              onClick={
                                IconOrder === 1
                                  ? orderBySecuencialDesc
                                  : orderBySecuencialAsc
                              }
                              Status={IconOrder}
                            />
                          </div>
                        </th>
                        <th
                          scope="col"
                          className=" px-3 py-3.5  text-sm font-semibold  lg:table-cell hidden "
                        >
                          Fecha autorización
                          <DocumentOrder
                            onClick={
                              IconOrder === 1
                                ? orderByFechaEmisionDesc
                                : orderByFechaEmisionAsc
                            }
                            Status={IconOrder}
                          />
                        </th>
                        <th
                          scope="col"
                          className=" px-3 py-3.5  text-sm font-semibold  lg:table-cell hidden "
                        >
                          Fecha emisión
                          <DocumentOrder
                            onClick={
                              IconOrder === 1
                                ? orderByFechaEmisionDesc
                                : orderByFechaEmisionAsc
                            }
                            Status={IconOrder}
                          />
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5  text-sm font-semibold  lg:table-cell hidden "
                        >
                          Total
                          <DocumentOrder
                            onClick={
                              IconOrder === 1
                                ? orderByImporteTotalDesc
                                : orderByImporteTotalAsc
                            }
                            Status={IconOrder}
                          />
                        </th>
                        {/* <th
                        scope="col"
                        className="px-3 py-3.5  text-sm font-semibold  lg:table-cell hidden"
                      >
                        Identificación
                      </th> */}
                        <th
                          scope="col"
                          className="px-3 py-3.5  text-sm font-semibold lg:table-cell hidden w-[15vh]"
                        >
                          Contribuyente
                          <DocumentOrder
                            onClick={
                              IconOrder === 1
                                ? orderByRazonSocialDesc
                                : orderByRazonSocialAsc
                            }
                            Status={IconOrder}
                          />
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5  text-sm font-semibold  lg:table-cell hidden"
                        >
                          Estado
                          <DocumentOrder
                            onClick={
                              IconOrder === 1
                                ? orderByEstadoDocumentoDesc
                                : orderByEstadoDocumentoAsc
                            }
                            Status={IconOrder}
                          />
                        </th>
                        {/* <th
                        scope="col"
                        className="px-3 py-3.5  text-sm font-semibold "
                      >
                        Correo
                      </th> */}
                        <th
                          scope="col"
                          className="px-3 py-3.5  text-sm font-semibold hidden"
                        >
                          Traza...
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5  text-sm font-semibold  hidden"
                        >
                          Documentos
                        </th>
                        {/* <th
                        scope="col"
                        className="px-3 py-3.5  text-sm font-semibold  hidden"
                      >
                        Correo
                      </th> */}
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-sm font-semibold sm:hidden sm:table-cell md:table-cell lg:table-cell"
                        >
                          Opciones
                        </th>
                        <th>

                        </th>
                      </tr>
                    </thead>
                    <tbody className={TableStyleTBody}>
                      {documentos.length < 1 ? (
                        <MesageWarningTable
                          colSpan="11"
                          message="No se encontraron datos para la búsqueda."
                          className="py-1"
                        />
                      ) : null}
                      {documentos.map((obj, index) => {
                        const fechaGuardada = new Date(obj.fechaCreacion);

                        const fechaActual = new Date();

                        const diferenciaEnMS = fechaActual - fechaGuardada;
                        const minutosTranscurridos =
                          diferenciaEnMS / (1000 * 60);

                        const hanPasado20Minutos = minutosTranscurridos >= 20;

                        const fechaEmision = moment(obj.fechaEmision);
                        const fechaAutorizacion = moment(obj.fechaAutorizacion);
                        const diffInHours = fechaAutorizacion.diff(
                          fechaEmision,
                          "hours"
                        );

                        const isOver72Hours = diffInHours >= 72;

                        // console.log(obj)
                        return (
                          <tr
                            key={index}
                            className={`${isOver72Hours
                              ? "bg-red-100 dark:bg-gray-600 dark:text-gray-100"
                              : ""
                              }`}
                          >
                            <td className=" px-3 py-2 text-sm text-gray-400   ">
                              <div className="flex items-center">
                                <input
                                  id="checkbox-table-search-2"
                                  type="checkbox"
                                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  checked={CheckedProp.documentsChecked.find(
                                    (el) => el === obj.idDocumentoElectronico
                                  )}
                                  onChange={() => {
                                    CheckedProp.handleClick(index);
                                  }}
                                />
                                <label
                                  htmlFor="checkbox-table-search-2"
                                  className="sr-only"
                                >
                                  checkbox
                                </label>
                              </div>
                            </td>
                            <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm lg:hidden">
                              <div className="font-medium text-gray-500">
                                {(() => {
                                  switch (obj.codigoTipoDocumento) {
                                    case "01":
                                      return "FA";

                                    case "07":
                                      return "RE";

                                    case "04":
                                      return "NC";

                                    case "05":
                                      return "ND";

                                    case "06":
                                      return "GR";

                                    case "03":
                                      return "LC";

                                    default:
                                      return "N/A";
                                  }
                                })()}
                                <span className="lg:hidden pl-1">
                                  · {rellenarCeros(obj.establecimiento)}-
                                  {rellenarCeros(obj.puntoEmision)}-
                                  {rellenarCeros(obj.secuencial, 9)}
                                  <span>
                                    {" "}
                                    /
                                    {moment(obj.fechaEmision).format(
                                      "DD-MM-YYYY"
                                    )}{" "}
                                    /{" "}
                                    <NumberFormat
                                      value={obj.importeTotal}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                    />{" "}
                                  </span>
                                </span>
                              </div>
                              <div className="mt-1 flex flex-col left-0 dark:text-white sm:block lg:hidden">
                                <div className="lg:hidden gid grid-cols-2">
                                  <div>
                                    {obj.idContribuyenteNavigation
                                      .razonSocial == null
                                      ? "NONE"
                                      : obj.idContribuyenteNavigation.razonSocial.toUpperCase()}
                                  </div>
                                  <div className="flex flex-row flex-wrap gap-2 justify-center">
                                    <EstadoTable
                                      nombre={
                                        obj
                                          .idEstadoDocumentoElectronicoNavigation
                                          .nombre
                                      }
                                      codigo={
                                        obj
                                          .idEstadoDocumentoElectronicoNavigation
                                          .codigo
                                      }
                                    />
                                    <div className="flex flex-row flex-wrap gap-2 lg:hidden md:hidden justify-center ">
                                      {(() => {
                                        switch (obj.enviado) {
                                          case true:
                                            return (
                                              <div className="group relative md:table-cell lg:table-cell">
                                                <span className="inline-flex items-center rounded-md p-1 text-sm font-medium">
                                                  <img
                                                    src={enviado}
                                                    width={32}
                                                    alt="enviado"
                                                  />
                                                </span>
                                                <div className="absolute mx-auto w-24 -top-8 -left-8 hidden dark:bg-white dark:text-black rounded-md bg-gray-800 group-hover:flex text-center p-1">
                                                  <p className="text-white dark:text-black mx-auto text-sm">
                                                    Enviado
                                                  </p>
                                                </div>
                                              </div>
                                            );
                                          case false:
                                            return (
                                              <div className="group relative md:table-cell lg:table-cell ">
                                                <span className="inline-flex items-center rounded-md p-1 text-sm font-medium">
                                                  <img
                                                    src={no_enviado}
                                                    alt="no enviado"
                                                    width={32}
                                                  />
                                                </span>
                                                <div className="absolute mx-auto w-24 -top-8 -left-8 hidden dark:bg-white dark:text-black rounded-md bg-gray-800 group-hover:flex text-center p-1">
                                                  <p className="text-white dark:text-black mx-auto text-sm">
                                                    No enviado
                                                  </p>
                                                </div>
                                              </div>
                                            );
                                          case null:
                                            return (
                                              <div className="group relative md:table-cell lg:table-cell">
                                                <span className="inline-flex items-center rounded-md p-1 text-sm font-medium">
                                                  <img
                                                    src={sin_correo}
                                                    alt="no enviado"
                                                    width={32}
                                                  />
                                                </span>
                                                <div className="absolute mx-auto w-24  -top-8 -left-8 hidden dark:bg-white dark:text-black     rounded-md bg-gray-800 group-hover:flex text-center p-1">
                                                  <p className="text-white dark:text-black mx-auto text-sm">
                                                    Sin correo
                                                  </p>
                                                </div>
                                              </div>
                                            );

                                          default:
                                            break;
                                        }
                                      })()}
                                      <div className="group relative ">
                                        <button
                                          onClick={() =>
                                            setInfoTrazabilidad(
                                              obj.trazabilidad
                                            )
                                          }
                                          data-bs-toggle="modal"
                                          data-bs-target="#trazabilidadModal"
                                          tabIndex="-1"
                                          className="inline-flex p-1 cursor-pointer items-center justify-center rounded-md hover:shadow-md transition"
                                        >
                                          {/* <img src={logoBuscar} alt='Search icon' width={25} /> */}

                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 25 21"
                                            strokeWidth="2"
                                            stroke="var(--color,#727272 )"
                                            class="w-6 h-6"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                            />
                                          </svg>
                                        </button>
                                        <div className="absolute mx-auto w-24  -top-8 -left-8 hidden dark:bg-white dark:text-black     rounded-md bg-gray-800 group-hover:flex text-center p-1">
                                          <p className="text-white dark:text-black mx-auto text-sm">
                                            Trazabilidad
                                          </p>
                                        </div>
                                      </div>
                                      <div className="group relative ">
                                        <button
                                          onClick={() =>
                                            descargarPdf(
                                              obj.idEmpresa,
                                              obj.idDocumentoElectronico
                                            )
                                          }
                                          disabled={IsLoading}
                                          className="inline-flex p-1 cursor-pointer items-center justify-center rounded-md hover:shadow-md transition "
                                        >
                                          <img
                                            src={logoPdf}
                                            width={25}
                                            alt="descargar pdf"
                                          />
                                        </button>
                                        <div className="absolute mx-auto w-32  -top-8 -left-8 hidden dark:bg-white dark:text-black rounded-md bg-gray-800 group-hover:flex text-center p-1">
                                          <p className="text-white dark:text-black mx-auto text-sm">
                                            {IsLoading
                                              ? "Descargando pdf..."
                                              : "Descargar pdf"}
                                          </p>
                                        </div>
                                      </div>
                                      <Menu
                                        as="div"
                                        className="relative inline-block text-left"
                                      >
                                        <div>
                                          <Menu.Button className="inline-flex w-full justify-center self-center rounded-md border border-gray-300  dark:border-gray-600 bg-white px-2 py-1.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 dark:bg-gray-700 dark:text-gray-400">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth="1.5"
                                              stroke="currentColor"
                                              className=" h-5 w-5 fill-gray-600"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                              ></path>
                                            </svg>
                                          </Menu.Button>
                                        </div>

                                        <Transition
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95"
                                        >
                                          <Menu.Items className="absolute  right-10 z-10 -mt-20  w-56 origin-top-right rounded-md dark:bg-gray-700  dark:text-gray-400 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <button
                                                    onClick={() =>
                                                      descargarXml(
                                                        obj.idEmpresa,
                                                        obj.idDocumentoElectronico
                                                      )
                                                    }
                                                    disabled={IsLoading}
                                                    className={classNames(
                                                      active
                                                        ? "bg-gray-200 text-gray-900"
                                                        : "text-gray-700",
                                                      " px-4 py-2 text-sm w-full text-left group dark:text-gray-400 flex items-center "
                                                    )}
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      className="mr-1 h-5 w-5 dark:fill-white  fill-gray-600"
                                                      fill="none"
                                                      stroke="currentColor"
                                                      strokeWidth="2"
                                                      viewBox="0 0 384 512"
                                                    >
                                                      <path d="M162.1 257.8c-7.812-7.812-20.47-7.812-28.28 0l-48 48c-7.812 7.812-7.812 20.5 0 28.31l48 48C137.8 386.1 142.9 388 148 388s10.23-1.938 14.14-5.844c7.812-7.812 7.812-20.5 0-28.31L128.3 320l33.86-33.84C169.1 278.3 169.1 265.7 162.1 257.8zM365.3 93.38l-74.63-74.64C278.6 6.742 262.3 0 245.4 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.34 28.65 64 64 64H320c35.2 0 64-28.8 64-64V138.6C384 121.7 377.3 105.4 365.3 93.38zM336 448c0 8.836-7.164 16-16 16H64.02c-8.838 0-16-7.164-16-16L48 64.13c0-8.836 7.164-16 16-16h160L224 128c0 17.67 14.33 32 32 32h79.1V448zM221.9 257.8c-7.812 7.812-7.812 20.5 0 28.31L255.7 320l-33.86 33.84c-7.812 7.812-7.812 20.5 0 28.31C225.8 386.1 230.9 388 236 388s10.23-1.938 14.14-5.844l48-48c7.812-7.812 7.812-20.5 0-28.31l-48-48C242.3 250 229.7 250 221.9 257.8z" />
                                                    </svg>
                                                    {IsLoading
                                                      ? "Descargando..."
                                                      : "Descargar Xml"}
                                                  </button>
                                                )}
                                              </Menu.Item>

                                              {obj
                                                .idEstadoDocumentoElectronicoNavigation
                                                .codigo === "1" ? (
                                                <Menu.Item>
                                                  {({ active }) => (
                                                    <button
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#editCorreosModal"
                                                      aria-label="Delete"
                                                      title="Correo"
                                                      onClick={() =>
                                                        VerCorreos(obj)
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      className={classNames(
                                                        active
                                                          ? "bg-gray-200 text-gray-900"
                                                          : "text-gray-700",
                                                        " px-4 py-2 text-sm w-full text-left group flex items-center "
                                                      )}
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 512 512"
                                                        className="mr-1 h-5 w-5 dark:fill-white  fill-gray-600"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                      >
                                                        <path d="M0 128C0 92.65 28.65 64 64 64H448C483.3 64 512 92.65 512 128V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V128zM48 128V150.1L220.5 291.7C241.1 308.7 270.9 308.7 291.5 291.7L464 150.1V127.1C464 119.2 456.8 111.1 448 111.1H64C55.16 111.1 48 119.2 48 127.1L48 128zM48 212.2V384C48 392.8 55.16 400 64 400H448C456.8 400 464 392.8 464 384V212.2L322 328.8C283.6 360.3 228.4 360.3 189.1 328.8L48 212.2z" />
                                                      </svg>
                                                      <span className="dark:text-white">
                                                        Correo
                                                      </span>
                                                    </button>
                                                  )}
                                                </Menu.Item>
                                              ) : (
                                                <></>
                                              )}
                                              {obj
                                                .idEstadoDocumentoElectronicoNavigation
                                                .codigo === "2" ||
                                                obj
                                                  .idEstadoDocumentoElectronicoNavigation
                                                  .codigo === "5" ||
                                                obj
                                                  .idEstadoDocumentoElectronicoNavigation
                                                  .codigo === "9" ||
                                                obj
                                                  .idEstadoDocumentoElectronicoNavigation
                                                  .codigo === "7" ? (
                                                <Menu.Item disabled={IsLoading}>
                                                  {({ active }) => (
                                                    <button
                                                      aria-label="Delete"
                                                      title="Reprocesar"
                                                      onClick={() =>
                                                        handleReprocesarComprobante(
                                                          obj.idDocumento,
                                                          obj.idEmpresa,
                                                          obj.claveAcceso,
                                                          obj.idDocumentoElectronico,
                                                          obj.url,
                                                          obj
                                                            .idContribuyenteNavigation
                                                            .razonSocial
                                                        )
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      className={classNames(
                                                        active
                                                          ? "bg-gray-200 text-gray-900"
                                                          : "text-gray-700",
                                                        "px-4 py-2 text-sm w-full text-left group flex items-center"
                                                      )}
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                        className="mr-1 h-5 w-5 dark:fill-white  fill-gray-600"
                                                      >
                                                        <path
                                                          fillRule="evenodd"
                                                          d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
                                                          clipRule="evenodd"
                                                        />
                                                      </svg>

                                                      <span className="dark:text-white">
                                                        {IsLoading
                                                          ? "Reenviando..."
                                                          : "Reenviar"}
                                                      </span>
                                                    </button>
                                                  )}
                                                </Menu.Item>
                                              ) : (
                                                <></>
                                              )}

                                              {obj
                                                .idEstadoDocumentoElectronicoNavigation
                                                .codigo === "0" &&
                                                (hanPasado20Minutos ? (
                                                  <Menu.Item
                                                    disabled={IsLoading}
                                                  >
                                                    {({ active }) => (
                                                      <button
                                                        aria-label="Delete"
                                                        title="Reprocesar"
                                                        onClick={() =>
                                                          handleReprocesarComprobante(
                                                            obj.idDocumento,
                                                            obj.idEmpresa,
                                                            obj.claveAcceso,
                                                            obj.idDocumentoElectronico,
                                                            obj.url,
                                                            obj
                                                              .idContribuyenteNavigation
                                                              .razonSocial
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        className={classNames(
                                                          active
                                                            ? "bg-gray-200 text-gray-900"
                                                            : "text-gray-700",
                                                          "px-4 py-2 text-sm w-full text-left group flex items-center"
                                                        )}
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 24 24"
                                                          fill="currentColor"
                                                          className="mr-1 h-5 w-5 dark:fill-white  fill-gray-600"
                                                        >
                                                          <path
                                                            fillRule="evenodd"
                                                            d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
                                                            clipRule="evenodd"
                                                          />
                                                        </svg>

                                                        <span className="dark:text-white">
                                                          {IsLoading
                                                            ? "Reenviando..."
                                                            : "Reenviar"}
                                                        </span>
                                                      </button>
                                                    )}
                                                  </Menu.Item>
                                                ) : (
                                                  <></>
                                                ))}

                                              {obj.idEstadoDocumentoElectronico ===
                                                "1777aa9d-fa14-49bd-b9f0-ece6d2952756" &&
                                                (permisosInEmpresa.find(
                                                  (el) =>
                                                    el.rolId ===
                                                    "80dd2f96-00f0-4a19-aa3d-01ed687a2970"
                                                ) ||
                                                  isAdmin) && (
                                                  <Menu.Item>
                                                    {({ active }) => (
                                                      <button
                                                        onClick={() =>
                                                          handleAnularFactura(
                                                            obj
                                                          )
                                                        }
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#anularFacturaModal"
                                                        className={classNames(
                                                          active
                                                            ? "bg-gray-200 text-gray-900 "
                                                            : "text-gray-700 ",
                                                          " px-4 py-2 text-sm w-full text-left group flex items-center  "
                                                        )}
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          fill="none"
                                                          viewBox="0 0 24 24"
                                                          strokeWidth={2}
                                                          stroke="currentColor"
                                                          className="mr-1 h-5 w-5 dark:fill-white  fill-gray-600"
                                                        >
                                                          <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                                                          />
                                                        </svg>
                                                        <span className="dark:text-white">
                                                          Anular
                                                        </span>
                                                      </button>
                                                    )}
                                                  </Menu.Item>
                                                )}
                                              {permisosInEmpresa.find(
                                                (el) =>
                                                  el.codigo === "60" &&
                                                  obj.idEstadoDocumentoElectronico.toUpperCase() ===
                                                  "1777AA9D-FA14-49BD-B9F0-ECE6D2952756"
                                              ) &&
                                                obj.codigoTipoDocumento ===
                                                "01" && (
                                                  <Menu.Item>
                                                    {({ active }) => (
                                                      <button
                                                        aria-label="PuntoVenta"
                                                        title="PuntoVenta"
                                                        onClick={() =>
                                                          descargarPdfPV(
                                                            obj.idEmpresa,
                                                            obj.idDocumentoElectronico
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        className={classNames(
                                                          active
                                                            ? "bg-gray-200 text-gray-900"
                                                            : "text-gray-700",
                                                          " px-4 py-2 text-sm w-full text-left group flex items-center "
                                                        )}
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          className="mr-1 h-5 w-5 dark:fill-white"
                                                          width="24"
                                                          height="24"
                                                          viewBox="0 0 24 24"
                                                          strokeWidth="2"
                                                          stroke="currentColor"
                                                          fill="none"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        >
                                                          <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                            fill="none"
                                                          ></path>
                                                          <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2"></path>
                                                          <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4"></path>
                                                          <path d="M7 13m0 2a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2z"></path>
                                                        </svg>

                                                        <span className="dark:text-white">
                                                          Imprimir Ticket
                                                        </span>
                                                      </button>
                                                    )}
                                                  </Menu.Item>
                                                )}

                                              {obj.idEstadoDocumentoElectronico ===
                                                "4d10c970-5024-491d-ae6e-499a54e33b9e" &&
                                                (permisosInEmpresa.find(
                                                  (el) =>
                                                    el.rolId ===
                                                    "80dd2f96-00f0-4a19-aa3d-01ed687a2970"
                                                ) ||
                                                  isAdmin) && (
                                                  <Menu.Item>
                                                    {({ active }) => (
                                                      <button
                                                        onClick={() =>
                                                          handleAutorizarFactura(
                                                            obj
                                                          )
                                                        }
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#AutorizarFacturaModal"
                                                        className={classNames(
                                                          active
                                                            ? "bg-gray-200 text-gray-900"
                                                            : "text-gray-700",
                                                          " px-4 py-2 text-sm w-full text-left group flex items-center "
                                                        )}
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          fill="none"
                                                          viewBox="0 0 24 24"
                                                          strokeWidth="1.5"
                                                          stroke="currentColor"
                                                          className="mr-1 w-5 h-5 dark:fill-white  fill-gray-600"
                                                        >
                                                          <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                          />
                                                        </svg>
                                                        <span className="dark:text-white dark:hover:text-black">
                                                          Autorizar
                                                        </span>
                                                      </button>
                                                    )}
                                                  </Menu.Item>
                                                )}
                                            </div>
                                          </Menu.Items>
                                        </Transition>
                                      </Menu>
                                    </div>
                                  </div>
                                </div>
                                <span></span>
                                <span className="hidden sm:inline"> </span>
                                <span></span>
                              </div>
                            </td>
                            <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm  lg:table-cell hidden">
                              {(() => {
                                switch (obj.codigoTipoDocumento) {
                                  case "01":
                                    return "FA";

                                  case "07":
                                    return "RE";

                                  case "04":
                                    return "NC";

                                  case "05":
                                    return "ND";

                                  case "06":
                                    return "GR";

                                  case "03":
                                    return "LC";

                                  default:
                                    return "N/A";
                                }
                              })()}
                            </td>

                            <td className="hidden px-3 py-2 text-sm  lg:table-cell ">
                              {/* {obj.claveAcceso} */}
                              {rellenarCeros(obj.establecimiento)}-
                              {rellenarCeros(obj.puntoEmision)}-
                              {rellenarCeros(obj.secuencial, 9)}
                            </td>
                            <td className="hidden px-3 py-3.5 text-sm lg:table-cell">
                              {obj.fechaAutorizacion === null ? "" : moment(obj.fechaAutorizacion).format("DD-MM-YYYY")}
                            </td>
                            <td className="hidden px-3 py-3.5 text-sm lg:table-cell">
                              {moment(obj.fechaEmision).format("DD-MM-YYYY")}
                            </td>
                            <td className="hidden px-3 py-3.5 text-sm  lg:table-cell">
                              <NumberFormat
                                value={obj.importeTotal}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </td>
                            <td className="relative py-4 text-sm lg:table-cell hidden truncate text-ellipsis  px-2  w-[300px]  max-w-[300px]">
                              {obj.idContribuyenteNavigation.razonSocial == null
                                ? "NONE"
                                : obj.idContribuyenteNavigation.razonSocial.toUpperCase()}
                            </td>

                            <td className=" py-3.5 text-sm flex items-center justify-center text-center lg:table-cell hidden">
                              <EstadoTable
                                nombre={
                                  obj.idEstadoDocumentoElectronicoNavigation
                                    .nombre
                                }
                                codigo={
                                  obj.idEstadoDocumentoElectronicoNavigation
                                    .codigo
                                }
                              />
                            </td>
                            <td className="hidden px-3 py-3.5 text-sm  ">
                              <div className="flex items-center space-x-4 text-sm">
                                <button
                                  onClick={() =>
                                    setInfoTrazabilidad(obj.trazabilidad)
                                  }
                                  className="flex  dark:hover:bg-gray-700 hover:bg-gray-200  rounded-lg p-2 items-center justify-between px-2   text-sm font-medium leading-5  dark:text-gray-400 focus:shadow-outline-gray focus:outline-none focus:shadow-outline-grayduration-150 ease-in-out"
                                  data-bs-toggle="modal"
                                  data-bs-target="#trazabilidadModal"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    className="h-4 w-4 dark:fill-white  fill-gray-600"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                  >
                                    <path d="M565.6 36.24C572.1 40.72 576 48.11 576 56V392C576 401.1 569.8 410.9 560.5 414.4L392.5 478.4C387.4 480.4 381.7 480.5 376.4 478.8L192.5 417.5L32.54 478.4C25.17 481.2 16.88 480.2 10.38 475.8C3.882 471.3 0 463.9 0 456V120C0 110 6.15 101.1 15.46 97.57L183.5 33.57C188.6 31.6 194.3 31.48 199.6 33.23L383.5 94.52L543.5 33.57C550.8 30.76 559.1 31.76 565.6 36.24H565.6zM48 421.2L168 375.5V90.83L48 136.5V421.2zM360 137.3L216 89.3V374.7L360 422.7V137.3zM408 421.2L528 375.5V90.83L408 136.5V421.2z" />
                                  </svg>
                                </button>
                              </div>
                            </td>
                            <td className="hidden px-3 py-3.5 text-sm  ">
                              <div className="flex items-center space-x-4 text-sm">
                                <button
                                  className="flex items-center justify-between px-2 hover:bg-gray-200  dark:hover:bg-gray-700  rounded-lg p-2  text-sm font-medium leading-5  dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
                                  aria-label="Edit"
                                  data-bs-toggle="tooltip"
                                  title="Descargar Xml"
                                  onClick={() =>
                                    descargarXml(
                                      obj.idEmpresa,
                                      obj.idDocumentoElectronico
                                    )
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4 dark:fill-white fill-gray-600 "
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    viewBox="0 0 384 512"
                                  >
                                    <path d="M162.1 257.8c-7.812-7.812-20.47-7.812-28.28 0l-48 48c-7.812 7.812-7.812 20.5 0 28.31l48 48C137.8 386.1 142.9 388 148 388s10.23-1.938 14.14-5.844c7.812-7.812 7.812-20.5 0-28.31L128.3 320l33.86-33.84C169.1 278.3 169.1 265.7 162.1 257.8zM365.3 93.38l-74.63-74.64C278.6 6.742 262.3 0 245.4 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.34 28.65 64 64 64H320c35.2 0 64-28.8 64-64V138.6C384 121.7 377.3 105.4 365.3 93.38zM336 448c0 8.836-7.164 16-16 16H64.02c-8.838 0-16-7.164-16-16L48 64.13c0-8.836 7.164-16 16-16h160L224 128c0 17.67 14.33 32 32 32h79.1V448zM221.9 257.8c-7.812 7.812-7.812 20.5 0 28.31L255.7 320l-33.86 33.84c-7.812 7.812-7.812 20.5 0 28.31C225.8 386.1 230.9 388 236 388s10.23-1.938 14.14-5.844l48-48c7.812-7.812 7.812-20.5 0-28.31l-48-48C242.3 250 229.7 250 221.9 257.8z" />
                                  </svg>
                                </button>
                                {/*De aqui se descargo el svg del pdf https://www.svgrepo.com/svg/66745/pdf*/}
                                <button
                                  className="flex items-center dark:hover:bg-gray-700 hover:bg-gray-200   rounded-lg p-2 justify-between px-2   text-sm font-medium leading-5  dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
                                  aria-label="Delete"
                                  data-bs-toggle="tooltip"
                                  title="Descargar Pdf"
                                  onClick={() =>
                                    descargarPdf(
                                      obj.idEmpresa,
                                      obj.idDocumentoElectronico
                                    )
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                    className="h-4 w-4 dark:fill-white fill-gray-600"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                  >
                                    <path d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z" />
                                  </svg>
                                </button>
                              </div>
                            </td>
                            <td className="hidden px-3 py-3.5 text-sm  ">
                              <div className="flex">
                                <button
                                  className="flex items-center justify-between px-2 dark:hover:bg-gray-700  hover:bg-gray-200  rounded-lg p-2  text-sm font-medium leading-5  dark:text-gray-400 focus:outline-none focus:shadow-outline-gray focus:shadow-outline-grayduration-150 ease-in-out"
                                  data-bs-toggle="modal"
                                  data-bs-target="#editCorreosModal"
                                  aria-label="Delete"
                                  title="Correo"
                                  onClick={() => VerCorreos(obj)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    className="h-4 w-4 dark:fill-white fill-gray-600 "
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                  >
                                    <path d="M0 128C0 92.65 28.65 64 64 64H448C483.3 64 512 92.65 512 128V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V128zM48 128V150.1L220.5 291.7C241.1 308.7 270.9 308.7 291.5 291.7L464 150.1V127.1C464 119.2 456.8 111.1 448 111.1H64C55.16 111.1 48 119.2 48 127.1L48 128zM48 212.2V384C48 392.8 55.16 400 64 400H448C456.8 400 464 392.8 464 384V212.2L322 328.8C283.6 360.3 228.4 360.3 189.1 328.8L48 212.2z" />
                                  </svg>
                                </button>
                              </div>
                            </td>
                            <td className="  w-32   py-3.5 text-sm lg:table-cell md:table-cell hidden">
                              <div className="flex  flex-row flew-wrap justify-center items-center ">
                                <div className="    flew-wrap justify-center items-center flex-row lg:flex md:flex hidden">
                                  {(() => {
                                    switch (obj.enviado) {
                                      case true:
                                        return (
                                          <div className="group relative w-12 ">
                                            <span className="inline-flex items-center rounded-md p-1 text-sm font-medium">
                                              <img
                                                src={enviado}
                                                width={35}
                                                alt="enviado"
                                              />
                                            </span>
                                            <div className="absolute mx-auto w-24 -top-8 -left-8 hidden dark:bg-white dark:text-black rounded-md bg-gray-800 group-hover:flex text-center p-1">
                                              <p className="text-white dark:text-black mx-auto text-sm">
                                                Enviado
                                              </p>
                                            </div>
                                          </div>
                                        );
                                      case false:
                                        return (
                                          <div className="group relative w-12  ">
                                            <span className="inline-flex items-center rounded-md p-1 text-sm font-medium">
                                              <img
                                                src={no_enviado}
                                                alt="no enviado"
                                                width={35}
                                              />
                                            </span>
                                            <div className="absolute mx-auto w-24 -top-8 -left-8 hidden dark:bg-white dark:text-black rounded-md bg-gray-800 group-hover:flex text-center p-1">
                                              <p className="text-white dark:text-black mx-auto text-sm">
                                                No enviado
                                              </p>
                                            </div>
                                          </div>
                                        );
                                      case null:
                                        return (
                                          <div className="group relative w-12 ">
                                            <span className="inline-flex items-center rounded-md p-1 text-sm font-medium">
                                              <img
                                                src={sin_correo}
                                                alt="no enviado"
                                                width={32}
                                              />
                                            </span>
                                            <div className="absolute mx-auto w-24  -top-8 -left-8 hidden dark:bg-white dark:text-black     rounded-md bg-gray-800 group-hover:flex text-center p-1">
                                              <p className="text-white dark:text-black mx-auto text-sm">
                                                Sin correo
                                              </p>
                                            </div>
                                          </div>
                                        );

                                      default:
                                        break;
                                    }
                                  })()}
                                  <div className="group relative ">
                                    <button
                                      onClick={() =>
                                        setInfoTrazabilidad(obj.trazabilidad)
                                      }
                                      data-bs-toggle="modal"
                                      data-bs-target="#trazabilidadModal"
                                      tabIndex="-1"
                                      className="inline-flex p-1 cursor-pointer items-center justify-center rounded-md hover:shadow-md transition"
                                    >
                                      {/* <img src={logoBuscar} alt='Search icon' width={25} /> */}

                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 25 21"
                                        strokeWidth="2"
                                        stroke="var(--color,#727272 )"
                                        class="w-6 h-6"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                        />
                                      </svg>
                                    </button>
                                    <div className="absolute mx-auto w-24  -top-8 -left-8 hidden dark:bg-white dark:text-black rounded-md bg-gray-800 group-hover:flex text-center p-1">
                                      <p className="text-white dark:text-black mx-auto text-sm">
                                        Trazabilidad
                                      </p>
                                    </div>
                                  </div>
                                  <div className="group relative ">
                                    <button
                                      onClick={() =>
                                        descargarPdf(
                                          obj.idEmpresa,
                                          obj.idDocumentoElectronico
                                        )
                                      }
                                      //disabled={IsLoading} /* esto permite descargar uno a la vez */
                                      className="inline-flex p-1  w-12 cursor-pointer items-center justify-center rounded-md hover:shadow-md transition "
                                    >
                                      <img
                                        src={logoPdf}
                                        width={25}
                                        alt="descargar pdf"
                                      />
                                    </button>
                                    <div className="absolute mx-auto w-32  -top-8 -left-8 hidden dark:bg-white dark:text-black rounded-md bg-gray-800 group-hover:flex text-center p-1">
                                      <p className="text-white dark:text-black mx-auto text-sm">
                                        {IsLoading
                                          ? "Descargando pdf..."
                                          : "Descargar pdf"}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <Menu
                                  as="div"
                                  className="relative inline-block text-left"
                                >
                                  <div>
                                    <Menu.Button className="inline-flex w-full justify-center self-center rounded-md border border-gray-300  dark:border-gray-600 bg-white px-2 py-1.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 dark:bg-gray-700 dark:text-gray-400">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className=" h-5 w-5 fill-gray-600"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                        ></path>
                                      </svg>
                                    </Menu.Button>
                                  </div>

                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute  right-10 z-10 -mt-20  w-56 origin-top-right rounded-md dark:bg-gray-700  dark:text-gray-400 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <div className="py-1">
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              onClick={() =>
                                                descargarXml(
                                                  obj.idEmpresa,
                                                  obj.idDocumentoElectronico
                                                )
                                              }
                                              disabled={IsLoading}
                                              className={classNames(
                                                active
                                                  ? "bg-gray-200 text-gray-900"
                                                  : "text-gray-700",
                                                " px-4 py-2 text-sm w-full text-left group dark:text-gray-400 flex items-center "
                                              )}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="mr-1 h-5 w-5 dark:fill-white  fill-gray-600"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                viewBox="0 0 384 512"
                                              >
                                                <path d="M162.1 257.8c-7.812-7.812-20.47-7.812-28.28 0l-48 48c-7.812 7.812-7.812 20.5 0 28.31l48 48C137.8 386.1 142.9 388 148 388s10.23-1.938 14.14-5.844c7.812-7.812 7.812-20.5 0-28.31L128.3 320l33.86-33.84C169.1 278.3 169.1 265.7 162.1 257.8zM365.3 93.38l-74.63-74.64C278.6 6.742 262.3 0 245.4 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.34 28.65 64 64 64H320c35.2 0 64-28.8 64-64V138.6C384 121.7 377.3 105.4 365.3 93.38zM336 448c0 8.836-7.164 16-16 16H64.02c-8.838 0-16-7.164-16-16L48 64.13c0-8.836 7.164-16 16-16h160L224 128c0 17.67 14.33 32 32 32h79.1V448zM221.9 257.8c-7.812 7.812-7.812 20.5 0 28.31L255.7 320l-33.86 33.84c-7.812 7.812-7.812 20.5 0 28.31C225.8 386.1 230.9 388 236 388s10.23-1.938 14.14-5.844l48-48c7.812-7.812 7.812-20.5 0-28.31l-48-48C242.3 250 229.7 250 221.9 257.8z" />
                                              </svg>
                                              {IsLoading
                                                ? "Descargando..."
                                                : "Descargar Xml"}
                                            </button>
                                          )}
                                        </Menu.Item>

                                        {obj
                                          .idEstadoDocumentoElectronicoNavigation
                                          .codigo === "1" ? (
                                          <Menu.Item>
                                            {({ active }) => (
                                              <button
                                                data-bs-toggle="modal"
                                                data-bs-target="#editCorreosModal"
                                                aria-label="Delete"
                                                title="Correo"
                                                onClick={() => VerCorreos(obj)}
                                                style={{ cursor: "pointer" }}
                                                className={classNames(
                                                  active
                                                    ? "bg-gray-200 text-gray-900"
                                                    : "text-gray-700",
                                                  " px-4 py-2 text-sm w-full text-left group flex items-center "
                                                )}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 512 512"
                                                  className="mr-1 h-5 w-5 dark:fill-white  fill-gray-600"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeWidth="2"
                                                >
                                                  <path d="M0 128C0 92.65 28.65 64 64 64H448C483.3 64 512 92.65 512 128V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V128zM48 128V150.1L220.5 291.7C241.1 308.7 270.9 308.7 291.5 291.7L464 150.1V127.1C464 119.2 456.8 111.1 448 111.1H64C55.16 111.1 48 119.2 48 127.1L48 128zM48 212.2V384C48 392.8 55.16 400 64 400H448C456.8 400 464 392.8 464 384V212.2L322 328.8C283.6 360.3 228.4 360.3 189.1 328.8L48 212.2z" />
                                                </svg>
                                                <span className="dark:text-white">
                                                  Correo
                                                </span>
                                              </button>
                                            )}
                                          </Menu.Item>
                                        ) : (
                                          <></>
                                        )}
                                        {obj
                                          .idEstadoDocumentoElectronicoNavigation
                                          .codigo === "2" ||
                                          obj.idEstadoDocumentoElectronicoNavigation
                                            .codigo === "5" ||
                                          obj.idEstadoDocumentoElectronicoNavigation
                                            .codigo === "9" ||
                                          obj.idEstadoDocumentoElectronicoNavigation
                                            .codigo === "7" ? (
                                          <Menu.Item disabled={IsLoading}>
                                            {({ active }) => (
                                              <button
                                                aria-label="Delete"
                                                title="Reprocesar"
                                                onClick={() =>
                                                  handleReprocesarComprobante(
                                                    obj.idDocumento,
                                                    obj.idEmpresa,
                                                    obj.claveAcceso,
                                                    obj.idDocumentoElectronico,
                                                    obj.url,
                                                    obj.idContribuyenteNavigation
                                                      .razonSocial
                                                  )
                                                }
                                                style={{ cursor: "pointer" }}
                                                className={classNames(
                                                  active
                                                    ? "bg-gray-200 text-gray-900"
                                                    : "text-gray-700",
                                                  "px-4 py-2 text-sm w-full text-left group flex items-center"
                                                )}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                  fill="currentColor"
                                                  className="mr-1 h-5 w-5 dark:fill-white  fill-gray-600"
                                                >
                                                  <path
                                                    fillRule="evenodd"
                                                    d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
                                                    clipRule="evenodd"
                                                  />
                                                </svg>

                                                <span className="dark:text-white">
                                                  {IsLoading
                                                    ? "Reenviando..."
                                                    : "Reenviar"}
                                                </span>
                                              </button>
                                            )}
                                          </Menu.Item>
                                        ) : (
                                          <></>
                                        )}

                                        {obj
                                          .idEstadoDocumentoElectronicoNavigation
                                          .codigo === "0" &&
                                          (hanPasado20Minutos ? (
                                            <Menu.Item disabled={IsLoading}>
                                              {({ active }) => (
                                                <button
                                                  aria-label="Delete"
                                                  title="Reprocesar"
                                                  onClick={() =>
                                                    handleReprocesarComprobante(
                                                      obj.idDocumento,
                                                      obj.idEmpresa,
                                                      obj.claveAcceso,
                                                      obj.idDocumentoElectronico,
                                                      obj.url,
                                                      obj
                                                        .idContribuyenteNavigation
                                                        .razonSocial
                                                    )
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                  className={classNames(
                                                    active
                                                      ? "bg-gray-200 text-gray-900"
                                                      : "text-gray-700",
                                                    "px-4 py-2 text-sm w-full text-left group flex items-center"
                                                  )}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    fill="currentColor"
                                                    className="mr-1 h-5 w-5 dark:fill-white  fill-gray-600"
                                                  >
                                                    <path
                                                      fillRule="evenodd"
                                                      d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
                                                      clipRule="evenodd"
                                                    />
                                                  </svg>

                                                  <span className="dark:text-white">
                                                    {IsLoading
                                                      ? "Reenviando..."
                                                      : "Reenviar"}
                                                  </span>
                                                </button>
                                              )}
                                            </Menu.Item>
                                          ) : (
                                            <></>
                                          ))}

                                        {obj
                                          .idEstadoDocumentoElectronicoNavigation
                                          .codigo === "4" &&
                                          (hanPasado20Minutos ? (
                                            <Menu.Item disabled={IsLoading}>
                                              {({ active }) => (
                                                <button
                                                  aria-label="Delete"
                                                  title="Reprocesar"
                                                  onClick={() =>
                                                    handleReprocesarComprobante(
                                                      obj.idDocumento,
                                                      obj.idEmpresa,
                                                      obj.claveAcceso,
                                                      obj.idDocumentoElectronico,
                                                      obj.url,
                                                      obj
                                                        .idContribuyenteNavigation
                                                        .razonSocial
                                                    )
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                  className={classNames(
                                                    active
                                                      ? "bg-gray-200 text-gray-900"
                                                      : "text-gray-700",
                                                    "px-4 py-2 text-sm w-full text-left group flex items-center"
                                                  )}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    fill="currentColor"
                                                    className="mr-1 h-5 w-5 dark:fill-white  fill-gray-600"
                                                  >
                                                    <path
                                                      fillRule="evenodd"
                                                      d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
                                                      clipRule="evenodd"
                                                    />
                                                  </svg>

                                                  <span className="dark:text-white">
                                                    {IsLoading
                                                      ? "Reenviando..."
                                                      : "Reenviar"}
                                                  </span>
                                                </button>
                                              )}
                                            </Menu.Item>
                                          ) : (
                                            <></>
                                          ))}

                                        {obj
                                          .idEstadoDocumentoElectronicoNavigation
                                          .codigo === "11" &&
                                          (hanPasado20Minutos ? (
                                            <Menu.Item disabled={IsLoading}>
                                              {({ active }) => (
                                                <button
                                                  aria-label="Delete"
                                                  title="Reprocesar"
                                                  onClick={() =>
                                                    handleReprocesarComprobante(
                                                      obj.idDocumento,
                                                      obj.idEmpresa,
                                                      obj.claveAcceso,
                                                      obj.idDocumentoElectronico,
                                                      obj.url,
                                                      obj
                                                        .idContribuyenteNavigation
                                                        .razonSocial
                                                    )
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                  className={classNames(
                                                    active
                                                      ? "bg-gray-200 text-gray-900"
                                                      : "text-gray-700",
                                                    "px-4 py-2 text-sm w-full text-left group flex items-center"
                                                  )}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    fill="currentColor"
                                                    className="mr-1 h-5 w-5 dark:fill-white  fill-gray-600"
                                                  >
                                                    <path
                                                      fillRule="evenodd"
                                                      d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
                                                      clipRule="evenodd"
                                                    />
                                                  </svg>

                                                  <span className="dark:text-white">
                                                    {IsLoading
                                                      ? "Reenviando..."
                                                      : "Reenviar"}
                                                  </span>
                                                </button>
                                              )}
                                            </Menu.Item>
                                          ) : (
                                            <></>
                                          ))}

                                        {obj.idEstadoDocumentoElectronico ===
                                          "1777aa9d-fa14-49bd-b9f0-ece6d2952756" &&
                                          (permisosInEmpresa.find(
                                            (el) =>
                                              el.rolId ===
                                              "80dd2f96-00f0-4a19-aa3d-01ed687a2970"
                                          ) ||
                                            isAdmin) && (
                                            <Menu.Item>
                                              {({ active }) => (
                                                <button
                                                  onClick={() =>
                                                    handleAnularFactura(obj)
                                                  }
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#anularFacturaModal"
                                                  className={classNames(
                                                    active
                                                      ? "bg-gray-200 text-gray-900 "
                                                      : "text-gray-700 ",
                                                    " px-4 py-2 text-sm w-full text-left group flex items-center  "
                                                  )}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={2}
                                                    stroke="currentColor"
                                                    className="mr-1 h-5 w-5 dark:fill-white  fill-gray-600"
                                                  >
                                                    <path
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                                                    />
                                                  </svg>
                                                  <span className="dark:text-white">
                                                    Anular
                                                  </span>
                                                </button>
                                              )}
                                            </Menu.Item>
                                          )}
                                        {permisosInEmpresa.find(
                                          (el) =>
                                            el.codigo === "60" &&
                                            obj.idEstadoDocumentoElectronico.toUpperCase() ===
                                            "1777AA9D-FA14-49BD-B9F0-ECE6D2952756"
                                        ) &&
                                          obj.codigoTipoDocumento === "01" && (
                                            <Menu.Item>
                                              {({ active }) => (
                                                <button
                                                  aria-label="PuntoVenta"
                                                  title="PuntoVenta"
                                                  onClick={() =>
                                                    descargarPdfPV(
                                                      obj.idEmpresa,
                                                      obj.idDocumentoElectronico
                                                    )
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                  className={classNames(
                                                    active
                                                      ? "bg-gray-200 text-gray-900"
                                                      : "text-gray-700",
                                                    " px-4 py-2 text-sm w-full text-left group flex items-center "
                                                  )}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="mr-1 h-5 w-5 dark:fill-white"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                  >
                                                    <path
                                                      stroke="none"
                                                      d="M0 0h24v24H0z"
                                                      fill="none"
                                                    ></path>
                                                    <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2"></path>
                                                    <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4"></path>
                                                    <path d="M7 13m0 2a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2z"></path>
                                                  </svg>

                                                  <span className="dark:text-white">
                                                    Imprimir Ticket
                                                  </span>
                                                </button>
                                              )}
                                            </Menu.Item>
                                          )}

                                        {obj.idEstadoDocumentoElectronico ===
                                          "4d10c970-5024-491d-ae6e-499a54e33b9e" &&
                                          (permisosInEmpresa.find(
                                            (el) =>
                                              el.rolId ===
                                              "80dd2f96-00f0-4a19-aa3d-01ed687a2970"
                                          ) ||
                                            isAdmin) && (
                                            <Menu.Item>
                                              {({ active }) => (
                                                <button
                                                  onClick={() =>
                                                    handleAutorizarFactura(obj)
                                                  }
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#AutorizarFacturaModal"
                                                  className={classNames(
                                                    active
                                                      ? "bg-gray-200 text-gray-900"
                                                      : "text-gray-700",
                                                    " px-4 py-2 text-sm w-full text-left group flex items-center "
                                                  )}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="mr-1 w-5 h-5 dark:fill-white  fill-gray-600"
                                                  >
                                                    <path
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                    />
                                                  </svg>
                                                  <span className="dark:text-white dark:hover:text-black">
                                                    Autorizar
                                                  </span>
                                                </button>
                                              )}
                                            </Menu.Item>
                                          )}
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            </td>

                            <td className="">
                              <div className="flex items-center justify-center">
                                {isOver72Hours && (
                                  <svg
                                    onClick={toggleModal}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                                    />
                                  </svg>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="bg-white dark:bg-gray-700 dark:text-gray-400 px-4 py-3 flex items-center justify-between border-t border-gray-200 dark:border-gray-700 sm:px-6">
                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                      <span className="text-sm font-normal ml-2 text-gray-400 dark:text-gray-400">
                        Mostrando
                        <span className="font-semibold text-gray-900 dark:text-white">
                          {"\n"}
                          {(Paginacion._paginaActual - 1) *
                            Paginacion._totalEnPagina >
                            1
                            ? (Paginacion._paginaActual - 1) *
                            Paginacion._totalEnPagina
                            : 1}
                          {"\n"} - {"\n"}
                          {Paginacion._paginaActual === Paginacion._totalpaginas
                            ? Paginacion._totalelementos
                            : Paginacion._totalEnPagina *
                            Paginacion._paginaActual}{" "}
                          {"\n"}{" "}
                        </span>
                        de
                        <span className="font-semibold text-gray-900 dark:text-white">
                          {" "}
                          {Paginacion._totalelementos}{" "}
                        </span>{" "}
                        Registros
                      </span>
                      <div>
                        <nav
                          className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                          aria-label="Pagination"
                        >
                          {Paginacion.pagina !== 1 ? (
                            <>
                              <button
                                onClick={handleClickFirstPage}
                                type="button"
                                className="relative inline-flex items-center dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50"
                                disabled=""
                              >
                                <span className="sr-only">First</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                              <button
                                onClick={handleClickBeforePage}
                                type="button"
                                className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                                disabled=""
                              >
                                <span className="sr-only">Previous</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5 "
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="relative inline-flex items-center dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600 px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50"
                                disabled
                              >
                                <span className="sr-only">First</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                              <button
                                type="button"
                                className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                                disabled
                              >
                                <span className="sr-only">Previous</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                            </>
                          )}

                          {Paginacion.pagina <= Paginacion._totalpaginas - 1 ? (
                            <>
                              <button
                                onClick={handleClickNextPage}
                                type="button"
                                className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                              >
                                <span className="sr-only">Next</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                              <button
                                onClick={handleClickLastPage}
                                type="button"
                                className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50  dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                                disabled=""
                              >
                                <span className="sr-only">Last</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                  <path
                                    fillRule="evenodd"
                                    d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                disabled
                                className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50  dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                              >
                                <span className="sr-only">Next</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                              <button
                                type="button"
                                className="relative inline-flex items-center px-2 py-2 rounded-r-md border dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  border-gray-300 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50"
                                disabled
                              >
                                <span className="sr-only">Last</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                  <path
                                    fillRule="evenodd"
                                    d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                            </>
                          )}
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListaFacturas;
