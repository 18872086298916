import React, { useContext } from "react";
import MesageError from "../../../features/components/MessageError";
import { ReporteServicioContext } from "./ReporteServicioProvider";
import {
  StyleSelect,
  StyleLabel,
  StyleInput,
  ButtonStyleDefault,
} from "../../../features/Constantes";
import { SectionFilter } from "../../../features/components/SectionFilter";
import { ButtonDefault } from "../../../features/components/ButtonDefault";
const FormReporteServicio = () => {
  const {
    formik,
    cargandoData,
    setActivarModalAdd,
    setPaginacion,
    initialStatePaginacion,
    setStart,
  } = useContext(ReporteServicioContext);

  const handleClickAdd = () => {
    setActivarModalAdd(true);
  };

  const handleChangeText = (e, length) => {
    if (e.target.value.length > length) {
      return e.target.value;
    }
    formik.handleChange(e);
  };

  return (
    <>
      <h2 className="my-2 py-2 pl-4 text-2xl font-semibold text-gray-700 dark:text-gray-200">
        Reporte de productos / servicios
      </h2>
      <hr className="py-2 mx-4 border-1 dark:border-gray-600 " />

      <form
        onSubmit={(e) => {
          setStart(0);
          setPaginacion(initialStatePaginacion);
          formik.handleSubmit(e);
        }}
      >
        <>
          <SectionFilter title={"Busqueda"}>
            <div className=" p-4 grid md:grid-cols-5 md:gap-4 gap-2 bg-[#DBDBDB]  dark:bg-gray-700">
              <div className="relative z-0  w-full group">
                <label
                  htmlFor="fechaInicio"
                  className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                >
                  Código/Descripción
                </label>
                <input
                  type="text"
                  className={StyleInput}
                  placeholder=" "
                  id="codigoPrincipal"
                  name="codigoPrincipal"
                  value={formik.values.codigoPrincipal}
                  onChange={(e) => handleChangeText(e, 60)}
                  onBlur={formik.handleBlur}
                />

                {formik.touched.codigoPrincipal &&
                formik.errors.codigoPrincipal ? (
                  <MesageError message={formik.errors.codigoPrincipal} />
                ) : null}
              </div>

              <div className="relative z-0  w-full group">
                <label
                  htmlFor="fechaInicio"
                  className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                >
                  Estado
                </label>
                <select
                  type="text"
                  name="estado"
                  id="estado"
                  className={StyleSelect}
                  placeholder=" "
                  defaultValue={formik.values.estado}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Todos</option>
                  <option value="True">Activo</option>
                  <option value="False">Inactivo</option>
                </select>
              </div>

              <div className="relative z-0  w-full group">
                <label
                  htmlFor="fechaInicio"
                  className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                >
                  Tipo
                </label>
                <select
                  type="text"
                  name="tipo"
                  id="tipo"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tipo}
                  className={StyleSelect}
                  placeholder=" "
                >
                  <option key="0" value="0">
                    TODOS
                  </option>
                  <option key="1" value="1">
                    Producto
                  </option>
                  <option key="2" value="2">
                    Servicio
                  </option>
                </select>
              </div>
              {/* <div className="relative z-0 mb-6 w-full group"></div> */}
            </div>

            <div className="px-3 mt-6 text-white flex-col space-y-2 ">
              <ButtonDefault
                type={"submit"}
                title={"Buscar"}
                loading={cargandoData}
                color={"primary"}
                svg={
                  !cargandoData ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="-ml-0.5 mr-2 h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                  ) : (
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline mr-3 w-4 h-4 animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  )
                }
              />

              <ButtonDefault
                type={"button"}
                color={"secondary"}
                onClick={handleClickAdd}
                title={"Añadir"}
                data-bs-toggle="modal"
                data-bs-target="#addProductoModal"
                svg={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="inline mr-2 -ml-1 w-5 h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                }
              />
            </div>
          </SectionFilter>
        </>
        <div className="mx-4 my-2 p-4 lg:grid md:grid md:grid-cols-5 hidden md:gap-4 gap-2 rounded-md bg-[#DBDBDB] dark:bg-gray-800 ">
          <div className="relative z-0  w-full group">
            <label
              htmlFor="fechaInicio"
              className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
            >
              Código/Descripción
            </label>
            <input
              type="text"
              className={StyleInput}
              placeholder=" "
              id="codigoPrincipal"
              name="codigoPrincipal"
              value={formik.values.codigoPrincipal}
              onChange={(e) => handleChangeText(e, 60)}
              onBlur={formik.handleBlur}
            />

            {formik.touched.codigoPrincipal && formik.errors.codigoPrincipal ? (
              <MesageError message={formik.errors.codigoPrincipal} />
            ) : null}
          </div>

          <div className="relative z-0  w-full group">
            <label
              htmlFor="fechaInicio"
              className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
            >
              Estado
            </label>
            <select
              type="text"
              name="estado"
              id="estado"
              className={StyleSelect}
              placeholder=" "
              defaultValue={formik.values.estado}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="">Todos</option>
              <option value="True">Activo</option>
              <option value="False">Inactivo</option>
            </select>
          </div>

          <div className="relative z-0  w-full group">
            <label
              htmlFor="fechaInicio"
              className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
            >
              Tipo
            </label>
            <select
              type="text"
              name="tipo"
              id="tipo"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.tipo}
              className={StyleSelect}
              placeholder=" "
            >
              <option key="0" value="0">
                TODOS
              </option>
              <option key="1" value="1">
                Producto
              </option>
              <option key="2" value="2">
                Servicio
              </option>
            </select>
          </div>
          {/* <div className="relative z-0 mb-6 w-full group"></div> */}
        </div>

        <div className="px-3 my-4 mx-2 text-white lg:flex md:flex hidden space-x-2 ">
          <ButtonDefault
            type={"submit"}
            title={"Buscar"}
            loading={cargandoData}
            color={"primary"}
            svg={
              !cargandoData ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="-ml-0.5 mr-2 h-5 w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              ) : (
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline mr-3 w-4 h-4 animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              )
            }
          />

          <ButtonDefault
            type={"button"}
            color={"secondary"}
            onClick={handleClickAdd}
            title={"Añadir"}
            data-bs-toggle="modal"
            data-bs-target="#addProductoModal"
            svg={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline mr-2 -ml-1 w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            }
          />
        </div>
      </form>

      {/* <hr className="border-1 dark:border-gray-800 " /> */}
    </>
  );
};

export default FormReporteServicio;
