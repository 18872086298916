import React from "react";

export const CardPlanes = (props) => {
  return (
    <div className="item">
      <section className="flex flex-col overflow-hidden border dark:border-gray-600 rounded-3xl   shadow-lg shadow-gray-900/5 bg-white">
        <div className="flex items-center justify-between w-full  text-lg font-semibold text-white">
          <div className=" flex bg-[#00314D] text-2xl dark:bg-gray-700 text-center justify-center w-full p-6 rounded-t-lg">
            {props.titulo}
          </div>
        </div>
        <div className="bg-[#B4E4FF] dark:bg-gray-700  dark:text-white font-semibold w-full p-2 text-center">
        {props.descripcion}
        </div>

        <div className="bg-[#00314D] dark:bg-gray-700 text-white flex justify-center space-x-2 gap-x-2 font-semibold w-full p-2 text-center">
          Contactar
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
            <path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1" />
          </svg>
        </div>

        <div className="text-center flex justify-center py-4 dark:bg-gray-700 dark:text-white  flex-col mx-auto     w-full">
          <p className="relative dark:bg-gray-700 dark:text-white   text-3xl font-medium tracking-tight text-gray-900">
            $ {props.precio}
          </p>
          <p className="relative  dark:bg-gray-700 dark:text-white  text-base tracking-tight text-gray-900">
            Precio incluye IVA
          </p>
        </div>

        <div className=" py-4 border-t-[3px] border-b-[3px] dark:bg-gray-700 dark:text-white border-t-gray-300 border-b-gray-300 px-2">
          <div className="flex justify-center font-semibold p-2 border-b border-b-gray-300">
            <p>Requisitos</p>
          </div>
          <ul className=" divide-y text-sm my-5 px-2 dark:bg-gray-700 dark:text-white  divide-gray-200 text-gray-700 h-44">
            {props.lista.map((item, index) => (
              <li key={index} className="flex py-2 items-center">
                <svg
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  className="h-6 w-6 flex-none text-cyan-500"
                >
                  <path
                    d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                    fill="currentColor"
                  ></path>
                  <circle
                    cx="12"
                    cy="12"
                    r="8.25"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></circle>
                </svg>
                <span className="ml-4">{item}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex justify-center dark:bg-gray-700 dark:text-white">
          <a
            href={props.link}
            target="_blank"
            rel="noopener noreferrer" //solo borrar en caso de fallo esta linea
            className="border px-9 py-2 my-4 w-auto rounded-lg bg-[#00314D] dark:bg-gray- dark:border-gray-700  font-semibold text-white"
          >
            Solicitar
          </a>
        </div>
      </section>
    </div>
  );
};
