import { PutElement } from "../../../../services";
import UserAgregadoIcon from "../AddContribuyente/Icons/userAgregado";
import UserErrorIcon from "../AddContribuyente/Icons/userErrorIcon";

export default function serviceEditContribuyente() {
  const getContribuyente = async (
    empresa,
    TotalValues,
    setMessageSubmit,
    setMessageDescripcionSubmit,
    setsvgSubmit
  ) => {
    try {

      setMessageSubmit("Editando contribuyente...");
      setMessageDescripcionSubmit("Por favor espere un momento.");
      await PutElement(empresa, "EditContribuyente", TotalValues);
      setMessageSubmit("Contribuyente Editado");
      setMessageDescripcionSubmit(
        "El contribuyente ha sido editado exitosamente."
      );
      setsvgSubmit(<UserAgregadoIcon />);
    } catch (ex) {
      setMessageSubmit("Ocurrio un error...");
      setMessageDescripcionSubmit(
        "No se ha podido editar el contribuyente, por favor intente nuevamente."
      );
      setsvgSubmit(<UserErrorIcon />);
      throw ex;
    }
  };

  return { getContribuyente };
}
