import { useDispatch } from "react-redux";
import { CancelIcon, SaveIcon } from "../../../Icons/ButtonIcons";
import { ButtonStyleDefault } from "../../../features/Constantes";

// Componente de botones: Cancelar y Guardar para los modales

export const ModalButtons = ({ closeFn, useRedux = false, isSending }) => {
  const dispatch = useDispatch();

  return (
    <div className="flex flex-row mt-2 h-fit gap-4 w-full justify-end items-center text-white">
      {/* <button
        type="button"
        className="bg-gray-600 p-5 h-8 text-[12px] hover:bg-gray-700 gap-2 flex items-center justify-center w-28 rounded-lg"
        onClick={useRedux ? () => dispatch(closeFn()) : () => closeFn()}
      >
        Cancelar
        <CancelIcon />
      </button> */}
      <button
        type="submit"
        disabled={isSending}
        className={
          ButtonStyleDefault +
          "bg-[#04BC53] flex gap-3 hover:bg-green-600 focus:ring-gray-500"
        }
      >
        Guardar
        <SaveIcon />
      </button>
    </div>
  );
};
