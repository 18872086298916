import React from "react";
import SubirRetenciones from "./SubirRetenciones";
import EmitirDocProvider from "../../EmisionElectronico/EmitirRetencion/EmitirDocProvider";

const SubirRetencionesGeneral = () => {
  return (
    <div >
        <EmitirDocProvider>
          <SubirRetenciones/>
        </EmitirDocProvider>
      </div>
  );
};

export default SubirRetencionesGeneral;
