import { CategoriaProvider } from "../../Categorias/context/CategoriaContext";
import { ProductoProvider } from "../context/ProductContext";
import { EditarProductoSection } from "./EditarProductoSection";

export const IndexEditarProducto = () => {
  return (
      <CategoriaProvider>
        <ProductoProvider>
          <EditarProductoSection />
        </ProductoProvider>
      </CategoriaProvider>
  );
};
