export const ValoracionInventarioItem = ({ producto, availableRows }) => {
  return (
    <tr className="bg-white text-center font-normal  border-b-2 ">
      <th
        style={{ padding: "15px" }}
        className="font-normal border-2 dark:bg-gray-700 dark:text-white dark:border-gray-600 border-gray-300"
      >
        {producto.codigo}
      </th>
      <th className="font-normal border-2 dark:bg-gray-700 dark:text-white dark:border-gray-600 border-gray-300">
        {producto.nombre}
      </th>
      {availableRows.some((row) => row.row === "Inventario Inicial") && (
        <>
          <th className="font-normal border-2 dark:bg-gray-700 dark:text-white dark:border-gray-600 border-gray-300">
            {producto.inventarioInicial.cantidad}
          </th>
          <th className="font-normal border-2 dark:bg-gray-700 dark:text-white dark:border-gray-600 border-gray-300">
            ${" "}
            {Number(producto.inventarioInicial.costoPromedio).toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
          </th>
          <th className="font-normal border-2 dark:bg-gray-700 dark:text-white dark:border-gray-600 border-gray-300">
            ${" "}
            {Number(producto.inventarioInicial.total).toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
          </th>
        </>
      )}
      {availableRows.some((row) => row.row === "Compras") && (
        <>
          <th className="font-normal border-2 dark:bg-gray-700 dark:text-white dark:border-gray-600 border-gray-300">
            {"N/A"}
          </th>
          <th className="font-normal border-2 dark:bg-gray-700 dark:text-white dark:border-gray-600 border-gray-300">
            {"N/A"}
          </th>
        </>
      )}
      <th className="font-normal border-2 dark:bg-gray-700 dark:text-white dark:border-gray-600 border-gray-300">
        ${" "}
        {Number(producto.costoPromMes).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </th>
      {availableRows.some((row) => row.row === "Devolución Compras") && (
        <>
          <th className="font-normal border-2 dark:bg-gray-700 dark:text-white dark:border-gray-600 border-gray-300">
            {"N/A"}
          </th>
          <th className="font-normal border-2 dark:bg-gray-700 dark:text-white dark:border-gray-600 border-gray-300">
            {"N/A"}
          </th>
        </>
      )}
      {availableRows.some((row) => row.row === "Ventas") && (
        <>
          <th className="font-normal border-2 dark:bg-gray-700 dark:text-white dark:border-gray-600 border-gray-300">
            {"N/A"}
          </th>
          <th className="font-normal border-2 dark:bg-gray-700 dark:text-white dark:border-gray-600 border-gray-300">
            {"N/A"}
          </th>
        </>
      )}
      {availableRows.some((row) => row.row === "Devolución Ventas") && (
        <>
          <th className="font-normal border-2 dark:bg-gray-700 dark:text-white dark:border-gray-600 border-gray-300">
            {"N/A"}
          </th>
          <th className="font-normal border-2 dark:bg-gray-700 dark:text-white dark:border-gray-600 border-gray-300">
            {"N/A"}
          </th>
        </>
      )}
      {availableRows.some((row) => row.row === "Ingresos de Inventario") && (
        <>
          <th className="font-normal border-2 dark:bg-gray-700 dark:text-white dark:border-gray-600 border-gray-300">
            {producto.ingresoInventario.cantidad}
          </th>
          <th className="font-normal border-2 dark:bg-gray-700 dark:text-white dark:border-gray-600 border-gray-300">
            ${" "}
            {Number(producto.ingresoInventario.total).toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
          </th>
        </>
      )}
      {availableRows.some((row) => row.row === "Egresos de Inventario") && (
        <>
          <th className="font-normal border-2 dark:bg-gray-700 dark:text-white dark:border-gray-600 border-gray-300">
            {producto.egresoInventario.cantidad}
          </th>
          <th className="font-normal border-2 dark:bg-gray-700 dark:text-white dark:border-gray-600 border-gray-300">
            ${" "}
            {Number(producto.egresoInventario.total).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </th>
        </>
      )}
      {availableRows.some((row) => row.row === "Inventario Final") && (
        <>
          <th className="font-normal border-2 dark:bg-gray-700 dark:text-white dark:border-gray-600 border-gray-300">
            {producto.inventarioFinal.cantidad}
          </th>
          <th
            style={{ padding: "15px" }}
            className="border-2 dark:border-gray-600 dark:bg-gray-700 dark:text-white border-gray-300 font-normal"
          >
            ${" "}
            {Number(producto.inventarioFinal.total).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </th>
        </>
      )}
    </tr>
  );
};
