import { FormProvider, useForm } from "react-hook-form";
import { InputContext } from "../../../../features/components/InputProvider/InputContext";
import { useEffect, useState } from "react";
import { MailsRegExp, UTF8RegExp } from "../../../../services/Utilitario";
import { SelectContext } from "../../../../features/components/SelectProvider/SelectContext";
import MailsForm from "../components/MailsForm";
import { useNavigate, useParams } from "react-router";
import { NumberFormatProvider } from "../../../../features/components/NumberFormatProvider/NumberFormatProvider";
import { StyleLabel, StyleSelect } from "../../../../features/Constantes";
import ModalSubmit from "../AddContribuyente/ModalSubmit";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import serviceEditContribuyente from "./serviceEditContribuyente";

export default function EditContribuyenteForm() {
  let { id } = useParams();

  const initialState = {
    identificacion: "",
    razonsocial: "",
    telefonocelular: "",
    telefonoconvencional: "",
    activo: true,
    direccion: "",
    correo: "",
    idTipoIdentificacion: "",
    IsTransportista: "0",
    placa: "",
    IsPartRel: "0",
    IdContribuyente: "",
  };
  const navigate = useNavigate();

  const [loadingForm, setLoadingForm] = useState(false);
  const [identificacionObs, setIdentificacionObs] = useState("");

  const [IsLoading, setIsLoading] = useState(false);
  const [messageSubmit, setMessageSubmit] = useState("");
  const [messageDescripcionSubmit, setMessageDescripcionSubmit] = useState("");
  const [svgSubmit, setsvgSubmit] = useState(null);
  const [tipoIdentificacion, setTipoIdentificacion] = useState("");
  const [DataFinal, setDataFinal] = useState({});
  const [OpenCorreo, setOpenCorreo] = useState(false);

  const methods = useForm({ defaultValues: initialState });
  const valueTransportista = methods.watch("IsTransportista");

  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const { getContribuyente } = serviceEditContribuyente();

  const handleSubmitModal = async (request) => {
    try {
      setIsLoading(true);
      toggleModal();
      setLoadingForm(true);
      request.IdContribuyente = id;
      request.identificacion = request.identificacion.trim();
      request.razonsocial = request.razonsocial.trim();
      request.correo = request.correo.trim();
      request.IsTransportista = request.IsTransportista === "1";
      request.IsPartRel = request.IsPartRel === "1";
      request.EsRuc = request.EsRuc === "1";
      request.activo = request.activo === "1";
      request.direccion = request.direccion.trim();
      if (request.EsCedula === "1") {
        request.idTipoIdentificacion = 1;
      }

      if (request.EsRuc === "1") {
        request.identificacion = request.identificacion + "001";
      }

      if (request.correo === "") {
        const emails = request.correo.split(";");
        for (let i = 0; i < emails.length; i++) {
          const email = emails[i].trim();
          if (!MailsRegExp.test(email)) {
            return toast.warning("El correo ingresado no es válido!", {
              isLoading: false,
              autoClose: 2000,
            });
          }
        }
      }
      delete request._links;
      delete request.EsCedula;
      delete request.EsRuc;
      if (request.identificacion === "") {
        toast.warning("El campo identificacion es obligatorio!", {
          isLoading: false,
          autoClose: 2000,
        });
        return;
      }
      if (request.razonsocial === "") {
        toast.warning("El campo razon social es obligatorio!", {
          isLoading: false,
          autoClose: 2000,
        });
        return;
      }

      await getContribuyente(
        empresa,
        request,
        setMessageSubmit,
        setMessageDescripcionSubmit,
        setsvgSubmit
      );
      setIsLoading(false);
      setLoadingForm(false);
      setTimeout(() => {
        navigate("/emision/reporte/contribuyentes");
      }, 1000);
    } catch (error) {
      setLoadingForm(false);
      setIsLoading(false);
      throw error;
    }
  };

  const handleEsRucChange = (e) => {
    const newValue = e.target.value;

    if (newValue === "1") {
      methods.setValue("idTipoIdentificacion", 1);
    } else {
      methods.setValue("idTipoIdentificacion", 2);
    }
  };

  const handleRucChange = (e) => {
    const newValue = e.target.value;
    if (newValue === "1") {
      methods.setValue("esRuc", 1);
    } else {
      methods.setValue("esRuc", false);
    }
  };
  const empresa = useSelector((store) => store.empresa.empresa);

  const loadUser = async () => {
    const { data } = await axios.get(
      `/api/Contribuyente/CargarContribuyente/${empresa.idEmpresa}/?IdContribuyente=${id}`
    );
    // console.log({ ...data });

    if (data.esRuc) data.identificacion += "001";
    data.IsTransportista = data.isTransportista ? "1" : "0";
    data.IsPartRel = data.isPartRel ? "1" : "0";
    data.EsRuc = data.esRuc ? "1" : "0";
    data.activo = data.activo ? "1" : "0";

    delete data.isTransportista;
    delete data.isPartRel;

    if (data === "") {
      setTipoIdentificacion("");
    } else if (data.esRuc) {
      setTipoIdentificacion("RUC");
    } else if (data.idTipoIdentificacion === 1) {
      setTipoIdentificacion("CEDULA");
    } else if (data.idTipoIdentificacion === 2) {
      setTipoIdentificacion("RUC");
    } else if (data.idTipoIdentificacion === 3) {
      setTipoIdentificacion("Pasaporte");
    } else if (data.idTipoIdentificacion === 5) {
      setTipoIdentificacion("Extranjero");
    } else {
      setTipoIdentificacion("");
    }
    return data;
  };

  useEffect(() => {
    (async () => {
      const data = await loadUser();
      // console.log(data);
      if (data.configuracion && Object.values(data.configuracion).some(value => value !== "" && value !== null)) {
        setOpenCorreo(true);
    }
    

      methods.reset({ ...data });
      setDataFinal(data);
    })();
  }, []);
  const StyleNormalSelect =
    "border-gray-300 focus:outline-none left-0 relative dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  focus:border-blue-500 focus:ring-blue-500 focus:ring-1 ring-0 w-full  border rounded-md h-[38px] pl-1 text-xs space-x-0";

  return (
    <>
      <ModalSubmit
        disabled={loadingForm}
        isOpen={isOpen}
        toggleModal={toggleModal}
        IsLoading={IsLoading}
        message={messageSubmit}
        messageDescription={messageDescripcionSubmit}
        svgSubmit={svgSubmit}
      />
      <div className="space-y-6 xl:mx-4 my-2 mt-5 bg-white dark:bg-gray-700 p-4">
        <div className="bg-[#DBDBDB] dark:bg-gray-800 dark:text-white p-5 rounded-md ">
          <p className="font-semibold text-xl dark:text-white  text-black">
            Información del Contribuyente / Cliente
          </p>
          <p className="dark:text-gray-400">Editar información</p>
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(handleSubmitModal)}
            className="bg-white shadow  dark:bg-gray-800 sm:rounded-lg md:py-2 md:px-4 py-5 px-2"
          >
            <p className="p-2 border-b text-lg text-black dark:text-gray-400 font-semibold border-b-gray-400">
              Datos Generales
            </p>
            <div className="relative z-0 mb-2  pt-2 w-full  mt-7 ">
            <div className="w-full grid  lg:grid-cols-5 md:grid-cols-2 grid-cols-1 md:gap-4 mb-2 gap-2  ">
            <InputContext
                  disabled={true}
                  name={"identificacion"}
                  isTooltip={true}
                  isMessageTooltip={"Número de RUC, cédula o pasaporte"}
                  title={`Identificación${
                    tipoIdentificacion ? ` / ${tipoIdentificacion}` : ""
                  } *`}
                  validations={{
                    required: {
                      value: true,
                      message: "Identificación es requerido",
                    },
                    maxLength: {
                      value: 20,
                      message: "No más de 20 carácteres!",
                    },
                    minLength: {
                      value: 3,
                      message: "Mínimo 3 carácteres",
                    },
                    pattern: /^[a-zA-Z0-9 -]+$/,
                  }}
                  onChange={(e) => {
                    setIdentificacionObs(e.target.value);
                  }}
                  max={20}
                />

                <InputContext
                  name={"razonsocial"}
                  title={"Razón social *"}
                  validations={{
                    required: {
                      value: true,
                      message: "Nombre es requerido",
                    },
                    maxLength: {
                      value: 300,
                      message: "No más de 300 carácteres!",
                    },
                    minLength: {
                      value: 4,
                      message: "Mínimo 4 carácteres",
                    },
                    pattern: UTF8RegExp,
                  }}
                  max={300}
                />

                <InputContext
                  name={"direccion"}
                  title={"Dirección *"}
                  validations={{
                    required: {
                      value: true,
                      message: "Nombre es requerido",
                    },
                    maxLength: {
                      value: 300,
                      message: "No más de 300 carácteres!",
                    },
                    minLength: {
                      value: 3,
                      message: "Mínimo 3 carácteres",
                    },
                    pattern: UTF8RegExp,
                  }}
                  maxLength={300}
                  max={300}
                />
                <div className="lg:col-span-2">
                <InputContext
                    specificStyles={"col-span-2"}
                    name={"correo"}
                    isTooltip={true}
                    isMessageTooltip={"ejemplo1@hotmail.com;ejemplo2@hotmail.com;..."}
                    title={"Correo *"}
                    validations={{
                      required: {
                        value: true,
                        message: "Correo es requerido",
                      },
                      maxLength: {
                        value: 300,
                        message: "No más de 300 carácteres!",
                      },
                      minLength: {
                        value: 4,
                        message: "Mínimo 4 carácteres",
                      },
                      pattern: MailsRegExp,
                    }}
                    max={300}
                    onChange={(e) => {
                      const trimmedValue = e.target.value.trim();
                      methods.setValue("correo", trimmedValue);
                      console.log(trimmedValue + "input actualizado");
                    }}
                  />
                </div>

                <InputContext
                  specificStyles={"col-span-2"}
                  name={"telefonocelular"}
                  isTooltip={true}
                  isMessageTooltip={"(EJ:0963855966)"}
                  title={"Teléfono celular (Opcional)"}
                  validations={{
                    maxLength: {
                      value: 14,
                      message: "No más de 13 carácteres!",
                    },
                    minLength: {
                      value: 4,
                      message: "Mínimo 4 carácteres",
                    },
                  }}
                  max={14}
                  pattern="^\+?[0-9]+$"
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^\+?[0-9]*$/;
                    const plusCount = (value.match(/\+/g) || []).length;
                    if (
                      regex.test(value) &&
                      plusCount <= 1 &&
                      (plusCount === 0 || value.startsWith("+"))
                    ) {
                      methods.setValue("telefonocelular", value);
                    } else {
                      let sanitizedValue = value.replace(/[^0-9]/g, "");
                      if (value.startsWith("+")) {
                        sanitizedValue = "+" + sanitizedValue;
                      }
                      methods.setValue("telefonocelular", sanitizedValue);
                    }
                  }}
                  maxLength={14}
                />
                <SelectContext
                  children={
                    <>
                      <option value="0">INACTIVO</option>
                      <option value="1">ACTIVO</option>
                    </>
                  }
                  name="activo"
                  title={"Estado"}
                />
              </div>
              <h1 className="text-lg mb-8 dark:text-white   font-semibold p-2 border-b border-b-gray-400">
                Datos Adicionales
              </h1>
              <div className="w-full grid  lg:grid-cols-5   md:grid-cols-2 grid-cols-1 md:gap-4 gap-2 pt-2 ">
                <NumberFormatProvider
                  name={"telefonoconvencional"}
                  isTooltip={true}
                  title={" Teléfono convencional (Opcional)"}
                  isMessageTooltip={"(EJ:042123456)"}
                  value={methods.watch("telefonoconvencional")}
                  validations={{
                    maxLength: {
                      value: 15,
                      message: "No más de 15 carácteres!",
                    },
                    minLength: {
                      value: 4,
                      message: "Mínimo 4 carácteres",
                    },
                  }}
                  max={15}
                />
                {DataFinal.esRuc && DataFinal.idTipoIdentificacion === 1 ? (
                  <div className="text-xs">
                    <label
                      className={
                        "w-full text-gray-600 my-auto font-normal flex flex-col gap-1"
                      }
                      htmlFor="También es Cédula"
                    >
                      <span className="dark:text-gray-300">También es Cédula</span>
                      <select
                        className={StyleNormalSelect}
                        onChange={handleEsRucChange}
                      >
                        <option value="1">SI</option>
                        <option value="0">NO</option>
                      </select>
                    </label>
                  </div>
                ) : DataFinal.esRuc && DataFinal.idTipoIdentificacion === 2 ? (
                  <div className="text-xs">
                    <label
                      className={
                        "w-full text-gray-600 my-auto font-normal flex flex-col gap-1"
                      }
                      htmlFor="También es Cédula"
                    >
                      <span className="dark:text-gray-300">También es Cédula</span>
                      <select
                        className={StyleNormalSelect}
                        onChange={handleEsRucChange}
                      >
                        <option value="0">NO</option>
                        <option value="1">SI</option>
                      </select>
                    </label>
                  </div>
                ) : methods.getValues().idTipoIdentificacion === 1 ? (
                  <div className="text-xs">
                    <label
                      className={
                        "w-full text-gray-600 my-auto font-normal flex flex-col gap-1"
                      }
                      htmlFor="También es Ruc"
                    >
                      <span className="dark:text-gray-300">También es Ruc</span>
                      <select
                        className={StyleNormalSelect}
                        onChange={handleRucChange}
                      >
                        <option value="0">NO</option>
                        <option value="1">SI</option>
                      </select>
                    </label>
                  </div>
                ) : null}

                <SelectContext
                  children={
                    <>
                      <option value="0">NO</option>
                      <option value="1">SI</option>
                    </>
                  }
                  name="IsPartRel"
                  title={"Es Parte Relacionada"}
                />
                <SelectContext
                  name="IsTransportista"
                  title={"Es transportista"}
                  children={
                    <>
                      <option value="0">NO</option>
                      <option value="1">SI</option>
                    </>
                  }
                />
                {valueTransportista === "1" && (
                  <InputContext
                    name={"placa"}
                    title={"Placa (GMT-1234)"}
                    validations={{
                      maxLength: {
                        value: 10,
                        message: "No más de 10 carácteres!",
                      },
                      minLength: {
                        value: 4,
                        message: "Mínimo 4 carácteres",
                      },
                      required: {
                        value: true,
                        message: "Nombre es requerido",
                      },
                      pattern: UTF8RegExp,
                    }}
                    maxLength="10"
                    max={10}
                  />
                )}
              </div>
            </div>
            <div className="">
              <div>
                <div>
                  <p
                    onClick={() => setOpenCorreo(!OpenCorreo)}
                    className="text-lg font-semibold p-2 border-b border-b-gray-400 select-none cursor-pointer dark:text-white flex gap-x-2  items-center   font-body"
                  >
                    Personalización de correos de envío (Opcional){" "}
                    {!OpenCorreo ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    )}
                  </p>
                  {OpenCorreo ? (
                    <MailsForm
                      setValue={methods.setValue}
                      watch={methods.watch}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="flex align-middle lg:justify-end justify-center my-2  ">
              <button
                onClick={() => navigate("/emision/reporte/contribuyentes")}
                className="text-white bg-[#6B6B6B] hover:bg-gray-600 focus:ring-4 focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-base px-6 py-2.5 text-center md:mr-5 mb-3 md:mb-0 inline-flex items-center justify-center"
              >
                Cancelar
              </button>

              <button
                type="submit"
                disabled={IsLoading}
                className=" text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-base px-6 py-2.5 text-center md:mr-5 mb-3 md:mb-0 inline-flex items-center justify-center"
              >
                <p>Editar</p>
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
}
