import { SeccionProvider } from "../../Secciones/context/SeccionContext";
import { ProductoProvider } from "../context/ProductContext";
import { CrearProducto } from "./CrearProducto";

export const IndexProducto = () => {
  return (
      <ProductoProvider>
        <CrearProducto />
      </ProductoProvider>
  );
};
