import { BodegaProvider } from "../../Bodegas/Crear/context/BodegasContext";
import { CategoriaProvider } from "../../Categorias/context/CategoriaContext";
import { ProductoProvider } from "../../Productos/context/ProductContext";
import { ValoracionInventarioProvider } from "./context/ValoracionInventarioContext";
import { ValoracionInventarioHeader } from "./ValoracionInventarioHeader";
import { ValoracionInventarioList } from "./ValoracionInventarioList";

export const ValoracionInventario = () => {
  return (
    <ValoracionInventarioProvider>
      <section className="w-full h-full flex flex-col gap-5">
        <ValoracionInventarioHeader />
        <ValoracionInventarioList />
      </section>
    </ValoracionInventarioProvider>
  );
};
