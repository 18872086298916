import React, { createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  GuardarTransaccion,
  ObtenerTransaccionesEmpresa,
  DescargarPdfTransaccion,
} from "../../../../../services/InventarioService";
import { toast } from "react-toastify";

export const TransaccionesContext = createContext();

export const TransaccionesProvider = ({ children }) => {
  const [transacciones, setTransacciones] = useState([]);
  const empresa = useSelector((state) => state.empresa.empresa);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedTransaccion, setSelectedTransaccion] = useState(null);
  const [infoPaginacion, setInfoPaginacion] = useState({
    totalRecords: 0,
    totalPages: 0,
  });
  const [parametroPaginacion, setParametroPaginacion] = useState({
    pageNumber: 1,
    pageSize: 50,
  });
  const [Filtros, setFiltros] = useState({
    fechaInicio: new Date().toISOString().split("T")[0],
    fechaFin: new Date().toISOString().split("T")[0],
    idTipoTransaccion: "",
    idSeccion: "",
  });

  useEffect(() => {
    if (empresa && empresa.idEmpresa) getTransacciones();
  }, [empresa, Filtros, parametroPaginacion]);

  const getTransacciones = async () => {
    setIsLoading(true);
    try {
      const response = await ObtenerTransaccionesEmpresa(
        "/api/transacciones/obtener-transacciones",
        empresa.idEmpresa,
        parametroPaginacion.pageSize,
        parametroPaginacion.pageNumber,
        Filtros.fechaInicio
          ? Filtros.fechaInicio
          : new Date().toISOString().split("T")[0],
        Filtros.fechaFin
          ? Filtros.fechaFin
          : new Date().toISOString().split("T")[0],
        Filtros.idTipoTransaccion,
        Filtros.idSeccion
      );
      setTransacciones(response.data.data);
      setInfoPaginacion({
        totalPages: response.data.totalPages,
        totalRecords: response.data.totalRecords,
      });
    } catch (err) {
      throw new Error(
        err,
        "No se pudo obtener la información de las transacciones. Inténtelo de nuevo más tarde."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const addTransaccion = async (transaction) => {
    const res = await GuardarTransaccion(
      "api/transacciones/crear-transaccion",
      transaction
    );
    if (res.code === 200) await getTransacciones();
    else {
      throw new Error("No se pudo guardar la transacción");
    }
  };

  const generatePdf = async (selectedTransaccion) => {
    const total = selectedTransaccion.productos.reduce((total, producto) => {
      const productoTotal = producto.cantidad * producto.costoDecimal;
      return total + productoTotal;
    }, 0);

    const body = {
      idEmpresa: empresa.idEmpresa,
      idTransaccion: selectedTransaccion.idTransaccion,
      tipoTransaccion: selectedTransaccion.tipoTransaccion,
      codigo: selectedTransaccion.codigo,
      trasaccion: selectedTransaccion.trasaccion,
      nombreEmpresa: selectedTransaccion.nombreEmpresa || "",
      ruc: selectedTransaccion.ruc || "",
      total: total,
      codigoEstablecimiento: selectedTransaccion.codigoEstablecimiento,
      fechaCreacion: selectedTransaccion.fechaCreacion,
      nombreBodegaOrigen: selectedTransaccion.nombreBodegaOrigen,
      nombreSeccionOrigen: selectedTransaccion.nombreSeccionOrigen,
      nombreBodegaDestino: selectedTransaccion.nombreBodegaDestino || "",
      nombreSeccionDestino: selectedTransaccion.nombreSeccionDestino || "",
      ubicacion: selectedTransaccion.ubicacion,
      detalle: selectedTransaccion.detalle,
      productos: selectedTransaccion.productos.map((producto) => ({
        idProducto: producto.idProducto,
        nombre: producto.nombre,
        cuentaAnalitica: producto.cuentaAnalitica,
        cantidad: producto.cantidad,
        costoDecimal: producto.costoDecimal,
        stock: producto.stock,
        ajuste: producto.ajuste || 0,
        lote: producto.lote || "",
        fechaCaducidad: producto.fechaCaducidad || "",
        codigo: producto.codigo,
      })),
    };

    const res = await DescargarPdfTransaccion(body);
    const fileBase64 = res
      .replace(/\\/g, "")
      .replace(/"/g, "")
      .replace(/\n/g, "");

    const fileType = "application/pdf";
    const extension = "pdf";

    const fileUrl = `data:${fileType};base64,${fileBase64}`;
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = `${selectedTransaccion.trasaccion}.${extension}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <TransaccionesContext.Provider
      value={{
        transacciones,
        addTransaccion,
        selectedTransaccion,
        isLoading,
        parametroPaginacion,
        infoPaginacion,
        setFiltros,
        setParametroPaginacion,
        setSelectedTransaccion,
        generatePdf,
      }}
    >
      {children}
    </TransaccionesContext.Provider>
  );
};
