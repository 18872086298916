import { useStore } from "zustand";
import { useModalSeccionStore } from "./SeccionModalContext";
import { FormProvider, useForm } from "react-hook-form";
import { InputGlobal } from "../../context/inputContext";
import { useContext, useEffect, useState } from "react";
import { SeccionContext } from "../context/SeccionContext";
import { ModalButtons } from "../../Shared/ModalButtons";
import { AlfaNumerico } from "../../regex.d";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ModalBase } from "../../Shared/ModalBase";

export const ModalEditarSeccion = () => {
  const empresa = useSelector((state) => state.empresa.empresa);
  const { updateSeccion } = useContext(SeccionContext);
  const [isSending, setIsSending] = useState(false);
  const { turnSeccionModalOff, SeccionAfectada } =
    useStore(useModalSeccionStore);

  const methods = useForm({
    defaultValues: {
      bodega: SeccionAfectada.nombreBodega,
      seccion: SeccionAfectada.nombre,
    },
  });

  useEffect(() => {
    toast.dismiss();
  }, []);

  const saveChanges = async (data) => {
    const EditedSeccion = {
      idSeccion: SeccionAfectada.idSeccion,
      idEmpresa: empresa.idEmpresa,
      idBodega: SeccionAfectada.idBodega,
      nombre: data.seccion.trimEnd().trimStart(),
      nombreBodega: SeccionAfectada.nombreBodega,
      codigoEstablecimiento: SeccionAfectada.codigoEstablecimiento,
    };
    try {
      setIsSending(true);
      await toast.promise(updateSeccion(EditedSeccion), {
        pending: "Editando sección...",
        success: "¡La sección fue editada con éxito!",
        error: {
          render({ data }) {
            return (
              data?.response?.data?.message ||
              "Hubo un error al editar la sección."
            );
          },
        },
      });
      turnSeccionModalOff();
    } catch (err) {
      console.log(err);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <ModalBase onClose={turnSeccionModalOff}>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(saveChanges)}
          autoComplete="off"
          style={{
            padding: "20px",
            height: "fit-content",
            width: "fit-content",
            position: "relative",
          }}
          className="shadow-xl rounded-lg flex bg-white dark:bg-gray-900 flex-col gap-4 lg:w-[500px] md:w-[450px] w-[350px]"
        >
          <div
            style={{ width: "100%" }}
            className="bg-gray-200 dark:bg-gray-700 rounded-lg dark:text-white text-[12px] whitespace-nowrap flex flex-col justify-center p-6"
          >
            <h1 className="font-semibold text-2xl font-[Outfit]">
              Información de Secciones
            </h1>
            <h4>Editar Información</h4>
          </div>
          <div className="grid grid-cols-3  relative gap-4 w-full ">
            <InputGlobal
              disabled={true}
              title={"Bodega"}
              name={"bodega"}
              isHook={true}
            />
            <InputGlobal
              title={"Sección"}
              name={"seccion"}
              validations={{
                required: true,
                pattern: AlfaNumerico,
                maxLength: 25,
              }}
              specificStyle={"col-span-2"}
              isPlaceHolder={"Ingrese el nuevo nombre de la sección."}
              isHook={true}
            />
          </div>
          <ModalButtons isSending={isSending} closeFn={turnSeccionModalOff} />
        </form>
      </FormProvider>
    </ModalBase>
  );
};
