import { useStore } from "zustand";
import { EditIcon } from "../../../../Icons/ButtonIcons";
import { DeleteIcon } from "../../../../Icons/ButtonIcons";
import {
  ModalCategoriasStatus,
  useModalCategoriaStore,
} from "./ModalCategoriaContext";
import { useContext } from "react";
import { CategoriaContext } from "../context/CategoriaContext";

export const CrearCategoriaItem = ({
  Categoria,
  CategoriasChecked,
  handleClick,
}) => {
  const { changeStateCategoria } = useContext(CategoriaContext);

  const { changeCategoriaModal } = useStore(useModalCategoriaStore);

  const ChangeActive = (id) => {
    changeStateCategoria(id);
  };

  return (
    <tr
      className={`bg-white dark:bg-gray-700 text-center dark:text-white text-[12px] ${
        Categoria.estado ? "" : "opacity-60"
      } font-normal  border-y-2 border-gray-300 dark:border-gray-600`}
    >
      <th style={{ padding: "15px" }}>
        <input
          type="checkbox"
          checked={
            CategoriasChecked.find(
              (categoria) => categoria === Categoria.idCategoriaProducto
            )
              ? true
              : false
          }
          onChange={() => handleClick(Categoria.idCategoriaProducto)}
        />
      </th>
      <th className="font-normal">{Categoria.codigo}</th>
      <th className="font-normal">{Categoria.nombre}</th>
      <th>
        <input
          type="checkbox"
          checked={Categoria.estado}
          onChange={(e) => ChangeActive(Categoria.idCategoriaProducto)}
        />
      </th>
      <th
        style={{ padding: "15px" }}
        className="flex justify-center flex-row gap-2"
      >
        <button
          onClick={() =>
            changeCategoriaModal(ModalCategoriasStatus.editar, Categoria)
          }
        >
          <EditIcon />
        </button>

        <button
          onClick={() =>
            changeCategoriaModal(ModalCategoriasStatus.eliminar, Categoria)
          }
        >
          <DeleteIcon />
        </button>
      </th>
    </tr>
  );
};
