import React, { useContext, useEffect, useState } from "react";
import MesageError from "../../../features/components/MessageError";
import { useSelector } from "react-redux";
import {
  StyleInput,
  StyleInputError,
  StyleSelect,
} from "../../../features/Constantes";
import Contribuyente from "../../../features/components/Contribuyente";

import { getListV2, PostElement } from "../../../services";
import { EmitirDocContext } from "./EmitirDocProvider";
import {
  getFechaV1,
  getNoventaDiasAtras,
  rellenarCeros,
  roundToTwo,
} from "../../../services/Utilitario";
import NumberFormat from "react-number-format";
import useVistaPrevia from "../hooks/useVistaPrevia";
import MobileButtonOptionsNotaC from "./MobileButtonOptionsNotaC";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Toggle from "../../../features/components/Toggle";
import { CalcularImpuestosList } from "../EmitirFactura/services/impuestos";
import { ModalHours } from "../../../features/hooks/ModalHours";

const FormDoc = () => {
  const {
    formik,
    DetalleList,
    Totales,
    InformacionAdicionalList,

    setInformacionAdicionalList,
    resetFormNc,
    isOpenModalHours,
    toggleModal
  } = useContext(EmitirDocContext);
  //const formikProps = useFormikContext()
  const methods = useForm({});
  const empresa = useSelector((store) => store.empresa.empresa);

  const { GetVistaPreviaNotaCredito, IsLoading } = useVistaPrevia();

  const [Establecimientos, setEstablecimientos] = useState([]);
  const [PuntosEmision, setPuntosEmision] = useState([]);

  const [estabTmp, setEstabTmp] = useState("###");
  const [pteTmp, setPteTmp] = useState("###");
  const [secTmp, setSecTmp] = useState("#########");

  useEffect(() => {
    (async () => {
      //ObtenerEstablecimientosPorEmpresa
      const GetAllEstablecimientos = await getListV2(
        empresa,
        "ObtenerEstablecimientosPorEmpresa"
      );
      //  if (GetAllEstablecimientos._embedded.length > 1) {

      formik.setFieldValue(
        "establecimiento",
        GetAllEstablecimientos._embedded[0].codigo
      );
      setEstabTmp(GetAllEstablecimientos._embedded[0].codigo);
      setEstablecimientos(GetAllEstablecimientos._embedded);

      await getPuntosEmision(GetAllEstablecimientos._embedded[0]);
      //  }
    })();
  }, []);

  const handleChangeEstablecimiento = async (e) => {
    if (e.target.value) {
      const index = e.target.selectedOptions[0].getAttribute("data-index");

      formik.setFieldValue("puntoEmision", "");
      formik.setFieldValue("secuencial", "");
      setEstabTmp(e.target.value);
      const establecimiento = Establecimientos[index];

      await getPuntosEmision(establecimiento);

      formik.handleChange(e);
    }
  };

  const getPuntosEmision = async (establecimiento) => {
    const GetAllPuntosEmision = await getListV2(
      establecimiento,
      "GetListPuntosEmision",
      `?Estado=1`
    );

    setSecTmp("#########");
    if (GetAllPuntosEmision._embedded.length > 0) {
      setPteTmp(GetAllPuntosEmision._embedded[0].codigo);
      setPuntosEmision(GetAllPuntosEmision._embedded);
      formik.setFieldValue(
        "puntoEmision",
        GetAllPuntosEmision._embedded[0].codigo
      );
      await getSecuencial(GetAllPuntosEmision._embedded[0]);
    } else {
      setPteTmp("###");
      formik.setFieldValue("puntoEmision", "");
      setPuntosEmision([]);
    }
  };

  const getSecuencial = async (puntoEmisionObj) => {
    try {
      // agregar codigo de buscar la secuencia por idPuntoMEision y el tipo de documento 01=factura

      const GetAllPuntosEmision = await getListV2(
        puntoEmisionObj,
        "getSecuencia-NotaCredito"
      );
      // set el secuencial en el form_secuencial y en el numero

      setSecTmp(GetAllPuntosEmision._embedded.codigo);
      formik.setFieldValue("secuencial", GetAllPuntosEmision._embedded.codigo);
    } catch (error) {
      //   console.log(error);
      setSecTmp("#########");
      formik.setFieldValue("secuencial", "");
    }
  };

  const handleVistaPrevia = async () => {
    let parsedInfo = {
      IdentificacionContribuyente: formik.values.clienteIdentificacion,
      RazonSocialContribuyente: formik.values.clienteRazonSocial,
      correoContribuyente: formik.values.clienteEmail
        .replace(/,/g, ";")
        .replace(/\s+/g, ""),
      Direccion: formik.values.clienteDireccion,
      TelefonoCelular: formik.values.clienteTelefono,
      CodigoComprobanteModificar: formik.values.codigoComprobanteModificar,
      NumeroComprobanteModificar: formik.values.numeroComprobanteModificar,
      Motivo: formik.values.motivo,
      FechaEmisionComprobanteModificar: getFechaV1(
        formik.values.fechaEmisionComprobanteModificar
      ),
      Ambiente: process.env.REACT_APP_ENVIRONMENT,
      IdEmpresa: empresa.idEmpresa,
      RucEmpresa: empresa.ruc,
      CodDoc: 4,
      FechaEmision: getFechaV1(formik.values.fechaEmision),
      ImporteTotal: Totales.importeTotal,
      TotalSinImpuestos: Totales.totalSinImpuesto,
      TotalDescuento: Totales.totalDescuentos,

      ListaImpuestoNC: await CalcularImpuestosList(DetalleList,empresa,Totales),

      InformacionAdicionalCredito: InformacionAdicionalList.map((x) => {
        return {
          Descripcion: x.nombre,
          Valor: x.valor,
        };
      }),
      DetalleNotaCredito: DetalleList.map((e) => {
        return {
          Descripcion: e.descripcion,
          Cantidad: e.cantidad,
          CodigoPrincipal: e.codigoPrincipal,
          CodigoAuxiliar: e.codigoAuxiliar,
          PrecioUnitario: e.precioUnitario,
          Descuento: 0,
          ImpuestoCodigoIva: 2,
          ImpuestoCodigoPorcentajeIva: e.idImpuestoIvaNavigation.codigo,
          PrecioTotalSinImpuesto: roundToTwo(e.subTotal),
          ImpuestoBaseImponibleIva: roundToTwo(e.subTotal),
          ImpuestoValorIva: e.iva === undefined ? 0 : roundToTwo(e.iva),
          Tarifa: roundToTwo(e.idImpuestoIvaNavigation.porcentaje * 100),
          DetalleServicioNotaCredito:
            e.servicioDetalle === undefined
              ? []
              : e.servicioDetalle === null
              ? []
              : e.servicioDetalle.map((x) => {
                  return {
                    Valor: x.nombre,
                    Descripcion: x.valor,
                  };
                }),
        };
      }),
      IdImpuestoNoObjectoImpuesto:
        Totales.subTotalNoObjetoIva !== 0
          ? "8E7CF164-C66B-4312-945F-57AE08C86A33"
          : "",
      IdImpuestoExentoIva:
        Totales.subTotalExentoIva !== 0
          ? "F03C7F10-2773-4505-ACCB-B2FA88D62049"
          : "",
      IdImpuestoIVA12:
        Totales.subTotalIva !== 0 ? "A5B8F607-8D38-4150-97AF-C591F83678D8" : "",
      IdImpuestoIVA0:
        Totales.subTotalIva0 !== 0
          ? "81BAF871-E113-48CF-8AA8-670CB43494B6"
          : "",
      //IdImpuestoIVA14= 8DCD2CFA-5F9D-4FB7-B27A-87447D6CDDE7
      TotalTotalIva0: 0,
      TotalTotalIva: roundToTwo(Totales.totalIva),
      TotalNoObjetoImpuesto: 0,
      TotalExentoIva: 0,
      TotalBaseIva0: Totales.subTotalIva0,
      TotalBaseIva: roundToTwo(Totales.subTotalIva),
      TotalBaseNoObjetoImpuesto: Totales.subTotalNoObjetoIva,
      TotalBaseExentoIva: Totales.subTotalExentoIva,
      Establecimiento: formik.values.establecimiento,
      PuntoEmision: formik.values.puntoEmision,
      Secuencial: formik.values.secuencial,
    };
    if (formik.values.clienteEmail !== null)
      if (formik.values.clienteEmail.trim() !== "")
        parsedInfo.InformacionAdicionalCredito.push({
          Valor: formik.values.clienteEmail
            .replace(/,/g, ";")
            .replace(/\s+/g, ""),
          Descripcion: "email",
        });

    let data = await GetVistaPreviaNotaCredito(parsedInfo);
    let byteCharacters = atob(data);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: "application/pdf;base64" });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const handleChangePuntoEmision = (e) => {
    setSecTmp("#########");
    if (e.target.value) {
      setPteTmp(e.target.value);

      const index = e.target.selectedOptions[0].getAttribute("data-index");

      const puntoEmision = PuntosEmision[index];

      getSecuencial(puntoEmision);

      formik.handleChange(e);
    }
  };

  const handleClickSubmitFactura = (e) => {
    validateForm(formik.values);
    if (DetalleList.length != 0) {
      setSecTmp(Number(formik.values.secuencial) + 1);
    } else {
      // console.log("lista coun :" + DetalleList.length);
      e.preventDefault();
    }
  };

  // const handleBlurElement = async (e) => {
  //   let elements = formik.values.numeroComprobanteModificar.split("-");

  //   let bodyReq = {
  //     Establecimiento: Number(elements[0]),
  //     PuntoEmision: Number(elements[1]),
  //     Secuencial: Number(elements[2]),
  //   };

  //   try {

  //     let el = await PostElement(empresa, "SearchFacturaBySecuencial", bodyReq);
  //     if (!el.item2) {
  //       setSaveData(true);
  //       setSaveDataMessage("Documento no Autorizado");
  //       setSaveDataSvg(-1);
  //       setsaveEstado(true);
  //     }
  //     setDetalleList(el.item1);

  //     formik.handleBlur(e);
  //   } catch (error) {

  //     setSaveData(true);
  //     setSaveDataMessage("Documento no Encontrado");
  //     setSaveDataSvg(-1);
  //     setsaveEstado(true);

  //   }
  // };

  const handleResetDocument = () => {
    resetFormNc();
    setInformacionAdicionalList([]);
  };

  const validateForm = (data) => {
    if (
      data.clienteIdentificacion !== "9999999999999" &&
      (data.clienteDireccion === "" ||
        data.clienteEmail === "" ||
        data.clienteIdentificacion === "" ||
        data.clienteRazonSocial === "")
    ) {
      toast.error("Falta informacion en el apartado de contribuyente!");
      throw new Error("Error de validacion");
    }
  };

  const [valueOptionsHeader, setValueOptionsHeader] = useState("FACTURA");

  return (
    <form onSubmit={formik.handleSubmit}>
        <ModalHours isOpenModalHours={isOpenModalHours} toggleModal={toggleModal} />
      {IsLoading && (
        <div
          style={{ width: "100vw", height: "100vh", zIndex: "100" }}
          className="bg-opacity-70 fixed justify-center align-middle top-0 l-0 bg-white text-center"
        >
          <div className="w-full h-full flex justify-center items-center py-4">
            <div
              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-600"
              role="status"
            >
              <span className="hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}

      <div className="border dark:border-gray-600 rounded-xl bg-white dark:bg-gray-900 ">
        <div className="flex justify-between">
        

          <Toggle
            text="Nota de Credito"
            setValueOptionsHeader={setValueOptionsHeader}
          >
            <MobileButtonOptionsNotaC />
          </Toggle>

          <div className="my-auto lg:flex md:flex sm:hidden ">
            <div className="group relative mr-4 hidden md:table-cell lg:table-cell">
              {/* nuevo boton */}

              {/*  */}
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#searchFacturaModal"
                id="searchFacturaModalButton"
                className="flex items-center dark:text-white border p-2 mt-1 cursor-pointer gap-x-2 justify-center rounded-xl  hover:shadow-md    transition dark:bg-gray-800 dark:hover:bg-gray-800"
              >
                Consultar factura
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-6 w-6 dark:text-white "
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                  />
                </svg>
              </button>
            </div>

            <div className=" group relative inline-block">
              <div className=" cursor-pointer w-10 h-10 rounded-full  inline-flex items-center justify-center">
                <button
                  type="button"
                  onClick={handleResetDocument}
                  className="inline-flex h-10 w-10 cursor-pointer mt-1  hover:shadow-md  items-center justify-center rounded-xl  transition dark:bg-gray-900 dark:hover:bg-gray-800"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-6 dark:text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>

                  <div className="absolute mx-auto w-24  -top-8 -left-8 hidden dark:bg-white     rounded-md bg-gray-800 group-hover:flex text-center p-1">
                    <p className="text-white dark:text-black mx-auto text-sm ">
                      Limpiar
                    </p>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="p-2">
          {valueOptionsHeader === "FACTURA" ? (
            <>
              <div className="bg-[#F7F7F7] dark:bg-gray-700 p-2 rounded-lg">
                <div className=" grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1  gap-2 w-full max-w-2xl  text-center">
                  <div className="relative z-0 w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black dark:text-white text-left"
                    >
                      {" "}
                      Establecimiento:{" "}
                    </label>
                    <select
                      onChange={handleChangeEstablecimiento}
                      type="text"
                      name="establecimiento"
                      id="establecimiento"
                      className={StyleSelect}
                      placeholder=" "
                      defaultValue={formik.values.establecimiento}
                      onBlur={formik.handleBlur}
                    >
                      {/*data-id-establecimiento={el.idEstablecimiento}*/}
                      {Establecimientos.map((el, index) => {
                        return (
                          <option
                            key={el.idEstablecimiento}
                            data-index={index}
                            value={el.codigo}
                          >
                            {rellenarCeros(el.codigo)} {/*- {el.descripcion}*/}
                          </option>
                        );
                      })}
                    </select>
                    {/* <label htmlFor="establecimiento" className={StyleLabelForm}>
                  Establecimiento.
                </label> */}
                    {formik.touched.establecimiento &&
                    formik.errors.establecimiento ? (
                      <MesageError message={formik.errors.establecimiento} />
                    ) : null}
                  </div>

                  <div className="relative z-0 w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black dark:text-white text-left"
                    >
                      {" "}
                      Punto emisión:{" "}
                    </label>
                    <select
                      type="text"
                      className={StyleSelect}
                      placeholder=" "
                      name="puntoEmision"
                      id="puntoEmision"
                      defaultValue={formik.values.puntoEmision}
                      onChange={handleChangePuntoEmision}
                      onBlur={formik.handleBlur}
                    >
                      {PuntosEmision.map((el, index) => {
                        return (
                          <option
                            key={el.idPuntoEmision}
                            value={el.codigo}
                            data-index={index}
                          >
                            {rellenarCeros(el.codigo)} {/*- {el.descripcion}*/}
                          </option>
                        );
                      })}
                    </select>
                    {/* <label htmlFor="puntoEmision" className={StyleLabelForm}>
                  Punto Emisión
                </label> */}
                    {formik.touched.puntoEmision &&
                    formik.errors.puntoEmision ? (
                      <MesageError message={formik.errors.puntoEmision} />
                    ) : null}
                  </div>

                  <div className="relative z-0 w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black dark:text-white text-left"
                    >
                      {" "}
                      Secuencial:{" "}
                    </label>
                    <input
                      type="text"
                      className={StyleInput + " bg-[#EBEBEB]"}
                      value={rellenarCeros(secTmp, 9)}
                      disabled
                    />
                    {/* <label htmlFor="secuencial" className={StyleLabelForm}>
                  Secuencial
                </label> */}
                    {formik.touched.secuencial && formik.errors.secuencial ? (
                      <MesageError message={formik.errors.secuencial} />
                    ) : null}
                  </div>

                  <div className="relative z-0 w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black dark:text-white text-left "
                    >
                      {" "}
                      Fecha emisión:{" "}
                    </label>
                    <input
                      type="date"
                      className={StyleInput}
                      name="fechaEmision"
                      id="fechaEmision"
                      min={getNoventaDiasAtras()}
                      value={formik.values.fechaEmision}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder=" "
                    />
                    {/* <label htmlFor="secuencial" className={StyleLabelForm}>
                  Secuencial
                </label> */}
                    {formik.touched.secuencial && formik.errors.secuencial ? (
                      <MesageError message={formik.errors.secuencial} />
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="bg-[#F7F7F7] dark:bg-gray-700 p-2 rounded-lg">
                <div className=" grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-2 w-full max-w-2xl  text-center">
                  <div className="relative z-0    group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black dark:text-white text-left"
                    >
                      {" "}
                      Fecha emisión factura:{" "}
                    </label>

                    <input
                      type="date"
                      name="fechaEmisionComprobanteModificar"
                      id="fechaEmisionComprobanteModificar"
                      value={formik.values.fechaEmisionComprobanteModificar}
                      onChange={formik.handleChange}
                      // onBlur={handleBlurElement}
                      className={StyleInput}
                      placeholder=" "
                    />
                    {/* <label htmlFor="fechaEmisionComprobanteModificar" className={StyleLabelForm}>
                Fecha Emisión Factura
              </label> */}
                  </div>
                  <div className="relative z-0   group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black dark:text-white text-left"
                    >
                      {" "}
                      Secuencial Factura: *{" "}
                    </label>
                    <NumberFormat
                      format="###-###-#########"
                      name="numeroComprobanteModificar"
                      id="numeroComprobanteModificar"
                      value={formik.values.numeroComprobanteModificar}
                      onChange={formik.handleChange}
                      // onBlur={handleBlurElement}
                      className={
                        formik.touched.numeroComprobanteModificar &&
                        formik.errors.numeroComprobanteModificar
                          ? StyleInputError
                          : StyleInput
                      }
                      placeholder=" "
                    />
                    {/* <label htmlFor="numeroComprobanteModificar" className={StyleLabelForm}>
                Secuencial Factura
              </label> */}
                  </div>

                  <div className="relative z-0  w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black dark:text-white text-left"
                    >
                      {" "}
                      Motivo: *{" "}
                    </label>
                    <input
                      type="text"
                      name="motivo"
                      id="motivo"
                      value={formik.values.motivo}
                      onChange={formik.handleChange}
                      // onBlur={handleBlurElement}
                      className={
                        formik.touched.motivo && formik.errors.motivo
                          ? StyleInputError
                          : StyleInput
                      }
                      placeholder=" "
                    />
                    {/* <label htmlFor="motivo" className={StyleLabelForm}>
              Motivo
            </label> */}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className=" bg-white dark:bg-gray-900 px-2 lg:px-4 md:px-4 pt-2 rounded-t-lg lg:py-2  mt-2">
        <Contribuyente
          IsShowConsumFinal={true}
          Totales={Totales}
          formik={formik}
        />
      </div>
    </form>
  );
};

export default FormDoc;
