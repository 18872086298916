import axios from "axios";

const useContribuyenteServices = () => {
  const constribuyenteService = async (data, pageNumber, pageSize) => {
  
    try {
        let res =  await axios.get(
          `api/Contribuyente/ObtenerContribuyenteByCatrasto?identificacion=${data}&pageNumber=${pageNumber}&pageSize=${pageSize}`
       )
      return res.data.data.data.main;
    } catch (error) {
      throw error.response?.data?.message ?? "Hubo un error inesperado";
    }
  };

  return {
    constribuyenteService,
  };
};
export default useContribuyenteServices;
