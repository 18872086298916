import React from 'react';

const ModalAdvertencias = ({ isVisible, advertenciasDetalles, onClose }) => {
    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 ${isVisible ? '' : 'hidden'}`}
            onClick={onClose}
        >
            <div
                className="relative w-auto pointer-events-auto bg-white rounded-lg overflow-hidden"
                onClick={handleModalClick}
            >
                <div className="max-h-screen overflow-y-auto overflow-x-hidden border-none shadow-lg relative flex flex-col w-full bg-clip-padding rounded-md outline-none text-current">
                    <button
                        type="button"
                        onClick={onClose}
                        className="shadow absolute -right-5 -top-5 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                    <div className="w-full p-8">
                        <h2 className="font-bold text-2xl mb-6 text-gray-800 dark:text-white pb-2 border-b-gray-600 border-b">Advertencias</h2>
                        <div className="py-4 text-sm">
                            <p>
                                El comprobante que intentas procesar tiene una <strong>fecha de emisión anterior</strong> a la actual.
                            </p>
                            <p>
                                Según la normativa vigente, el SRI permite un plazo máximo de autorización de 72 horas o 4 días hábiles.</p>

                            <p>
                                Para evitar posibles sanciones, te recomendamos regularizar la fecha o continuar con el proceso según corresponda.
                            </p>
                            {/* <p>
                                Para evitar sanciones del SRI, regulariza con tu contador. Más info en resoluciones SRI:
                            </p>
                            <p className="font-semibold">NAC-DGERCGC22-00000064 y NACDGERCGC18-00000233</p> */}
                        </div>

                        {advertenciasDetalles.length > 0 && (
                            <div className="overflow-x-auto">
                                <table className="min-w-full divide-y divide-gray-200 text-[0.813rem] text-center">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="px-3 py-1 border-r border-gray-300">N. Línea Excel</th>
                                            <th className="px-3 py-1 border-r border-gray-300">Establecimiento</th>
                                            <th className="px-3 py-1 border-r border-gray-300">Pto. Emisión</th>
                                            <th className="px-3 py-1 border-r border-gray-300">Secuencial</th>
                                            <th className="px-3 py-1 border-r border-gray-300">Advertencia</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {advertenciasDetalles.map((detalle, index) => (
                                            <tr key={index}>
                                                <td className="px-6 py-4 whitespace-nowrap">{detalle.LINEA}</td>
                                                <td className="px-6 py-4 whitespace-nowrap">{detalle.ESTABLECIMIENTO}</td>
                                                <td className="px-6 py-4 whitespace-nowrap">{detalle.PTOEMISION}</td>
                                                <td className="px-6 py-4 whitespace-nowrap">{detalle.SECUENCIAL}</td>
                                                <td className="px-6 py-4 whitespace-nowrap">{detalle.WARNING}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        <div className="flex flex-row flex-wrap items-center justify-center mt-6">
                            <button
                                onClick={onClose}
                                className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-yellow-500 border border-transparent rounded-md shadow-sm hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalAdvertencias;
