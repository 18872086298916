import { ConfiguracionModalOff } from "./modalConfiguracion.Slice";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { ModalButtons } from "../Shared/ModalButtons";
import { MedidasContext } from "./context/MedidasContext";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import useGlobalToastify from "../../../features/hooks/GlobalToastify/useGlobalToastify";

export const MedidasModal = () => {
  const dispatch = useDispatch();
  const {
    medidas,
    medidasEmpresa,
    saveChangesMedidas,
    setMedidasEmpresa,
    isLoading,
  } = useContext(MedidasContext);
  const { ErrorToast } = useGlobalToastify();
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    toast.dismiss();
  }, []);

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setMedidasEmpresa(medidas.map((medida) => medida.idUnidadMedida));
    } else {
      setMedidasEmpresa([]);
    }
  };
  const setMedidas = async (e) => {
    e.preventDefault();
    if (medidasEmpresa.length === 0) {
      ErrorToast("Debes seleccionar al menos una unidad de medida.");
      return;
    }
    toast.dismiss();
    try {
      setIsSending(true);
      await toast.promise(saveChangesMedidas(), {
        pending: "Guardando cambios...",
        success: "¡Configuración de medidas guardada con éxito!",
        error: "Hubo un error al guardar la configuración de medidas.",
      });

      dispatch(ConfiguracionModalOff());
    } catch (err) {
      console.log(err);
      dispatch(ConfiguracionModalOff());
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      {isLoading === true ? (
        <div className="flex h-[250px] flex-row items-center w-full justify-center">
          <ClipLoader color="#d8d4d4" />
        </div>
      ) : (
        <>
          <form
            className="w-full flex flex-col gap-6 mt-6 items-center justify-center"
            onSubmit={(e) => setMedidas(e)}
          >
            <table className="w-[90%] border-1 text-sm border-gray-300 dark:border-gray-600">
              <thead>
                <tr className=" dark:text-white p-2 font-semibold">
                  <th
                    style={{ marginBottom: "10px" }}
                    className="text-center w-full flex justify-between items-center"
                  >
                    <span>Medidas</span>
                    <span>Activo</span>
                  </th>
                </tr>
              </thead>
              <tbody className="text-black dark:text-white">
                {medidas &&
                  medidas.length > 0 &&
                  medidas.map((medida) => {
                    return (
                      <tr
                        className="text-center p-2 border-gray-300 dark:border-gray-600"
                        key={medida.idUnidadMedida}
                      >
                        <td
                          style={{ paddingTop: "6px", paddingBottom: "6px" }}
                          className="text-center pr-3 w-full flex justify-between items-center"
                        >
                          <label htmlFor={medida.idUnidadMedida}>
                            {medida.nombre}
                          </label>
                          <input
                            key={medida.idUnidadMedida}
                            checked={medidasEmpresa.includes(
                              medida.idUnidadMedida
                            )}
                            disabled={isSending}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setMedidasEmpresa((prevState) => [
                                  ...prevState,
                                  medida.idUnidadMedida,
                                ]);
                              } else {
                                setMedidasEmpresa((prevState) =>
                                  prevState.filter(
                                    (id) => id !== medida.idUnidadMedida
                                  )
                                );
                              }
                            }}
                            type="checkbox"
                          />
                        </td>
                      </tr>
                    );
                  })}
                {medidas.length > 0 && (
                  <tr className="text-center p-2 border-gray-300 dark:border-gray-600">
                    <td
                      style={{ paddingTop: "6px", paddingBottom: "6px" }}
                      className="text-center pr-3 w-full flex justify-between items-center"
                    >
                      <label htmlFor="all">Seleccionar Todo</label>
                      <input
                        type="checkbox"
                        id="all"
                        disabled={isSending}
                        checked={medidasEmpresa.length === medidas.length}
                        onChange={(e) => handleSelectAll(e)}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <ModalButtons
              closeFn={ConfiguracionModalOff}
              isSending={isSending}
              useRedux={true}
            />
          </form>
        </>
      )}
    </>
  );
};
