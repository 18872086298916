import { ConfiguracionModalOff } from "./modalConfiguracion.Slice";
import { useDispatch } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { ModalButtons } from "../Shared/ModalButtons";
import { TrazabilidadContext } from "./context/TrazabilidadContext";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";

export const TrazabilidadModal = () => {
  const dispatch = useDispatch();
  const {
    trazabilidad,
    trazabilidadEmpresa,
    saveChangesTrazabilidad,
    setTrazabilidadEmpresa,
    isLoading,
  } = useContext(TrazabilidadContext);

  useEffect(() => {
    toast.dismiss();
  }, []);

  const [isSending, setIsSending] = useState(false);

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setTrazabilidadEmpresa(
        trazabilidad.map((trazabilidad) => trazabilidad.idTrazabilidad)
      );
    } else {
      setTrazabilidadEmpresa([]);
    }
  };

  const setTrazabilidad = async (e) => {
    e.preventDefault();
    try {
      setIsSending(true);
      await toast.promise(saveChangesTrazabilidad(), {
        pending: "Guardando cambios...",
        success: "¡Configuración de trazabilidad guardada con éxito!",
        error: "Hubo un error al guardar la configuración de trazabilidad.",
      });
      dispatch(ConfiguracionModalOff());
    } catch (err) {
      console.log(err);
      dispatch(ConfiguracionModalOff());
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      {isLoading === true ? (
        <div className="flex h-[250px] flex-row items-center w-full justify-center">
          <ClipLoader color="#d8d4d4" />
        </div>
      ) : (
        <>
          {" "}
          <form
            className="w-full flex flex-col gap-6 mt-6 items-center justify-center"
            onSubmit={(e) => setTrazabilidad(e)}
          >
            <table className="w-[90%] border-1 text-[14px] border-gray-300 dark:border-gray-600">
              <thead>
                <tr className=" dark:text-white p-2 font-semibold">
                  <th
                    style={{ marginBottom: "10px" }}
                    className="text-center w-full flex justify-between items-center"
                  >
                    <span>Detalle</span>
                    <span>Activo</span>
                  </th>
                </tr>
              </thead>
              <tbody className="text-black dark:text-white">
                {trazabilidad &&
                  trazabilidad.length > 0 &&
                  trazabilidad.map((trazabilidad) => {
                    return (
                      <tr
                        className=" text-center p-2 border-gray-300 dark:border-gray-600"
                        key={trazabilidad.idTrazabilidad}
                      >
                        <td
                          style={{ paddingTop: "6px", paddingBottom: "6px" }}
                          className="text-center pr-3 w-full flex justify-between items-center"
                        >
                          <label
                            className="whitespace-nowrap"
                            htmlFor={trazabilidad.idTrazabilidad}
                          >
                            {trazabilidad.nombre}
                          </label>

                          <input
                            disabled={isSending}
                            id={trazabilidad.idTrazabilidad}
                            checked={trazabilidadEmpresa.includes(
                              trazabilidad.idTrazabilidad
                            )}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setTrazabilidadEmpresa((prevState) => [
                                  ...prevState,
                                  trazabilidad.idTrazabilidad,
                                ]);
                              } else {
                                setTrazabilidadEmpresa((prevState) =>
                                  prevState.filter(
                                    (id) => id !== trazabilidad.idTrazabilidad
                                  )
                                );
                              }
                            }}
                            type="checkbox"
                          />
                        </td>
                      </tr>
                    );
                  })}
                {trazabilidad.length > 0 && (
                  <tr className="text-center p-2 border-gray-300 dark:border-gray-600">
                    <td
                      style={{ paddingTop: "6px", paddingBottom: "6px" }}
                      className="text-center pr-3 w-full flex justify-between items-center"
                    >
                      <label htmlFor="all">Seleccionar Todo</label>
                      <input
                        type="checkbox"
                        disabled={isSending}
                        id="all"
                        checked={
                          trazabilidadEmpresa.length === trazabilidad.length
                        }
                        onChange={(e) => handleSelectAll(e)}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <ModalButtons
              closeFn={ConfiguracionModalOff}
              isSending={isSending}
              useRedux={true}
            />
          </form>
        </>
      )}
    </>
  );
};
