import { ArrowsColIcon } from "../../../../Icons/ButtonIcons";
import { KardexCompuestoRow, KardexRow } from "./KardexConstants.d";
import { KardexValoradoItem } from "./KardexValoradoItem";
import { useSelector } from "react-redux";

export const KardexValoradoList = ({ kardex }) => {
  const empresa = useSelector((state) => state.empresa.empresa);
  return (
    <>
      <div className="flex flex-col p-4 w-full rounded-lg">
        <div className=" w-full flex md:flex-row flex-col text-[15px] ">
          <div className="w-fit gap-2 p-3 pl-0 flex flex-col items-center justify-center ">
            {!kardex?.imagen64 && (
              <div className="w-96 h-48 bg-gray-200 border border-gray-300 dark:border-gray-600 opacity-50 rounded-md dark:bg-gray-700 flex items-center justify-center">
                <svg
                  className="w-10 h-10 text-gray-300 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>
            )}
            {kardex?.imagen64 && (
              <img
                src={kardex?.imagen64}
                alt="Imagen del Producto"
                className="w-96 h-48 border-2 border-gray-300 dark:border-gray-600 rounded-md"
              />
            )}
          </div>
          <section className="border border-gray-300 dark:border-gray-600 dark:text-white  header my-3 flex-1">
            <div className="flex flex-row gap-1 bg-gray-200 dark:border-gray-600 dark:bg-gray-700 items-center justify-center p-3 title w-full border-gray-300 border">
              <h1 className="md:text-xl text-lg font-semibold font-[Outfit]">
                Resumen de Kardex
              </h1>
            </div>

            <div className="company border border-gray-300 dark:bg-gray-700 dark:border-gray-600 w-full flex items-center justify-center">
              <span className="font-bold">
                Compañia:{" "}
                <span className="font-normal">
                  {" "}
                  {empresa?.razonSocial
                    ? empresa.razonSocial
                    : "Nombre de la Compañia"}
                </span>
              </span>{" "}
            </div>
            <div className="dateInicio border border-gray-300 dark:bg-gray-700 dark:border-gray-600 w-full flex items-center justify-center">
              <span className="font-bold">
                Fecha de Inicio:{" "}
                <span className="font-normal"> {kardex?.fechaInicio} </span>
              </span>{" "}
            </div>
            <div className="dateFin border border-gray-300 dark:bg-gray-700 dark:border-gray-600 w-full flex items-center justify-center">
              <span className="font-bold">
                Fecha Final:{" "}
                <span className="font-normal"> {kardex?.fechaFin} </span>
              </span>
            </div>
            <div className="ubicacion border border-gray-300 dark:bg-gray-700 dark:border-gray-600  w-full flex items-center justify-center">
              <span className="font-bold">
                Ubicación:{" "}
                <span className="font-normal">{kardex?.ubicacion}</span>
              </span>
            </div>
            <div className="producto border border-gray-300 dark:bg-gray-700 dark:border-gray-600 w-full flex items-center justify-center">
              <span className="font-bold">
                Producto:{" "}
                <span className="font-normal">{kardex?.nombreProducto}</span>
              </span>{" "}
            </div>
            <div className="categoria border border-gray-300 dark:bg-gray-700 dark:border-gray-600 w-full flex items-center justify-center">
              <span className="font-bold">
                Categoria:{" "}
                <span className="font-normal">{kardex?.nombreCategoria}</span>
              </span>
            </div>
          </section>
        </div>
        <div className="flex flex-col gap-3">
          <div className="w-full pb-5 overflow-x-auto">
            <table className="self-center w-full mt-2 border-2 border-gray-300 dark:bg-gray-700 dark:border-gray-600 text-[12px]">
              <thead>
                <tr className="bg-gray-200 dark:bg-gray-700 dark:text-white text-center">
                  {!kardex.isCompuesto ? KardexRow.map((row, i) => (
                    <th
                      scope="col"
                      className="py-4 text-[10px] pl-8 pr-6 whitespace-nowrap "
                      key={i}
                    >
                      <div className="flex flex-row gap-3 items-center">
                        <span>{row}</span>{" "}
                        <button className="opacity-50 hover:opacity-100">
                          <ArrowsColIcon />
                        </button>
                      </div>
                    </th>
                  )) : KardexCompuestoRow.map((row, i) => (
                    <th
                      scope="col"
                      className="py-4 text-[10px] pl-[80px] pr-6 whitespace-nowrap "
                      key={i}
                    >
                      <div className="flex flex-row gap-3 items-center">
                        <span>{row}</span>{" "}
                        <button className="opacity-50 hover:opacity-100">
                          <ArrowsColIcon />
                        </button>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {kardex?.listado.map((registro, i) => (
                  <KardexValoradoItem registro={registro} isCompuesto={kardex.isCompuesto} key={i} />
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex flex-row text-sm justify-end mt-2 gap-10 font-normal">
            <div className="flex flex-row gap-4 dark:text-white">
              <h1 className="flex flex-row gap-2 items-center">
                <span className="font-bold flex flex-row gap-1">
                  {!kardex.isCompuesto ? "Total Unitario:" : "Total Posible Actual:"}
                  <span className="font-normal">
                    {Number(kardex?.totalUnitario)}
                  </span>
                </span>
                {kardex.isCompuesto && (
                  <span className="relative group pointer-events-auto">
                    <svg
                      fill="#000000"
                      viewBox="0 0 32 32"
                      height={"20"}
                      width={"20"}
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="M16 3c-7.18 0-13 5.82-13 13s5.82 13 13 13 13-5.82 13-13-5.82-13-13-13zM18.039 20.783c-0.981 1.473-1.979 2.608-3.658 2.608-1.146-0.187-1.617-1.008-1.369-1.845l2.16-7.154c0.053-0.175-0.035-0.362-0.195-0.419-0.159-0.056-0.471 0.151-0.741 0.447l-1.306 1.571c-0.035-0.264-0.004-0.7-0.004-0.876 0.981-1.473 2.593-2.635 3.686-2.635 1.039 0.106 1.531 0.937 1.35 1.85l-2.175 7.189c-0.029 0.162 0.057 0.327 0.204 0.379 0.16 0.056 0.496-0.151 0.767-0.447l1.305-1.57c0.035 0.264-0.024 0.726-0.024 0.902zM17.748 11.439c-0.826 0-1.496-0.602-1.496-1.488s0.67-1.487 1.496-1.487 1.496 0.602 1.496 1.487c0 0.887-0.67 1.488-1.496 1.488z"></path>{" "}
                      </g>
                    </svg>
                    <div className="absolute bottom-full w-32 mb-2 left-1/2 transform shadow-md -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 bg-gray-800 text-white text-xs rounded-md py-1 px-2 pointer-events-none">
                      Hace referencia a la cantidad de
                      productos compuestos que puedo formar actualmente con mi
                      stock.
                    </div>
                  </span>
                )}
              </h1>
              {!kardex.isCompuesto && (
                <h1>
                  <span className="font-bold">Total USD: </span> $
                  {Number(kardex?.totalUsd).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
