import { useFormContext } from "react-hook-form";
import NumberFormat from "react-number-format";

export const NumberFormatProvider = ({
  name,
  validations,
  pattern,
  step,
  disabled,
  onChange,
  min,
  max,
  readOnly,
  value,
  type,
  title,
  specificStyle,
  isMoneyInput,
  pressfn,
  isHook,
  isPlaceHolder,
  maxLength,
  isTooltip,
  isMessageTooltip,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const StyleInput =
    "border-gray-300 focus:outline-none left-0 relative dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600 focus:border-blue-500 focus:ring-blue-500 focus:ring-1 ring-0 w-full border rounded-md pl-3 text-xs h-[39px] space-x-0";
  const StyleInputError =
    "border-red-500 focus:outline-none left-0 relative  focus:border-red-500 focus:ring-red-500 dark:bg-gray-700 dark:text-gray-400 focus:ring-1 ring-0 w-full border rounded-md pl-3 text-xs h-[39px] space-x-0";

  const StyleDisabledInput =
    "border-gray-300 focus:outline-none left-0 relative focus:border-blue-500 focus:ring-blue-500 focus:ring-1 ring-0 w-full border rounded-md h-[38px] text-xs pl-3 space-x-0 bg-gray-200";

  return (
    <div className={`text-xs ${specificStyle ? specificStyle : ""}`}>
      <label
        htmlFor={title}
        className={
          errors[name]
            ? "w-full text-red-600 my-auto  flex flex-col gap-1 whitespace-nowrap"
            : "w-full text-gray-600 my-auto font-normal flex flex-col gap-1 whitespace-nowrap"
        }
      >
         <p className="flex items-center dark:text-gray-300 gap-x-2">
          {title}:{" "}
          {isTooltip && (
            <span className="relative group">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-4"
              >
                <path
                  fillRule="evenodd"
                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 px-2 py-1 text-xs text-white bg-gray-700 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                {isMessageTooltip}
              </span>
            </span>
          )}
        </p>

        <div className="relative">
          {isMoneyInput && (
            <span className="absolute z-10 top-[12px] left-1.5">$</span>
          )}
         
          <NumberFormat
            format="##-####-###"
            
            disabled={disabled ? disabled : false}
            className={
                errors[name]
                  ? StyleInputError
                  : disabled
                  ? StyleDisabledInput
                  : StyleInput
              }
            placeholder=" "
            id={title}
              
            {...register(name, validations)}
            onValueChange={(values) => {
              setValue(name, values.value); // Actualiza manualmente el valor en RHF
            }}
            maxLength={maxLength}
            onChange={onChange}
            value={value}
          />
        </div>
        {errors[name] && errors[name].type === "required" && (
          <span className="text-red-600 text-[10px]">
            El Campo {title.toLowerCase()} es requerido.
          </span>
        )}
        {errors[name] && errors[name].type === "maxLength" && (
          <span className="text-red-600 text-[10px]">
            El Campo {title.toLowerCase()} no puede tener mas de{" "}
            {validations.maxLength} caracteres.
          </span>
        )}
        {/* {errors[name] &&
          errors[name].type === "pattern" &&
          (validations.pattern === AlfaNumerico ? (
            <span className="text-red-600 text-[10px]">
              El Campo {title.toLowerCase()} solo puede contener caracteres
              alfanumericos.
            </span>
          ) : validations.pattern === SoloLetras ? (
            <span className="text-red-600 text-[10px]">
              El Campo {title.toLowerCase()} solo puede contener letras.
            </span>
          ) : (
            <span className="text-red-600 text-[10px]">
              El Campo {title.toLowerCase()} contiene caracteres invalidos.
            </span>
          ))} */}
        {errors[name] && errors[name].type === "min" && type === "date" && (
          <span className="text-red-600 text-[10px]">
            El Campo {title.toLowerCase()} contine una fecha muy antigua.
          </span>
        )}
      </label>
    </div>
  );
};
