import { WarningIcon } from "../../../../Icons/WarningIcon";
import { useContext } from "react";
import { CategoriaContext } from "../context/CategoriaContext";
import { useModalCategoriaStore } from "./ModalCategoriaContext";
import { useStore } from "zustand";
import { ModalBase } from "../../Shared/ModalBase";
import { toast } from "react-toastify";

export const ModalEliminarCategoria = () => {
  const { deleteCategoria } = useContext(CategoriaContext);
  const { turnCategoriaModalOff, CategoriaAfectada } = useStore(
    useModalCategoriaStore
  );

  const doCategoriaDelete = async () => {
    turnCategoriaModalOff();
    await toast.promise(
      deleteCategoria(CategoriaAfectada.idCategoriaProducto),
      {
        pending: "Eliminando categoria...",
        success: "¡La categoria fue eliminada con éxito!",
        error: {
          render({ data }) {
            return (
              data?.response?.data?.message ||
              "Hubo un error al eliminar la categoria."
            );
          },
        },
      }
    );
  };

  return (
    <ModalBase onClose={turnCategoriaModalOff}>
      <section
        style={{
          padding: "38px",
          height: "fit-content",
          position: "relative",
        }}
        className="shadow-xl rounded-lg flex bg-white dark:bg-gray-900 flex-col gap-6 items-center lg:w-[600px] md:w-[450px] w-[375px]"
      >
        <div className="text-[#003B5B] h-full items-center justify-center">
          <WarningIcon />
        </div>
        <div className="flex flex-col gap-5 items-center">
          <h1 className="text-md font-semibold dark:text-white text-center">
            ¿Estás seguro que deseas eliminar la Categoria{" "}
            {CategoriaAfectada.nombre}?
          </h1>
          <div className="flex flex-row-reverse gap-8 text-white">
            <button
              onClick={() => doCategoriaDelete()}
              className="bg-[#003B5B] hover:bg-[#112935] p-8 pt-3 pb-3 h-8 text-[12px] w-28 flex items-center justify-center rounded-lg whitespace-nowrap"
            >
              Si, eliminar
            </button>
            <button
              onClick={() => turnCategoriaModalOff()}
              className="bg-gray-600 p-8 pt-2 pb-2 h-8 text-[12px] hover:bg-gray-700 flex items-center justify-center w-28 rounded-lg whitespace-nowrap"
            >
              No, mantener
            </button>
          </div>
        </div>
      </section>
    </ModalBase>
  );
};
