import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

export default function ModalComponent({
  isOpen,
  onClose,
  title,
  children,
  svg,
  dimensiones,
  disabled,
}) {
  const sizes = {
    largo: "lg:w-[48rem] md:w-[48rem]",
    mediano: "lg:w-[28rem] md:w-[28rem]",
  };

  const dimensionClass = sizes[dimensiones] || sizes.mediano;

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={disabled ? () => {} : onClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`w-full dark:bg-gray-700 transform overflow-hidden rounded-2xl ${dimensionClass} bg-white p-6 text-left align-middle shadow-xl transition-all`}
                >
                  <div className="flex flex-row justify-between items-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg flex dark:text-white flex-row gap-3 items-center font-medium leading-6 text-gray-900"
                    >
                      {svg && <span>{svg}</span>}
                      {title}
                    </Dialog.Title>

                    {!disabled && (
                      <div className="flex justify-end">
                        <svg
                          onClick={onClose}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-8 h-8 text-gray-500 cursor-pointer"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    )}
                  </div>

                  <div className="mt-2 w-full ">{children}</div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
