import { useContext, useState, useEffect } from "react";
import { FormBaseInventario } from "../../Shared/FormBaseInventario";
import { InputGlobal } from "../../context/inputContext";
import * as XLSX from "xlsx";
import { SeccionContext } from "../context/SeccionContext";
import { v4 as uuidv } from "uuid";
import AutoCompleteInput from "../../context/AutoCompleteSelect";
import { AlfaNumerico } from "../../regex.d";
import { useSelector } from "react-redux";
import useGlobalToastify from "../../../../features/hooks/GlobalToastify/useGlobalToastify";
import { toast } from "react-toastify";
import { ModalSubirExcel } from "../../Shared/ModalSubirExcel";
import { useProgressBar } from "../../hooks/useProgressBar";
import { EstablecimientosContext } from "../../../Mantenimiento/Establecimiento/EstablecimientosProvider";
import {
  GenerarExcelSecciones,
  ObtenerTodasBodegas,
} from "../../../../services/InventarioService";

export const CrearSeccionForm = () => {
  const [bodegas, setBodegas] = useState([]);
  const { documentos } = useContext(EstablecimientosContext);
  const { addSeccion, secciones, uploadExcelSecciones, getSecciones } =
    useContext(SeccionContext);
  const empresa = useSelector((state) => state.empresa.empresa);
  const { WarningToast, ErrorToast } = useGlobalToastify();
  const [isSending, setIsSending] = useState(false);
  const {
    isUploading,
    setIsUploading,
    progress,
    updateProgressBar,
    setProgress,
  } = useProgressBar();

  useEffect(() => {
    if (empresa && empresa.idEmpresa) {
      ObtenerTodasBodegas(
        "/api/bodega/obtener-todas-bodegas",
        empresa.idEmpresa
      ).then((response) => setBodegas(response.data));
    }
  }, [empresa]);

  const createSection = async (data) => {
    const newSeccion = {
      idSeccion: uuidv(),
      idEmpresa: empresa.idEmpresa,
      idBodega: data.bodega.idBodega,
      nombre: data.seccion.trimEnd().trimStart(),
      nombreBodega: data.bodega.nombre,
      codigoEstablecimiento: documentos.find(
        (establecimiento) =>
          establecimiento.idEstablecimiento === data.bodega.idEstablecimiento
      )?.codigo,
    };
    toast.dismiss();
    try {
      setIsSending(true);
      await toast.promise(addSeccion(newSeccion), {
        pending: "Guardando Sección...",
        success: "¡La sección fue guardada con éxito!",
        error: {
          render({ data }) {
            return (
              data?.response?.data?.message ||
              "Hubo un error al registrar la sección."
            );
          },
        },
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsSending(false);
    }
  };

  const handleFileImportSeccion = (e) => {
    const importedFile = e.target.files[0];
    const reader = new FileReader();
    const propiedadesRequeridas = [
      "Nombre Bodega",
      "Nombre de la sección",
      "Código del Establecimiento",
    ];

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
      const xlData = XLSX.utils.sheet_to_json(worksheet, { header: 0 });

      let isValid = true;

      for (const prop of propiedadesRequeridas) {
        if (!headers.includes(prop)) {
          isValid = false;
        }
      }
      if (isValid === false) {
        ErrorToast(
          "La plantilla de excel no cumple con los campos requeridos."
        );
        return;
      }

      let percentageIncrement = 0;
      let newSecciones = [];
      let seccionesNoUploaded = [];
      const totalIteraciones =
        Math.round(xlData.length / 10) < 1 ? 1 : Math.round(xlData.length / 10);

      percentageIncrement = 100 / totalIteraciones;
      for (const seccion of xlData) {
        const hasAllRequiredValues = propiedadesRequeridas.every(
          (prop) =>
            seccion[prop] !== "" &&
            seccion[prop] !== null &&
            seccion[prop] !== undefined
        );

        if (!hasAllRequiredValues) {
          seccionesNoUploaded.push(seccion);
          continue;
        } else {
          const newSeccion = {
            idSeccion: uuidv(),
            idEmpresa: empresa.idEmpresa,
            idBodega: bodegas.find(
              (bodega) =>
                String(bodega.nombre).toUpperCase().trim() ===
                String(seccion["Nombre Bodega"]).toUpperCase().trim()
            )?.idBodega,
            nombre: String(seccion["Nombre de la sección"]),
            codigoEstablecimiento: documentos
              .filter((d) => d.activo === true)
              .find(
                (establecimiento) =>
                  String(establecimiento.codigo).padStart(3, "0") ===
                  String(seccion["Código del Establecimiento"])
              )?.codigo,
          };

          if (newSeccion.idBodega && newSeccion.codigoEstablecimiento) {
            newSecciones.push(newSeccion);
          } else seccionesNoUploaded.push(newSeccion);
        }
      }
      setIsUploading(true);
      for (let i = 0; i < totalIteraciones; i++) {
        const batch = newSecciones.slice(i * 10, (i + 1) * 10);
        try {
          await uploadExcelSecciones(batch);
          updateProgressBar(percentageIncrement);
        } catch (err) {
          console.log(err);
        }
      }
      await getSecciones();
      setIsUploading(false);
      setProgress(0);

      if (seccionesNoUploaded.length > 0) {
        WarningToast(
          "No se subieron algunas secciones que no cumplieron los requerimientos"
        );
      }
    };
    reader.readAsArrayBuffer(importedFile);
  };

  const handleFileExportSecciones = async () => {
    try {
      const excelBase64 = await GenerarExcelSecciones(empresa.idEmpresa);
      const excelBinary = atob(excelBase64);
      const byteNumbers = new Array(excelBinary.length);
      for (let i = 0; i < excelBinary.length; i++) {
        byteNumbers[i] = excelBinary.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const blobUrl = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = "Secciones.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (err) {
      console.error("Error al procesar el archivo Excel:", err);
    }
  };

  const searchBodega = async (inputText) => {
    return bodegas.filter(
      (bodega) =>
        String(bodega.codigo)
          .toLowerCase()
          .includes(String(inputText).toLowerCase()) ||
        String(bodega.nombre)
          .toLowerCase()
          .includes(String(inputText).toLowerCase())
    );
  };

  return (
    <>
      <FormBaseInventario
        isSending={isSending}
        title={"Registro de Secciones"}
        submitFn={createSection}
        subtitle={
          <>
            <span>
              <svg
                fill="#ffffff"
                height="18"
                width="18"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                stroke="#ffffff"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <g>
                    {" "}
                    <g>
                      {" "}
                      <path d="M469.333,0h-192H42.667C30.885,0,21.333,9.551,21.333,21.333v128v213.333V448v42.667c0,11.782,9.551,21.333,21.333,21.333 C54.449,512,64,502.449,64,490.667v-21.333h213.333H448v21.333c0,11.782,9.551,21.333,21.333,21.333 c11.782,0,21.333-9.551,21.333-21.333V448V341.333V234.667V128V21.333C490.667,9.551,481.115,0,469.333,0z M64,170.667h42.667V192 c0,11.782,9.551,21.333,21.333,21.333c11.782,0,21.333-9.551,21.333-21.333v-21.333h21.333V192 c0,11.782,9.551,21.333,21.333,21.333s21.333-9.551,21.333-21.333v-21.333H256v64v106.667H64V170.667z M448,213.333H298.667v-64 H448V213.333z M298.667,256H448v64H298.667V256z M448,106.667H298.667v-64H448V106.667z M64,42.667h192V128h-42.667v-21.333 c0-11.782-9.551-21.333-21.333-21.333s-21.333,9.551-21.333,21.333V128h-21.333v-21.333c0-11.782-9.551-21.333-21.333-21.333 c-11.782,0-21.333,9.551-21.333,21.333V128H64V42.667z M64,384h192v42.667H64V384z M298.667,362.667H448v64H298.667V362.667z"></path>{" "}
                    </g>{" "}
                  </g>{" "}
                </g>
              </svg>
            </span>
            Formulario de Creación
          </>
        }
        valueProblem={["bodega", "seccion"]}
        exportFn={handleFileExportSecciones}
        importFn={handleFileImportSeccion}
      >
        <AutoCompleteInput
          title={"Bodega"}
          identificador={"idBodega"}
          parametro={"nombre"}
          validations={{ required: true }}
          array={bodegas}
          searchFn={searchBodega}
          option={(e) => `${String(e["codigo"])} - ${e["nombre"]}`}
        />
        <InputGlobal
          name={"seccion"}
          validations={{ required: true, pattern: AlfaNumerico, maxLength: 25 }}
          title={"Nombre de la Sección"}
          isPlaceHolder={"Ingrese el nombre de la sección"}
          type={"text"}
        />
      </FormBaseInventario>
      <ModalSubirExcel
        isVisible={isUploading}
        progress={progress}
        elemento={"Secciónes"}
      />
    </>
  );
};
