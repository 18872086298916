import { createContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getFechaInicio } from "../../../../../../services/Utilitario";
import { useSelector } from "react-redux";
import { ObtenerSecuencialPerTransaccion } from "../../../../../../services/InventarioService";

export const IngresoProviderContext = createContext();

const IngresoProvider = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const empresa = useSelector((state) => state.empresa.empresa);
  const [secuencial, setSecuencial] = useState(0);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (empresa && empresa.idEmpresa) getSecuencial();
  }, [empresa]);

  const getSecuencial = () => {
    ObtenerSecuencialPerTransaccion(
      empresa.idEmpresa,
      "8537215C-11CF-4A87-AF24-4AB5F8EBF66E"
    ).then((response) => setSecuencial(response.data));
  };

  const methods = useForm({
    defaultValues: {
      fechaCreacion: getFechaInicio(),
      tipoOperacion: "Ingreso",
    },
  });

  const [rows, setRows] = useState([
    {
      codigo: "",
      cuenta: "",
      cantidad: "",
      costo: "",
      lote: "",
      fechaCaducidad: "",
    },
  ]);

  return (
    <IngresoProviderContext.Provider
      value={{
        rows,
        setRows,
        methods,
        isSending,
        setIsSending,
        getSecuencial,
        setIsOpen,
        isOpen,
        secuencial,
      }}
    >
      {props.children}
    </IngresoProviderContext.Provider>
  );
};

export default IngresoProvider;
