import { FormProvider, useForm } from "react-hook-form";
import { InputContext } from "../../../../features/components/InputProvider/InputContext";
import { useEffect, useState } from "react";
import { MailsRegExp, UTF8RegExp } from "../../../../services/Utilitario";
import { StyleLabel, StyleSelect } from "../../../../features/Constantes";
import { SelectContext } from "../../../../features/components/SelectProvider/SelectContext";
import MailsForm from "../components/MailsForm";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import { getListV2, PostElement } from "../../../../services";
import validateSolicitud from "./validateSolicitud";
import { useNavigate } from "react-router";
import { Oval } from "react-loader-spinner";
import ModalSubmit from "./ModalSubmit";
import serviceAddContribuyente from "./serviceAddContribuyente";
import { NumberFormatProvider } from "../../../../features/components/NumberFormatProvider/NumberFormatProvider";
import UserErrorIcon from "./Icons/userErrorIcon";

export default function AddContribuyenteForm() {
  const initialState = {
    identificacion: "",
    razonsocial: "",
    telefonocelular: "",
    telefonoconvencional: "",
    activo: true,
    direccion: "",
    correo: "",
    idTipoIdentificacion: "",
    IsTransportista: "0",
    Placa: "",
    IsPartRel: "0",
  };
  const [IsLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const methods = useForm({ defaultValues: initialState });
  const empresa = useSelector((store) => store.empresa.empresa);
  const { validate } = validateSolicitud();
  const { postContribuyente } = serviceAddContribuyente();
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const [loadingForm, setLoadingForm] = useState(false);

  const handleSubmitModal = async (TotalValues) => {
    try {
      setIsLoading(true);
      toggleModal();
      setLoadingForm(true);

      await validate(
        TotalValues,
        methods,
        valueTransportista,
        empresa,
        setMessageSubmit,
        setMessageDescripcionSubmit,
        setsvgSubmit
      );
      await postContribuyente(
        empresa,
        TotalValues,
        setMessageSubmit,
        setMessageDescripcionSubmit,
        setsvgSubmit
      );

      setIsLoading(false);
      setLoadingForm(false);
      setTimeout(() => {
        navigate("/emision/reporte/contribuyentes");
        }, 1000);
    } catch (error) {
      setLoadingForm(false);
      console.log(error);
      setIsLoading(false);
    
      throw error;
    }
  };
  const [identificacionObs, setIdentificacionObs] = useState("");
  const [tipoIdentificacion, setTipoIdentificacion] = useState("");
  const valueTransportista = methods.watch("IsTransportista");
  const [messageSubmit, setMessageSubmit] = useState("");
  const [messageDescripcionSubmit, setMessageDescripcionSubmit] = useState("");
  const [svgSubmit, setsvgSubmit] = useState(null);

  const [OpenCorreo, setOpenCorreo] = useState(false);
  const [ImpLista, setImpLista] = useState([]);
  const [NewItem, setNewItem] = useState(initialState);

  useEffect(() => {
    const tipoIdentificacionMap = [
      { pattern: /^\d{10}$/, type: "Cédula" }, // Cédula con 10 dígitos
      { pattern: /^\d{13}$/, type: "RUC" }, // RUC con 13 dígitos
      { pattern: /^\d{11}$/, type: "Pasaporte" }, // 11 o 12 dígitos, vacío
      { pattern: /^[a-zA-Z0-9]+$/, type: "Pasaporte" }, // Pasaporte con letras y números
    ];
    const match = tipoIdentificacionMap.find((item) =>
      item.pattern.test(identificacionObs)
    );
    setTipoIdentificacion(match ? match.type : "");
  }, [identificacionObs]);

  useEffect(() => {
    (async () => {
      let impList = await getListV2(
        empresa,
        "GetTipoIdentificacion",
        "?activo=true"
      );
      let filterElements = impList._embedded.filter((el) => el.codigo !== "07");
      setImpLista(filterElements);

      setNewItem({
        ...initialState,
        idEmpresa: empresa.idEmpresa,
        idTipoIdentificacion: impList._embedded[0].idTipoIdentificacion,
      });
    })();
  }, []);

  return (
    <>
      <ModalSubmit
        disabled={loadingForm}
        isOpen={isOpen}
        toggleModal={toggleModal}
        IsLoading={IsLoading}
        message={messageSubmit}
        messageDescription={messageDescripcionSubmit}
        svgSubmit={svgSubmit}
      />
      <div className="space-y-6 xl:mx-4 my-2 mt-5 bg-white dark:bg-gray-700 p-4">
        <div className="bg-[#DBDBDB] dark:bg-gray-800 dark:text-white p-5 rounded-md ">
          <p className="font-semibold text-xl dark:text-white  text-black">
            Información del Contribuyente / Cliente
          </p>
          <p className="dark:text-gray-400">Agregar información</p>
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(handleSubmitModal)}
            className="bg-white shadow  dark:bg-gray-800 sm:rounded-lg md:py-2 md:px-4 py-5 px-2"
          >
            <p className="p-2 border-b text-lg text-black font-semibold border-b-gray-400 dark:text-gray-400">
              Datos Generales
            </p>
            <div className="relative z-0 mb-2  pt-2 w-full  mt-4 ">
              <div className="w-full grid  lg:grid-cols-5 md:grid-cols-2 grid-cols-1 md:gap-4 mb-2 gap-2  ">
                <InputContext
                  name={"identificacion"}
                  isTooltip={true}
                  isMessageTooltip={"Número de RUC, cédula o pasaporte"}
                  title={`Identificación${
                    tipoIdentificacion ? ` / ${tipoIdentificacion}` : ""
                  } *`}
                  validations={{
                    required: {
                      value: true,
                      message: "Identificación es requerido",
                    },
                    maxLength: {
                      value: 20,
                      message: "No más de 20 carácteres!",
                    },
                    minLength: {
                      value: 3,
                      message: "Mínimo 3 carácteres",
                    },
                    pattern: /^[a-zA-Z0-9 -]+$/,
                  }}
                  max={20}
                  onChange={(e) => {
                    const value = e.target.value.trim();
                    methods.setValue("identificacion", value);
                    setIdentificacionObs(value);
                  }}
                />

                <InputContext
                  name={"razonsocial"}
                  title={"Razón social *"}
                  validations={{
                    required: {
                      value: true,
                      message: "Nombre es requerido",
                    },
                    maxLength: {
                      value: 300,
                      message: "No más de 300 carácteres!",
                    },
                    minLength: {
                      value: 4,
                      message: "Mínimo 4 carácteres",
                    },
                    pattern: UTF8RegExp,
                  }}
                  max={300}
                />

                <InputContext
                  name={"direccion"}
                  title={"Dirección *"}
                  validations={{
                    required: {
                      value: true,
                      message: "Nombre es requerido",
                    },
                    maxLength: {
                      value: 300,
                      message: "No más de 300 carácteres!",
                    },
                    minLength: {
                      value: 3,
                      message: "Mínimo 3 carácteres",
                    },
                    pattern: UTF8RegExp,
                  }}
                  maxLength={300}
                  max={300}
                />
                <div className="lg:col-span-2">
                  <InputContext
                    specificStyles={"col-span-2"}
                    name={"correo"}
                    title={"Correo *"}
                    isTooltip={true}
                    isMessageTooltip={"ejemplo1@hotmail.com;ejemplo2@hotmail.com;..."}
                    validations={{
                      required: {
                        value: true,
                        message: "Correo es requerido",
                      },
                      maxLength: {
                        value: 300,
                        message: "No más de 300 carácteres!",
                      },
                      minLength: {
                        value: 4,
                        message: "Mínimo 4 carácteres",
                      },
                      pattern: MailsRegExp,
                    }}
                    max={300}
                    onChange={(e) => {
                      const trimmedValue = e.target.value.trim();
                      methods.setValue("correo", trimmedValue);
                      console.log(trimmedValue + "input actualizado");
                    }}
                  />
                </div>

                <InputContext
                  specificStyles={"col-span-2"}
                  name={"telefonocelular"}
                  isTooltip={true}
                  
                  isMessageTooltip={"(EJ:0963855966)"}
                  title={"Teléfono celular (Opcional)"}
                  validations={{
                    maxLength: {
                      value: 14,
                      message: "No más de 13 carácteres!",
                    },
                    minLength: {
                      value: 4,
                      message: "Mínimo 4 carácteres",
                    },
                  }}
                  max={14}
                  pattern="^\+?[0-9]+$"
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^\+?[0-9]*$/;
                    const plusCount = (value.match(/\+/g) || []).length;
                    if (
                      regex.test(value) &&
                      plusCount <= 1 &&
                      (plusCount === 0 || value.startsWith("+"))
                    ) {
                      methods.setValue("telefonocelular", value);
                    } else {
                      let sanitizedValue = value.replace(/[^0-9]/g, "");
                      if (value.startsWith("+")) {
                        sanitizedValue = "+" + sanitizedValue;
                      }
                      methods.setValue("telefonocelular", sanitizedValue);
                    }
                  }}
                  maxLength={14}
                />
              </div>
              <h1 className="text-lg mb-8 dark:text-white   font-semibold p-2 border-b border-b-gray-400">
                Datos Adicionales
              </h1>
              <div className="w-full grid  lg:grid-cols-5   md:grid-cols-2 grid-cols-1 md:gap-4 gap-2 pt-2 ">
                <NumberFormatProvider
                  name={"telefonoconvencional"}
                  isTooltip={true}
                  isMessageTooltip={"(EJ:042123456)"}
                  title={" Teléfono convencional (Opcional)"}
                  max={14}
                
                  validations={{
                    maxLength: {
                      value: 15,
                      message: "No más de 15 carácteres!",
                    },
                    minLength: {
                      value: 4,
                      message: "Mínimo 4 carácteres",
                    },
                  }}
                />
                {["RUC"].includes(tipoIdentificacion) && (
                  <SelectContext
                    name="EsRuc"
                    children={
                      <>
                        <option value="0">NO</option>
                        <option value="1">SI</option>
                      </>
                    }
                    title={" También es Cédula"}
                  />
                )}

                {["Cédula"].includes(tipoIdentificacion) && (
                  <SelectContext
                    name="EsCedula"
                    children={
                      <>
                        <option value="0">NO</option>
                        <option value="1">SI</option>
                      </>
                    }
                    title={"También es RUC"}
                  />
                )}

                <SelectContext
                  children={
                    <>
                      <option value="0">NO</option>
                      <option value="1">SI</option>
                    </>
                  }
                  name="IsPartRel"
                  title={"Es Parte Relacionada"}
                />
                <SelectContext
                  name="IsTransportista"
                  title={"Es transportista"}
                  children={
                    <>
                      <option value="0">NO</option>
                      <option value="1">SI</option>
                    </>
                  }
                />
                {valueTransportista === "1" && (
                  <InputContext
                    name={"Placa"}
                    title={"Placa (GMT-1234)"}
                    validations={{
                      maxLength: {
                        value: 10,
                        message: "No más de 10 carácteres!",
                      },
                      minLength: {
                        value: 4,
                        message: "Mínimo 4 carácteres",
                      },
                      required: {
                        value: true,
                        message: "Nombre es requerido",
                      },
                      pattern: UTF8RegExp,
                    }}
                    max={10}
                    maxLength="10"
                  />
                )}
              </div>
            </div>
            <div className="">
              <div>
                <div>
                  <p
                    onClick={() => setOpenCorreo(!OpenCorreo)}
                    className="text-lg font-semibold p-2 border-b border-b-gray-400 select-none cursor-pointer dark:text-white flex gap-x-2  items-center   font-body"
                  >
                    Personalización de correos de envío (Opcional){" "}
                    {!OpenCorreo ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    )}
                  </p>
                  {OpenCorreo ? (
                    <MailsForm
                      setValue={methods.setValue}
                      watch={methods.watch}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="flex align-middle lg:justify-end justify-center my-2  ">
              <button
                onClick={() => navigate("/emision/reporte/contribuyentes")}
                className="text-white bg-[#6B6B6B] hover:bg-gray-600 focus:ring-4 focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-base px-6 py-2.5 text-center md:mr-5 mb-3 md:mb-0 inline-flex items-center justify-center"
              >
                Cancelar
              </button>

              <button
                type="submit"
                disabled={IsLoading}
                className=" text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-base px-6 py-2.5 text-center md:mr-5 mb-3 md:mb-0 inline-flex items-center justify-center"
              >
                <p>Agregar</p>
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
}
