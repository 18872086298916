import React, { createContext, useState, useEffect } from "react";
import {
  GuardarConfiguracion,
  ObtenerConfiguracionInventarioEmpresa,
  ObtenerElementoConfiguracion,
} from "../../../../services/InventarioService";
import { useSelector } from "react-redux";
import { ModalCategoriasStatus } from "../../Categorias/Crear/ModalCategoriaContext";
import { ModalStatusConfiguracion } from "../modalConfiguracion.Slice";

export const MedidasContext = createContext();

export const MedidasProvider = ({ children }) => {
  const [medidas, setMedidas] = useState([]);
  const empresa = useSelector((state) => state.empresa.empresa);
  const [medidasEmpresa, setMedidasEmpresa] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const modalStatus = useSelector(
    (state) => state.modalConfiguracion.modalStatus
  );

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        if (medidas.length === 0) {
          await getUnidades();
        }
        if (
          empresa &&
          empresa.idEmpresa &&
          modalStatus === ModalStatusConfiguracion.medidas
        ) {
          await getEmpresaUnidades();
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [empresa, modalStatus]);

  const getUnidades = async () => {
    const response = await ObtenerElementoConfiguracion(
      "/api/unidad-medida/obtener-unidad-medida"
    );
    setMedidas(response.data);
  };

  const getEmpresaUnidades = async () => {
    const res = await ObtenerConfiguracionInventarioEmpresa(
      "/api/unidad-medida/obtener-unidad-medida-empresa",
      empresa.idEmpresa
    );
    setMedidasEmpresa(res.data);
  };

  const saveChangesMedidas = async () => {
    const changes = {
      idEmpresa: empresa.idEmpresa,
      idsUnidadesMedidas: medidasEmpresa,
    };
    await GuardarConfiguracion(
      "/api/unidad-medida/crear-unidad-medida-empresa",
      changes
    );
  };

  return (
    <MedidasContext.Provider
      value={{
        medidas,
        medidasEmpresa,
        isLoading,
        setMedidasEmpresa,
        saveChangesMedidas,
      }}
    >
      {children}
    </MedidasContext.Provider>
  );
};
