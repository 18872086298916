import { useContext, useState } from "react";
import { TransaccionesContext } from "./context/TransaccionesContext";
import { ClipLoader } from "react-spinners";
import useGlobalToastify from "../../../../features/hooks/GlobalToastify/useGlobalToastify";

export const TransaccionesEmitidasItem = ({
  Transaccion,
  TransaccionesChecked,
  handleClick,
}) => {
  const { setSelectedTransaccion, generatePdf } =
    useContext(TransaccionesContext);
  const [isDownloading, setIsDownloading] = useState(false);
  const { ErrorToast } = useGlobalToastify();

  const downloadPdf = async () => {
    try {
      setIsDownloading(true);
      await generatePdf(Transaccion);
    } catch (err) {
      ErrorToast("Hubo un error al descargar el PDF.");
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <tr className="bg-white dark:bg-gray-700 text-center dark:text-white font-normal border-y-2 border-gray-300 dark:border-gray-600">
      <th style={{ padding: "15px" }}>
        <input
          type="checkbox"
          checked={
            TransaccionesChecked.find(
              (transaccion) => transaccion === Transaccion.idTransaccion
            )
              ? true
              : false
          }
          onChange={() => handleClick(Transaccion.idTransaccion)}
        />
      </th>
      <th className="font-normal whitespace-nowrap px-4">
        {Transaccion.tipoTransaccion.slice(0, 1) +
          Transaccion.tipoTransaccion.slice(1).toLowerCase()}
      </th>
      <th className="font-normal whitespace-nowrap px-4">
        {Transaccion?.fechaCreacion.toString().replace("T", "-")}
      </th>
      <th className="font-normal whitespace-nowrap">
        {Transaccion.trasaccion}
      </th>
      <th className="font-normal px-4">
        {" "}
        {Transaccion?.ubicacion.replace(":", "↔")}
      </th>
      <th
        style={{ padding: "15px" }}
        className="flex flex-row gap-3 items-center justify-center"
      >
        <button
          onClick={() => {
            setSelectedTransaccion(Transaccion);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-6 w-6 dark:text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        </button>
        <button
          onClick={() => {
            downloadPdf();
          }}
          className="flex items-center"
        >
          {isDownloading === false ? (
            <img src="/PFD.png" width="20" alt="descargar pdf" />
          ) : (
            <ClipLoader color="#d8d4d4" size={"20"} />
          )}
        </button>
      </th>
    </tr>
  );
};
