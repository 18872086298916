import React, { useContext, useEffect } from "react";
import { EmitirFacturaContext } from "./EmitirFacturaProvider";
import { rellenarCeros, roundToTwo } from "../../../services/Utilitario";
import useReembolso from "../../../features/hooks/Reembolso/useReembolso";
import MobileButtonOptionsFactura from "./MobileButtonOptionsFactura";
import useFacturaExportacion from "./hooks/FacturaExportacion/useFacturaExportacion";
import EmisorFactura from "./EmisorFactura";
import { FormProvider } from "react-hook-form";
import ContribuyenteUseForm from "../../../features/components/ContribuyenteUseForm";
import MobileSubmitButton from "./MobileSubmitButton";
import useVistaPreviaFactura from "./hooks/useVistaPreviaFactura";
import ExportacionForm from "./hooks/FacturaExportacion/ExportacionForm";
import FormBuscarDetalle from "./FormBuscarDetalle";
import ListaDetalle from "./ListaDetalle";
import Reembolso from "./Reembolso";
import FormaPago from "./FormaPago";
import TotalDocumento from "./TotalDocumento";
import BuscarFacturaModalUseForm from "./BuscarFacturaModalUseForm";
import InformacionAdicionalUseForm from "../../../features/components/InformacionAdicionalUseForm";
import useGlobalToastify from "../../../features/hooks/GlobalToastify/useGlobalToastify";
import { useState } from "react";
import { PuntoVentaContext } from "../../Mantenimiento/InfoEmpresa/PuntoVentaProvider";
import { useSelector } from "react-redux";
import { ReembolsoContext } from "../../../features/hooks/Reembolso/ReembolsoProvider";
import SelectForm from "../../../features/components/common/SelectForm";
import HeaderFactura from "./HeaderFactura";
import HeaderFacturaOtros from "./HeaderFacturaOtros";
import { FacturaExportacionContext } from "./hooks/FacturaExportacion/FacturaExportacionProvider";
import { Transition } from "@headlessui/react";
import Toggle from "../../../features/components/Toggle";
import moment from "moment";
import { toast } from "react-toastify";
import SecuencialProvider from "./hooks/SecuencialProvider";
import { ModalHours } from "../../../features/hooks/ModalHours";
import { TextMessageCom } from "../../../features/components/TextMessageCom";
import { ClipLoader } from "react-spinners";
const FormFactura = () => {
  const {
    DetalleList,
    Totales,
    guardarDocumento,
    setSaveData,
    setSaveDataSvg,
    setSaveDataMessage,
    methods,
    handleResetDocument,
    FormaPagoList,
    setActiveReembolso,
    setSaveDataNotificacion,
    setSaveDataPTVenta,
    setConsumidorFinalToggle,
    ConsumidorFinalToggle,
    isOpenModalHours,
    toggleModal,
    isOver72Hours,
  } = useContext(EmitirFacturaContext);

  const { IsActiveExportacion, listExportacion } = useContext(
    FacturaExportacionContext
  );

  const empresa = useSelector((store) => store.empresa.empresa);

  const { getVistaPreviaFactura, IsLoading, getVistaPreviaProforma } =
    useVistaPreviaFactura();

  const { setIsActiveExportacion } = useFacturaExportacion();
  const { ErrorToast } = useGlobalToastify();

  const SecuencialVal = methods.watch("secuencial");
  const clienteIdentificacionObs = methods.watch("clienteIdentificacion");

  const [ShowExtendedButtons, setShowExtendedButtons] = useState(true);

  useEffect(() => {
    if (clienteIdentificacionObs === "9999999999999") {
      setShowExtendedButtons(false);
      setActiveReembolso(false);
      setIsActiveExportacion(false);
    } else {
      setShowExtendedButtons(true);
    }
  }, [clienteIdentificacionObs]);

  const handleClickSubmitFactura = (e) => {
    try {
      setSaveDataSvg(3);
      validateForm(methods.getValues());
      //if (Totales.ValorTotal !== 0) return e.preventDefault();
      if (DetalleList.length <= 0) return e.preventDefault();
      setSaveData(true);
      if (isOver72Hours) {
        setSaveDataMessage(<TextMessageCom />);
      } else {
        if (ConsumidorFinalToggle && Totales.importeTotal > 50) {
          setSaveDataNotificacion(
            'El valor total supera los $ 50 USD <br /> <br /> Si aplicas a la: RESOLUCIÓN "Nro. NAC-DGERCGC23-00000023 ACTIVIDAD DE TRANSPORTE COMERCIAL EN TAXIS Y TRANSPORTE PÚBLICO INTRACANTONAL E INTRAPROVINCIAL EN BUSES" Las facturas de "CONSUMIDOR FINAL" pueden tener cualquier valor de importe total en estos casos. <br /> <br / > Si no aplicas debes AGREGAR datos de tu cliente'
          );
        }
        setSaveDataMessage("¿ESTÁ SEGURO DE QUE DESEA ENVIAR AL SRI?");
      }
    } catch (ex) {}
  };

  const handleClickSubmitFacturaPTVenta = (e) => {
    try {
      validateForm(methods.getValues());
      if (Totales.ValorTotal !== 0) return e.preventDefault();
      if (DetalleList.length <= 0) return e.preventDefault();
      setSaveDataPTVenta(true);
      setSaveDataMessage("¿ESTÁ SEGURO DE QUE DESEA ENVIAR AL SRI?");
    } catch (ex) {}
    /* getVistaPreviaFactura()
     getVistaPreviaFacturaPTVenta() */
  };
  const [valueOptions, setValueOptions] = useState("FORMAPAGO");

  const [valueOptionsHeader, setValueOptionsHeader] = useState("FACTURA");
  const validateForm = (data) => {
    if (
      data.guiaRemision !== "" &&
      data.guiaRemision !== undefined &&
      !/^[0-9]{3}-[0-9]{3}-[0-9]{9}/.test(data.guiaRemision)
    ) {
      ErrorToast(
        "El campo 'Guia Remision' no coincide con el patron requerido!"
      );
      throw new Error("Error de validacion");
    }

    if (data.fechaEmision > moment().format("YYYY-MM-DD")) {
      ErrorToast("La fecha de emisión no puede ser mayor a la fecha actual");
      throw new Error("Error de validacion");
    }

    if (data.fechaEmision > "2024-03-31") {
      DetalleList.forEach((detalle) => {
        if (detalle.idImpuestoIva === "a5b8f607-8d38-4150-97af-c591f83678d8") {
          ErrorToast(
            `El producto ${detalle.codigoPrincipal} tiene un impuesto no vigente`
          );
          throw new Error("Error de validacion");
        }
      });
    }

    const importeTotal = roundToTwo(
      Number(listExportacion.fleteInternacional || 0) +
        Number(listExportacion.seguroInternacional || 0) +
        Number(listExportacion.gastosAduaneros || 0) +
        Number(listExportacion.gastosTransporteOtros || 0) +
        (Totales.importeTotal || 0)
    );
    if (importeTotal !== Totales.TotalFormaPago) {
      ErrorToast(
        "El total de las formas de pago no coincide con el total de la factura!"
      );
      throw new Error("Error de validacion");
    }
    if (
      data.clienteIdentificacion !== "9999999999999" &&
      (data.clienteDireccion === "" ||
        data.clienteEmail === "" ||
        data.clienteIdentificacion === "" ||
        data.clienteRazonSocial === "")
    ) {
      ErrorToast("Falta informacion en el apartado de contribuyente!");
      throw new Error("Error de validacion");
    }

    if (DetalleList.length < 1) {
      ErrorToast("Falta agregar un producto o servicio en la factura!");
      throw new Error("Error de validacion");
    }

    if (FormaPagoList.length < 1) {
      ErrorToast("Falta agregar una forma de pago valida a la factura!");
      throw new Error("Error de validacion");
    }

    if (IsActiveExportacion) {
      if (data.exportacion.incotermFactura === "") {
        ErrorToast("Falta");
        throw new Error("Error de validacion");
      }

      const regex = /^[A-Z]+$/;

      if (!regex.test(data.exportacion.incotermFactura)) {
        ErrorToast(
          "El campo 'Incoterm factura*' solo acepta letras en mayusculas"
        );
        throw new Error("Error de validacion");
      }
      if (!regex.test(data.exportacion.incotermTotalSinImp)) {
        ErrorToast(
          "El campo 'Incoterm total sin impuesto' solo acepta letras en mayusculas"
        );
        throw new Error("Error de validacion");
      }
    }

    if (!ConsumidorFinalToggle) {
      const cadenaCorreosModificada = data.clienteEmail
        .replace(/,/g, ";")
        .replace(/\s+/g, "");

      if (
        cadenaCorreosModificada !== "" &&
        !/^([^\s@]+@[^\s@]+\.[^\s@]+)(;[^\s@]+@[^\s@]+\.[^\s@]+)*$/.test(
          cadenaCorreosModificada
        )
      ) {
        ErrorToast("El campo 'Correo' no coincide con el patron requerido222");
        throw new Error("Error de validacion");
      }
    }
    // if(ActiveReembolso){
    //   const reembolso = roundToTwo(SumaReembolso);
    //     if(reembolso !== Totales.importeTotal){
    //       ErrorToast("El total de reembolso no coincide con el total de la factura!");
    //       throw new Error("Error de validacion");
    //     }
    // }

    const fechaEmision = moment(data.fechaEmision, "YYYY-MM-DD");
    const fechaLimite = moment().subtract(3, "months");

    if (fechaEmision.isAfter(fechaLimite)) {
      console.log(
        "La fecha de emisión es válida y está dentro de los últimos 3 meses."
      );
    } else {
      toast.error(
        "La fecha de emisión no es válida, no está dentro de los últimos 3 meses."
      );

      throw new Error("Error de validacion");
    }
  };

  const ClassAvite =
    " lg:py-1.5 md:py-1.5 py-1 px-1 bg-[#003B5B] cursor-pointer poin w-full rounded-tl-lg flex items-center justify-center text-white";

  const ClassInactive =
    " lg:py-1.5 md:py-1.5 py-1 px-1 w-full bg-gray-400 cursor-pointer text-white flex items-center justify-center rounded-tl-lg";
  const ClassInactive2 =
    " lg:py-1.5 md:py-1.5 py-1 px-1 w-full bg-gray-400 cursor-pointer text-white flex lg:justify-center justify-between items-center rounded-tr-lg";

  const ClassActive2 =
    " lg:py-1.5 md:py-1.5 py-1 px-1 text-white w-full cursor-pointer bg-[#003B5B] flex lg:justify-center justify-between  items-center rounded-tr-lg";

  const [isOpen, setIsOpen] = useState(true);
  const [isOpen2, setIsOpen2] = useState(true);
  return (
    <FormProvider {...methods}>
      <ModalHours
        isOpenModalHours={isOpenModalHours}
        toggleModal={toggleModal}
      />
      {IsLoading && (
        <div
          style={{ width: "100%", height: "100%", zIndex: "100" }}
          className="bg-opacity-80 fixed justify-center flex items-center top-0 l-0  bg-white text-center"
        >
          <ClipLoader color="#d8d4d4" size={"50"} />
        </div>
      )}
      <form onSubmit={guardarDocumento}>
        <SecuencialProvider>
          <div className=" rounded-lg bg-white  dark:bg-gray-900  w-full  ">
            <div className="flex  justify-between ">
              <Toggle
                setValueOptionsHeader={setValueOptionsHeader}
                text={"Factura"}
              >
                <MobileButtonOptionsFactura />
              </Toggle>

              <div className="lg:flex md:flex sm:hidden items-center  ">
                <div className="group relative mr-4  hidden md:table-cell lg:table-cell">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#searchFacturaModal"
                    id="searchFacturaModalButton"
                    type="button"
                    className="flex items-center border mt-1 p-2 dark:text-white  cursor-pointer gap-x-2 justify-center rounded-xl  hover:shadow-md    transition dark:bg-gray-800 dark:hover:bg-gray-800"
                  >
                    Replicar factura
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="h-6 w-6 dark:text-white "
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                      />
                    </svg>
                  </button>
                </div>
                <div className=" group relative inline-block">
                  <div className=" cursor-pointer w-10 h-10 rounded-full   inline-flex items-center justify-center">
                    <button
                      type="button"
                      onClick={handleResetDocument}
                      className="inline-flex h-10 w-10 mt-1 cursor-pointer  hover:shadow-md  items-center justify-center rounded-xl  transition dark:bg-gray-900 dark:hover:bg-gray-800"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-6 w-6 dark:text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>

                      <div className="absolute mx-auto w-24  -top-8 -left-8 hidden dark:bg-white     rounded-md bg-gray-800 group-hover:flex text-center p-1">
                        <p className="text-white dark:text-black mx-auto text-sm ">
                          Limpiar
                        </p>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className=" w-full p-2 ">
              {valueOptionsHeader === "FACTURA" ? (
                <div className="bg-[#F7F7F7] p-2 dark:bg-gray-700">
                  <HeaderFactura SecuencialVal={SecuencialVal} />
                </div>
              ) : (
                <div className="bg-[#F7F7F7] p-2 dark:bg-gray-700 ">
                  <HeaderFacturaOtros
                    ShowExtendedButtons={ShowExtendedButtons}
                  />
                </div>
              )}
            </div>
          </div>

          <div className=" bg-white md:block flex flex-col gap-2  dark:bg-gray-900 rounded-l px-4  py-4 my-2">
            <div className=" borde flex flex-wrap-reverse lg:gap-14 md:gap-14 ">
              <div className="relative w-full group lg:mx-0 md:mx-0 mx-1">
                <ContribuyenteUseForm
                  setConsumidorFinalToggle={setConsumidorFinalToggle}
                  ConsumidorFinalToggle={ConsumidorFinalToggle}
                />
              </div>
            </div>

            <div className=" lg:py-4 md:py-4 pt-1">
              <FormBuscarDetalle />
            </div>

            <div className="">
              <ExportacionForm />
            </div>

            <div className="">
              <Reembolso />
            </div>

            <div className="lg:grid md:grid hidden lg:grid-cols-2 grid-cols-1 md:gap-14 w-full ">
              <div
                className={`relative z-0 mb-2   ${
                  DetalleList.length > 0 && "border"
                }  rounded-lg  w-full group dark:border-gray-600`}
              >
                <div className="flex space-x-0 w-full">
                  <h3
                    onClick={() => setValueOptions("FORMAPAGO")}
                    className={
                      valueOptions === "FORMAPAGO" ? ClassAvite : ClassInactive
                    }
                  >
                    Forma de pago
                  </h3>
                  <h3
                    onClick={() => setValueOptions("INFORMACIONADICIONAL")}
                    className={
                      valueOptions === "INFORMACIONADICIONAL"
                        ? ClassActive2
                        : ClassInactive2
                    }
                  >
                    Información adicional (Opcional)
                  </h3>
                </div>

                <Transition
                  show={
                    DetalleList.length === 0 ? false : DetalleList.length > 0
                  }
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  {(ref) =>
                    valueOptions === "FORMAPAGO" ? (
                      <FormaPago />
                    ) : (
                      <InformacionAdicionalUseForm
                        Contexto={EmitirFacturaContext}
                      />
                    )
                  }
                </Transition>
              </div>

              <div className="relative z-0 border rounded-lg border-gray-300 dark:border-gray-600 w-full group">
                <TotalDocumento />
              </div>
            </div>

            <div className="lg:hidden md:hidden border dark:border-gray-600 rounded-b-lg rounded-t-lg mx-1 my-1">
              <div className="text-sm py-1.5   px-2 text-left bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 dark:border-gray-700 rounded-t-lg font-medium leading-6 text-white flex justify-between">
                <h3 className="flex w-full justify-between items-center">
                  Forma de pago
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => setIsOpen(!isOpen)}
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`w-4 h-4 duration-200 ${
                      isOpen ? "rotate-180" : ""
                    }`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </h3>
              </div>
              <Transition
                show={isOpen}
                className={"mx-4"}
                enter="transition ease-out duration-300" // Aumenta la duración de entrada
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-300" // Aumenta la duración de salida
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <FormaPago />
              </Transition>
            </div>

            <div className="lg:hidden md:hidden border dark:border-gray-600 rounded-b-lg rounded-t-lg mx-1 my-1">
              <div className="text-sm py-1.5  px-2 text-left bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 dark:border-gray-700 rounded-t-lg font-medium leading-6 text-white flex justify-between">
                <h3 className="flex w-full justify-between items-center">
                  Información adicional (Opcional)
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => setIsOpen2(!isOpen2)}
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`w-4 h-4 duration-200 ${
                      isOpen2 ? "rotate-180" : ""
                    }`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </h3>
              </div>
              <Transition
                show={isOpen2}
                className={""}
                enter="transition ease-out duration-300" // Aumenta la duración de entrada
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-300" // Aumenta la duración de salida
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <InformacionAdicionalUseForm Contexto={EmitirFacturaContext} />
              </Transition>
            </div>
            <div className="lg:hidden md:hidden lg:px-0 md:px-0 px-1  border rounded-lg dark:border-gray-600 w-full ">
              <TotalDocumento />
            </div>

            <div className="flex lg:justify-end justify-center py-4 space-x-2 ">
              <div className="group relative  border-gray-300 border rounded-md inline-block">
                <button
                  type="button"
                  onClick={getVistaPreviaFactura}
                  className="inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-xl  hover:shadow-md    transition dark:bg-gray-900 dark:hover:bg-gray-800"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-6 dark:text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>

                  <div className="absolute mx-auto w-24  -top-8 -left-8 hidden dark:bg-white dark:text-black    rounded-md bg-gray-800 group-hover:flex text-center p-1">
                    <p className="text-white dark:text-black  mx-auto text-sm">
                      Vista previa{" "}
                    </p>
                  </div>
                </button>
              </div>

              <div className="group relative border-gray-300 border rounded-md hidden md:table-cell lg:table-cell">
                {/* nuevo boton */}
                {empresa.puntoventa && (
                  <button
                    //onClick={ptventa}
                    onClick={handleClickSubmitFacturaPTVenta}
                    type="button"
                    className="inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-xl  dark:text-white hover:shadow-md    transition dark:bg-gray-900 dark:hover:bg-gray-800"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z"
                      />
                    </svg>
                  </button>
                )}
                {/*  */}

                <div className="absolute mx-auto w-28  -top-8 -left-8 hidden dark:bg-white dark:text-black     rounded-md bg-gray-800 group-hover:flex text-center p-1">
                  <p className="text-white dark:text-black mx-auto text-sm">
                    Punto de Venta{" "}
                  </p>
                </div>
              </div>

              <div className="group relative  border-gray-300 border rounded-md inline-block">
                <button
                  type="button"
                  onClick={getVistaPreviaProforma}
                  className="inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-xl  hover:shadow-md    transition dark:bg-gray-900 dark:hover:bg-gray-800"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-6 dark:text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                    />
                  </svg>

                  <div className="absolute mx-auto w-24  -top-8 -left-8 hidden dark:bg-white dark:text-black    rounded-md bg-gray-800 group-hover:flex text-center p-1">
                    <p className="text-white dark:text-black  mx-auto text-sm">
                      Proforma{" "}
                    </p>
                  </div>
                </button>
              </div>

              <button
                onClick={handleClickSubmitFactura}
                type="button"
                className="border p-2 rounded-lg text-white bg-[#04BC53] hover:bg-[#03a448] transition-all duration-150 dark:bg-gray-800 dark:border-gray-600  w-auto flex"
              >
                <div className="mx-2">Enviar al SRI</div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 mr-2 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                  />
                </svg>
              </button>
            </div>

            {/* <MobileSubmitButton onSubmitFactura={handleClickSubmitFactura} /> */}
          </div>
        </SecuencialProvider>
      </form>

      <BuscarFacturaModalUseForm />
    </FormProvider>
  );
};

export default FormFactura;
