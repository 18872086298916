import React, { useEffect, createContext, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { post } from "../../../services";
import {
  getFechaInicio,
  getFechaV1,
  rellenarCeros,
  roundToTwo,
} from "../../../services/Utilitario";
import {
  CalcularSubTotales,
  TotalInicial,
} from "../../../features/bl/DocumentSharedBL-Debito";
import useGlobalToastify from "../../../features/hooks/GlobalToastify/useGlobalToastify";
import { CalcularImpuestosList } from "../EmitirFactura/services/impuestos";
import { toast } from "react-toastify";
import moment from "moment";
import { TextMessageCom } from "../../../features/components/TextMessageCom";
import { useHours } from "../../../features/hooks/useHours";

export const EmitirDocContext = createContext();

const EmitirDocProvider = (props) => {
  const empresa = useSelector((store) => store.empresa.empresa);

  const [InformacionAdicionalList, setInformacionAdicionalList] = useState([]);
  const [FormaPagoList, setFormaPagoList] = useState([]);
  const [saveData, setSaveData] = useState(false);
  const [saveDataMessage, setSaveDataMessage] = useState("");
  const [saveDataSvg, setSaveDataSvg] = useState(0);
  const [DetalleList, setDetalleList] = useState([]);
  const [Totales, setTotales] = useState({ ...TotalInicial });
  const [ValorTotal, setValorTotal] = useState(1);
  const [ObjSaveData, setObjSaveData] = useState({});
  const [DestinatariosList, setDestinatarioList] = useState([]);
  const [DataFromAdd, setDataFromAdd] = useState([]);
  const [ActivarModalDetalle, setActivarModalDetalle] = useState(false);
  const [DetalleItem, setDetalleItem] = useState([]);
  const { ErrorToast } = useGlobalToastify();
  const formik = useFormik({
    initialValues: {
      establecimiento: "",
      puntoEmision: "",
      secuencial: "",
      fechaEmision: getFechaInicio(),
      codigoComprobanteModificar: "01",
      numeroComprobanteModificar: "",
      fechaEmisionComprobanteModificar: getFechaInicio(),
      clienteIdentificacion: "",
      clienteRazonSocial: "",
      clienteDireccion: "",
      clienteEmail: "",
      clienteTelefono: "",
    },
    validationSchema: Yup.object({
      /*establecimiento: Yup.string()
                .max(3, 'Tamaño del campo 3'),
            puntoEmision: Yup.string()
                .max(3, 'Tamaño del campo 3'),*/
      secuencial: Yup.string().max(9, "Tamaño del campo 9"),
      clienteIdentificacion: Yup.string()
        .required("Este campo es requerido")
        .max(300),
      clienteRazonSocial: Yup.string()
        .required("Este campo es requerido")
        .max(300),
      clienteDireccion: Yup.string()
        .required("Este campo es requerido")
        .max(300),
      clienteEmail: Yup.string().required("Este campo es requerido").max(300),
      clienteTelefono: Yup.string().max(20),
    }),
    onSubmit: async (obj) => {
      if (formik.values.numeroComprobanteModificar.trim().length < 1) {
        toast.error(
          "El campo Secuencial Factura es Requerido, por favor dirijase a la sección 'Más opciones' y complete el campo"
        );
        formik.setErrors({
          numeroComprobanteModificar:
            "El campo Secuencial Factura es Requerido, por favor dirijase a la sección 'Más opciones' y complete el campo ",
        });
        return;
      }
      if (formik.values.numeroComprobanteModificar.trim().length !== 17) {
        toast.error("El campo Secuencial Factura debe contener 15 caracteres");
        return;
      }
      const fechaEmision = moment(formik.values.fechaEmision, "YYYY-MM-DD");
      const fechaLimite = moment().subtract(3, "months");
      if (fechaEmision.isAfter(fechaLimite)) {
        console.log(
          "La fecha de emisión es válida y está dentro de los últimos 3 meses."
        );
      } else {
        toast.error(
          "La fecha de emisión no es válida, no está dentro de los últimos 3 meses."
        );

        throw new Error("Error de validacion");
      }

      if (formik.values.fechaEmision > moment().format("YYYY-MM-DD")) {
        toast.error("La fecha de inicio no puede ser mayor a la fecha actual");
        return;
      }
      setSaveDataSvg(0);
      if (isOver72Hours) {
        setSaveDataMessage(<TextMessageCom />);
      } else {
        setSaveDataMessage("¿ESTÁ SEGURO DE QUE DESEA ENVIAR AL SRI?");
      }
      setSaveData(true);
      //console.log('---------------------')
      //console.log(obj)
      setObjSaveData(obj);
    },
  });

  const [isOpenModalHours, setisOpenModalHours] = useState(false);

  const toggleModal = () => {
    setisOpenModalHours(!isOpenModalHours);
  };

  const { isOver72Hours } = useHours({
    fechaActual: getFechaInicio(),
    FechaInput: formik.values.fechaEmision,
    toggleModal,
  });
  const guardarDocumento = async () => {
    setSaveDataSvg(1);
    setSaveDataMessage("PROCESANDO...");
    if (DetalleList.length > 0 && FormaPagoList.length > 0) {
      try {
        if (DetalleList.length > 0) {
          const documento = {
            Emisor: empresa.emisor,
            IdContribuyente: ObjSaveData.idContribuyente,
            IdentificacionCliente: ObjSaveData.clienteIdentificacion,
            RazonSocialContribuyente: ObjSaveData.clienteRazonSocial,
            Direccion: ObjSaveData.clienteDireccion,
            Correo: ObjSaveData.clienteEmail,
            TelefonoCelular: ObjSaveData.clienteTelefono,
            CodigoDocumentoModificado: ObjSaveData.codigoComprobanteModificar,
            NumeroDocumentoModificado:
              ObjSaveData.numeroComprobanteModificar.replace(/\s+/g, ""),
            FechaEmisionDocumentoModificado: getFechaV1(
              ObjSaveData.fechaEmisionComprobanteModificar
            ),
            Ambiente: process.env.REACT_APP_ENVIRONMENT,
            IdEmpresa: empresa.idEmpresa,
            RucEmpresa: empresa.ruc,
            TotalDescuento: Totales.totalDescuentos,
            CodDoc: 5,
            FechaEmision: getFechaV1(ObjSaveData.fechaEmision),
            ImporteTotal: Totales.importeTotal,
            TotalSinImpuestos: Totales.totalSinImpuesto,
            ListaImpuestoND: await CalcularImpuestosList(
              DetalleList,
              empresa,
              Totales
            ),

            InformacionAdicionalNotaDebito: InformacionAdicionalList.map(
              (x) => {
                return {
                  Descripcion: x.nombre,
                  Valor: x.valor,
                };
              }
            ),
            DetalleNotaDebito: DetalleList.map((e) => {
              return {
                Motivos: e.motivo,
                // (parseFloat (e.valor) * parseFloat(e.porcentaje)) + e.valor,
                Valor: e.valor,
                Descripcion: e.descripcion,
                Cantidad: e.cantidad,
                ImpuestoValorIva: e.iva,
                // CodigoPrincipal: e.codigoPrincipal,
                // PrecioUnitario: e.precioUnitario,
                // Descuento: 0,
                ImpuestoCodigoIva: 2,
                ImpuestoCodigoPorcentajeIva: e.idImpuestoIvaNavigation.codigo,
                PrecioTotalSinImpuesto: e.subTotal,
                ImpuestoBaseImponibleIva: e.subTotal,

                Tarifa: roundToTwo(e.idImpuestoIvaNavigation.porcentaje * 100),
                InformacionAdicionalProductoCredito:
                  e.servicioDetalle === undefined
                    ? []
                    : e.servicioDetalle === null
                    ? []
                    : e.servicioDetalle.map((x) => {
                        return {
                          Valor: x.nombre,
                          Descripcion: x.valor,
                        };
                      }),
              };
            }),

            FormaPagoNotaDebito: FormaPagoList.map((x) => {
              // alert("");

              return {
                UnidaDeTiempo: x.tiempoPago,
                Plazo: x.plazoPago,
                Total: x.valor,
                IdFormaPagoSri: x.tipoPago,
              };
            }),

            IdImpuestoNoObjectoImpuesto: DetalleList.find(
              (e) =>
                e.idImpuestoIvaNavigation.idImpuestoDetalle.toUpperCase() ===
                "8E7CF164-C66B-4312-945F-57AE08C86A33"
            )
              ? "8E7CF164-C66B-4312-945F-57AE08C86A33"
              : null,
            IdImpuestoExentoIva: DetalleList.find(
              (e) =>
                e.idImpuestoIvaNavigation.idImpuestoDetalle.toUpperCase() ===
                "F03C7F10-2773-4505-ACCB-B2FA88D62049"
            )
              ? "F03C7F10-2773-4505-ACCB-B2FA88D62049"
              : null,
            IdImpuestoIVA12: DetalleList.find(
              (e) =>
                e.idImpuestoIvaNavigation.idImpuestoDetalle.toUpperCase() ===
                "A5B8F607-8D38-4150-97AF-C591F83678D8"
            )
              ? "A5B8F607-8D38-4150-97AF-C591F83678D8"
              : null,
            IdImpuestoIVA0: DetalleList.find(
              (e) =>
                e.idImpuestoIvaNavigation.idImpuestoDetalle.toUpperCase() ===
                "81BAF871-E113-48CF-8AA8-670CB43494B6"
            )
              ? "81BAF871-E113-48CF-8AA8-670CB43494B6"
              : null,

            IdImpuestoIVA15: DetalleList.find(
              (e) =>
                e.idImpuestoIvaNavigation.idImpuestoDetalle.toUpperCase() ===
                "BED68281-CF9C-458E-9E0F-517A8FB02528"
            )
              ? "BED68281-CF9C-458E-9E0F-517A8FB02528"
              : null,

            IdImpuestoIVA5: DetalleList.find(
              (e) =>
                e.idImpuestoIvaNavigation.idImpuestoDetalle.toUpperCase() ===
                "2ED52B34-BA6C-475B-A82D-0F7C933412A7"
            )
              ? "2ED52B34-BA6C-475B-A82D-0F7C933412A7"
              : null,
            //IdImpuestoIVA14= 8DCD2CFA-5F9D-4FB7-B27A-87447D6CDDE7
            TotalTotalIva0: 0,
            TotalTotalIva: Totales.totalIva,
            TotalNoObjetoImpuesto: 0,
            TotalExentoIva: 0,
            TotalBaseIva0: Totales.subTotalIva0,
            TotalBaseIva: Totales.subTotalIva,
            TotalBaseNoObjetoImpuesto: Totales.subTotalNoObjetoIva,
            TotalBaseExentoIva: Totales.subTotalExentoIva,
            Establecimiento: ObjSaveData.establecimiento,
            PuntoEmision: ObjSaveData.puntoEmision,
            SubtotalIva12: Totales.totalSinImpuesto,
            Iva12: Totales.totalIva.toFixed(2),
          };

          const secuencial222 = ObjSaveData.numeroComprobanteModificar.replace(
            /\s+/g,
            ""
          );
          if (secuencial222.length !== 17) {
            ErrorToast(
              "El campo 'Número Doc. Sust.*' no coincide con el patron requerido"
            );
            throw new Error("Error de validacion");
          }

          const cadenaCorreosModificada = formik.values.clienteEmail
            .replace(/,/g, ";")
            .replace(/\s+/g, "");

          if (
            cadenaCorreosModificada !== "" &&
            !/^([^\s@]+@[^\s@]+\.[^\s@]+)(;[^\s@]+@[^\s@]+\.[^\s@]+)*$/.test(
              cadenaCorreosModificada
            )
          ) {
            ErrorToast("El campo 'Correo' no coincide con el patron requerido");
            throw new Error("Error de validacion");
          }

          if (ObjSaveData.clienteEmail !== null)
            if (ObjSaveData.clienteEmail.trim() !== "")
              documento.InformacionAdicionalNotaDebito.push({
                Valor: ObjSaveData.clienteEmail
                  .replace(/,/g, ";")
                  .replace(/\s+/g, ""),
                Descripcion: "email",
              });

          // console.log(JSON.stringify(documento))

          const Establecimiento = ObjSaveData.establecimiento;
          const PuntoEmision = ObjSaveData.puntoEmision;
          const Secuencial = ObjSaveData.secuencial;
          await post("/api/NotaDebito/Guardar", documento);
          formik.resetForm();
          formik.setFieldValue("establecimiento", Establecimiento);
          formik.setFieldValue("puntoEmision", PuntoEmision);
          formik.setFieldValue("secuencial", rellenarCeros(Secuencial, 9));
          setTotales({ ...TotalInicial });
          setDetalleList([]);
          setFormaPagoList([]);
          setInformacionAdicionalList([]);
          setSaveDataSvg(2);
          setSaveDataMessage("EL DOCUMENTO SE GENERO EXITOSAMENTE");
        }
      } catch (error) {
        //   console.log(error)
        setSaveDataSvg(-1);
        setSaveDataMessage("ERROR AL GENERAR EL DOCUMENTO");
      }
    } else {
      setSaveDataSvg(-1);
      if (DetalleList.length < 1) {
        setSaveDataMessage("FALTA AGREGAR DETALLE AL DOCUMENTO");
      } else if (FormaPagoList.length < 1) {
        setSaveDataMessage("EL DOCUMENTO NO POSEE FORMA DE PAGO");
      }
      // else if(ObjSaveData.NumeroDocumentoModificado == ""){
      //     setSaveDataMessage('NUMERO DOCUMENTO SUSTENTO REQUERIDO ')

      // }
    }
  };

  // useEffect(() => {
  //     let subTotalIva0 = 0
  //     let subTotalNoObjetoIva = 0
  //     let subTotalExentoIva = 0
  //     let subTotalIva = 0
  //     let propina = 0;
  //     let totalIce = 0;
  //     let totalIRBPNR = 0;
  //     let totalDescuentos = 0;
  //     let importeTotal = 0;
  //     let totalIva = 0;
  //     let totalSinImpuesto = 0;
  //     let formasPagoFactura = 0;

  //     DetalleList.map((a) => {
  //         totalSinImpuesto += Number(a.valor);
  //     })
  //     DetalleList.map((a) => {
  //         totalIva  += Number(a.valor * a.porcentaje)
  //     })

  //     DetalleList.map((a) => {
  //         let ivas =  Number(a.valor * a.porcentaje);
  //         importeTotal  += Number(a.valor)+ivas;
  //     })

  //     console.log(DetalleList);

  //     FormaPagoList.map((a) => {
  //         formasPagoFactura += Number(a.valor)

  //     });

  //     setValorTotal(importeTotal - formasPagoFactura);

  //     setTotales({
  //         subTotalIva: subTotalIva,
  //         subTotalIva0: subTotalIva0,
  //         subTotalNoObjetoIva: subTotalNoObjetoIva,
  //         subTotalExentoIva: subTotalExentoIva,
  //         totalSinImpuesto: totalSinImpuesto,
  //         SubtotalIva12:totalSinImpuesto,
  //         Iva12:totalIva,
  //         totalDescuentos: totalDescuentos,
  //         totalIce: totalIce,
  //         totalIva: totalIva,
  //         totalIRBPNR: totalIRBPNR,
  //         propina: propina,
  //         importeTotal: importeTotal
  //     })

  // }, [DetalleList, FormaPagoList])

  useEffect(() => {
    let Totales = CalcularSubTotales(DetalleList, FormaPagoList);
    //console.log(Totales)
    setTotales(Totales);
    setValorTotal(Totales.ValorTotal);
  }, [DetalleList, FormaPagoList]);

  return (
    <EmitirDocContext.Provider
      value={{
        isOpenModalHours,
        toggleModal,
        DetalleList,
        setDetalleList,
        saveData,
        setSaveData,
        saveDataMessage,
        saveDataSvg,
        guardarDocumento,
        formik,
        Totales,
        FormaPagoList,
        setFormaPagoList,
        InformacionAdicionalList,
        setInformacionAdicionalList,
        ActivarModalDetalle,
        setActivarModalDetalle,
        DetalleItem,
        setDetalleItem,
        ValorTotal,
        setTotales,
        DestinatariosList,
        setDestinatarioList,
        DataFromAdd,
        setDataFromAdd,
      }}
    >
      {props.children}
    </EmitirDocContext.Provider>
  );
};

export default EmitirDocProvider;
