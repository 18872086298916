import { Oval } from "react-loader-spinner";
import ModalComponent from "../../../../features/components/ModalComponets/ModalComponent";

export default function ModalSubmit({
  isOpen,
  toggleModal,
  IsLoading,
  message,
  disabled,
  messageDescription,
  svgSubmit
}) {
  return (
    <ModalComponent isOpen={isOpen} onClose={toggleModal} disabled={disabled}>
      <>
        <div className=" bg-white dark:bg-gray-700  text-gray-800 max-w-md mx-auto dark:text-gray-300">
          {IsLoading ? (
            <div className="flex flex-col items-center justify-center space-y-4">
              <Oval color="#00BFFF" height={30} width={30} />
              <p className="font-semibold text-lg">{message}</p>

              <p className="text-center text-gray-600 text-xs dark:text-gray-300">
                {messageDescription}
              </p>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center space-y-4">
              {/* <svg
                height={50}
                width={50}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="text-green-500"
              >
                <path
                  fillRule="evenodd"
                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                  clipRule="evenodd"
                />
              </svg> */}
              {svgSubmit}
              {/* Contribuyente agregado 
              El contribuyente ha sido agregado exitosamente. */}
              <p className="font-semibold text-sm dark:text-gray-300">{message}</p>

              <p className="text-center text-gray-600 text-xs dark:text-gray-300">
                {messageDescription}
              </p>
            </div>
          )}
          {/* <div className="mt-4 flex justify-end">
        <button
          onClick={toggleModal}
          className="px-4 py-2 text-sm font-semibold text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Cerrar
        </button>
      </div> */}
        </div>
      </>
    </ModalComponent>
  );
}
