import React, { useContext } from "react";

import { ReporteContribuyentesContext } from "./ReporteContribuyentesProvider";
import MesageWarningTable from "../../../features/components/MesageWarningTable";
import {
  TableStyle,
  TableStyleDiv,
  TableStyleTBody,
  TableStyleTHead,
} from "../../../features/Constantes";
import { NavLink } from "react-router-dom";
const ListaFacturas = () => {
  const {
    documentos,
    setNewItem,
    setActivarModalEdit,
    Paginacion,
    setPaginacion,
    formik,
    start,
    setStart,
    setInfoEmail,
  } = useContext(ReporteContribuyentesContext);

  // const handleClickEditarItem = (el, index) => {
  //   setNewItem({ ...el });
  //   // console.log("verificando");
  //   // console.log(el);
  //   setActivarModalEdit(true);
  // };

  const handleClickNextPage = () => {
    setStart(start + 1);
    setPaginacion({ ...Paginacion, pagina: Paginacion.pagina + 1 });
    formik.handleSubmit(formik.values);
  };

  const handleClickBeforePage = () => {
    setStart(start - 1);
    setPaginacion({ ...Paginacion, pagina: Paginacion.pagina - 1 });
    formik.handleSubmit(formik.values);
  };

  const handleClickLastPage = () => {
    setPaginacion({ ...Paginacion, pagina: Paginacion._totalpaginas });
    formik.handleSubmit(formik.values);
  };

  const handleClickFirstPage = () => {
    setPaginacion({ ...Paginacion, pagina: 1 });
    formik.handleSubmit(formik.values);
  };

  return (
    <>
    <div className="bg-white dark:bg-gray-700 mt-4 rounded-lg p-4">
      <div className=" inline-flex z-0 mx-4 my-3  group">
        <h1 className="float-left  mr-2 dark:text-white text-black">Mostrar</h1>
        <select
          type="text"
          name="cantidad"
          id="cantidad"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.cantidad}
          className="origin-left border-gray-300 border pr-2 pl-1  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          placeholder=" "
        >
          <option key="25" value="25">
            25
          </option>
          <option key="50" value="50">
            50
          </option>
          <option key="75" value="75">
            75
          </option>
          <option key="100" value="100">
            100
          </option>
        </select>
        <h1 className="float-left  ml-2 dark:text-white text-black">
          Registros
        </h1>
      </div>

      <div className={TableStyleDiv}>
        <table className={TableStyle}>
          <thead className={TableStyleTHead + " bg-[#d9d9d9] text-black"}>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3  text-sm font-semibold  sm:pl-6 lg:hidden "
              >
                Razón social
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3  text-sm font-semibold  sm:pl-6 lg:table-cell hidden"
              >
                Razón social
              </th>
              <th
                scope="col"
                className=" px-3 py-3.5  text-sm font-semibold  lg:table-cell hidden"
              >
                Identificación
              </th>
              <th
                scope="col"
                className=" px-3 py-3.5  text-sm font-semibold  lg:table-cell hidden "
              >
                Tipo Id.
              </th>

              <th scope="col" className="px-3 py-3.5  text-sm font-semibold  ">
                Dirección
              </th>
              <th
                scope="col"
                className="px-3 py-3.5  text-sm font-semibold  lg:table-cell hidden"
              >
                Celular
              </th>
              <th
                scope="col"
                className="px-3 py-3.5  text-sm font-semibold  lg:table-cell hidden "
              >
                Convencional
              </th>
              <th
                scope="col"
                className="px-3 py-3.5  text-sm font-semibold whitespace-nowrap"
              >
                Correo
              </th>
              <th scope="col" className="px-3 py-3.5  text-sm font-semibold ">
                Estado
              </th>
              <th scope="col" className="px-3 py-3.5  text-sm font-semibold ">
                Editar
              </th>
            </tr>
          </thead>
          <tbody className={TableStyleTBody}>
            {documentos.length < 1 ? (
              <MesageWarningTable
                colSpan="10"
                message="No se encontraron datos para la búsqueda."
              />
            ) : null}
            {documentos.map((obj, index) => {
              return (
                <tr key={index}>
                  <td className=" px-3 py-2 text-sm  lg:table-cell ">
                    <div className="text-sm  ">{obj.razonSocial}</div>
                    <div className="mt-1 flex flex-col  sm:block lg:hidden">
                      <span> {obj.identificacion} </span>
                      <span className="hidden sm:inline"> · </span>
                      <span>{obj.idTipoIdentificacionNavigation?.nombre}</span>
                    </div>
                  </td>
                  <td className="hidden px-3 py-2 text-sm  lg:table-cell ">
                    {obj.idTipoIdentificacionNavigation?.nombre === "RUC"
                      ? obj.identificacion + "001"
                      : obj.identificacion}  
 {obj.idTipoIdentificacion === 1 && obj.esRuc === true
                      ? " / 001"
                      : <></>}

                  </td>
                  {/*  <td className="hidden px-3 py-2 text-sm  lg:table-cell ">
                    {obj.idTipoIdentificacionNavigation?.nombre}
                  </td> */}

                  {/* <td className="hidden px-3 py-2 text-sm  lg:table-cell ">
                    {obj.identificacion.length === 10 &&
                    obj.esRuc &&
                    obj.idTipoIdentificacion === 2
                      ? "Cedula - Ruc"
                      : obj.idTipoIdentificacionNavigation?.nombre}
                  </td> */}

                  <td className="hidden px-3 py-2 text-sm  lg:table-cell ">
                    {obj.idTipoIdentificacion === 1 && obj.esRuc === false
                      ? "Cédula"
                      : obj.idTipoIdentificacion === 2 && obj.esRuc === true
                      ? "RUC"
                      : obj.idTipoIdentificacion === 1 && obj.esRuc === true
                      ? "Cédula / Ruc"
                      : obj.idTipoIdentificacionNavigation?.nombre}
                  </td>

                  <td className=" px-3 py-2 text-sm  lg:table-cell ">
                    <div className=" "> {obj.direccion}</div>
                    <div className="mt-1 flex flex-col  sm:block lg:hidden">
                      <span> {obj.telefonoCelular} </span>
                      <span className="hidden sm:inline"> · </span>
                      <span> {obj.telefonoConvencional}</span>
                    </div>
                  </td>
                  <td className="hidden px-3 py-2 text-sm  lg:table-cell ">
                    {obj.telefonoCelular}
                  </td>
                  <td className="hidden px-3 py-2 text-sm  lg:table-cell ">
                    {obj.telefonoConvencional}
                  </td>
                  <td className=" px-3 py-2 text-sm  lg:table-cell whitespace-nowrap">
                    {/* <span>{obj.correo.length > 50 ? obj.correo.slice(0,50) + "..." : obj.correo}</span> */}
                    <button
                      className="
                              hover:bg-gray-200  dark:hover:bg-gray-700  rounded-lg p-2  text-sm font-medium leading-5  dark:text-gray-400 focus:outline-none focus:shadow-outline-grayduration-150
                              ease-in-out"
                      data-bs-toggle="modal"
                      data-bs-target="#EmailModal"
                      // onClick={setInfoEmail.obj.correo}
                      onClick={() => setInfoEmail(obj.correo)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-5 w-5 dark:stroke-white stroke-gray-600"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                        />
                      </svg>
                    </button>
                  </td>
                  <td className=" text-sm  lg:table-cell ">
                    {obj.activo ? (
                      <div className="flex items-center">
                        <div className="h-2.5 w-2.5 rounded-full bg-green-400 mr-2"></div>
                        ACTIVO
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <div className="h-2.5 w-2.5 rounded-full bg-red-500 mr-2"></div>
                        INACTIVO
                      </div>
                    )}
                  </td>
                  <td className=" px-3 py-2 text-sm text-gray-400 lg:table-cell ">
                    <NavLink
                      className={"w-full"}
                      exact="true"
                      to={`/mantenimiento/contribuyente/edit/${obj.idContribuyente}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 dark:stroke-white stroke-gray-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                        />
                      </svg>
                    </NavLink>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <>
        <div className="bg-white dark:bg-gray-700 dark:text-gray-400 px-4 py-3 my-2 flex items-center justify-between border-t border-gray-200 dark:border-gray-700 sm:px-6">
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <span className="text-sm font-normal ml-2 text-gray-400 dark:text-gray-400">
              Mostrando
              <span className="font-semibold text-gray-900 dark:text-white">
                {"\n"} {Paginacion._inicioRegistro} {"\n"}- {"\n"}
                {Paginacion._totalEnPagina} {"\n"}{" "}
              </span>
              de
              <span className="font-semibold text-gray-900 dark:text-white">
                {" "}
                {Paginacion._totalelementos}{" "}
              </span>{" "}
              Registros
            </span>
            <div>
              <nav
                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                aria-label="Pagination"
              >
                {Paginacion.pagina !== 1 ? (
                  <>
                    <button
                      onClick={handleClickFirstPage}
                      type="button"
                      className="relative inline-flex items-center dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50"
                      disabled=""
                    >
                      <span className="sr-only">First</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="h-5 w-5"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                    <button
                      onClick={handleClickBeforePage}
                      type="button"
                      className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                      disabled=""
                    >
                      <span className="sr-only">Previous</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="h-5 w-5 "
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="relative inline-flex items-center dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600 px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50"
                      disabled
                    >
                      <span className="sr-only">First</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="h-5 w-5"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                    <button
                      type="button"
                      className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                      disabled
                    >
                      <span className="sr-only">Previous</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="h-5 w-5"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </>
                )}

                {Paginacion.pagina <= Paginacion._totalpaginas - 1 ? (
                  <>
                    <button
                      onClick={handleClickNextPage}
                      type="button"
                      className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                    >
                      <span className="sr-only">Next</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="h-5 w-5"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                    <button
                      onClick={handleClickLastPage}
                      type="button"
                      className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50  dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                      disabled=""
                    >
                      <span className="sr-only">Last</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="h-5 w-5"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                        <path
                          fillRule="evenodd"
                          d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      disabled
                      className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50  dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                    >
                      <span className="sr-only">Next</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="h-5 w-5"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                    <button
                      type="button"
                      className="relative inline-flex items-center px-2 py-2 rounded-r-md border dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  border-gray-300 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50"
                      disabled
                    >
                      <span className="sr-only">Last</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="h-5 w-5"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                        <path
                          fillRule="evenodd"
                          d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </>
                )}
              </nav>
            </div>
          </div>
        </div>
      </>
      </div>
    </>
  );
};

export default ListaFacturas;
