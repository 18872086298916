import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { FormProvider } from "react-hook-form";
import { CategoriaContext } from "../context/CategoriaContext";
import { useStore } from "zustand";
import { useModalCategoriaStore } from "./ModalCategoriaContext";
import { InputGlobal } from "../../context/inputContext";
import { ModalButtons } from "../../Shared/ModalButtons";
import { AlfaNumerico, SoloLetras } from "../../regex.d";
import { toast } from "react-toastify";
import { ModalBase } from "../../Shared/ModalBase";

export const ModalEditarCategoria = () => {
  const { updateCategoria } = useContext(CategoriaContext);
  const { CategoriaAfectada, turnCategoriaModalOff } = useStore(
    useModalCategoriaStore
  );
  const [isSending, setIsSending] = useState(false);
  const methods = useForm({
    defaultValues: {
      codigo: CategoriaAfectada.codigo,
      nombreCategoria: CategoriaAfectada.nombre,
    },
  });

  const doCategoriaEdit = async (data) => {
    const editedCategoria = {
      idCategoriaProducto: CategoriaAfectada.idCategoriaProducto,
      estado: CategoriaAfectada.estado,
      codigo: data.codigo.trimEnd().trimStart(),
      nombre: data.nombreCategoria.trimEnd().trimStart(),
      idEmpresa: CategoriaAfectada.idEmpresa,
    };
    try {
      setIsSending(true);
      await toast.promise(updateCategoria(editedCategoria), {
        pending: "Editando categoria...",
        success: "¡La categoria fue editada con éxito!",
        error: {
          render({ data }) {
            return (
              data?.response?.data?.message ||
              "Hubo un error al editar la categoria."
            );
          },
        },
      });
      turnCategoriaModalOff();
    } catch (err) {
      console.log(err);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <ModalBase onClose={turnCategoriaModalOff}>
      <FormProvider {...methods}>
        <section
          style={{
            padding: "20px",
            height: "fit-content",
            position: "relative",
          }}
          className="shadow-xl rounded-lg flex flex-col bg-white dark:bg-gray-900 gap-4 lg:w-[500px] md:w-[450px] w-[375px]"
        >
          <div className="bg-gray-200 dark:bg-gray-700 rounded-lg dark:text-white text-[12px] w-full flex flex-col justify-center p-6">
            <h1 className="font-semibold text-2xl font-[Outfit]">
              Información de Categoría
            </h1>
            <h4>Editar Información</h4>
          </div>
          <form
            onSubmit={methods.handleSubmit(doCategoriaEdit)}
            className="flex flex-col gap-8"
            autoComplete="off"
          >
            <div className="grid grid-cols-2 gap-2" style={{ rowGap: "20px" }}>
              <InputGlobal
                title={"Código"}
                type={"text"}
                max={13}
                validations={{
                  required: true,
                  pattern: AlfaNumerico,
                  maxLength: 13,
                }}
                isHook={true}
                name={"codigo"}
                isPlaceHolder={"Digite el codigo de la bodega"}
              />
              <InputGlobal
                title={"Nombre de la Categoria"}
                max={50}
                specificStyle={"col-span-2"}
                className="col-start-2 col-end-4"
                validations={{
                  required: true,
                  maxLength: 50,
                }}
                type={"text"}
                name={"nombreCategoria"}
                isHook={true}
                isPlaceHolder={"Digite el nombre de la bodega"}
              />
              {/* <SelectGlobal
              title={"Valoración de Inventario"}
              name={"valoracionInventario"}
            >
              <option value="Manual">Manual</option>
              <option value="Automatizado">Automatizado</option>
            </SelectGlobal>
            <SelectGlobal
              title={"Cuentas Contables"}
              validations={{ required: true }}
              name={"cuentasContables"}
            >
              <option value="" selected hidden>
                Seleccione una Cuenta Contable
              </option>
              <option value="Cuenta de Ingresos">Cuenta de Ingresos</option>
              <option value="Cuenta de Gastos">Cuenta de Gastos</option>
            </SelectGlobal> */}
            </div>
            <ModalButtons
              isSending={isSending}
              closeFn={turnCategoriaModalOff}
            />
          </form>
        </section>
      </FormProvider>
    </ModalBase>
  );
};
