import React, { useContext, useEffect, useState } from "react";
import MesageError from "../../../features/components/MessageError";
import { useSelector } from "react-redux";
import { StyleInput, StyleSelect } from "../../../features/Constantes";
import { getListV2 } from "../../../services";
import { EmitirDocContext } from "./EmitirDocProvider";
import {
  getFechaV1,
  getNoventaDiasAtras,
  rellenarCeros,
} from "../../../services/Utilitario";
import MobileButtonOptionsRt from "./MobileButtonOptionsRt";
import useVistaPrevia from "../hooks/useVistaPrevia";

import ContribuyenteRetencion from "./ContribuyenteRetencion";
import ListaDetalle from "./ListaDetalle";
import { Transition } from "@headlessui/react";
import { ModalHours } from "../../../features/hooks/ModalHours";

const FormDoc = () => {
  const {
    formik,
    setIsEdit,
    resetForm,
    setDataFromAdd,
    DetalleList,
    setInformacionAdicionalList,
    Totales,
    DataFromAdd,
    InformacionAdicionalList,
    setgenxml,
    genxml,
    isOpenModalHours,
    toggleModal,
  } = useContext(EmitirDocContext);
  //const formikProps = useFormikContext()

  const empresa = useSelector((store) => store.empresa.empresa);

  const [Establecimientos, setEstablecimientos] = useState([]);
  const [PuntosEmision, setPuntosEmision] = useState([]);
  const [ptoobj, setptoobj] = useState([]);
  const [estabTmp, setEstabTmp] = useState("###");
  const [pteTmp, setPteTmp] = useState("###");
  const [secTmp, setSecTmp] = useState("#########");

  const { GetVistaPreviaRetencion, IsLoading } = useVistaPrevia();

  useEffect(() => {
    (async () => {
      if (genxml === true) {
        try {
          // agregar codigo de buscar la secuencia por idPuntoMEision y el tipo de documento 01=factura

          const GetAllPuntosEmision = await getListV2(
            ptoobj,
            "getSecuencia-Retencion"
          );
          // set el secuencial en el form_secuencial y en el numero

          setSecTmp(GetAllPuntosEmision._embedded.codigo);
          formik.setFieldValue(
            "secuencial",
            GetAllPuntosEmision._embedded.codigo
          );
        } catch (error) {
          //console.log(error);
          setSecTmp("#########");
          formik.setFieldValue("secuencial", "");
        }
        setgenxml(false);
      }
    })();
  }, [genxml]);

  useEffect(() => {
    (async () => {
      //ObtenerEstablecimientosPorEmpresa
      const GetAllEstablecimientos = await getListV2(
        empresa,
        "ObtenerEstablecimientosPorEmpresa"
      );
      // console.log(GetAllEstablecimientos._embedded[0].codigo);
      if (GetAllEstablecimientos._embedded.length > 0) {
        formik.setFieldValue(
          "establecimiento",
          GetAllEstablecimientos._embedded[0].codigo
        );

        setEstabTmp(rellenarCeros(GetAllEstablecimientos._embedded[0].codigo));
        setEstablecimientos(GetAllEstablecimientos._embedded);

        await getPuntosEmision(GetAllEstablecimientos._embedded[0]);
      }
    })();
  }, []);

  const handleResetRetencion = (index) => {
    DataFromAdd.splice(index, 1);
    setDataFromAdd([]);
    setInformacionAdicionalList([]);
    resetForm();
  };
  const handleChangeEstablecimiento = async (e) => {
    if (e.target.value) {
      const index = e.target.selectedOptions[0].getAttribute("data-index");

      formik.setFieldValue("puntoEmision", "");
      formik.setFieldValue("secuencial", "");
      setEstabTmp(e.target.value);
      const establecimiento = Establecimientos[index];

      await getPuntosEmision(establecimiento);

      formik.handleChange(e);
    }
  };

  const getPuntosEmision = async (establecimiento) => {
    const GetAllPuntosEmision = await getListV2(
      establecimiento,
      "GetListPuntosEmision",
      `?Estado=1`
    );

    setSecTmp("#########");
    if (GetAllPuntosEmision._embedded.length > 0) {
      setPteTmp(rellenarCeros(GetAllPuntosEmision._embedded[0].codigo));
      setPuntosEmision(GetAllPuntosEmision._embedded);
      formik.setFieldValue(
        "puntoEmision",
        GetAllPuntosEmision._embedded[0].codigo
      );
      await getSecuencial(GetAllPuntosEmision._embedded[0]);
    } else {
      setPteTmp("###");
      formik.setFieldValue("puntoEmision", "");
      setPuntosEmision([]);
    }
  };

  const getSecuencial = async (puntoEmisionObj) => {
    try {
      // agregar codigo de buscar la secuencia por idPuntoMEision y el tipo de documento 01=factura
      setptoobj(puntoEmisionObj);
      const GetAllPuntosEmision = await getListV2(
        puntoEmisionObj,
        "getSecuencia-Retencion"
      );
      // set el secuencial en el form_secuencial y en el numero

      setSecTmp(GetAllPuntosEmision._embedded.codigo);
      formik.setFieldValue("secuencial", GetAllPuntosEmision._embedded.codigo);
    } catch (error) {
      //  console.log(error);
      setSecTmp("#########");
      formik.setFieldValue("secuencial", "");
    }
  };

  const handleVistaPrevia = async () => {
    let parsedInfo = {
      IdentificacionContribuyente: formik.values.clienteIdentificacion,
      RazonSocialContribuyente: formik.values.clienteRazonSocial,
      Direccion: formik.values.clienteDireccion,
      Correo: formik.values.clienteEmail.replace(/,/g, ";").replace(/\s+/g, ""),
      TelefonoCelular: formik.values.clienteTelefono,
      CodigoComprobanteModificar: formik.values.codigoComprobanteModificar,
      IdContribuyente: formik.values.idContribuyente,
      IsPartRel: formik.values.IsPartRel === "1" ? "True" : "False",
      Ambiente: process.env.REACT_APP_ENVIRONMENT,
      IdEmpresa: empresa.idEmpresa,

      FechaEmision: getFechaV1(formik.values.fechaEmision),
      PeriodoFiscal: formik.values.periodoFiscal,
      ImporteTotal: Totales.importeTotal,
      TotalSinImpuestos: Totales.totalSinImpuesto,
      TotalDescuento: Totales.totalDescuentos,
      DocsSustento: DataFromAdd,
      infoAdicional: InformacionAdicionalList.map((x) => {
        return {
          Descripcion: x.nombre,
          Valor: x.valor,
        };
      }),
      IdImpuestoNoObjectoImpuesto:
        Totales.subTotalNoObjetoIva !== 0
          ? "8E7CF164-C66B-4312-945F-57AE08C86A33"
          : "",
      IdImpuestoExentoIva:
        Totales.subTotalExentoIva !== 0
          ? "F03C7F10-2773-4505-ACCB-B2FA88D62049"
          : "",
      IdImpuestoIVA12:
        Totales.subTotalIva !== 0 ? "A5B8F607-8D38-4150-97AF-C591F83678D8" : "",
      IdImpuestoIVA0:
        Totales.subTotalIva0 !== 0
          ? "81BAF871-E113-48CF-8AA8-670CB43494B6"
          : "",
      TotalTotalIva0: 0,
      TotalTotalIva: Totales.totalIva,
      TotalNoObjetoImpuesto: 0,
      TotalExentoIva: 0,
      TotalBaseIva0: Totales.subTotalIva0,
      TotalBaseIva: Totales.subTotalIva,
      TotalBaseNoObjetoImpuesto: Totales.subTotalNoObjetoIva,
      TotalBaseExentoIva: Totales.subTotalExentoIva,
      Establecimiento: formik.values.establecimiento,
      PuntoEmision: formik.values.puntoEmision,
      Secuencial: formik.values.secuencial,
    };

    if (formik.values.clienteEmail !== null)
      if (formik.values.clienteEmail.trim() !== "")
        parsedInfo.infoAdicional.push({
          Valor: formik.values.clienteEmail
            .replace(/,/g, ";")
            .replace(/\s+/g, ""),
          Descripcion: "email",
        });

    let data = await GetVistaPreviaRetencion(parsedInfo);
    let byteCharacters = atob(data);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: "application/pdf;base64" });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const handleChangePuntoEmision = (e) => {
    setSecTmp("#########");
    if (e.target.value) {
      setPteTmp(e.target.value);

      const index = e.target.selectedOptions[0].getAttribute("data-index");

      const puntoEmision = PuntosEmision[index];

      // console.log(`${puntoEmision.idPuntoEmision}`);

      getSecuencial(puntoEmision);

      formik.handleChange(e);
    }
  };

  // const handleClickSubmitRetencion = (e) => {
  //   if (DetalleList.length <= 0) return e.preventDefault();
  //  // setSecTmp(Number(formik.values.secuencial) + 1);
  // };

  const [valueOptionsHeader, setValueOptionsHeader] = useState("FACTURA");


 
    const [isOpen, setIsOpen] = useState(true);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
      <ModalHours isOpenModalHours={isOpenModalHours} toggleModal={toggleModal} />
        {IsLoading && (
          <div
            style={{ width: "100vw", height: "100vh", zIndex: "100" }}
            className="bg-opacity-70 fixed justify-center align-middle top-0 l-0 bg-white text-center"
          >
            <div className="w-full h-full flex justify-center items-center py-4">
              <div
                className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-600"
                role="status"
              >
                <span className="hidden">Loading...</span>
              </div>
            </div>
          </div>
        )}

        <div className="border dark:border-gray-600 rounded-xl bg-white dark:bg-gray-900 p-2 ">
          <div className="flex justify-between border-b dark:border-b-gray-600 ">
            <div className="flex  justify-between w-full ">
              <div
                onClick={() => setValueOptionsHeader("FACTURA")}
                className=" text-xl font-semibold dark:text-white"
              >
                Retención
              </div>
              <MobileButtonOptionsRt />
            </div>

            <div className="my-auto lg:flex md:flex sm:hidden  ">
              <div className=" group relative inline-block">
                <div className=" cursor-pointer w-10 h-10 rounded-full  inline-flex items-center justify-center">
                  <button
                    type="button"
                    onClick={handleResetRetencion}
                    className="inline-flex h-10 w-10 cursor-pointer  hover:shadow-md  items-center justify-center rounded-xl  transition dark:bg-gray-900 dark:hover:bg-gray-800"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="h-6 w-6 dark:text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>

                    <div className="absolute mx-auto w-24  -top-8 -left-8 hidden dark:bg-white     rounded-md bg-gray-800 group-hover:flex text-center p-1">
                      <p className="text-white dark:text-black mx-auto text-sm ">
                        Limpiar
                      </p>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="p-2">
            {valueOptionsHeader === "FACTURA" ? (
              <>
                <div className="bg-[#F7F7F7] dark:bg-gray-700 p-2  rounded-lg">
                  <div className=" grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-2 w-full max-w-2xl  text-center">
                    <div className="relative z-0 w-full group">
                      <label
                        htmlFor="company-website"
                        className="block text-sm font-medium text-left taext-black dark:text-white"
                      >
                        {" "}
                        Establecimiento:{" "}
                      </label>
                      <select
                        onChange={handleChangeEstablecimiento}
                        type="text"
                        name="establecimiento"
                        id="establecimiento"
                        className={StyleSelect}
                        placeholder=" "
                        defaultValue={formik.values.establecimiento}
                        onBlur={formik.handleBlur}
                      >
                        {/*data-id-establecimiento={el.idEstablecimiento}*/}
                        {Establecimientos.map((el, index) => {
                          return (
                            <option
                              key={el.idEstablecimiento}
                              data-index={index}
                              value={el.codigo}
                            >
                              {rellenarCeros(el.codigo)}{" "}
                              {/*- {el.descripcion}*/}
                            </option>
                          );
                        })}
                      </select>
                      {/* <label htmlFor="establecimiento" className={StyleLabelForm}>
                  Establecimiento.
                </label> */}
                      {formik.touched.establecimiento &&
                      formik.errors.establecimiento ? (
                        <MesageError message={formik.errors.establecimiento} />
                      ) : null}
                    </div>

                    <div className="relative z-0 w-full group">
                      <label
                        htmlFor="company-website"
                        className="block text-sm font-medium text-left text-black dark:text-white"
                      >
                        {" "}
                        Punto emisión:{" "}
                      </label>
                      <select
                        type="text"
                        className={StyleSelect}
                        placeholder=" "
                        name="puntoEmision"
                        id="puntoEmision"
                        defaultValue={formik.values.puntoEmision}
                        onChange={handleChangePuntoEmision}
                        onBlur={formik.handleBlur}
                      >
                        {PuntosEmision.map((el, index) => {
                          return (
                            <option
                              key={el.idPuntoEmision}
                              value={el.codigo}
                              data-index={index}
                            >
                              {rellenarCeros(el.codigo)}{" "}
                              {/*- {el.descripcion}*/}
                            </option>
                          );
                        })}
                      </select>
                      {/* <label htmlFor="puntoEmision" className={StyleLabelForm}>
                  Punto Emisión
                </label> */}
                      {formik.touched.puntoEmision &&
                      formik.errors.puntoEmision ? (
                        <MesageError message={formik.errors.puntoEmision} />
                      ) : null}
                    </div>

                    <div className="relative z-0 w-full group">
                      <label
                        htmlFor="company-website"
                        className="block text-sm font-medium text-left text-black dark:text-white"
                      >
                        {" "}
                        Secuencial:
                      </label>
                      <input
                        type="text"
                        className={StyleInput + " bg-block"}
                        disabled
                        value={rellenarCeros(secTmp, 9)}
                      />
                      {/* <label htmlFor="secuencial" className={StyleLabelForm}>
                  Secuencial
                </label> */}
                      {formik.touched.secuencial && formik.errors.secuencial ? (
                        <MesageError message={formik.errors.secuencial} />
                      ) : null}
                    </div>

                    <div className="relative z-0 w-full group">
                      <label
                        htmlFor="company-website"
                        className="block text-sm font-medium text-left text-black dark:text-white"
                      >
                        {" "}
                        Fecha emisión:{" "}
                      </label>
                      <input
                        type="date"
                        className={StyleInput}
                        name="fechaEmision"
                        id="fechaEmision"
                        min={getNoventaDiasAtras()}
                        value={formik.values.fechaEmision}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder=" "
                      />
                      {/* <label htmlFor="secuencial" className={StyleLabelForm}>
                  Secuencial
                </label> */}
                      {formik.touched.secuencial && formik.errors.secuencial ? (
                        <MesageError message={formik.errors.secuencial} />
                      ) : null}
                    </div>

                    <div className=" relative z-0  w-full group">
                      <label
                        htmlFor="company-website"
                        className="block text-sm font-medium text-left text-black dark:text-white"
                      >
                        {" "}
                        Periodo fiscal:{" "}
                      </label>
                      <input
                        type="month"
                        id="periodoFiscal"
                        name="periodoFiscal"
                        onChange={formik.handleChange}
                        value={formik.values.periodoFiscal}
                        onBlur={formik.handleBlur}
                        className={StyleInput}
                      />
                    </div>
                  </div>{" "}
                </div>
              </>
            ) : (
              <>
                <div className="bg-[#F7F7F7]  rounded-lg">
                  <div className=" grid grid-cols-4 gap-2 w-full max-w-2xl my-2  px-2 text-center"></div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="bg-white dark:bg-gray-900 rounded-t-lg p-2 lg:px-4 lg:mt-4 md:mt-4">
          <ContribuyenteRetencion formik={formik} />
          <div className="    w-full group border-l border-r border-b rounded-lg dark:border-gray-600 bg-white dark:bg-gray-900">
            <h3 className="text-sm flex justify-between items-center py-1.5 my-4 px-2 text-left bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-t-lg font-medium leading-6 text-white">
              Detalle de impuesto
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                onClick={() => setIsOpen(!isOpen)}
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className={`w-4 h-4 lg:hidden md:hidden duration-150 ${
                  isOpen ? `transform rotate-180` : `transform rotate-0`
                }`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
            </h3>

            <Transition
              show={isOpen}
              enter="transition ease-out duration-300" // Aumenta la duración de entrada
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-300" // Aumenta la duración de salida
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className=" px-4 flex-col space-y-2">
                <button
                  onClick={() => setIsEdit(false)}
                  type="button"
                  className="inline-block bg-blue-600 dark:bg-gray-700 dark:border-none lg:w-auto w-full text-white my-2 hover:bg-blue-700 font-semibold py-2 px-4 text-sm border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:shadow-outline-grayduration-150 ease-in-out"
                  data-bs-toggle="modal"
                  data-bs-target="#addImpuestoRetencionModal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="inline mr-2 -ml-1 w-5 h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  Agregar
                </button>

                <ListaDetalle />
              </div>
            </Transition>
          </div>
        </div>
      </form>
    </>
  );
};

export default FormDoc;
