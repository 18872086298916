import { useFormContext } from "react-hook-form";

export const SelectContext = ({
  name,
  children,
  validations,
  disabled,
  onChange,
  value,
  title,
  isHook,
  isPlaceHolder,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const StyleNormalSelect =
    "border-gray-300 focus:outline-none left-0 relative  focus:border-blue-500 dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600 focus:ring-blue-500 focus:ring-1 ring-0 w-full  border rounded-md h-[38px] pl-1 text-xs space-x-0";

  const StyleErrorSelect =
    "border-red-500 focus:outline-none left-0 relative  focus:border-red-500 dark:bg-gray-700 dark:text-gray-400 focus:ring-red-500 focus:ring-1 ring-0 w-full border rounded-md pl-1 h-[38px] text-black text-xs space-x-0";

  const StyleDisableSelect =
    "border-black focus:outline-none left-0 relative focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-400 focus:ring-1 ring-0 w-full bg-gray-300 border text-black rounded-md h-9 pl-1 text-xs space-x-0";

  return (
    <div className={`text-xs`}>
      <label
        className={
          errors[name]
            ? "w-full text-red-600 my-auto  flex flex-col gap-1"
            : "w-full text-gray-600 my-auto font-normal flex flex-col gap-1"
        }
        htmlFor={title}
      >
        <span className="dark:text-gray-300">{title}:</span>
        <select
          className={
            errors[name]
              ? StyleErrorSelect
              : disabled
              ? StyleDisableSelect
              : StyleNormalSelect
          }
          onChange={onChange}
          value={value}
          {...register(name, validations)}
          disabled={disabled ? disabled : false}
          data-placeholder={isPlaceHolder}
          id={title}
        >
          {children}
        </select>
        {errors[name] && errors[name].type === "required" && (
          <span className="text-red-600 text-[10px]">
            El Campo {title.toLowerCase()} es requerido.
          </span>
        )}
      </label>
    </div>
  );
};
