import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getListV2, PutElement } from "../../../services";
import {
  StyleInput,
  StyleLabel,
  StyleSelect,
} from "../../../features/Constantes";
import { ReporteUsuariosContext } from "./ReporteUsuariosProvider";
import { LargeModalForm } from "../../../features/components/LargeModalForm";
import { toast } from "react-toastify";
import useGlobalToastify from "../../../features/hooks/GlobalToastify/useGlobalToastify";

const ModalEdit = () => {
  const { formik, NewItem, setNewItem, initialState, IsLoading, setIsLoading } =
    useContext(ReporteUsuariosContext);

  const { ErrorToast } = useGlobalToastify();

  const [EsAdminShow, setEsAdminShow] = useState("True");

  const [FormulariosAdmin, setFormulariosAdmin] = useState([]);

  const [FormulariosInvitado, setFormulariosInvitado] = useState([]);

  const [CheckedItems, setCheckedItems] = useState([]);

  const [ShowCheckeds, setShowCheckeds] = useState(false);

  const empresa = useSelector((store) => store.empresa.empresa);

  useEffect(() => {
    (async () => {
      await handleSearchUser(NewItem.identificacion);
    })();
  }, [NewItem.identificacion]);

  const handleChangeEsAdmin = (e) => {
    if (e.target.value === "False") {
      let unCheckedItems = CheckedItems.filter(
        (el) => el.rolId !== "80dd2f96-00f0-4a19-aa3d-01ed447a2970"
      );
      setCheckedItems([...unCheckedItems]);

      let uncheckedAdmin = FormulariosAdmin.map((el) => {
        el.checked = false;
        return el;
      });
      setFormulariosAdmin([...uncheckedAdmin]);
    }
    setEsAdminShow(e.target.value);
    handleChangeModal(e);
  };

  const consultarListaFormularios = async () => {
    let formsListInvitados = await getListV2(empresa, "GetInvitadoForms");
    let invitados = [...formsListInvitados._embedded];
    setFormulariosInvitado(invitados);

    let formsListAdmin = await getListV2(empresa, "GetAdminForms");
    let admins = [...formsListAdmin._embedded];
    const formulariosActivos = formsListAdmin._embedded.filter(formulario => formulario.activo);

    setFormulariosAdmin(formulariosActivos);
    return { Invitados: invitados, Admins: formulariosActivos };
  };

  const handleSearchUser = async (id) => {
    try {
      if (id) {
        setShowCheckeds(false);
        let userInfo = await getListV2(
          empresa,
          "GetUserEdit",
          `?identificacion=${id}`
        );
        let forms = await consultarListaFormularios();
        let activo = userInfo._embedded.activo ? "True" : "False";

        setNewItem({
          ...NewItem,
          nombre: userInfo._embedded.nombre,
          idUsuario: userInfo._embedded.idUsuario,
          correo: userInfo._embedded.username,
        });

        //esta variable guarda todos los elementos checkeds, para evitar
        //volver a hacer un forEach innecesario
        let AllCheckeds = [];
        let CheckedsInv = [...forms.Invitados];
        let CheckedsAdmin = [...forms.Admins];

        CheckedsInv.forEach((form, index) => {
          userInfo._embedded.empresaFormularioUsuario.forEach((el) => {
            if (form.idFormulario === el.formularioId) {
              AllCheckeds.push(CheckedsInv[index]);
              CheckedsInv[index].checked = true;
            }
          });
        });

        CheckedsAdmin.forEach((form, index) => {
          userInfo._embedded.empresaFormularioUsuario.forEach((el) => {
            if (form.idFormulario === el.formularioId) {
              AllCheckeds.push(CheckedsAdmin[index]);
              CheckedsAdmin[index].checked = true;
            }
          });
        });

        setFormulariosInvitado([...CheckedsInv]);
        setFormulariosAdmin([...CheckedsAdmin]);
        setCheckedItems([...AllCheckeds]);

        let findAdmin = AllCheckeds.some(
          (el) => el.rolId === "80dd2f96-00f0-4a19-aa3d-01ed447a2970"
        );

        setEsAdminShow(findAdmin ? "True" : "False");
        setNewItem({
          ...NewItem,
          activo: activo,
          esAdministrador: findAdmin ? "True" : "False",
          correo: userInfo._embedded.username,
        });

        setShowCheckeds(true);
      }
    } catch (error) {
      // console.log(error);
      setNewItem({ ...NewItem, nombre: "", email: "", idUsuario: null });
    }
  };

  const handleSubmitModal = async () => {
    const valco = NewItem.correo.replace(/\s+/g, "");

    if (valco !== "" && !/^([^\s@]+@[^\s@]+\.[^\s@]+)*$/.test(valco)) {
      ErrorToast("El campo 'Correo' no coincide con el patron requerido");
      throw new Error("Error de validacion");
    }

    let request = NewItem;
    request.estado = request.activo;
    request.formularios = CheckedItems;
    const putRes = await PutElement(empresa, "EditUserInEmpresa", request);
    const toastPro = () =>
      new Promise((resolve) => setInterval(() => resolve(putRes), 2000));

    await toast.promise(toastPro(), {
      pending: {
        render() {
          setIsLoading(true);
          return "Editando usuario...";
        },
        isLoading: true,
      },
      success: {
        render() {
          formik.handleSubmit();
          let formsDesactivadosAdmin = FormulariosAdmin.map((el) => {
            el.checked = false;
            return el;
          });
          setFormulariosAdmin([...formsDesactivadosAdmin]);

          let formsDesactivadosInvitado = FormulariosInvitado.map((el) => {
            el.checked = false;
            return el;
          });
          setFormulariosInvitado([...formsDesactivadosInvitado]);

          setNewItem(initialState);
          setCheckedItems([]);
          setShowCheckeds(false);
          document.getElementById("editUsuarioModalclose").click();
          setIsLoading(false);
          return "Usuario editado con exito.";
        },
        autoClose: 2000,
        closeOnClick: true,
      },
      error: {
        render() {
          setIsLoading(false);
          return "Algo ha sucedido, intente nuevamente.";
        },
        autoClose: 2000,
        closeOnClick: true,
      },
    });
  };

  const handleChangeModal = (e) => {
    setNewItem({
      ...NewItem,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckedItemInvitado = (el, e, index) => {
    let datos = [...FormulariosInvitado];
    datos[index].checked = e.target.checked;
    setFormulariosInvitado(datos);

    if (e.target.checked) {
      setCheckedItems([...CheckedItems, el]);
    } else {
      let newItems = CheckedItems.filter(
        (el2) => el.idFormulario !== el2.idFormulario
      );

      setCheckedItems(newItems);
    }
  };

  const handleCheckedItemAdmin = (el, e, index) => {
    let datos = [...FormulariosAdmin];
    datos[index].checked = e.target.checked;
    setFormulariosAdmin(datos);

    if (e.target.checked) {
      setCheckedItems([...CheckedItems, el]);
    } else {
      let newItems = CheckedItems.filter(
        (el2) => el.idFormulario !== el2.idFormulario
      );

      setCheckedItems(newItems);
    }
  };
  const handleCheckAllInvitado = (e) => {
    let datos = FormulariosInvitado.map((el) => {
      el.checked = e.target.checked;
      return el;
    });
    setFormulariosInvitado([...datos]);

    if (e.target.checked) {
      setCheckedItems((prev) => [...prev, ...datos]);
    } else {
      let newItems = CheckedItems.filter(
        (el) => el.rolId !== "80dd2f96-00f0-4a19-aa3d-01ed687a2970"
      );
      setCheckedItems([...newItems]);
    }
  };

  const handleCheckAllAdmin = (e) => {
    let datosAdmin = FormulariosAdmin.map((el) => {
      el.checked = e.target.checked;
      return el;
    });

    setFormulariosAdmin([...datosAdmin]);

    if (e.target.checked) {
      setCheckedItems((prev) => [...prev, ...datosAdmin]);
    } else {
      let newItems = CheckedItems.filter(
        (el) => el.rolId !== "80dd2f96-00f0-4a19-aa3d-01ed447a2970"
      );
      setCheckedItems([...newItems]);
    }
  };

  const handleCorreoChange = (event) => {
    setNewItem({
      ...NewItem,
      correo: event.target.value,
    });
  };

  return (
    <LargeModalForm
      textSuccess={"Edicion del elemento realizada con exito!"}
      buttonModal={"editUsuarioModal"}
      submitText={"Guardar"}
      title="Editar Usuario"
      submitModal={handleSubmitModal}
      IsLoading={IsLoading}
    >
      <div className="relative z-0 mb-2 w-full group pt-7">
        <div className="w-full grid md:grid-cols-2 md:gap-2 mb-2 ">
          <div className="relative z-0 mb-2 w-full group">
            <input
              readOnly
              type="text"
              className={StyleInput + " bg-gray-200 dark:bg-gray-600"}
              placeholder=" "
              id="identificacion"
              name="identificacion"
              onChange={handleChangeModal}
              value={NewItem.identificacion || ""}
            />
            <label htmlFor="identificacion" className={StyleLabel}>
              Identificación
            </label>
          </div>
          <div className="relative z-0 mb-2 w-full group">
            <input
              type="text"
              className={StyleInput + " bg-gray-200 dark:bg-gray-600"}
              placeholder=" "
              id="nombre"
              name="nombre"
              readOnly
              value={NewItem.nombre || ""}
            />
            <label htmlFor="nombre" className={StyleLabel}>
              Nombre
            </label>
          </div>
        </div>
        <div className="relative z-0 mb-2 w-full group">
          <input
            type="email"
            className={StyleInput}
            placeholder=" "
            id="Correo"
            name="Correo"
            value={NewItem.correo || ""}
            onChange={handleCorreoChange}
          />
          <label htmlFor="Correo" className={StyleLabel}>
            Correo
          </label>
        </div>
      </div>

      <div className="relative z-0 mb-2 w-full group">
        <div className="w-full grid md:grid-cols-2 md:gap-2 mb-2 ">
          <div className="relative z-0 mb-2 w-full group">
            <select
              name="activo"
              id="activo"
              onChange={handleChangeModal}
              value={NewItem.activo}
              className={StyleSelect}
              x-model="item.gst"
            >
              <option value="True">ACTIVO</option>
              <option value="False">INACTIVO</option>
            </select>
            <label htmlFor="activo" className={StyleLabel}>
              Estado
            </label>
          </div>
          <div className="relative z-0 mb-2 w-full group">
            <select
              name="esAdministrador"
              id="esAdministrador"
              onChange={handleChangeEsAdmin}
              value={NewItem.esAdministrador}
              className={StyleSelect}
              x-model="item.gst"
            >
              <option value="True">SI</option>
              <option value="False">NO</option>
            </select>
            <label htmlFor="nombre" className={StyleLabel}>
              Es administrador
            </label>
          </div>
        </div>
      </div>

      <div className="py-0 mb-4 flex-wrap justify-between">
        <div className="overflow-x-auto  relative overflow-hidden shadow ring-1 ring-black  ring-opacity-5 md:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase divide-y bg-[#D7D7D7] dark:bg-gray-600 dark:text-gray-400">
              <tr>
                <th scope="col" className="py-1 px-4 ">
                  <input
                    onChange={(e) => handleCheckAllInvitado(e)}
                    checked={
                      FormulariosInvitado.length > 0 &&
                      FormulariosInvitado.every((el) => el.checked)
                    }
                    id="checkbox-table-search-2"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </th>
                <th scope="col" className="py-2 px-6 ">
                  Permisos generales:
                </th>
              </tr>
            </thead>
            <tbody>
              {ShowCheckeds
                ? FormulariosInvitado.map((el, index) => (
                    <tr
                      key={index}
                      className="bg-white dark:bg-gray-800 dark:hover:bg-gray-900 hover:bg-gray-200  border-b-gray-600"
                    >
                      <td className="py-2 px-4 w-4">
                        <div className="flex items-center">
                          <input
                            onChange={(e) =>
                              handleCheckedItemInvitado(el, e, index)
                            }
                            checked={FormulariosInvitado[index].checked}
                            id="checkbox-table-search-2"
                            type="checkbox"
                            className="
                                                        w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600
                                                        "
                          />
                          <label
                            htmlFor="checkbox-table-search-2"
                            className="sr-only"
                          >
                            checkbox
                          </label>
                        </div>
                      </td>
                      <td className="py-1 px-6">{el.nombre}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>

      {ShowCheckeds && EsAdminShow === "True" ? (
        <div className="py-0  flex-wrap justify-between mb-0">
          <div className="overflow-x-auto  relative overflow-hidden shadow ring-1 ring-black  ring-opacity-5 md:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase divide-y bg-[#D7D7D7] dark:bg-gray-600 dark:text-gray-400">
                <tr>
                  <th scope="col" className="py-1 px-4 ">
                    <input
                      onChange={(e) => handleCheckAllAdmin(e)}
                      checked={
                        FormulariosAdmin.length > 0 &&
                        FormulariosAdmin.every((el) => el.checked)
                      }
                      id="checkbox-table-search-2"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </th>
                  <th scope="col" className="py-2 px-6 ">
                    PERMISOS ADMINISTRADOR:
                  </th>
                </tr>
              </thead>
              <tbody>
                {FormulariosAdmin.map((el, index) => (
                  <tr
                    key={index}
                    className="bg-white dark:bg-gray-800 dark:hover:bg-gray-900 hover:bg-gray-200  border-b-gray-600"
                  >
                    <td className="py-2 px-4 w-4">
                      <div className="flex items-center">
                        <input
                          checked={FormulariosAdmin[index].checked}
                          onChange={(e) => handleCheckedItemAdmin(el, e, index)}
                          id="checkbox-table-search-2"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="checkbox-table-search-2"
                          className="sr-only"
                        >
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="py-1 px-6">{el.nombre}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </LargeModalForm>
  );
};

export default ModalEdit;
