import { TransaccionesEmitidasItem } from "./TransaccionesEmitidasItem";
import { useContext } from "react";
import { TransaccionesContext } from "./context/TransaccionesContext";
import { ClipLoader } from "react-spinners";
import { PaginacionInventario } from "../../Shared/PaginacionInventario";

export const TransaccionesEmitidasList = ({ CheckedHookTransacciones }) => {
  const {
    transacciones,
    isLoading,
    setParametroPaginacion,
    parametroPaginacion,
    infoPaginacion,
  } = useContext(TransaccionesContext);

  return (
    <>
      <div className="w-full bg-white dark:bg-gray-900 h-fit p-6 shadow-md rounded-lg flex flex-col gap-5">
        <div className=" inline-flex z-0 mx-4 my-3  group overflow-x-auto">
          <h1 className="float-left mr-2 dark:text-white text-black">
            Mostrar
          </h1>
          <select
            type="text"
            onChange={(e) => {
              setParametroPaginacion((prevState) => ({
                ...prevState,
                pageSize: e.target.value,
              }));
            }}
            name="Cantidad"
            id="Cantidad"
            className="origin-left border-gray-300 border pr-2 pl-1  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            placeholder=" "
          >
            <option key="50" value="50">
              50
            </option>
            <option key="75" value="75">
              75
            </option>
            <option key="100" value="100">
              100
            </option>
            <option key="200" value="200">
              200
            </option>
            <option key="300" value="300">
              300
            </option>
            <option key="400" value="400">
              400
            </option>
            <option key="500" value="500">
              500
            </option>
            <option key="1000" value="1000">
              1000
            </option>
          </select>
          <h1 className="float-left  ml-2 dark:text-white text-black">
            Registros
          </h1>
        </div>
        <div className="w-full overflow-x-auto p-2">
          <table className="self-center w-full border-2 border-gray-300 dark:border-gray-600 text-[12px]">
            <thead>
              <tr className="bg-gray-200 border-y-2 dark:border-gray-600 border-gray-300  dark:bg-gray-700 dark:text-white text-center">
                <th
                  style={{ padding: "15px" }}
                  className="whitespace-nowrap px-2"
                >
                  <input
                    type="checkbox"
                    checked={CheckedHookTransacciones.IsCheckedAll}
                    onChange={() => {
                      CheckedHookTransacciones.setIsCheckedAll(
                        !CheckedHookTransacciones.IsCheckedAll
                      );
                      CheckedHookTransacciones.handleSelectAll();
                    }}
                  />
                </th>
                <th className="whitespace-nowrap px-2">Tipo</th>
                <th className="whitespace-nowrap px-2">Fecha de Creación</th>
                <th className="whitespace-nowrap px-2">Código</th>
                <th className="whitespace-nowrap px-2">Ubicación</th>
                <th
                  style={{ padding: "15px" }}
                  className="whitespace-nowrap px-2"
                >
                  Opciones
                </th>
              </tr>
            </thead>
            <tbody>
              {transacciones.length > 0 &&
                isLoading === false &&
                transacciones.map((transaccion) => (
                  <TransaccionesEmitidasItem
                    key={transaccion.idTransaccion}
                    Transaccion={transaccion}
                    TransaccionesChecked={
                      CheckedHookTransacciones.elementosChecked
                    }
                    handleClick={CheckedHookTransacciones.handleClick}
                  />
                ))}
              {transacciones.length === 0 && isLoading === false && (
                <tr className="bg-white dark:bg-gray-700 dark:text-white text-center font-normal">
                  <td colSpan={6} style={{ padding: "15px" }}>
                    <span className="flex flex-row gap-2 items-center justify-center">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      No se encontraron datos para la busqueda
                    </span>
                  </td>
                </tr>
              )}
              {isLoading && (
                <tr className="bg-white dark:bg-gray-700 text-center font-normal">
                  <td colSpan={8} style={{ padding: "15px" }}>
                    <ClipLoader color="#d8d4d4" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <PaginacionInventario parametroPaginacion={parametroPaginacion} elementos={transacciones} infoPaginacion={infoPaginacion} setParametroPaginacion={setParametroPaginacion}/>
      </div>
    </>
  );
};
