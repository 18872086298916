import { useContext, useState, useEffect } from "react";
import {
  ObtenerCategoriasActivas,
  ObtenerTerminoProducto,
  ObtenerTipoProducto,
  ObtenerUsoProducto,
} from "../../../../services/InventarioService";
import { InputGlobal } from "../../context/inputContext";
import { SelectGlobal } from "../../context/selectContext";
import { CategoriaContext } from "../../Categorias/context/CategoriaContext";
import { useFormContext } from "react-hook-form";
import AutoCompleteInput from "../../context/AutoCompleteSelect";
import { AlfaNumerico } from "../../regex.d";
import { handleKeyPress } from "../../TiposOperaciones/Transacciones/Ingreso/IngresoDetalle";
import { useModalProductoStore } from "../Crear/ModalProductoContext";
import { useSelector } from "react-redux";
import {
  ObtenerConfiguracionInventarioEmpresa,
  ObtenerElementoConfiguracion,
} from "../../../../services/InventarioService";
import { useStore } from "zustand";

export const EditarProductGeneralForm = () => {
  const { ProductoAfectado } = useStore(useModalProductoStore);
  const [medidasEmpresa, setMedidasEmpresa] = useState([]);
  const [medidas, setMedidas] = useState([]);
  const empresa = useSelector((state) => state.empresa.empresa);
  const [terminoProducto, setTerminoProducto] = useState([]);
  const [tipoProducto, setTipoProducto] = useState([]);
  const [UsoProducto, setUsoProducto] = useState([]);
  const [TrazabilidadEmpresa, setTrazabilidadEmpresa] = useState([]);
  const [categoriasActivas, setCategoriasActivas] = useState([]);
  const { watch, setValue } = useFormContext();

  useEffect(() => {
    if (empresa && empresa.idEmpresa) {
      ObtenerConfiguracionInventarioEmpresa(
        "/api/unidad-medida/obtener-unidad-medida-empresa",
        empresa.idEmpresa
      ).then((response) => setMedidasEmpresa(response.data));
    }
  }, [empresa]);

  useEffect(() => {
    if (empresa && empresa.idEmpresa) {
      ObtenerCategoriasActivas(
        "/api/categoria/obtener-categorias-activas",
        empresa.idEmpresa
      ).then((response) => setCategoriasActivas(response.data));
    }
  }, [empresa]);

  useEffect(() => {
    if (empresa && empresa.idEmpresa) {
      ObtenerConfiguracionInventarioEmpresa(
        "/api/trazabilidad/obtener-trazabilidad-empresa",
        empresa.idEmpresa
      ).then((response) => setTrazabilidadEmpresa(response.data));
    }
  }, [empresa]);

  useEffect(() => {
    ObtenerElementoConfiguracion(
      "/api/unidad-medida/obtener-unidad-medida"
    ).then((response) => setMedidas(response.data));
  }, []);

  useEffect(() => {
    ObtenerTerminoProducto().then((res) => {
      setTerminoProducto(res.data);
    });
  }, []);

  useEffect(() => {
    if (terminoProducto.length > 0 && ProductoAfectado.idTerminoProducto) {
      setValue("termino", ProductoAfectado.idTerminoProducto);
    }
  }, [terminoProducto, ProductoAfectado]);

  useEffect(() => {
    ObtenerTipoProducto().then((res) => {
      setTipoProducto(res.data);
    });
  }, []);

  useEffect(() => {
    if (tipoProducto.length > 0 && ProductoAfectado.idTipoProducto) {
      setValue("tipoProducto", ProductoAfectado.idTipoProducto);
    }
  }, [tipoProducto, ProductoAfectado]);

  useEffect(() => {
    ObtenerUsoProducto().then((res) => {
      setUsoProducto(res.data);
    });
  }, []);

  useEffect(() => {
    if (medidas.length > 0 && ProductoAfectado.idUnidadesMedida)
      setValue("medidaProducto", ProductoAfectado.idUnidadesMedida);
  }, [medidas, medidasEmpresa]);

  useEffect(() => {
    if (UsoProducto.length > 0 && ProductoAfectado.idUsoProducto) {
      setValue("usoProducto", ProductoAfectado.idUsoProducto);
    }
  }, [UsoProducto, ProductoAfectado]);

  const minimoStock = watch("isMinimoStock");

  return (
    <div className="flex flex-col">
      <p className="bg-[#003B5B] text-white flex items-center flex-row gap-2 font-semibold rounded-t-lg p-3">
        Datos Generales
      </p>
      <div className="w-full grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-1 gap-4 p-8 pl-8 pr-8 border-gray-200 dark:border-gray-600 border rounded-b-lg text-[12px]">
        <InputGlobal
        disabled={ProductoAfectado.hasTransaction}
          title={"Código"}
          max={30}
          validations={{
            required: true,
            pattern: AlfaNumerico,
            maxLength: 30,
          }}
          name={"codigo"}
        />
        <InputGlobal
          title={"Nombre del Producto"}
          max={100}
          validations={{
            required: true,
            maxLength: 100,
          }}
          name={"nombreProducto"}
        />
        <InputGlobal
          title={"Detalle del Producto"}
          max={100}
          validations={{
            pattern: AlfaNumerico,
            maxLength: 100,
          }}
          specificStyle={"md:col-span-2"}
          name={"detalleProducto"}
        />
        <SelectGlobal
         disabled={ProductoAfectado.hasTransaction}
          title={"Término"}
          validations={{ required: true }}
          name={"termino"}
        >
          {terminoProducto.length > 0 && (
            <>
              {terminoProducto.map((option) => (
                <option
                  key={option.idTerminoProducto}
                  value={option.idTerminoProducto}
                >
                  {option.nombre}
                </option>
              ))}
            </>
          )}
          {terminoProducto.length === 0 && (
            <option value="" selected hidden>
              Cargando Opciones....
            </option>
          )}
        </SelectGlobal>
        <SelectGlobal
          disabled={ProductoAfectado?.isSubProducto || ProductoAfectado?.hasTransaction}
          title={"Tipo de Producto"}
          name={"tipoProducto"}
        >
          {tipoProducto.length > 0 && (
            <>
              {tipoProducto.map((option) => (
                <option
                  key={option.idTipoProducto}
                  value={option.idTipoProducto}
                >
                  {option.nombre}
                </option>
              ))}
            </>
          )}
          {tipoProducto.length === 0 && (
            <option value="" selected hidden>
              Cargando Opciones....
            </option>
          )}
        </SelectGlobal>
        <SelectGlobal   disabled={ProductoAfectado.hasTransaction} title={"Uso de Producto"} name={"usoProducto"}>
          {UsoProducto.length > 0 && (
            <>
              {UsoProducto.map((option) => (
                <option key={option.idUsoProducto} value={option.idUsoProducto}>
                  {option.nombre}
                </option>
              ))}
            </>
          )}
          {UsoProducto.length === 0 && (
            <option value="" selected hidden>
              Cargando Opciones....
            </option>
          )}
        </SelectGlobal>
        <AutoCompleteInput
          title={"Categoria"}
          name={"categoria"}
          validations={{ required: true }}
          active={true}
          array={categoriasActivas.filter((c) => c.estado === true)}
          parametro={"nombre"}
          option={(e) => e["nombre"]}
          identificador={"idCategoriaProducto"}
        />
        <InputGlobal
          disabled={ProductoAfectado.hasTransaction || ProductoAfectado.codigoBarra !== ""}
          title={"Código de Barras"}
          max={30}
          validations={{ pattern: AlfaNumerico, maxLength: 30 }}
          name={"codigoBarras"}
        />
        {TrazabilidadEmpresa.includes(
          "dc1bb646-0470-4eac-95cb-8f0fb4a3e558"
        ) && (
          <SelectGlobal title={"Control de Lote"} name={"controlLote"}>
            <option value="true">Si</option>
            <option value="false">No</option>
          </SelectGlobal>
        )}
        {TrazabilidadEmpresa.includes(
          "db8ef384-8263-4831-be1e-3dd5a4bcba90"
        ) && (
          <SelectGlobal
            title={"Control de Fecha de Expiración"}
            name={"controlFechaCaducidad"}
          >
            <option value="true">Si</option>
            <option value="false">No</option>
          </SelectGlobal>
        )}
        <SelectGlobal
          title={"Medida"}
          disabled={true}
          validations={{ required: true }}
          name={"medidaProducto"}
        >
          {medidas.length > 0 && (
            <>
              {medidas.map((option) => (
                <option
                  key={option.idUnidadMedida}
                  value={option.idUnidadMedida}
                >
                  {option.nombre}
                </option>
              ))}
            </>
          )}
          {medidas.filter((medida) =>
            medidasEmpresa.includes(medida.idUnidadMedida)
          ).length === 0 && (
            <option value="" selected hidden>
              Cargando Opciones....
            </option>
          )}
        </SelectGlobal>
        <SelectGlobal title={"Minimo de Stock"} name={"isMinimoStock"}>
          <option value="true">Si</option>
          <option value="false">No</option>
        </SelectGlobal>
        {minimoStock === "true" && (
          <InputGlobal
            title={"Limite de Stock"}
            name={"alertaStock"}
            pressfn={handleKeyPress}
            min={1}
            type={"number"}
            validations={{ required: true }}
          />
        )}
        {/* <AutoCompleteInput
          title={"Sección"}
          name={"seccion"}
          searchFn={searchUbicacion}
          validations={{ required: true }}
          array={secciones}
          option={(e) => `${String(e["nombreBodega"])} - ${e["nombre"]}`}
          identificador={"idSeccion"}
        /> */}
      </div>
    </div>
  );
};
