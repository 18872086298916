export const InventarioSubMenus = [
  {
    nameMenu: "Bodegas",
    item: "subItem1",
    subMenus: [
      {
        nameSubMenu: "Crear",
        route: "/inventario/bodegas/crear",
      },
    ],
  },
  {
    nameMenu: "Secciónes",
    item: "subItem2",
    subMenus: [
      {
        nameSubMenu: "Crear",
        route: "/inventario/secciones/crear",
      },
    ],
  },
  {
    nameMenu: "Categorías de Productos",
    item: "subItem3",
    subMenus: [
      {
        nameSubMenu: "Crear",
        route: "/inventario/categorias/crear",
      },
    ],
  },
  {
    nameMenu: "Productos",
    item: "subItem4",
    subMenus: [
      {
        nameSubMenu: "Crear",
        route: "/inventario/productos/crear",
      },
    ],
  },
  {
    nameMenu: "Tipos de Operaciones",
    item: "subItem5",
    subMenus: [
      {
        nameSubMenu: "Transacciones",
        route: "inventario/operaciones/transacciones",
      },
      {
        nameSubMenu: "Transacciones Emitidas",
        route: "inventario/operaciones/transaccionesEmitidas",
      },
      //   {
      //     nameSubMenu: "Compras de Inventario",
      //     route: "inventario/operaciones/comprasInventario",
      //   },
    ],
  },
  {
    nameMenu: "Informes de Inventario",
    item: "subItem6",
    subMenus: [
      //   {
      //     nameSubMenu: "Análisis de inventario",
      //     route: "inventario/informes/analisis",
      //   },
      {
        nameSubMenu: "Kardex Valorado",
        route: "inventario/informes/kardex",
      },
      //   {
      //     nameSubMenu: "Informe de Inventario",
      //     route: "inventario/informes/inventario",
      //   },
      {
        nameSubMenu: "Valoración de Inventario",
        route: "inventario/informes/valoracion",
      },
      //   {
      //     nameSubMenu: "Movimientos de Productos",
      //     route: "inventario/informes/movimientos",
      //   },
    ],
  },
  // {
  //     nameMenu: "Estadisticas de Inventario",
  //     item: "subItem7",
  //     subMenus: []
  // }
];
